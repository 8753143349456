import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Redirect, Route, Switch } from "react-router-dom";
import { history } from "../../store";
import * as sidebarAndDrawerActions from "../../actions/sidebarAndDrawer";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import OpenPanel from "@material-ui/icons/NavigateNext";
import { Box, CircularProgress, FormControl, InputBase, MenuItem, Select, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import toastr from "../../utils/customToastr";
import toolsDict, { layerSelectorTool } from "../../constants/tools";
import { getAppsSelector } from "../../selectors/appsSelectors";
import { getTools } from "../../selectors/toolsSelectors";
import { makeStyles } from "@material-ui/styles";
import { getIsSidebarOpen } from "../../selectors/sidebarAndDrawer";

const AppsInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: "1.05em",
        padding: "4px 10px 4px 10px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        display: "flex",
        alignItems: "center",
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
        }
    }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        transition: "all 0.4s ",
        top: 16,
        [theme.breakpoints.down("xs")]: {
            right: 4,
            left: 4,
            maxWidth: 450
        }
    },
    appBar: {
        boxShadow: "none"
    },
    content: {
        fontSize: 20
    },
    logo: {
        height: 22
    },
    toolbar: {
        backgroundColor: "white",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 16px",
        minHeight: 50,
        height: 50
    },
    appsMenu: {
        height: 35
    },
    formControl: {
        width: "45%"
    },
    appName: {
        fontSize: "0.785rem"
    }
}));

const SidebarRoot = ({ match, appName }) => {
    const classes = useStyles();
    const sidebarState = useSelector(getIsSidebarOpen);
    const apps = useSelector(getAppsSelector);
    const tools = useSelector(getTools);

    const dispatch = useDispatch();

    const redirectToHomePage = () => {
        history.push(document.location.pathname);
        history.go(0);
    };

    const onAppClick = (app) => {
        if (history.location.pathname !== `/${app.id}/map/layer-selector`) {
            if (app.isPublished) {
                history.push(`/${app.id}/map/layer-selector`);
            } else {
                toastr.warning("That app has not been published yet!");
            }
        }
    };

    const toolRoutes = tools.map((tool) => {
        const correspondingTool = toolsDict[tool.name];
        return (
            tool.enabled && (
                <Route
                    path={`${match.path}/${correspondingTool.routeSuffix}`}
                    component={correspondingTool.component}
                    key={correspondingTool.name}
                />
            )
        );
    });

    return (
        <div className="sidebar-wrapper">
            <div className={sidebarState ? "sidebar" : "sidebar hide"}>
                <div className="collapse-button" onClick={() => dispatch(sidebarAndDrawerActions.toggleSidebarOpen())}>
                    <OpenPanel
                        className={`collapse-button__icon ${sidebarState && `rotate180`}`}
                        style={{ fontSize: "1.7rem" }}
                    />
                </div>
                {/* <AppBar position="static" color="primary" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <img
                            onClick={redirectToHomePage}
                            src="/media/Lautec_WindGIS-ProductLogo_Black.svg"
                            className={classes.logo}
                            alt="Logo"
                        ></img>

                        {appName ? (
                            <FormControl className={classes.formControl}>
                                <Select
                                    id="apps-menu-select"
                                    value={appName}
                                    input={<AppsInput />}
                                    className={classes.appsMenu}
                                >
                                    {apps.map((app) => (
                                        <MenuItem key={app.name} value={app.name} onClick={() => onAppClick(app)}>
                                            <Typography variant="body2" noWrap className={classes.appName}>
                                                {app.name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <Skeleton variant="rect" className={classes.formControl} height={35} />
                        )}
                    </Toolbar>
                </AppBar> */}
                {!appName && (
                    <Box paddingTop={15} display="flex" flexDirection="column" alignItems="center">
                        <CircularProgress size={50} />
                        <Typography variant="h6" align="center">
                            Getting your app ready
                        </Typography>
                    </Box>
                )}
                {appName && (
                    <Switch>
                        <Route
                            exact
                            path={`${match.path}/${layerSelectorTool.routeSuffix}`}
                            component={layerSelectorTool.component}
                        />
                        {toolRoutes}
                        <Redirect to={`${match.path}/${layerSelectorTool.routeSuffix}`} />
                    </Switch>
                )}
            </div>
        </div>
    );
};

export default SidebarRoot;

import { makeStyles } from "@material-ui/core";

export const useInfoTableDownloadStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        height: "auto",
        paddingBottom: 20,
        outline: "none"
    },
    modalHeader: {
        backgroundColor: "#024F79",
        padding: theme.spacing(2),
        color: "#fff",
        textAlign: "center"
    },
    headerText: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff"
    },
    layerName: {
        padding: 5,
        fontSize: "1.25rem",
        color: "#e0e0e0"
    },
    downloadDialogSelectContainer: {
        padding: 20
    },
    downloadDialogSelect: {
        width: "40%"
    },
    modalText: {
        textAlign: "center",
        padding: 20
    },
    layerNameDownloadModal: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        fontWeight: "bold"
    },
    radioGroupContainer: {
        padding: 10
    }
}));

import { createSelector } from "reselect";
import widgetTypes from "../utils/tools/widgets";

const toolsState = (state) => state.tools;

export const getTools = createSelector(toolsState, (toolsState) => toolsState.tools);

export const getWidgets = createSelector(toolsState, (toolsState) => toolsState.widgets);

export const getPrintToggleStatus = createSelector(
    toolsState,
    (toolsState) => !!toolsState.widgets.find((tool) => tool.name === widgetTypes.PRINT)?.toggled
);

export const getSearchWidgetToggledStatus = createSelector(
    toolsState,
    (toolsState) => !!toolsState.widgets.find((tool) => tool.name === widgetTypes.SEARCH)?.toggled
);

import React, { useEffect, useState } from "react";
import { Modal, Fade, Typography, Backdrop, makeStyles, Grid, Paper, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getLayerMetadata, getMetadataSchema } from "../../../actions/layerSelector";
import toastr from "../../../utils/customToastr";
import dayjs from "dayjs";

const rootHeight = 440;
const headerHeight = 64;
const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 500,
        width: "95%",
        height: rootHeight,
        outline: "none",
        display: "flex",
        flexDirection: "column"
    },
    modalHeader: {
        backgroundColor: "#024F79",
        padding: theme.spacing(2, 4),
        textAlign: "center",
        height: headerHeight
    },
    headerText: {
        textAlign: "center",
        color: "#fff",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    metadataContainer: {
        height: rootHeight - headerHeight,
        overflow: "auto",
        padding: "16px"
    },
    tagList: {
        display: "flex",
        flexWrap: "wrap"
    },
    tag: {
        padding: "0 10px",
        borderRadius: "15px",
        display: "flex",
        alignItems: "center",
        marginRight: "4px",
        marginBottom: "4px",
        height: "30px",
        fontSize: "13px"
    },
    placeholderContainer: {
        display: "flex",
        alignContent: "center",
        height: "80%",
        justifyContent: "center",
        alignItems: "center"
    },
    loaderContainer: {
        flexDirection: "column"
    }
}));

const LayerMetadata = ({ open, handleClose, layer }) => {
    const classes = useStyles();
    const [metadata, setMetadata] = useState([]);
    const [loadingMetadata, setLoadingMetadata] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setMetadata([]);
        if (layer.resourceId && open) {
            setLoadingMetadata(true);
            dispatch(getLayerMetadata(layer.resourceId))
                .then((metaResponse) => {
                    handleMetadataResponse(metaResponse);
                })
                .catch((err) => {
                    toastr.error(err);
                })
                .finally(() => {
                    setLoadingMetadata(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layer]);

    const handleMetadataResponse = (metadataResponse) => {
        dispatch(getMetadataSchema())
            .then((schemaResponse) => {
                initMetadata(metadataResponse.metadata, schemaResponse.schema);
            })
            .catch((err) => {
                toastr.error(err);
            })
            .finally(() => {
                setLoadingMetadata(false);
            });
    };

    const initPropertyValue = (metadata, datasetMetadataTagMap, datasetMetadataMap) => {
        if (metadata.type === "tag_list") {
            metadata.value.forEach((tag) => {
                tag.enabled = !!datasetMetadataTagMap[tag.id]?.enabled;
            });
        } else {
            metadata.value = datasetMetadataMap[metadata.id].value;
        }
    };

    const initMetadata = (meta, schema) => {
        const datasetMetadataMap = meta.reduce((a, b) => {
            a[b.id] = b;
            return a;
        }, {});

        const metadataSchemaMap = schema.reduce((a, b) => {
            a[b.id] = b;
            return a;
        }, {});

        const datasetMetadataTagMap = meta.reduce((a, b) => {
            if (metadataSchemaMap.hasOwnProperty(b.id) && metadataSchemaMap[b.id].type === "tag_list") {
                b.value.forEach((tag) => {
                    a[tag.id] = tag;
                });
            }
            return a;
        }, {});

        schema.forEach((metadata) => {
            if (!datasetMetadataMap.hasOwnProperty(metadata.id)) {
                return;
            }
            initPropertyValue(metadata, datasetMetadataTagMap, datasetMetadataMap);
        });

        const usedSchema = schema.filter((schema) => {
            if (schema.type === "tag_list") {
                const enabled = schema.value.filter((x) => x.enabled);
                return enabled.length;
            }

            return schema.value && schema.value !== "";
        });

        setMetadata(usedSchema);
    };

    const lowerOpacity = (rgbaString, opacity = "0.15") => {
        if (rgbaString === "red") {
            //for backwards compatibility
            rgbaString = "rgba(255,0,0,1)";
        }

        let splitted = rgbaString.split(",");
        splitted[3] = opacity + ")";
        return splitted.join(",");
    };

    const renderValue = (item) => {
        switch (item.type) {
            case "text":
            case "number":
                return <Typography>{item.value}</Typography>;
            case "date":
                return <Typography>{dayjs(item.value).format("DD/MM/YYYY")}</Typography>;
            case "tag_list":
                return (
                    <div className={classes.tagList}>
                        {item.value.map((tag, index) =>
                            tag.enabled ? (
                                <div
                                    key={index}
                                    style={{ color: tag.color, backgroundColor: lowerOpacity(tag.color) }}
                                    className={classes.tag}
                                >
                                    <span style={{ filter: "brightness(0.8)" }}>{tag.name}</span>
                                </div>
                            ) : null
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    const renderItem = (item) => {
        return (
            <Grid container spacing={3} key={item.name}>
                <Grid item xs={4}>
                    <Typography style={{ fontWeight: "bold" }}>{item.name}</Typography>
                </Grid>
                <Grid item xs={8}>
                    {renderValue(item)}
                </Grid>
            </Grid>
        );
    };

    const renderMetadata = () => {
        if (!metadata) return;
        return metadata.map((x) => renderItem(x));
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open}>
                <Paper elevation={3} className={classes.root}>
                    <Grid container>
                        <Grid item xs={12} className={classes.modalHeader}>
                            <Typography className={classes.headerText} variant="h6">
                                Metadata of {layer.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.metadataContainer}>
                            {loadingMetadata ? (
                                <div className={[classes.placeholderContainer, classes.loaderContainer].join(" ")}>
                                    <CircularProgress size={30} />
                                    <Typography fontSize="4rem" align="center" color="textSecondary">
                                        Loading
                                    </Typography>
                                </div>
                            ) : metadata.length > 0 ? (
                                renderMetadata()
                            ) : (
                                <div className={classes.placeholderContainer}>
                                    <Typography align="center" color="textSecondary">
                                        No metadata available for the current layer
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Fade>
        </Modal>
    );
};

export default LayerMetadata;

import { makeStyles } from "@material-ui/core";

export const useMapPreviewStyles = makeStyles(() => ({
    root: {
        height: "90vh",
        display: "grid",
        gridTemplateColumns: ({ orientation }) =>
            orientation === "portrait" ? "100% min-content" : "minmax(80%, 100%) min-content",
        gridTemplateRows: ({ orientation }) =>
            orientation === "portrait" ? "minmax(70%,100%) min-content" : "100% min-content",
        gridTemplateAreas: '"modalMap legendLandscape" "legendPortrait legendLandscape"'
    },
    loaderContainer: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    modalMapContainer: {
        gridArea: "modalMap",
        position: "relative",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100%",
        "& .mapboxgl-ctrl-attrib": {
            display: "none"
        },
        "& .mapboxgl-ctrl-scale": {
            marginBottom: 25,
            marginRight: 10,
            fontSize: "0.5rem"
        }
    },
    mapContainerPortrait: {
        height: "100%",
        overflowX: "overlay"
    },
    mapContainerLandscape: {
        overflowX: "overlay",
        height: "100%"
    },
    modalLegendLandscape: {
        backgroundColor: "#fff",
        gridArea: "legendLandscape",
        minWidth: "25vh"
    },
    modalLegendPortrait: {
        backgroundColor: "#fff",
        maxHeight: "27vh",
        gridArea: "legendPortrait"
    },
    labels: {
        padding: 8,
        maxHeight: "45vh",
        overflow: "auto"
    },
    label: {
        fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"],
        display: "flex",
        alignItems: "center",
        padding: "2%"
    },
    selectedLabel: {
        display: "flex",
        alignItems: "center",
        padding: 8,
        backgroundColor: "lightgray"
    },
    multiLabel: {
        marginLeft: 8,
        flex: "1 1 auto",
        maxHeight: "80%"
    },
    multiLabelHeaderText: {
        paddingTop: 6,
        paddingBottom: 4,
        marginLeft: "3%",
        fontSize: 10,
        maxWidth: "100%",
        minWidth: 0
    },
    labelText: {
        flexGrow: 1,
        fontSize: 10,
        maxWidth: "10vw",
        minWidth: 0,
        textTransform: "capitalize"
    },
    multiLabelText: {
        flexGrow: 1,
        fontSize: 10,
        minWidth: 0,
        textTransform: "capitalize"
    },
    colorContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    colorBox: {}
}));

import React from "react";
import { useSelector } from "react-redux";
import { getMapZoomLevel } from "../../../../selectors/mapSelectors";
import { useZoomLevelStyles } from "./styles";

const ZoomLevelWidget = () => {
    const classes = useZoomLevelStyles();
    const zoomLevel = useSelector(getMapZoomLevel);
    return (
        <div className={classes.root}>
            <div className={classes.zoomContainer}> Zoom: {zoomLevel.toFixed(2) || "Loading"}</div>
        </div>
    );
};

export default ZoomLevelWidget;

import { createSelector } from "reselect";

const layerSelectorState = (state) => state.layerSelector;

const layerGroupsSelector = (state) => state.layerSelector.layerGroups;

const layerVisibilityMap = (state) => state.layerSelector.layerVisibilityMap;

const layerStylesMap = (state) => state.layerSelector.layerStylesMap;

const stylerLayerId = (state) => state.layerSelector.stylerLayerId;

export const getStylerLayerId = stylerLayerId;

export const getLayerForStyling = createSelector(layerGroupsSelector, stylerLayerId, (layerGroups, stylerLayerId) =>
    layerGroups.getRecursive(stylerLayerId)
);

export const getFetchingLayers = (state) => state.layerSelector.loading;

export const getFetchingLayerAttributes = (state) => state.layerSelector.fetchingAttributes;

export const getLayerGroups = createSelector(
    layerSelectorState,
    (layerSelectorState) => layerSelectorState.layerGroups
);

export const getLayerVisibility = (layerId) =>
    createSelector(layerVisibilityMap, (layerVisibilityMap) => layerVisibilityMap[layerId]);

export const getLayerStyles = (layerId) => createSelector(layerStylesMap, (layerStylesMap) => layerStylesMap[layerId]);

export const getLayerVisibilityMap = layerVisibilityMap;

export const getLayersError = createSelector(layerSelectorState, (layerSelectorState) => layerSelectorState.error);

export const getLayerData = createSelector(layerSelectorState, (layerSelectorState) => layerSelectorState.layerData);

export const getLayerStylesMap = layerStylesMap;

export const getStylesForLegend = createSelector(layerGroupsSelector, layerStylesMap, (layerGroups, layerStylesMap) => {
    const legendStyles = [];
    layerGroups.forLayersRecursive((layer) => {
        const styles = layerStylesMap[layer.resourceId];

        if (!styles?.length) return;
        const firstStyle = styles[0];
        legendStyles.push({
            paint: firstStyle?.properties.filter((prop) => prop.type === "paint") || [],
            type: firstStyle.type,
            layerId: firstStyle.styleId,
            datasetId: layer.resourceId,
            name: layer.name,
            sourceName: layer.sourceName
        });
    });
    return legendStyles;
});

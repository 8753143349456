import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tab, Tabs } from "@material-ui/core";

const StyledTabs = withStyles({
    root: {
        backgroundColor: "white",
        height: 48
    },
    flexContainer: {
        height: 48
    },
    indicator: {
        display: "flex",
        justifyContent: "center"
    }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        padding: 0,
        height: 48,
        minHeight: 48,
        textTransform: "none",
        fontSize: 16,
        "&:focus": {
            opacity: 1
        },
        "&:hover": {
            backgroundColor: theme.palette.background
        }
    },
    labelIcon: {
        margin: 0
    },
    wrapper: {
        flexDirection: "row",
        "& > *:first-child": {
            marginBottom: "0px !important",
            marginRight: 8,
            fontSize: 16
        }
    }
}))((props) => <Tab {...props} />);

export { StyledTabs, StyledTab };

import { makeStyles } from "@material-ui/core";

const useGroupStyles = makeStyles((theme) => ({
    root: {
        "&:not(:last-child)": {
            marginBottom: 8
        }
    },
    borderBox: {
        borderLeft: "1px solid #E3E4E5",
        marginLeft: 12
    },
    layerContainer: {
        padding: 16,
        paddingTop: 12,
        paddingBottom:12,
        backgroundColor: "white"
    },
    innerLayerContainer: {
        paddingTop: 16,
        marginLeft: 4
    },
    groupName: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        textTransform: "capitalize",
        minWidth: 200,
        fontWeight: "bold"
    },
    subGoupName: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        textTransform: "capitalize",
        minWidth: 200
    },
    layerCount: {
        color: theme.palette.primary.main,
        fontSize: 14
    },
    layerCountSeperator: {
        backgroundColor: "#D8D8D8",
        height: 24,
        width: 1,
        marginRight: 16,
        marginLeft: 8
    },
    squareBtn: {
        borderRadius: 0,
        height: 24,
        width: 24
    },
    collapseBtn: {
        borderRadius: 0,
        height: 24,
        width: 24,
        color: "#000000",
        marginRight: 16
    },
    innerCollapseBtn: {
        borderRadius: 0,
        height: 24,
        width: 24,
        marginRight: 8,
        color: "#000000"
    },
    deleteBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        color: "#ED3D3E",
        backgroundColor: "#FCE2E1"
    },
    actions: {},
    grow: {
        flexGrow: 1
    },
    groupSeperator: {
        backgroundColor: "#d0c9c9"
    },
    expandedRight: {
        transform: "rotate(-180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandedLeft: {
        transform: "rotate(180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    collapsed: {
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    }
}));

export default useGroupStyles;

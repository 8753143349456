import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { IconButton, Tooltip, Menu, MenuItem } from "@material-ui/core";
import { AspectRatio, AspectRatioTwoTone, FileCopyOutlined } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import { getMap } from "../map";
import proj4 from "proj4";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import { useCoordinatesWidgetStyles } from "./styles/coordinatesWidgetStyles";
import toastr from "../../../utils/customToastr";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CoordinatesWidget = (props) => {
    const classes = useCoordinatesWidgetStyles();
    const [pointerCoordinates, setPointerCoordinates] = useState({});
    const [capturingMapCoordinates, setCapturingMapCoordinates] = useState(false);
    const projection = useRef("EPSG:4326");
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        if (capturingMapCoordinates) {
            getMap().off("mousemove", updateCursorCoordinates);
            getMap().on("click", updateCursorCoordinates);
        } else {
            getMap().off("click", updateCursorCoordinates);
            getMap().on("mousemove", updateCursorCoordinates);
        }

        return () => {
            getMap().off("mousemove", updateCursorCoordinates);
            getMap().off("click", updateCursorCoordinates);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [capturingMapCoordinates]);

    function updateCursorCoordinates(e) {
        let source = new proj4.Proj("EPSG:4326");
        let dest = new proj4.Proj(projection.current);

        var coordinates = proj4(source, dest, [e.lngLat.lng, e.lngLat.lat]);
        setPointerCoordinates({ lng: coordinates[0], lat: coordinates[1] });
    }

    const handleCapturingMapCoordinates = () => {
        setCapturingMapCoordinates(!capturingMapCoordinates);
    };

    const copyCoordinatesToClipboard = () => {
        let coordinatesToBeCopied = `${pointerCoordinates.lng.toFixed(7)},${pointerCoordinates.lat.toFixed(7)}`;
        let dummyHtmlElement = document.createElement("input");
        document.body.appendChild(dummyHtmlElement);
        dummyHtmlElement.setAttribute("value", coordinatesToBeCopied);
        dummyHtmlElement.select();
        document.execCommand("copy");
        document.body.removeChild(dummyHtmlElement);
        toastr.success("Coordinates copied to clipboard.");
    };

    const handleClickProjections = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseProjections = () => {
        setAnchorEl(null);
    };

    function onProjectionChanged(item) {
        projection.current = item.crs;

        setAnchorEl(null);
    }

    return (
        <div className={classes.root}>
            <div className={classes.coordinatesText}>X : {pointerCoordinates.lng?.toFixed(7) || "N/A"}</div>
            <div className={classes.coordinatesText}>Y : {pointerCoordinates.lat?.toFixed(7) || "N/A"}</div>
            <Tooltip title="CRS">
                <IconButton size="small" onClick={handleClickProjections}>
                    <GpsFixedIcon className={classes.iconStyle} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Capture map coordinates of clicked point">
                <IconButton size="small" onClick={handleCapturingMapCoordinates}>
                    {capturingMapCoordinates ? (
                        <AspectRatioTwoTone className={classes.iconStyle} />
                    ) : (
                        <AspectRatio className={classes.iconStyle} />
                    )}
                </IconButton>
            </Tooltip>
            <Tooltip title="Copy coordinates">
                <div>
                    <IconButton size="small" disabled={!capturingMapCoordinates} onClick={copyCoordinatesToClipboard}>
                        <FileCopyOutlined className={classes.iconStyle} />
                    </IconButton>
                </div>
            </Tooltip>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseProjections}
            >
                {props.config.projections.map((item, index) => {
                    return (
                        <MenuItem
                            key={index}
                            className={item.crs === projection.current ? classes.selected : ""}
                            onClick={() => onProjectionChanged(item)}
                        >
                            <span className={classes.crsBox}>{item.crs}</span>
                            <div className={classes.crsName}> {item.name}</div>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

const mapStateToProps = (state) => ({
    config: state.config.config
});

export default connect(mapStateToProps)(CoordinatesWidget);

const WTG_POSITIONS = [
    [7.589148921373777, 57.00746696864809],
    [7.600815393390405, 57.012845183475662],
    [7.611479356385844, 57.01769765549205],
    [7.624713632039329, 57.023719708671791],
    [7.636278035795262, 57.028981912379336],
    [7.649086229691778, 57.03481008362202],
    [7.660294864195068, 57.039910400014001],
    [7.6728541598391, 57.045625313942715],
    [7.68392006596823, 57.050660683987637],
    [7.708458128374444, 57.061826351124061],
    [7.721029843891036, 57.067546916524385],
    [7.732700417525293, 57.072857431198848],
    [7.746432489683743, 57.079105999006529],
    [7.760152843091473, 57.085349234377304],
    [7.774548877630491, 57.09189992803379],
    [7.790508614188735, 57.099162160195569],
    [7.600155003597838, 57.000678165211276],
    [7.612283354328108, 57.00630385405001],
    [7.624619904695326, 57.012026115489576],
    [7.638014579185838, 57.018239183734018],
    [7.651876333780085, 57.024668905288117],
    [7.666375930016737, 57.031394487234806],
    [7.680098884853022, 57.037759827055019],
    [7.693373478634708, 57.043917196324529],
    [7.719641183475153, 57.056101370185132],
    [7.735429705728188, 57.063424815507133],
    [7.749550340789222, 57.06997461777005],
    [7.765325620310689, 57.077291920501928],
    [7.778849735034594, 57.083565029100782],
    [7.790987445289433, 57.089195059318989],
    [7.80463164072217, 57.095523866892798],
    [7.614035821464163, 56.990375688494581],
    [7.624660600620859, 56.995671650119064],
    [7.638762225934992, 57.002700658948825],
    [7.652675844188328, 57.009635954943263],
    [7.668477325079119, 57.017512263257416],
    [7.68352288171198, 57.025011778247027],
    [7.699135576339028, 57.032793985329192],
    [7.729606210139609, 57.047982188763996],
    [7.744526168997624, 57.055419099064508],
    [7.757620284902659, 57.061945910993195],
    [7.77096676516181, 57.068598514895086],
    [7.785005201987044, 57.075596027181952],
    [7.79747054981489, 57.081809427268546],
    [7.809683533288621, 57.08789703538195],
    [7.631848882352108, 56.980033889347737],
    [7.647088572008855, 56.988203127376892],
    [7.661481803746551, 56.995918621505879],
    [7.677498626035786, 57.004504441604489],
    [7.695723516154596, 57.014273896809264],
    [7.713573053893008, 57.023842144311651],
    [7.744257727977839, 57.040290668664326],
    [7.758707645585226, 57.048036549262662],
    [7.772105810502235, 57.055218637666421],
    [7.785888137090204, 57.062606655994173],
    [7.797501463137163, 57.0688319812419],
    [7.809970056393739, 57.075515772611411],
    [7.821020353870105, 57.081439286305638],
    [7.832550566175684, 57.08762005841929],
    [7.646476826392538, 56.972012113583631],
    [7.691094478948708, 56.996558927211261],
    [7.709056342546107, 57.006440812359209],
    [7.727796983282614, 57.016751148942738],
    [7.762024051086209, 57.035581486404759],
    [7.776411672734213, 57.04349696933371],
    [7.790412837883113, 57.051199839650501],
    [7.803182341174679, 57.05822509982125],
    [7.815976070097533, 57.065263687946803],
    [7.828552152872633, 57.072182536034056],
    [7.844032715798817, 57.080699310701256],
    [7.67242963033524, 56.957856038705785],
    [7.71998280594348, 56.986497802892274],
    [7.747848940947409, 57.003281861104206],
    [7.794431534750029, 57.031339033939112],
    [7.814535681742547, 57.043447967358688],
    [7.839214666837682, 57.058312372572757],
    [7.863064772023466, 57.072677534937135],
    [7.69759598567362, 56.944014543269674],
    [7.761082768953119, 56.986411913170599],
    [7.805414964349141, 57.016017578942829],
    [7.827326998085802, 57.03065074432979],
    [7.847643128206728, 57.044218142758211],
    [7.868901747628638, 57.058414948843769],
    [7.880366641318601, 57.066071366660815],
    [7.727795612079677, 56.927341832857998],
    [7.767439580358057, 56.959103374695061],
    [7.815251671658018, 56.997408968309649],
    [7.817608042693519, 57.091237721999178],
    [7.661293107127467, 56.980163427268216],
    [7.678162029446688, 56.989444020677901],
    [7.683798541077183, 56.964703649961244],
    [7.7026986028705, 56.976087350546322],
    [7.733757220650286, 56.994794273796522],
    [7.713775572565293, 56.954819499748098],
    [7.726908638617549, 56.963589946612608],
    [7.745700384970369, 56.97613934004616],
    [7.739120471981946, 56.936464636668148],
    [7.753905705743245, 56.947789496570408],
    [7.780644958290272, 56.96886631916631],
    [7.839156734452004, 57.01542407654231]
];

export default WTG_POSITIONS;

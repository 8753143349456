import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import MapCopyright from "../../map/printMapExtraInfo/mapCopyright";
import MapTitle from "../../map/printMapExtraInfo/mapTitle";
import MapDate from "../../map/printMapExtraInfo/mapDate";
import MapLegend from "./printLegend";
import MapNotes from "../../map/printMapExtraInfo/mapNotes";
import { getPrintFeatures } from "../../../selectors/printSelectors";
import { getPrintToggleStatus } from "../../../selectors/toolsSelectors";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        backgroundColor: "#fff",
        height: "100%"
    },
    innerRoot: {
        border: "1px solid grey",
        maxHeight: "87vh",
        overflow: "hidden"
    },
    legendTitle: {
        backgroundColor: "#024F79",
        color: "#fff",
        fontSize: 15,
        width: "100%",
        textAlign: "center"
    },
    sectionStyle: {
        backgroundColor: "#024F79",
        color: "#fff",
        fontSize: 20,
        maxHeight: "12vh",
        padding: "0 18px",
        minHeight: 22
    },
    legendContent: {
        height: ({ printFeatures }) =>
            !printFeatures.showMapTitle && !printFeatures.showMapNotes
                ? "76vh"
                : printFeatures.showMapTitle && printFeatures.showMapNotes
                ? "60.8vh"
                : "68.8vh",
        overflow: "hidden"
    }
}));

const MapLegendLandscape = ({ renderedLabels, orientation }) => {
    // eslint-disable-next-line
    const printFeatures = useSelector(getPrintFeatures);
    const modalOpen = useSelector(getPrintToggleStatus);
    const classes = useStyles({ printFeatures });

    return (
        <Grid id="modal-info-landscape" container className={classes.root}>
            <Grid item xs={12} className={classes.innerRoot}>
                <Grid container>
                    <Grid item xs={12} className={classes.sectionStyle}>
                        {printFeatures.showMapTitle && (
                            <MapTitle mapTitle={printFeatures.mapTitle} orientation={orientation} modal={modalOpen} />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="center" alignItems="center">
                            {/* TEMPORARILY DISABLED */}
                            {/* <Grid item xs={4}>
                                {printFeatures.showMapLogo && <MapLogo modal={modalOpen} />}
                            </Grid> */}
                            <Grid item xs={12}>
                                <Grid container justify="space-around" alignItems="center">
                                    {printFeatures.showMapCopyright && (
                                        <MapCopyright
                                            mapCopyright={printFeatures.mapCopyright}
                                            modal={modalOpen}
                                            showMapLegend={printFeatures.showMapLegend}
                                        />
                                    )}
                                    {printFeatures.showMapDate && (
                                        <MapDate
                                            mapDateFormat={printFeatures.mapDateFormat}
                                            modal={modalOpen}
                                            showMapLegend={printFeatures.showMapLegend}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.legendTitle}>Legend</Typography>
                    </Grid>
                    <Grid item xs={12} id="legend-modal" className={classes.legendContent}>
                        <MapLegend labels={renderedLabels} desiredNumberOfEntries={24} />
                    </Grid>
                    <Grid item xs={12}>
                        {printFeatures.showMapNotes && (
                            <MapNotes modal={modalOpen} mapNotes={printFeatures.mapNotes} orientation={orientation} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MapLegendLandscape;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { history } from "./store";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import "./scss/main.scss";
import "./utils/validators/utilFunctions";
import "mapbox-gl/dist/mapbox-gl.css";
import "./App.css";
import MainView from "./views/mapView";
import AppsView from "./views/appsView";
import ReduxToastr from "react-redux-toastr";
import Bootstrap from "./Bootstrap";
import { APPS_VIEW_PATH, ROOT_MAP_VIEW_PATH,ROOT_MAP_VIEW_PATH_WITH_LOCATION } from "./constants/routes";
import config from "./config";

require("@emblautec/rescursive-array-extensions");

function App() {
    return (
        <div className="App">
            {/* <Bootstrap> */}
            <Router history={history}>
                <Switch>
                    <Route exact path={APPS_VIEW_PATH} component={AppsView} />            
                    <Route path={ROOT_MAP_VIEW_PATH_WITH_LOCATION} component={MainView} />
                    <Route path={ROOT_MAP_VIEW_PATH} component={MainView} />

                    <Redirect to={APPS_VIEW_PATH} />
                </Switch>
            </Router>
            {/* </Bootstrap> */}
            <ReduxToastr
                timeOut={4000}
                newestOnTop={true}
                position="top-right"
                getState={(state) => state.toastr} // This is the default
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                closeOnToastrClick
                progressBar
            />
        </div>
    );
}

export default App;

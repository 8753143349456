import { AppBar, Box, Button, Dialog, Divider, Grid, Tab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import config from "../../../config";
import { emptySheet, initialSheet, MAX_SHEETS, MAX_SOCKET_RETRIES } from "../../../constants/zander/constants";
import { generateExcel, reset, setError } from "../../../reducers/zander";
import {
    getZanderError,
    getZanderErrorMessage,
    getZanderExcelLink,
    getZanderIsLoading,
    zanderIsConnectionStarted
} from "../../../selectors/zander";
import { default as toastr, ManagedToastr } from "../../../utils/customToastr";
import { SheetsSchema } from "../../../utils/validators/zander/zander";
import Typography from "../../common/CustomTypography/CustomTypography";
import { StyledTabs } from "./../../customTabs//customTabs";
import { useStyles } from "./styles";
import { sanitizeSheets } from "./utils";
import ZanderSheet from "./zanderSheet/ZanderSheet";

const ZanderModal = ({ open, onClose, lat, long, download }) => {
    const [activeSheet, setActiveSheet] = useState(0);
    const [sheets, setSheets] = useState([{ ...initialSheet }]);
    const [closeIconVisibility, setCloseIconVisibility] = useState(false);
    const isConnectionStarted = useSelector(zanderIsConnectionStarted);
    const isLoading = useSelector(getZanderIsLoading);
    const excelLink = useSelector(getZanderExcelLink);
    const error = useSelector(getZanderError);
    const errorMessage = useSelector(getZanderErrorMessage);
    const dispatch = useDispatch();
    const recaptchaRef = useRef();
    const classes = useStyles();
    //This is needed because we want to show a message untill the download is started
    const managedToastr = new ManagedToastr(dispatch);

    useEffect(() => {
        if (error) {
            toastr.error(errorMessage);
            managedToastr.remove("excelGenerating");
            dispatch(reset());
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, errorMessage]);

    useEffect(() => {
        if (isLoading) {
            managedToastr.info("excelGenerating", "Generating file");
        }

        if (!isLoading && excelLink !== "") {
            managedToastr.remove("excelGenerating");
            toastr.info("Your download should be starting soon");
            download(excelLink);
            dispatch(reset());
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, excelLink]);

    const validateSheets = () => {
        try {
            SheetsSchema.validateSync(sheets);
            return true;
        } catch (error) {
            return false;
        }
    };

    const onAddSheet = () => {
        setActiveSheet(sheets.length);
        setSheets([...sheets, { ...emptySheet }]);
    };

    const onClearSheet = () => {
        sheets.splice(activeSheet, 1, { ...emptySheet });
        setSheets([...sheets]);
    };

    const onSheetChanged = (newSheet, index) => setSheets(sheets.map((sheet, i) => (index === i ? newSheet : sheet)));

    const onRequestDocument = (trial) => {
        if (!isConnectionStarted) {
            if (trial === MAX_SOCKET_RETRIES) {
                dispatch(setError("Failed to connect to the server"));
            } else {
                setTimeout(() => onRequestDocument(trial + 1), 1000);
            }
        }

        recaptchaRef.current
            .executeAsync()
            .then((recaptchaToken) => {
                dispatch(
                    generateExcel({
                        sheets: sanitizeSheets(sheets),
                        lat,
                        long,
                        recaptchaToken
                    })
                );
                recaptchaRef.current.reset();
            })
            .catch(() => {
                dispatch(setError("Failed recaptcha"));
            });
    };

    const onDialogClose = () => {
        onClose();
        setActiveSheet(0);
        setSheets([{ ...initialSheet }]);
    };

    const TabPanel = (props) => <div hidden={!props.open}>{props.open && <div>{props.children}</div>}</div>;

    const handleCloseTab = (e, index) => {
        let length = sheets.length;
        e.stopPropagation();
        const currentSheets = sheets;
        currentSheets.splice(index, 1);
        setSheets([...currentSheets]);

        if (activeSheet === length - 1) {
            setActiveSheet(index - 1);
        }
    };

    const sheetToTab = (index) => (
        <Tab
            className={activeSheet === index ? classes.activeTab : ""}
            key={index}
            value={index}
            onMouseEnter={activeSheet === index && (() => setCloseIconVisibility(true))}
            onMouseLeave={activeSheet === index && (() => setCloseIconVisibility(false))}
            label={
                <div className={classes.tabLabel}>
                    <Typography variant="body1">Table {index + 1}</Typography>
                    {activeSheet === index && sheets.length > 1 && (
                        <CloseIcon
                            className={`${classes.closeTabIcon} ${closeIconVisibility ? classes.closeIconVisible : ""}`}
                            fontSize="small"
                            onClick={(e) => handleCloseTab(e, index)}
                        />
                    )}
                </div>
            }
        />
    );

    const sheetToTabPanel = (sheet, index) => (
        <TabPanel key={index} open={index === activeSheet}>
            <ZanderSheet key={index} sheet={sheet} onSheetChanged={(newSheet) => onSheetChanged(newSheet, index)} />
        </TabPanel>
    );

    const handleTabChange = (_, index) => {
        setActiveSheet(index);
        setCloseIconVisibility(true);
    };

    return (
        <Dialog className={`atlas-dialog ${classes.zanderDialog}`} open={open} onClose={onDialogClose} maxWidth="md">
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                className={`dialog-header `}
            >
                <Grid item>
                    <Typography variant="h6" className="dialog-title">
                        ESOX Zander
                    </Typography>
                </Grid>
                <Grid item>
                    <Box paddingRight={3} display="flex">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={onAddSheet}
                            disabled={sheets.length >= MAX_SHEETS}
                        >
                            <AddIcon className={classes.icon} fontSize="small" />
                            Add table
                        </Button>
                        <Button className={classes.clearButton} variant="text" color="primary" onClick={onClearSheet}>
                            Clear table
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Divider />
            <AppBar position="static" color="inherit">
                <StyledTabs
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    value={activeSheet}
                    scrollButtons="auto"
                    onChange={(_, index) => handleTabChange(_, index)}
                >
                    {sheets.map((_, index) => sheetToTab(index))}
                </StyledTabs>
            </AppBar>
            <Divider />
            <SwipeableViews index={activeSheet}>
                {sheets.map((sheet, index) => sheetToTabPanel(sheet, index))}
            </SwipeableViews>
            <Divider />
            <div className={classes.zanderDialogActions}>
                <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    disabled={isLoading || !validateSheets(sheets)}
                    onClick={() => onRequestDocument(0)}
                    className={classes.spacing}
                >
                    Generate excel file
                </Button>
                <Button size="medium" variant="text" color="primary" onClick={onDialogClose}>
                    Cancel
                </Button>
            </div>
            <ReCAPTCHA sitekey={config.recaptchaKey} size="invisible" ref={recaptchaRef} />
        </Dialog>
    );
};

export default ZanderModal;

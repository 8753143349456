import { CircularProgress, Fade, Grid, IconButton, LinearProgress, Paper, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLayerData, getFetchingLayerAttributes, getLayersError } from "../../../selectors/layerSelector";
import InfoTable from "./infoTable";
import DownloadLayerAttributes from "./infoTableDownload";
import * as layerSelectorActions from "../../../reducers/layerSelector/layerSelector";
import * as layerSelectorThunks from "../../../actions/layerSelector";

import useIfMounted from "../../../utils/customHooks/useIfMounted";
import { useInfoTablePortalStyles } from "./styles/infoTablePortalStyles";
import { generateCancelToken } from "../../../actions/apiClient";

const InfoTablePortal = ({ open, maxHeight, handleClose, selectedLayer, handleOpenDownloadModal }) => {
    const classes = useInfoTablePortalStyles();

    const [drawerHeight, setDrawerHeight] = React.useState(500);

    const [openDownloadAttributesModal, setOpenDownloadAttributesModal] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    const data = useSelector(getLayerData);
    const error = useSelector(getLayersError);
    const fetching = useSelector(getFetchingLayerAttributes);
    const execIfMounted = useIfMounted();

    const activeRequestsCancelTokensRef = useRef([]); //[[tokenKey, cancelToken],[...]]

    const dispatch = useDispatch();

    useEffect(() => {
        const tokenKey = selectedLayer.resourceId + 0;
        const cancelToken = generateCancelToken();

        const req = dispatch(
            layerSelectorThunks.getLayerData({
                datasetId: selectedLayer.resourceId,
                page: 0,
                cancelToken: cancelToken.token
            })
        );

        activeRequestsCancelTokensRef.current = [...activeRequestsCancelTokensRef.current, [tokenKey, cancelToken]];
        req.finally(() => {
            //No matter the result we remove the cancel token
            execIfMounted(() => {
                activeRequestsCancelTokensRef.current = activeRequestsCancelTokensRef.current.filter(
                    (tokenPair) => tokenPair[0] !== tokenKey
                );
            });
        });

        //This cleanup runs after each rerender in which the useEffect executed so it's safe to say
        //that this will clean the tokens and state when the attribute table closes/changes layer
        return () => {
            dispatch(layerSelectorActions.resetLayerData());
            activeRequestsCancelTokensRef.current.forEach((tokenPair) => tokenPair[1].cancel());
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLayer]);

    const handleOpenDownloadAttributesModal = (selected) => {
        setSelectedData(selected);
        setOpenDownloadAttributesModal(true);
    };

    const handleCloseDownloadAttributesModal = () => {
        setOpenDownloadAttributesModal(false);
    };

    const handleMouseDown = (e) => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback((e) => {
        const newHeight = document.body.offsetHeight - e.clientY;
        if (newHeight < document.body.offsetHeight / 2) {
            setDrawerHeight(newHeight);
        }
    }, []);

    return (
        <Fade in={open}>
            <Paper className={classes.root} style={{ height: drawerHeight }}>
                <Suspense
                    fallback={
                        <div className={classes.loaderContainer}>
                            <CircularProgress />
                        </div>
                    }
                >
                    <div id="map-table-dragbar" onMouseDown={handleMouseDown} />
                    <Grid container className={classes.attributeTableContainer}>
                        <Grid item xs={12} className={classes.modalHeader}>
                            <Typography className={classes.headerText} variant="h6">
                                Attribute table - {selectedLayer.name}
                            </Typography>
                            <IconButton className={classes.closeButton} onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                        {fetching && (
                            <Grid item xs={12}>
                                <LinearProgress variant="indeterminate" />
                            </Grid>
                        )}
                        {!error.message?.length && !!data.length ? (
                            <Grid item xs={12} className={classes.tableContainer}>
                                <InfoTable
                                    selectedLayer={selectedLayer}
                                    activeRequestsCancelTokensRef={activeRequestsCancelTokensRef}
                                    handleOpenDownloadModal={handleOpenDownloadModal}
                                    handleOpenDownloadAttributesModal={handleOpenDownloadAttributesModal}
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={12} container justify="center" className={classes.tableContainer}>
                                <Typography variant="body2">
                                    {fetching === true ? "Loading data" : "Layer data not available on the server."}
                                </Typography>
                            </Grid>
                        )}
                        <DownloadLayerAttributes
                            open={openDownloadAttributesModal}
                            selectedData={selectedData}
                            selectedLayer={selectedLayer}
                            handleClose={handleCloseDownloadAttributesModal}
                        />
                    </Grid>
                </Suspense>
            </Paper>
        </Fade>
    );
};
export default InfoTablePortal;

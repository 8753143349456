import React from "react";
import { Menu, MenuItem, Grid, Slider, Typography, withStyles } from "@material-ui/core";
import { getMap } from "../../map/map";
import { useSelector } from "react-redux";
import { getLayerVisibility } from "../../../selectors/layerSelector";

const CustomSlider = withStyles({
    valueLabel: {
        left: "calc(-50% - 5px)",
        fontSize: 11,
        top: -13,
        "& *": {
            background: "transparent",
            color: "#000"
        }
    }
})(Slider);

const Context = ({
    layer,
    onToggle,
    anchorEl,
    closeCMenu,
    toggleCMenu,
    open,
    opacity,
    handleOpacity,
    handleCommitOpacity,
    layerHandlers
}) => {
    const { handleOpenDownloadModal, handleOpenLayerMetadata, handleOpenAttributeTable, onStyleLayer } = layerHandlers;
    const visible = useSelector(getLayerVisibility(layer.resourceId));
    const zoomLayer = (layer, group) => {
        if (layer.bounds) {
            if (!visible) {
                onToggle(layer);
            }
            let bounds = [layer.bounds.coordinates[0][0], layer.bounds.coordinates[0][2]];
            getMap().fitBounds(bounds, { padding: 100 });
        }
    };

    return (
        <Menu
            elevation={2}
            anchorEl={anchorEl}
            onClose={() => {
                closeCMenu();
            }}
            open={open}
        >
            <MenuItem>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Typography variant="caption">0</Typography>
                    </Grid>
                    <Grid item xs>
                        <Grid container alignItems="center">
                            <CustomSlider
                                step={10}
                                marks
                                min={0}
                                max={100}
                                value={opacity}
                                defaultValue={opacity}
                                onChange={handleOpacity}
                                onChangeCommitted={() => handleCommitOpacity(layer)}
                                valueLabelDisplay="on"
                                aria-labelledby="layer-opacity-slider"
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">100</Typography>
                    </Grid>
                </Grid>
            </MenuItem>
            {zoomLayer && (
                <MenuItem
                    onClick={() => {
                        zoomLayer(layer);
                        toggleCMenu();
                    }}
                >
                    Zoom To Layer
                </MenuItem>
            )}
            {/* <MenuItem
                onClick={() => {
                    onStyleLayer(layer);
                    toggleCMenu();
                }}
            >
                Change Layer Style
            </MenuItem> */}
            {/* {layer.type !== "raster" && (
                <MenuItem
                    onClick={() => {
                        handleOpenLayerMetadata(layer);
                        toggleCMenu();
                    }}
                >
                    View Layer Metadata
                </MenuItem>
            )} */}
            {/* {layer.type !== "raster" && (
                <MenuItem
                    onClick={() => {
                        handleOpenAttributeTable(layer);
                        toggleCMenu();
                    }}
                >
                    View Layer Attribute Table
                </MenuItem>
            )} */}
            {/* {layer.options.downloadable && (
                <MenuItem
                    onClick={() => {
                        handleOpenDownloadModal(layer);
                        toggleCMenu();
                    }}
                >
                    Download Layer
                </MenuItem>
            )} */}
        </Menu>
    );
};

export default Context;

import React from "react";
import { Fragment } from "react";

export const convertStylesToLegend = (styles, layerGroups) => {
    let labels = [];
    let layerMap = {};
    layerGroups.forLayersRecursive((layer) => {
        layerMap[layer.sourceName] = layer;
    });

    let styleLabels = [];

    for (let k = 0; k < styles.length; k++) {
        let style = styles[k];
        let layer = layerMap[style.sourceName];

        if (!layer) {
            continue;
        }

        style.name = layer.name;
        //QUICK FIX
        let styleLabel;
        switch (style.type) {
            case "fill":
                styleLabel = styleTolabel(style, "fill-color", "fill-outline-color", "fill-opacity");
                break;
            case "fill-extrusion":
                styleLabel = styleTolabel(style, "fill-extrusion-color", "fill-extrusion-color");
                break;
            case "line":
                styleLabel = styleTolabel(style, "line-color", "line-color", "line-opacity");
                break;
            case "circle":
                styleLabel = styleTolabel(style, "circle-color", "circle-stroke-color", "circle-opacity");
                break;
            case "symbol":
                styleLabel = styleTolabel(style, "text-color", "icon-halo-color");
                break;
            case "raster":
                styleLabel = styleTolabel(style, "fill-color", "fill-outline-color", "fill-opacity");
                break;
            default:
                continue;
        }
        styleLabels.push({ ...styleLabel, layerId: layer.resourceId || style.layerId });
    }
    labels.push(...styleLabels);
    return labels;
};

const styleTolabel = (style, fillProp, fillOutlineProp, opacityProp) => {
    let fillColor = style.paint.find((paintType) => paintType.name === fillProp);
    let outlineColor = style.paint.find((paintType) => paintType.name === fillOutlineProp);
    let opacity = style.paint.find((paintType) => paintType.name === opacityProp);
    let layerId = style.layerId || style.datasetId + ":0";
    if (Array.isArray(fillColor?.value)) {
        return handleColorArray(layerId, style, fillColor.value, opacity?.value ?? 1);
    }

    let dashedPaint = style.paint.find((x) => x.name === "line-dasharray");
    let isDashed = dashedPaint !== undefined && dashedPaint.value[1] !== 0;

    return {
        text: style.name,
        backgroundColor: fillColor ? fillColor.value : "transparent",
        outlineColor: outlineColor ? outlineColor.value : "transparent",
        opacity: opacity ? opacity.value : "",
        type: isDashed ? "line-dasharray" : style.type,
        multi: false
    };
};

const handleColorArray = (layerId, style, colorValues, opacity) => {
    //Remove filterType and get function from array

    switch (colorValues[0]) {
        case "match":
            return handleMatchExpression(layerId, style, colorValues, opacity);
        case "interpolate":
            return handleInterpolateExpression(layerId, style, colorValues, opacity);
        default:
            return;
    }
};

const handleMatchExpression = (layerId, style, colorValues, opacity) => {
    let values = colorValues.slice(2, colorValues.length);
    let pairs = [];
    //Build pairs from remaning values, that are in this format ["key","value","key","value"]
    for (let i = 0; i < values.length - 1; i += 2) {
        pairs.push({
            name: values[i],
            value: values[i + 1],
            opacity: opacity
        });
    }

    return {
        text: style.name,
        layerId: layerId,
        backgroundColor: pairs,
        outlineColor: "#000",
        type: style.type,
        multi: true
    };
};

const handleInterpolateExpression = (layerId, style, colorValues, opacity) => {
    let values = colorValues.slice(3, colorValues.length);

    let pairs = [];
    //Build pairs from remaning values, that are in this format ["key","value","key","value"]
    for (let i = 0; i < values.length - 1; i += 2) {
        pairs.push({
            name: values[i],
            value: values[i + 1],
            opacity: opacity
        });
    }

    return {
        text: style.name,
        layerId: layerId,
        backgroundColor: pairs,
        outlineColor: "#000",
        type: style.type,
        multi: true
    };
};

export const renderLabels = (classes, visibleLabels) => {
    return visibleLabels.map((label, labelIndex) => {
        let style;
        switch (label.type) {
            case "fill":
                style = styleFillLabel(label);
                break;
            case "fill-extrusion":
                style = styleFillExtrusionLabel(label);
                break;
            case "circle":
                style = styleCircleLabel(label);
                break;
            case "line":
                style = styleLineLabel(label);
                break;
            case "line-dasharray":
                style = styleDashedLineLabel(label);
                break;
            case "symbol":
                style = styleSymbolLabel(label);
                break;
            case "raster":
                style = styleFillLabel(label);
                break;
            default:
                style = null;
        }

        if (label.multi) {
            return renderMultiLabel(label, labelIndex, style, classes);
        } else {
            return renderSingleLabel(label, labelIndex, style, classes);
        }
    });
};

const renderMultiLabel = (label, labelIndex, style, classes) => {
    let pairs = label.backgroundColor.map((subLabel, subLabelIndex) => {
        let subStyle = { ...style };
        subStyle.backgroundColor = subLabel.value;
        subStyle.opacity = subLabel.opacity;

        return (
            <div
                key={subLabelIndex}
                style={{
                    fontFamily: ["Roboto", "Helvetica Neue", "sans-serif"],
                    display: "flex",
                    alignItems: "center",
                    padding: 5,
                    marginLeft: "6%"
                }}
            >
                <div style={subStyle} className={classes.colorBox} />
                <div className={classes.multiLabelText}>{subLabel.name}</div>
            </div>
        );
    });

    return (
        <Fragment key={labelIndex}>
            <div className={classes.multiLabelHeaderText}>{label.text}</div>
            {pairs}
        </Fragment>
    );
};

const renderSingleLabel = (label, labelIndex, style, classes) => {
    return (
        <div key={labelIndex} className={classes.label}>
            <div className={classes.colorContainer}>
                <div style={style} className={classes.colorBox}>
                    {label.type === "symbol" ? "A" : ""}
                </div>
            </div>
            <div className={classes.labelText}>{label.text}</div>
        </div>
    );
};

//opacity can be set to label.opacity but with 1 it seems more clear to me
const styleFillLabel = (label) => {
    return {
        backgroundColor: label.backgroundColor,
        width: "10px",
        minWidth: "10px",
        height: "10px",
        opacity: 1,
        border: `${label.outlineColor} 1px solid`,
        marginRight: "10px"
    };
};

const styleFillExtrusionLabel = (label) => {
    return {
        backgroundColor: label.backgroundColor,
        border: `${label.outlineColor} 1px solid`,
        marginRight: "10px"
    };
};

const styleCircleLabel = (label) => {
    return {
        backgroundColor: label.backgroundColor,
        border: `${label.outlineColor} 1px solid`,
        marginRight: "10px",
        borderRadius: "10px",
        minWidth: "10px",
        width: "10px",
        height: "10px",
        opacity: 1
    };
};

const styleLineLabel = (label) => {
    return {
        backgroundColor: label.backgroundColor,
        marginRight: "12px",
        height: 4,
        minWidth: "10px",
        width: "10px"
    };
};

const styleDashedLineLabel = (label) => {
    return {
        background: `repeating-linear-gradient(to right, ${label.backgroundColor}, ${label.backgroundColor} 20%, white 20%, white 40%)`,
        height: 4,
        minWidth: "10px",
        width: "10px",
        marginRight: "12px"
    };
};

const styleSymbolLabel = (label) => {
    return {
        backgroundColor: "transparent",
        color: label.backgroundColor,
        lineHeight: "16px",
        fontWeight: "bold",
        fontSize: "16px",
        width: "10px",
        minWidth: "10px",
        marginRight: "12px"
    };
};

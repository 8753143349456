import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        overflow: "auto"
    },
    title: {
        padding: "8px 16px",
        backgroundColor: "#f5f7f8"
    },
    labels: {
        maxHeight: "45vh",
        overflow: "auto"
    },
    label: {
        fontFamily: ["Roboto", "Helvetica Neue", "sans-serif"],
        display: "flex",
        alignItems: "center",
        padding: 11
    },
    subLabel: {
        fontFamily: ["Roboto", "Helvetica Neue", "sans-serif"],
        display: "flex",
        alignItems: "center",
        padding: 5
    },
    selectedLabel: {
        display: "flex",
        alignItems: "center",
        padding: 8,
        backgroundColor: "lightgray"
    },
    multiLabel: {},
    multiLabelHeaderText: {
        paddingTop: 6,
        paddingBottom: 4,
        fontSize: 14
    },
    labelText: {
        flexGrow: 1,
        textTransform: "capitalize",
        fontSize: 14
    },
    multiLabelText: {
        flexGrow: 1,
        textTransform: "capitalize",
        fontSize: 14
    },
    colorContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    labelsContainer: {
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column"
    },
    colorBox: {
        // height: 10,
        // width: 10,
        // minWidth: 10
    }
}));

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Button, Divider, Grid, ListItemIcon, ListItemText, SvgIcon, Tooltip, Typography } from "@material-ui/core";
import { AddCircle, GetApp, MoreHoriz, PlaylistAdd, Publish } from "@material-ui/icons";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { kml } from "@tmcw/togeojson";
import turfArea from "@turf/area";
import turfCenter from "@turf/center";
import { lineString } from "@turf/helpers";
import turfLength from "@turf/length";
import turfMidpoint from "@turf/midpoint";
import csv2geojson from "csv2geojson";
import { CircleMode, DirectMode, DragCircleMode, SimpleSelectMode } from "mapbox-gl-draw-circle";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastr from "../../../utils/customToastr";
import shp from "shpjs";
import { v4 as generateUuid } from "uuid";
import * as digitizeActions from "../../../actions/digitize";
import * as mapActions from "../../../actions/map";
import * as toolsActions from "../../../actions/tools";
import { digitizeTools } from "../../../constants/digitizeTools";
import {
    getDigitizeLayers,
    getEditing,
    getNumberOfSelectedDigitizeLayers,
    getSelectedFeature
} from "../../../selectors/digitizeSelectors";
import { getOnClick } from "../../../selectors/mapSelectors";
import { getStylesForDigitizeLayerId } from "../../../selectors/stylesSelectors";
import { getWidgets } from "../../../selectors/toolsSelectors";
import { history } from "../../../store";
import { digitizeLayerToStyle } from "../../../utils/digitizeUtils";
import { digitizeIcons } from "../../../utils/mapIcons";
import { StyledTab, StyledTabs } from "../../customTabs/customTabs";
import { getDraw, getMap } from "../../map/map";
import { deselectAllFeatures } from "../../map/utils/deselectAllFeatures";
import CsvDialog from "../components/csvDialog";
import styleConfig from "../layerSelector/style/styleConfig";
import StyleList from "../layerSelector/style/styleList";
import StyledMenu from "./components/styledMenu";
import StyledMenuItem from "./components/styledMenuItem";
import DownloadDialog from "./digitizeDownloadDialog";
import DigitizeLayers from "./digitizeLayers";
import { useDigitizeStyles } from "./styles/digitizeStyles";

let modes = {
    ...MapboxDraw.modes,
    draw_circle: CircleMode,
    drag_circle: DragCircleMode,
    direct_select: DirectMode,
    simple_select: SimpleSelectMode
};
modes.static = StaticMode;

let drawingDistanceLabels = {
    type: "FeatureCollection",
    features: []
};

const Digitize = () => {
    const classes = useDigitizeStyles();
    const dispatch = useDispatch();
    const mapOnClick = useSelector(getOnClick);
    const editing = useSelector(getEditing);
    const selectedFeature = useSelector(getSelectedFeature);
    const widgets = useSelector(getWidgets);
    const digitizeLayers = useSelector(getDigitizeLayers);
    const numberOfSelectedLayers = useSelector(getNumberOfSelectedDigitizeLayers);

    const [csvFile, setCsvFile] = useState(null);
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    //     const [loaded, setLoaded] = useState(false);
    //     const [isDroppingFile, setIsDroppingFile] = useState(false);
    const [open, setOpen] = useState(false);
    const [downloadAllLayers, setDownloadAllLayers] = useState(false);
    const [addDataMenuAnchor, setAddDataMenuAnchor] = useState(null);
    const [actionsMenuAnchor, setActionsMenuAnchor] = useState(null);
    const [selectedLayer, _setSelectedLayer] = useState({});
    const selectedLayerRef = useRef(selectedLayer);
    const setSelectedLayer = (newSelectedLayer) => {
        selectedLayerRef.current = newSelectedLayer;
        _setSelectedLayer(newSelectedLayer);
    };

    const styles = useSelector(getStylesForDigitizeLayerId(selectedLayer?.id));

    const [isStylerOpen, setIsStylerOpen] = useState(false);
    const [selectedFeatureMarkers, setSelectedFeatureMarkers] = useState([]);

    let currentMousePosition = {};
    const drawingDistanceMarkersArrayRef = useRef([]);

    useEffect(() => {
        if (widgets.filter((widget) => widget.toggled).length) {
            if (editing) {
                getDraw().trash();
                dispatch(digitizeActions.editingFeatures(false));
            } else {
                setSelectedFeatureMarkers([]);
                getDraw().changeMode("static");
            }
        }
        resetDrawingState();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgets, editing]);

    useEffect(() => {
        //!!digitizeState.features.length && dispatch(digitizeActions.addDrawnFeatures(digitizeState.features));
        history.listen(() => {
            resetDrawingState();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let lastOnClickHandler = mapOnClick;
        getDraw().changeMode("simple_select");
        getMap().on("style.load", onDigitizeStart);
        getMap().on("draw.create", onFeatureCreated);
        getMap().on("draw.update", onFeatureUpdate);
        getMap().on("draw.selectionchange", onSelectionChanged);
        getMap().on("mousemove", onCreatingFeature);
        getMap().on("click", onMapClick);

        if (!getMap().getLayer("digitize-drawn-line-measurement") && getMap().isStyleLoaded()) {
            getMap().addSource("digitize-drawn-line-measurement", {
                type: "geojson",
                data: drawingDistanceLabels
            });

            getMap().addLayer({
                id: "digitize-drawn-line-measurement",
                type: "symbol",
                source: "digitize-drawn-line-measurement",
                paint: {
                    "text-color": "#000000"
                },
                layout: {
                    "symbol-placement": "point",
                    "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                    "text-field": "{distance}\n{unit}",
                    "text-anchor": "center",
                    "text-justify": "center",
                    "text-size": 13,
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true
                }
            });
        }
        document.getElementById("map").addEventListener("keydown", onPressingEsc);

        return () => {
            if (getMap()) {
                setSelectedFeatureMarkers([]);
                if (
                    getDraw().getMode() === "draw_line_string" ||
                    getDraw().getMode() === "draw_point" ||
                    getDraw().getMode() === "draw_polygon" ||
                    getDraw().getMode() === "drag_circle"
                ) {
                    getDraw().trash();
                }
                resetDrawingState();
                getDraw().changeMode("static");
                getMap().off("draw.create", onFeatureCreated);
                getMap().off("draw.update", onFeatureUpdate);
                getMap().off("draw.selectionchange", onSelectionChanged);
                getMap().off("mousemove", onCreatingFeature);

                if (getMap().getLayer("digitize-drawn-line-measurement"))
                    getMap().removeLayer("digitize-drawn-line-measurement");
                if (getMap().getSource("digitize-drawn-line-measurement"))
                    getMap().removeSource("digitize-drawn-line-measurement");
            }
            dispatch(mapActions.mapChangeClick(lastOnClickHandler));
            dispatch(digitizeActions.selectFeature(null));
            dispatch(digitizeActions.editingFeatures(false));
            document.getElementById("map").removeEventListener("keydown", onPressingEsc);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //Deselect all features
        if (!editing) {
            deselectAllFeaturesAndLabels();
        }
    }, [selectedLayer?.id, addDataMenuAnchor, actionsMenuAnchor]);

    useEffect(() => {
        if (editing && selectedFeatures[0]) {
            let feature = selectedFeatures[0];
            let newLayer = { ...selectedLayer };
            if (!newLayer.features) {
                newLayer.features = [];
                newLayer.features.push(feature.id);
                dispatch(digitizeActions.editLayer(newLayer));
            } else {
                newLayer.features.push(feature.id);
                dispatch(digitizeActions.editLayer(newLayer));
            }
            setSelectedLayer(newLayer);
            dispatch(digitizeActions.editingFeatures(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFeatures]);

    useEffect(() => {
        if (!!selectedFeatureMarkers.length && selectedFeature === null) {
            selectedFeatureMarkers.forEach((marker) => {
                marker.remove();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFeature]);

    const onPressingEsc = (e) => {
        if (e.key === "Escape") {
            resetDrawingState();
            dispatch(digitizeActions.editingFeatures(false));
        }
    };

    const deselectAllFeaturesAndLabels = () => {
        resetDrawingState();
        deselectAllFeatures();
    };

    const resetDrawingState = () => {
        drawingDistanceMarkersArrayRef.current = [];
        drawingDistanceLabels.features = [];
        getMap().getSource("digitize-drawn-line-measurement")?.setData(drawingDistanceLabels);
    };

    const handleClickActions = (event) => {
        setActionsMenuAnchor(event.currentTarget);
    };

    const handleCloseActions = () => {
        setActionsMenuAnchor(null);
    };

    const handleClickAddData = (event) => {
        setAddDataMenuAnchor(event.currentTarget);
    };

    const handleCloseAddData = () => {
        setAddDataMenuAnchor(null);
    };

    const onDigitizeStart = () => {
        getMap().addSource("digitize-drawn-line-measurement", {
            type: "geojson",
            data: drawingDistanceLabels
        });

        getMap().addLayer({
            id: "digitize-drawn-line-measurement",
            type: "symbol",
            source: "digitize-drawn-line-measurement",
            paint: {
                "text-color": "#000000"
            },
            layout: {
                "symbol-placement": "point",
                "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                "text-field": "{distance}\n{unit}",
                "text-anchor": "center",
                "text-justify": "center",
                "text-size": 13,
                "text-allow-overlap": true,
                "icon-allow-overlap": true,
                "icon-ignore-placement": true
            }
        });
    };

    const onFeatureCreated = (e) => {
        currentMousePosition = {};
        drawingDistanceMarkersArrayRef.current = [];
        let createdFeature = e.features[0];
        if (createdFeature.properties.isCircle) {
            createdFeature.properties.name = "Circle";
        }

        createdFeature.properties.name = createdFeature.geometry.type;
        createdFeature.properties.layerId = selectedLayerRef.current.id;
        createdFeature.properties.layerName = selectedLayerRef.current.name;
        dispatch(digitizeActions.addDrawnFeatures(e.features));
        dispatch(digitizeActions.selectFeature(createdFeature));
        const draw = getDraw();
        draw.setFeatureProperty(createdFeature.id, "customStyle", true);
        draw.setFeatureProperty(createdFeature.id, "hidden", !selectedLayerRef.current.selected);

        selectedLayerRef.current.properties.forEach((property) => {
            draw.setFeatureProperty(createdFeature.id, property.name, property.value);
        });
        draw.add(draw.get(createdFeature.id));

        createdFeature.geometry.type !== "Point" && renderDistanceMarkers(e);
    };

    const renderDistanceMarkers = (lines, helpLine = null) => {
        let drawnLinesFromParameter = [];
        if (!!lines.features?.length) {
            if (lines.features[0].properties.isCircle) {
                drawnLinesFromParameter = [];
            } else {
                if (lines.features[0].geometry.type === "Polygon") {
                    drawnLinesFromParameter = [...lines.features[0].geometry.coordinates[0]];
                } else {
                    drawnLinesFromParameter = [...lines.features[0].geometry.coordinates];
                }
            }
        } else {
            drawnLinesFromParameter = [...lines];
        }

        if (helpLine) {
            drawnLinesFromParameter[drawnLinesFromParameter.length] = helpLine;
        }
        let polygonLines = [];
        let unit = "kilometers";
        let unitShort = "km";
        polygonLines = drawnLinesFromParameter;
        let points = [];
        if (polygonLines.length > 0) {
            for (let i = 0; i <= polygonLines.length - 2; i++) {
                let polygonLine = lineString([polygonLines[i], polygonLines[i + 1]], { name: `segment-${i + 1}` });
                if (polygonLine) {
                    let midPoint = turfMidpoint(polygonLines[i], polygonLines[i + 1]);
                    let routeDistance = turfLength(polygonLine, { units: unit });
                    routeDistance = Number.parseFloat(routeDistance).toFixed(2);

                    points.push({
                        type: "Feature",
                        properties: { unit: unitShort, distance: routeDistance },
                        geometry: {
                            type: "Point",
                            coordinates: midPoint.geometry.coordinates
                        }
                    });
                }
            }
            drawingDistanceLabels.features = points;
            if (getMap().getSource("digitize-drawn-line-measurement")) {
                getMap().getSource("digitize-drawn-line-measurement").setData(drawingDistanceLabels);
            }
        } else {
            let circleCenter = turfCenter(lines.features[0]);
            let circleArea = turfArea(lines.features[0]) / 1000000;
            points.push({
                type: "Feature",
                properties: { unit: unitShort, distance: circleArea.toFixed(2) },
                geometry: {
                    type: "Point",
                    coordinates: circleCenter.geometry.coordinates
                }
            });
            drawingDistanceLabels.features = points;
            if (getMap().getSource("digitize-drawn-line-measurement")) {
                getMap().getSource("digitize-drawn-line-measurement").setData(drawingDistanceLabels);
            }
        }
    };

    const onMapClick = (e) => {
        if (
            getDraw().getMode() !== "static" &&
            getDraw().getMode() !== "simple_select" &&
            getDraw().getMode() !== "draw_point" &&
            getDraw().getMode() !== "direct_select" &&
            getDraw().getMode() !== "drag_circle"
        ) {
            let point = [];
            point[0] = e.lngLat.lng;
            point[1] = e.lngLat.lat;
            drawingDistanceMarkersArrayRef.current.push(point);
        }
    };

    const onFeatureUpdate = (e) => {
        dispatch(digitizeActions.editFeature(e.features[0]));
        if (getDraw().getMode() === "direct_select" || getDraw().getMode() === "drag_circle") {
            e.features[0].geometry.type !== "Point" && renderDistanceMarkers(e);
        }
    };

    const onCreatingFeature = (e) => {
        if (
            getDraw().getMode() !== "static" &&
            getDraw().getMode() !== "simple_select" &&
            getDraw().getMode() !== "draw_point" &&
            getDraw().getMode() !== "drag_circle"
        ) {
            currentMousePosition = e.lngLat;
            if (
                drawingDistanceMarkersArrayRef.current.length > 0 &&
                !!Object.getOwnPropertyNames(currentMousePosition).length
            ) {
                renderDistanceMarkers(drawingDistanceMarkersArrayRef.current, [
                    currentMousePosition.lng,
                    currentMousePosition.lat
                ]);
            }
        }
    };

    const onSelectionChanged = (e) => {
        if (!e.features.length) {
            drawingDistanceMarkersArrayRef.current = [];
            let emptyDrawingDistanceLabels = {
                type: "FeatureCollection",
                features: []
            };
            getMap().getSource("digitize-drawn-line-measurement") &&
                getMap().getSource("digitize-drawn-line-measurement").setData(emptyDrawingDistanceLabels);
        } else {
            if (Array.isArray(e.features[0].geometry.coordinates[0])) {
                renderDistanceMarkers(e);
            }
        }
        setSelectedFeatureMarkers([]);
        setSelectedFeatures(e.features);
    };

    const onAddLayer = (e, layer) => {
        e && e.stopPropagation();
        handleCloseAddData();

        let newLayer = { ...layer };
        newLayer.datasetId = generateUuid();
        newLayer.id = newLayer.datasetId + ":0";

        newLayer.type = layer.name;

        const typeToStyleType = {
            circle: "fill",
            polygon: "fill",
            line: "line",
            point: "circle"
        };
        const styleType = typeToStyleType[newLayer.type];
        newLayer.styleType = styleType;

        const properties = JSON.parse(JSON.stringify(styleConfig[styleType]));
        newLayer.properties = properties;
        newLayer.layout = properties.filter((x) => x.type === "layout");
        newLayer.paint = properties.filter((x) => x.type === "paint");

        newLayer.editing = false;
        newLayer.name = layer.name;
        newLayer.styleCount = 1;
        newLayer.selected = true;
        newLayer.features = [];
        dispatch(digitizeActions.addLayer(newLayer));
        dispatch(digitizeActions.editingFeatures(true));
        dispatch(digitizeActions.addDigitizeStyle(digitizeLayerToStyle(newLayer, 0)));
        // start feature creation by default when adding a new layer
        setSelectedLayer(newLayer);

        if (editing) {
            getDraw().trash();
        }
        closeWidgets();
        switch (newLayer.type) {
            case "point":
                getDraw().changeMode("draw_point");
                break;
            case "line":
                getDraw().changeMode("draw_line_string");
                break;
            case "polygon":
                getDraw().changeMode("draw_polygon");
                break;
            case "circle":
                getDraw().changeMode("drag_circle");
                break;
            default:
                return null;
        }
    };

    const closeWidgets = () => {
        widgets.forEach((widget) => {
            if (widget.toggled) {
                let updatedWidget = { ...widget };
                updatedWidget.toggled = false;
                dispatch(toolsActions.toggleWidget(updatedWidget));
            }
        });
    };

    const handleClickOpenDialog = (downloadAllLayers) => {
        setDownloadAllLayers(downloadAllLayers);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const selectAll = () => {
        dispatch(digitizeActions.toggleAllDigitizeLayers(true));

        const draw = getDraw();
        digitizeLayers.forEach((layer) => {
            layer.features.forEach((featureId) => {
                draw.setFeatureProperty(featureId, "hidden", false);
                draw.add(draw.get(featureId));
            });
        });

        resetDrawingState();
    };

    const onFileSelected = (e) => {
        const uploadedFile = e.target.files[0];

        var fileSize = (uploadedFile.size / 1024 / 1024).toFixed(4); // MB
        if (fileSize > 10) {
            toastr.error("For performance reasons, the maximum upload size is of 10 Mb.");
            return;
        }

        //This will allow us to upload the same file twice.
        document.getElementById("digitize-upload-features").value = "";
        loadFile(uploadedFile);
    };

    const handleCsvDialogClose = () => {
        setCsvFile(null);
    };

    const loadFile = (file) => {
        let fileNameSplit = file.name.split(".");
        let fileType = fileNameSplit[fileNameSplit.length - 1];
        if (fileType === "csv") {
            setCsvFile(file);
            return;
        }

        new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            if (fileType === "zip") {
                reader.readAsArrayBuffer(file);
            } else {
                reader.readAsText(file);
            }
        }).then((res) => {
            let geoJson;
            switch (fileType) {
                case "kml":
                    try {
                        let parser = new DOMParser();
                        let xmlDoc = parser.parseFromString(res, "text/xml");
                        geoJson = kml(xmlDoc);
                    } catch {
                        alert("Invalid data!");
                    }
                    break;
                case "geojson":
                    try {
                        geoJson = JSON.parse(res);
                    } catch {
                        alert("Invalid data!");
                    }
                    break;
                case "zip":
                    try {
                        let parsedGeojson = shp.parseZip(res);
                        if (typeof parsedGeojson === "object" && parsedGeojson !== null) {
                            geoJson = parsedGeojson;
                        } else {
                            let data = {
                                type: "FeatureCollection",
                                features: []
                            };
                            parsedGeojson.forEach((featureCollection) => {
                                featureCollection.features.forEach((feature) => {
                                    feature.id = feature.properties.id;
                                    data.features.push(feature);
                                });
                            });
                            geoJson = data;
                        }
                    } catch (error) {
                        alert("Invalid data!");
                    }
                    break;
                default:
                    break;
            }
            loadGeoJson(geoJson);
        });
    };

    const loadGeoJson = (geoJson) => {
        if (!geoJson) return;

        geoJson.features = geoJson.features.map((x) => {
            return { ...x, id: generateUuid() };
        });
        getDraw().add(geoJson);
        let newlyCreatedLayers = [];

        geoJson.features.forEach((feature) => {
            if (
                !newlyCreatedLayers.some((layer) =>
                    feature.geometry.type === "LineString"
                        ? layer.namePretty === "Line"
                        : feature.properties.isCircle
                        ? layer.namePretty === "Circle"
                        : layer.namePretty === feature.geometry.type
                )
            ) {
                let layer = {};
                let geometry =
                    feature.geometry.type === "LineString"
                        ? "Line"
                        : feature.properties.isCircle
                        ? "Circle"
                        : feature.geometry.type;
                let layerType = geometry;

                const typeToStyleType = {
                    Circle: "fill",
                    Polygon: "fill",
                    MultiPolygon: "fill",
                    Line: "line",
                    MultiLine: "line",
                    Point: "circle",
                    MultiPoint: "circle"
                };
                const styleType = typeToStyleType[layerType];
                layer.styleType = styleType;

                const properties = JSON.parse(JSON.stringify(styleConfig[styleType]));
                layer.properties = properties;
                layer.layout = properties.filter((x) => x.type === "layout");
                layer.paint = properties.filter((x) => x.type === "paint");

                layer.name = layerType.toLowerCase();
                layer.type = layerType.toLowerCase();
                layer.namePretty = layerType;
                layer.icon = `${layerType.toLowerCase()}Icon`;
                layer.datasetId = generateUuid();
                layer.id = layer.datasetId + ":0";

                layer.editing = false;
                layer.enabled = true;
                layer.selected = true;

                layer.features = [];
                layer.styleCount = 1;

                newlyCreatedLayers.push(layer);
            }
            let typeOfSelectedLayer =
                feature.geometry.type === "LineString"
                    ? "Line"
                    : feature.properties.isCircle
                    ? "Circle"
                    : feature.geometry.type;
            let foundLayer = newlyCreatedLayers.find((layer) => layer.namePretty === typeOfSelectedLayer);
            feature.properties.layerId = foundLayer.id;
            feature.properties.layerName = foundLayer.name;

            foundLayer.features.push(feature.id);
        });

        newlyCreatedLayers.forEach((newLayer) => {
            dispatch(digitizeActions.addLayer(newLayer));
            dispatch(digitizeActions.addDigitizeStyle(digitizeLayerToStyle(newLayer, 0)));
        });
        dispatch(digitizeActions.addDrawnFeatures(geoJson.features));

        newlyCreatedLayers.forEach((newLayer) => {
            const draw = getDraw();
            newLayer.features.forEach((featureId) => {
                draw.setFeatureProperty(featureId, "customStyle", true);
                draw.setFeatureProperty(featureId, "hidden", false);

                newLayer.properties.forEach((property) => {
                    draw.setFeatureProperty(featureId, property.name, property.value);
                });
            });
        });
        let fileInput = document.getElementById("digitize-upload-features");
        fileInput.value = "";
    };

    const onPropertiesChanged = (properties, layer) => {
        const newLayer = {
            ...digitizeLayers.find((oldLayer) => oldLayer.datasetId === layer.datasetId),
            properties
        };

        dispatch(digitizeActions.updateDigitizeStyle({ layerId: layer.layerId, properties }));
        dispatch(digitizeActions.editLayer(newLayer));
        setSelectedLayer({ ...selectedLayer, properties });
        const draw = getDraw();

        newLayer.features.forEach((featureId) => {
            newLayer.properties.forEach((property) => {
                draw.setFeatureProperty(featureId, property.name, property.value);
            });
            draw.add(draw.get(featureId));
        });
    };

    const addStyleToRasterLayer = (layer) => {
        const layerId = `${layer.datasetId}:${layer.styleCount}`;
        let properties = [
            {
                name: "fill-color",
                title: "Legend",
                type: "paint",
                propertyType: "color",
                expressionType: "match",
                index: 0,
                value: ["match", ["get", null], "replace this", "darkcyan", "replace this also", "pink", "black"]
            }
        ];
        let styleLayer = {
            name: layer.name,
            sourceName: layer.name,
            sourceId: layer.datasetId,
            datasetId: layer.datasetId,
            layerId: layerId,
            properties: properties,
            paint: properties.filter((x) => x.type === "paint"),
            layout: properties.filter((x) => x.type === "layout"),
            type: "raster",
            visible: true
        };
        layer.styleCount += 1;
        dispatch(digitizeActions.editLayer(layer));
        dispatch(digitizeActions.addDigitizeStyle(styleLayer));
    };

    const addStyleToVectorLayer = (layer) => {
        let layerId = `${layer.datasetId}:${layer.styleCount}`;
        let styleLayer = {
            name: layer.name,
            sourceName: layer.name,
            sourceId: layer.sourceId,
            datasetId: layer.datasetId,
            layerId: layerId,
            properties: layer.properties,
            paint: layer.properties.filter((x) => x.type === "paint"),
            layout: layer.properties.filter((x) => x.type === "layout"),
            type: layer.styleType
        };

        layer.styleCount += 1;
        dispatch(digitizeActions.editLayer(layer));
        dispatch(digitizeActions.addDigitizeStyle(styleLayer));
    };

    const onAddStyleToLayer = (layer) => {
        if (layer.type === "raster") {
            addStyleToRasterLayer(layer);
        } else {
            addStyleToVectorLayer(layer);
        }
    };

    const onRemoveStyleFromLayer = (layer, styleLayer) => {
        if (layer.styleCount > 1) {
            layer.styleCount -= 1;
            const firstGoodStyle = styles.find((x) => x.layerId !== styleLayer.layerId);
            layer.properties = firstGoodStyle.properties;
            layer.paint = firstGoodStyle.paint;
            layer.layout = firstGoodStyle.layout;
            const draw = getDraw();
            layer.features.forEach((featureId) => {
                firstGoodStyle.properties.forEach((property) => {
                    draw.setFeatureProperty(featureId, property.name, property.value);
                });
                draw.add(draw.get(featureId));
            });

            dispatch(digitizeActions.editLayer(layer));
            dispatch(digitizeActions.removeDigitizeStyle(styleLayer));
            dispatch(digitizeActions.reinitializeDigitizeStylesLayerId(layer));
        }
    };

    const onStyleTypeChanged = (type, styleLayer) => {};

    let styler =
        styles !== undefined ? (
            <StyleList
                layer={selectedLayer}
                isDigitizeLayer={true}
                styles={styles}
                onPropertiesChanged={onPropertiesChanged}
                onPropertyExpressionTypeChanged={onStyleTypeChanged} //Maybe render alternatives can be left out
                onTypeChanged={onStyleTypeChanged} //Maybe render alternatives can be left out, otherwise add geometryType to each layer/style
                onZoomSliderChange={() => {}} //No example implementation
                onAddStyleToLayer={onAddStyleToLayer}
                onRemoveStyleFromLayer={onRemoveStyleFromLayer}
            ></StyleList>
        ) : null;

    return (
        <Grid container className={classes.root} direction="column">
            <Grid item xs={12} className={classes.content}>
                <Grid item xs={12}>
                    <Divider />
                    <Grid container justify="space-between" alignItems="center" className={classes.title}>
                        <Typography variant="h6">Digitize</Typography>
                        <input
                            accept=".kml, .geojson, .csv, .zip"
                            className={classes.input}
                            id="digitize-upload-features"
                            type="file"
                            onChange={onFileSelected}
                        />
                        <div>
                            <Tooltip title="Digitize actions" className={classes.marginRight}>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    aria-label="Actions"
                                    size="small"
                                    onClick={handleClickActions}
                                    startIcon={<MoreHoriz />}
                                >
                                    Actions
                                </Button>
                            </Tooltip>
                            <Tooltip title="Digitize actions">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    aria-label="Add data"
                                    size="small"
                                    onClick={handleClickAddData}
                                    startIcon={<AddCircle />}
                                >
                                    Add data
                                </Button>
                            </Tooltip>
                        </div>

                        <StyledMenu
                            id="digitize-add-data-menu"
                            anchorEl={addDataMenuAnchor}
                            keepMounted
                            open={Boolean(addDataMenuAnchor)}
                            onClose={handleCloseAddData}
                        >
                            {digitizeTools.map((tool, index) => (
                                <Tooltip key={index} title={`Add ${tool.namePretty} layer`}>
                                    <StyledMenuItem onClick={(e) => onAddLayer(e, tool)}>
                                        <ListItemIcon className={classes.digitizeToolsIcon}>
                                            <SvgIcon className={classes.buttonIcon}>{digitizeIcons[tool.icon]}</SvgIcon>
                                        </ListItemIcon>
                                        <ListItemText
                                            className={classes.digitizeToolsText}
                                            primary={`${tool.namePretty} layer`}
                                        />
                                    </StyledMenuItem>
                                </Tooltip>
                            ))}
                        </StyledMenu>
                        <StyledMenu
                            id="digitize-actions-menu"
                            anchorEl={actionsMenuAnchor}
                            keepMounted
                            open={Boolean(actionsMenuAnchor)}
                            onClose={handleCloseActions}
                        >
                            <label htmlFor="digitize-upload-features">
                                <Tooltip title="Upload features">
                                    <StyledMenuItem>
                                        <ListItemIcon className={classes.digitizeToolsIcon}>
                                            <Publish />
                                        </ListItemIcon>
                                        <ListItemText className={classes.digitizeToolsText}>Upload</ListItemText>
                                    </StyledMenuItem>
                                </Tooltip>
                            </label>
                            <Tooltip title="Download selected layers">
                                <StyledMenuItem
                                    disabled={!numberOfSelectedLayers}
                                    onClick={() => handleClickOpenDialog(false)}
                                >
                                    <ListItemIcon className={classes.digitizeToolsIcon}>
                                        <GetAppOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText className={classes.digitizeToolsText}>
                                        Download selected layers
                                    </ListItemText>
                                </StyledMenuItem>
                            </Tooltip>
                            <Tooltip title="Download all layers">
                                <StyledMenuItem
                                    disabled={!digitizeLayers.length}
                                    onClick={() => handleClickOpenDialog(true)}
                                >
                                    <ListItemIcon className={classes.digitizeToolsIcon}>
                                        <GetApp />
                                    </ListItemIcon>
                                    <ListItemText className={classes.digitizeToolsText}>Download all</ListItemText>
                                </StyledMenuItem>
                            </Tooltip>
                            <Tooltip title="Select all">
                                <StyledMenuItem onClick={selectAll}>
                                    <ListItemIcon className={classes.digitizeToolsIcon}>
                                        <PlaylistAdd />
                                    </ListItemIcon>
                                    <ListItemText className={classes.digitizeToolsText}>Select all</ListItemText>
                                </StyledMenuItem>
                            </Tooltip>
                        </StyledMenu>
                    </Grid>
                    <Divider />
                    <DownloadDialog open={open} onClose={handleCloseDialog} downloadAllLayers={downloadAllLayers} />
                </Grid>
                <Grid item xs={12} className={classes.layersContainer}>
                    <DigitizeLayers
                        deselectAllFeaturesAndLabels={deselectAllFeaturesAndLabels}
                        resetDrawingState={resetDrawingState}
                        setIsStylerOpen={setIsStylerOpen}
                        selectedLayer={selectedLayer}
                        setSelectedLayer={setSelectedLayer}
                        renderDistanceMarkers={renderDistanceMarkers}
                    />
                </Grid>
            </Grid>
            {isStylerOpen && (
                <div className="edit-view-container">
                    <StyledTabs value={"style"} TabIndicatorProps={<div />}>
                        <StyledTab label={<div>Style</div>} value="style" />
                        <div className="close-button">
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                    setIsStylerOpen(false);
                                }}
                            >
                                Close
                            </Button>
                        </div>
                    </StyledTabs>
                    <Divider />
                    {<div className={classes.stylerDivWrapper}>{styler}</div>}
                </div>
            )}
            <CsvDialog csvFile={csvFile} loadGeoJson={loadGeoJson} handleClose={handleCsvDialogClose} />
        </Grid>
    );
};

export default Digitize;

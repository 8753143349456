import * as types from "../actions/actionTypes";
import { getApp } from "../actions/atlasClient";

const toolsInit = {
    tools: [],
    widgets: []
};

export function toolsReducer(state = toolsInit, action) {
    switch (action.type) {
        case getApp.fulfilled.type:
            return {
                tools: [...action.payload.configJson.tools],
                widgets: [...action.payload.configJson.widgets]
            };
        case types.TOOLS_TOGGLE_WIDGET:
            return {
                ...state,
                widgets: updateArray(state.widgets, action.result)
            };
        default:
            return state;
    }
}

function updateArray(array, item) {
    return array.map((x) => {
        if (item.name === x.name) return item;
        return x;
    });
}

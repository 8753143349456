import React, { Component, useEffect, useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircle";
import { toastr } from "react-redux-toastr";
import { Dialog, FormControl, InputLabel, MenuItem, Select, Tooltip } from "@material-ui/core";
import csv2Geojson from "csv2geojson";
import { v4 as generateUuid } from "uuid";

const styles = () => ({});

const CsvDialog = ({ handleClose, csvFile, onFinished, loadGeoJson }) => {
    const [xField, setXField] = useState("");
    const [yField, setYField] = useState("");
    const [columns, setColumns] = useState([]);

    const fileContent = useRef(null);

    useEffect(() => {
        if (csvFile) {
            setXField("");
            setYField("");

            new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = function (theFile) {
                    resolve(reader.result);
                };
                reader.readAsText(csvFile);
            }).then((res) => {
                let header = res.split("\n")[0];
                let columnNames = header.split(",").map((col) => col.trim());

                fileContent.current = res;
                setColumns(columnNames.map((columnName) => ({ name: columnName, id: generateUuid() })));
            });
        }
    }, [csvFile]);

    const onYChanged = (e) => {
        setYField(e.target.value);
    };

    const onXChanged = (e) => {
        setXField(e.target.value);
    };

    const onDone = () => {
        const options = {
            lonfield: xField,
            latfield: yField,
            delimite: ","
        };

        csv2Geojson.csv2geojson(fileContent.current, options, (err, data) => {
            if (err) {
                alert("Invalid data!");
            }
            let pointData = {
                type: "FeatureCollection",
                features: []
            };
            data.features.forEach((feature) => {
                feature.id = feature.properties.id;
                pointData.features.push(feature);
            });
            loadGeoJson(pointData);
            handleClose();
        });
    };

    return (
        <Dialog className="atlas-dialog create-group-modal" open={!!csvFile} onClose={handleClose}>
            <div className="dialog-header">
                <Typography variant="h6" className="dialog-title">
                    Upload {csvFile?.name}
                </Typography>
                <Tooltip
                    title={
                        !yField || !xField ? "Please select the latitude and longitude columns before proceeding" : ""
                    }
                >
                    <div>
                        <Button variant="contained" color="primary" disabled={!yField || !xField} onClick={onDone}>
                            <AddIcon style={{ marginRight: 8, marginLeft: -8 }} />
                            Done
                        </Button>
                    </div>
                </Tooltip>
            </div>
            <div className="container">
                <div className="section">
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="schema-label">Y Column</InputLabel>
                        <Select
                            labelId="schema-label"
                            label="Y Column"
                            className="column-select"
                            onChange={onYChanged}
                            value={yField}
                            fullWidth
                        >
                            {columns.map((column) => (
                                <MenuItem key={column.id} value={column.name}>
                                    {column.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <br />
                <div className="section">
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="schema-label">X Column</InputLabel>
                        <Select
                            labelId="schema-label"
                            label="X Column"
                            className="column-select"
                            onChange={onXChanged}
                            value={xField}
                            fullWidth
                        >
                            {columns.map((column) => (
                                <MenuItem key={column.id} value={column.name}>
                                    {column.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </Dialog>
    );
};

export default withStyles(styles)(CsvDialog);

import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./style.js";
import styles from "../../../scss/variables.module.scss";

const CustomTypography = (props, ref) => {
    const { variant, fontWeight, color, noWrap, className,gutterBottom } = props;

    let weight;
    switch (fontWeight) {
        case "regular":
            weight = "400";
            break;
        case "semibold":
            weight = "600";
            break;
        case "bold":
            weight = "700";
            break;
        default:
            weight = "inherit";
    }

    const styleProps = { weight: weight, color: color };
    const classes = useStyles(styleProps);

    return (
        <Typography
            ref={ref}
            variant={variant}
            className={`${classes.textStyle} ${className} ${className ? className : ""}`}
            noWrap={noWrap}
            gutterBottom={gutterBottom}
        >
            {props.children}
        </Typography>
    );
};

CustomTypography.defaultProps = {
    fontWeight: "inherit",
    color: styles.primaryTextColor,
    noWrap: false
};

export default React.forwardRef(CustomTypography);

import { HubConnectionBuilder } from "@microsoft/signalr";
import config from "../config";

class SignalRHub {
    constructor(url) {
        const apiUrl = config.apiUrl.split("/api/")[0];

        this.connection = new HubConnectionBuilder()
            .withUrl(apiUrl + url)
            .withAutomaticReconnect()
            .build();
        this.isConnectionStarted = false;
    }

    onEvent = (eventName, callback) => this.connection.on(eventName, callback);

    off = (eventName) => this.connection.off(eventName);

    start = async () => {
        if (this.isConnectionStarted) return;

        try {
            await this.connection.start();
            this.isConnectionStarted = true;
        } catch (error) {
            this.isConnectionStarted = false;
            setTimeout(this.start, 1000);
        }
    };

    stop = async () => {
        if (!this.isConnectionStarted) return;

        try {
            await this.connection.stop();
            this.isConnectionStarted = false;
        } catch (error) {
            setTimeout(this.stop, 500);
        }
    };

    onReconnect = (callback) => this.connection.onreconnected(callback);
}

export default SignalRHub;

import appStyles from "../../../../utils/styles";

export const legendJss = (theme) => ({
    root: {
        position: "absolute",
        backgroundColor: "#fff",
        bottom: 35,
        left: 6,
        zIndex: 1,
        maxWidth: 385
    },
    rootLegend: {
        position: "relative",
        zIndex: 1
    },
    rootClosed: {
        position: "absolute",
        backgroundColor: "transparent",
        bottom: 35,
        left: 6,
        zIndex: 1,
        minWidth: 20,
        opacity: 0.9
    },
    labels: {
        padding: 8,
        maxHeight: "45vh",
        overflow: "auto"
    },
    label: {
        fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"],
        display: "flex",
        alignItems: "center",
        padding: 8
    },
    subLabel: {
        fontFamily: ["Roboto", "Helvetica Neue", "sans-serif"],
        display: "flex",
        alignItems: "center",
        padding: 5,
        marginLeft: "9%"
    },
    selectedLabel: {
        display: "flex",
        alignItems: "center",
        padding: 8,
        backgroundColor: "lightgray"
    },
    multiLabel: {
        marginLeft: 8
    },
    multiLabelHeaderText: {
        paddingTop: 6,
        paddingBottom: 4,
        marginLeft: "3%",
        fontSize: 14
    },
    labelText: {
        flexGrow: 1,
        marginLeft: 8,
        fontSize: 14,
        textTransform: "capitalize"
    },
    multiLabelText: {
        flexGrow: 1,
        marginLeft: 16,
        fontSize: 14,
        textTransform: "capitalize"
    },
    colorBoxContainer: {
        minHeight: 12,
        minWidth: 12
    },
    colorContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "6%"
    },
    colorBox: {
        height: 12,
        width: 12,
        minWidth: 12
    },
    closeButton: {
        padding: 8,
        paddingLeft: 16,
        backgroundColor: appStyles.color.HEADER_BACKGROUND,
        color: "white",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between"
    },
    openButton: {
        padding: 8,
        backgroundColor: appStyles.color.HEADER_BACKGROUND,
        color: "white",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "50%"
    },
    headerText: {
        color: "#fff"
    }
});

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import { getMap } from "../map";
import { useSelector } from "react-redux";
import { getBasemapTitle, getLayouts, getMapLanguage } from "../../../selectors/mapSelectors";
import toastr from "../../../utils/customToastr";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles(() => ({
    iconRoot: {
        position: "absolute",
        top: 221, //258
        right: 10,
        zIndex: 1,
        borderRadius: 4,
        backgroundColor: "white",
        boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)"
    },
    iconContainer: {
        width: 29,
        height: 29,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        cursor: "pointer",
        "&:hover": {
            backgroundColor: "whitesmoke",
            borderRadius: 4
        }
    },
    icon: {
        color: "#484848"
    }
}));

const MapCopyState = (props) => {
    const classes = useStyles();
    const layouts = useSelector(getLayouts);
    const basemapTitle = useSelector(getBasemapTitle);
    const mapLanguage = useSelector(getMapLanguage);

    const handleCopyToClipboard = () => {
        let mainUrl = document.location.href;

        let bounds = getMap().getBounds();
        let boundsArray = [
            [bounds._sw.lng, bounds._sw.lat],
            [bounds._ne.lng, bounds._ne.lat]
        ];
        bounds = insertQueryParam("bounds", JSON.stringify(boundsArray));

        let visibleLayers = "";

        Object.values(layouts).forEach((mapLayout) => {
            mapLayout.properties.forEach((objProperty) => {
                if (objProperty.name === "visibility" && objProperty.value === "visible") {
                    if (visibleLayers.length) {
                        visibleLayers = visibleLayers + "," + mapLayout.datasetId;
                    } else {
                        visibleLayers = mapLayout.datasetId;
                    }
                }
            });
        });

        let basemapParam = insertQueryParam("basemap", basemapTitle);
        let languageParam = insertQueryParam("language", mapLanguage);
        let visibleLayersParam = insertQueryParam("visibleLayers", visibleLayers);

        let mainUrlWithState = mainUrl.split("?");
        if (mainUrlWithState[1]?.length) {
            mainUrl = mainUrlWithState[0];
        }
        let mapStateToBeCopied = `${mainUrl}?${bounds}${basemapParam}${languageParam}${visibleLayersParam}`;

        let dummyHtmlElement = document.createElement("input");
        document.body.appendChild(dummyHtmlElement);
        dummyHtmlElement.setAttribute("value", mapStateToBeCopied);
        dummyHtmlElement.select();
        document.execCommand("copy");
        document.body.removeChild(dummyHtmlElement);
        toastr.success("Map state copied to clipboard.");
    };

    const insertQueryParam = (key, value) => {
        let keyIn = encodeURIComponent(key);
        let valueIn = encodeURIComponent(value);
        let kvp = [""];
        let i = 0;
        for (; i < kvp.length; i++) {
            if (kvp[i].startsWith(keyIn + "=")) {
                let pair = kvp[i].split("=");
                pair[1] = valueIn;
                kvp[i] = pair.join("=");
                break;
            }
        }

        if (i >= kvp.length) {
            kvp[kvp.length] = [keyIn, valueIn].join("=");
        }

        // can return this or...
        return kvp.join("&");
    };

    return (
        <div className={classes.iconRoot}>
            <Tooltip title="Copy map state">
                <div className={classes.iconContainer} onClick={handleCopyToClipboard}>
                    <FileCopy className={classes.icon} />
                </div>
            </Tooltip>
        </div>
    );
};

export default MapCopyState;

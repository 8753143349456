import React from "react";
import dayjs from "dayjs";

const generatePropertyName = (propertyName) => {
    const re = /(?:[a-zA-Z0-9])_(?=[a-zA-Z0-9])/g;
    const originalPropName = propertyName;
    let match;
    while ((match = re.exec(originalPropName)) != null) {
        propertyName = propertyName.slice(0, match.index + 1) + " " + propertyName.slice(match.index + 2);
    }
    return propertyName;
};

export function BuildProperties(feature, classes) {
    let properties = Object.keys(feature.properties).reduce((a, b) => {
        a.push({
            key: b,
            value: feature.properties[b]
        });
        return a;
    }, []);

    let propertyPresent = false;
    const featurePropertiesElements = properties.map((propKeyValue, propIndex) => {
        let value;

        if (["ogc_fid", "geom_type"].indexOf(propKeyValue.key) >= 0) {
            return null;
        }
        propertyPresent = true;
        // Creating links
        if (
            propKeyValue.key.includes("link") ||
            propKeyValue.key.includes("foto") ||
            propKeyValue.value.toString().includes("http")
        ) {
            value = (
                <a href={propKeyValue.value} target="_blank" rel="noopener noreferrer">
                    Link{" "}
                </a>
            );
            // Formatting dates
        } else if (propKeyValue.key.includes("dato")) {
            value = dayjs().format("DD/MM-YYYY");
        } else {
            value = propKeyValue.value;
        }

        return (
            <tr className={classes.property} key={propIndex}>
                <td className={classes.propertyKey}>{generatePropertyName(propKeyValue.key)}</td>
                <td className={classes.propertyValue}>{value}</td>
            </tr>
        );
    });

    if (!propertyPresent) {
        return { featurePropertiesElements: <div className={classes.noData}>No properties to display</div> };
    }
    return { featurePropertiesElements, hasPropertiesToDisplay: true };
}

import { Divider, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertStylesToLegend, renderLabels } from "../../../utils/legendUtils";
import { getDigitizeStyles } from "../../../selectors/stylesSelectors";
import { getLayerGroups, getStylesForLegend } from "../../../selectors/layerSelector";
import { useStyles } from "./styles";
import { getAllLayersVisibilityMap } from "../../../selectors/generalSelectors";

const LegendTool = () => {
    const classes = useStyles();
    const stylesForLegend = useSelector(getStylesForLegend);
    const digitizeStyles = useSelector(getDigitizeStyles);
    const layerGroups = useSelector(getLayerGroups);
    const [labels, setLabels] = useState([]);
    const allLayersVisibilityMap = useSelector(getAllLayersVisibilityMap);

    let visibleLayers = [];

    Object.keys(allLayersVisibilityMap).forEach((layerId) => {
        if (allLayersVisibilityMap[layerId]) {
            let foundLayer = labels.find((layerLabel) => layerLabel.layerId === layerId);
            if (foundLayer) {
                visibleLayers.push(foundLayer);
            }
        }
    });
    let renderedLabels = renderLabels(classes, visibleLayers);

    useEffect(() => {
        const digitizedGroup = {
            name: "Digitized",
            layers: digitizeStyles.map((dStyle) => ({
                sourceName: dStyle.name,
                name: dStyle.name,
                layerId: dStyle.layerId
            }))
        };
        const copyOfDigitizedStyles = digitizeStyles.map((style) => ({ ...style }));
        setLabels(
            convertStylesToLegend([...stylesForLegend, ...copyOfDigitizedStyles], [...layerGroups, digitizedGroup])
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stylesForLegend, digitizeStyles, layerGroups]);

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.root}>
            <Divider />
            <Grid container justify="flex-start" className={classes.title}>
                <Typography variant="h6">Legend</Typography>
            </Grid>
            <Divider />
            <div className={classes.labelsContainer}>{renderedLabels}</div>
        </Grid>
    );
};

export default LegendTool;

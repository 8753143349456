import * as types from "../actions/actionTypes";
import { mapZoomEnd, toggleGroupLayers } from "../actions/globalActions";

const mapInit = {
    layers: [],
    paints: {},
    layouts: {},
    sources: [],
    onClick: null,
    clickPos: { lat: 0, lng: 0 },
    measuring: false,
    print: false,
    loaded: false,
    zoom: 3,
    measureDist: 0,
    flyTo: { x: 0, y: 0 },
    fitBounds: { bbox: [], options: {} },
    printOutline: null,
    mapCoordinates: {},
    basemap: { type: "none" },
    moveLayer: null,
    language: "en"
};

export function mapReducer(state = mapInit, action) {
    switch (action.type) {
        case types.MAP_ADD_LAYER:
            return {
                ...state,
                layers: [...state.layers, action.result]
            };
        case types.INIT_MAP_RESOURCES:
            return {
                ...state,
                layers: action.payload.layers,
                paints: action.payload.paintsMap,
                layouts: action.payload.layoutsMap
            };
        case types.MAP_UPDATE_LAYER:
            return {
                ...state,
                layers: updateArraySetChanged(state.layers, action.result),
                paints: removeFromDict(state.paints, action.result.layerId),
                layouts: removeFromDict(state.layouts, action.result.layerId)
            };
        case types.MAP_REMOVE_LAYER:
            //action.result == layerId
            return {
                ...state,
                layers: removeFromArrayById(state.layers, action.result),
                paints: removeFromDict(state.paints, action.result),
                layouts: removeFromDict(state.layouts, action.result)
            };
        case types.MAP_MOVE_LAYER:
            return {
                ...state,
                moveLayer: action.result,
                layers: swapLayers(state.layers, action.result.layerId, action.result.beforeLayerId)
            };
        case types.MAP_UPDATE_ZOOMRANGE:
            return {
                ...state,
                layers: state.layers.map((layer) =>
                    layer.layerId === action.result.layerId
                        ? { ...layer, ...action.result, zoomRangeChanged: true }
                        : layer
                )
            };
        case types.MAP_ADD_PAINT:
            return {
                ...state,
                paints: { ...state.paints, [action.result.layerId]: action.result }
            };
        case types.MAP_UPDATE_PAINT:
            return {
                ...state,
                paints: updatePropertiesDict(state.paints, action.result)
            };
        case types.MAP_ADD_LAYOUT:
            return {
                ...state,
                layouts: { ...state.layouts, [action.result.layerId]: action.result }
            };
        case types.MAP_UPDATE_LAYOUT:
            return {
                ...state,
                layouts: updatePropertiesDict(state.layouts, action.result)
            };
        case types.MAP_FLY_TO:
            return {
                ...state,
                flyTo: action.result
            };
        case types.MAP_CLEAR:
            return {
                ...state,
                layouts: {},
                paints: {},
                layers: [],
                sources: []
            };
        case mapZoomEnd.type:
            return {
                ...state,
                zoom: action.payload
            };
        case toggleGroupLayers.type:
            const { groupLayersMap, newVisibility } = action.payload;
            return {
                ...state,
                layouts: {
                    ...state.layers.reduce((acc, layer) => {
                        acc[layer.layerId] = groupLayersMap[layer.resourceId]
                            ? changeLayoutVisibility(state.layouts[layer.layerId], newVisibility)
                            : state.layouts[layer.layerId];
                        return acc;
                    }, {})
                }
            };
        case types.MAP_TOGGLE_MEASURE:
            return {
                ...state,
                measuring: action.result,
                measureDist: 0
            };
        case types.MAP_SET_MEASUREDIST:
            return {
                ...state,
                measureDist: action.result
            };
        case types.MAP_LOADED:
            return {
                ...state,
                loaded: action.result
            };
        case types.MAP_CHANGE_CLICK:
            return {
                ...state,
                onClick: action.result
            };
        case types.MAP_CLICK:
            return {
                ...state,
                clickPos: action.result
            };
        case types.MAP_FIT_BOUNDS:
            return {
                ...state,
                fitBounds: action.result
            };
        case types.MAP_SET_BASEMAP:
            return {
                ...state,
                basemap: action.result
            };
        case types.MAP_SET_LANGUAGE:
            return {
                ...state,
                language: action.result
            };
        case types.MAP_ADD_SOURCES:
            return {
                ...state,
                sources: [...state.sources, ...action.result]
            };
        case types.SET_CONFIG:
            return {
                ...state,
                basemap: setBasemapInitial(action.result.basemaps),
                language: setLanguageInitial(action.result.languages)
            };
        default:
            return state;
    }
}

const changeLayoutVisibility = (layout, newVisibility) => {
    return {
        ...layout,
        properties: layout.properties.map((prop) =>
            prop.name === "visibility" ? { ...prop, value: newVisibility ? "visible" : "none" } : prop
        )
    };
};

const setBasemapInitial = (basemaps) => {
    let queryParams = new URLSearchParams(document.location.search);
    let basemapQueryParam = queryParams.get("basemap");

    if (basemapQueryParam) {
        return basemaps.find((basemap) => basemap.title === basemapQueryParam);
    } else {
        return basemaps[0];
    }
};

const setLanguageInitial = (languages) => {
    let queryParams = new URLSearchParams(document.location.search);
    let languageQueryParam = queryParams.get("language");

    return languages?.find((x) => x.id === languageQueryParam)?.id || languages?.find((x) => x.default).id || "en";
};

const swapLayers = (array, layerIdOne, layerIdTwo) => {
    const layerOne = array.find((value) => value.layerId === layerIdOne);
    const layerTwo = array.find((value) => value.layerId === layerIdTwo);

    return array.map((arrayValue) =>
        arrayValue.layerId === layerIdOne ? layerTwo : arrayValue.layerId === layerIdTwo ? layerOne : arrayValue
    );
};

function updatePropertiesDict(dict, item) {
    let newProps = item.properties.reduce((a, b) => {
        a[b.name] = b;
        return a;
    }, {});
    const correspondingItem = dict[item.layerId];
    return {
        ...dict,
        [item.layerId]: {
            ...correspondingItem,
            properties: correspondingItem.properties.map((prop) => {
                if (newProps.hasOwnProperty(prop.name)) {
                    return newProps[prop.name];
                }
                return prop;
            })
        }
    };
}

function removeFromArrayById(array, layerId) {
    return array.filter((x) => layerId !== x.layerId);
}

function removeFromDict(dict, key) {
    delete dict[key];
    return { ...dict };
}

function updateArraySetChanged(array, item) {
    return array.map((x) => {
        if (item.layerId === x.layerId) return { ...item, changed: true };
        return { ...x, changed: false };
    });
}
// eslint-disable-next-line no-unused-vars
function updateArray(array, item) {
    return array.map((x) => {
        if (item.layerId === x.layerId) return { ...item };
        return { ...x };
    });
}

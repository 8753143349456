import { requestZanderConnectionStart, setError, setExcelLink, startConnection } from "../reducers/zander";
import SignalRHub from "../utils/SignalRHub";

let hub;

const zanderMiddleware = ({ dispatch }) => {
    return (next) => (action) => {
        if (action.type === requestZanderConnectionStart.type) {
            if (hub) {
                return next(action);
            } else {
                initHub(dispatch);
            }
        }

        return next(action);
    };
};

const initHub = (dispatch) => {
    hub = new SignalRHub("/zanderhub");

    hub.onEvent("zanderDocument", (response) => {
        if (response.error) {
            dispatch(setError(response.errorMessage));
        } else {
            dispatch(setExcelLink(response.documentLink));
        }
    });

    hub.onReconnect((connectionId) => dispatch(startConnection(connectionId)));

    hub.start().then(() => {
        dispatch(startConnection(hub.connection.connectionId));
    });
};

export default zanderMiddleware;

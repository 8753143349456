import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import MapCopyright from "../../map/printMapExtraInfo/mapCopyright";
import MapTitle from "../../map/printMapExtraInfo/mapTitle";
import MapDate from "../../map/printMapExtraInfo/mapDate";
import MapLegend from "./printLegend";
import MapNotes from "../../map/printMapExtraInfo/mapNotes";
import { getPrintFeatures } from "../../../selectors/printSelectors";
import { getPrintToggleStatus } from "../../../selectors/toolsSelectors";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        backgroundColor: "#fff"
    },
    innerRoot: {
        border: "1px solid grey"
    },
    legendTitle: {
        backgroundColor: "#024F79",
        color: "#fff",
        fontSize: 10,
        width: "15%",
        textAlign: "center"
    },
    rightSidePanel: {
        borderColor: "grey",
        borderStyle: "solid",
        borderWidth: "1px 1px 1px 0"
    },
    sectionStyle: {
        backgroundColor: "#024F79",
        color: "#fff",
        fontSize: 20
    },
    notesSectionStyle: {
        backgroundColor: "#fff",
        borderTop: "1px solid lightgrey",
        color: "#000",
        fontSize: 20
    },
    legendContainer: {
        maxHeight: 240,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap"
    }
}));

const MapLegendPortrait = ({ renderedLabels }) => {
    const classes = useStyles();
    // eslint-disable-next-line
    const printFeatures = useSelector(getPrintFeatures);
    const modalOpen = useSelector(getPrintToggleStatus);

    const isRightPanelNecessary =
        printFeatures.showMapTitle ||
        printFeatures.showMapCopyright ||
        printFeatures.showMapDate ||
        printFeatures.showMapNotes;

    return (
        <Grid id="modal-info-portrait" container className={classes.root}>
            <Grid item xs={isRightPanelNecessary ? 10 : 12} className={classes.innerRoot}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={classes.legendTitle}>Legend</Typography>
                    </Grid>
                    <Grid item xs={12} id="legend-modal" className={classes.legendContainer}>
                        <MapLegend labels={renderedLabels} desiredNumberOfEntries={20} />
                    </Grid>
                </Grid>
            </Grid>

            {isRightPanelNecessary && (
                <Grid item xs={2} className={classes.rightSidePanel}>
                    <Grid container>
                        {/* TEMPORARILY DISABLED */}
                        {/* <Grid item xs={12}>
                            {printFeatures.showMapLogo && <MapLogo modal={modalOpen} />}
                        </Grid> */}
                        <Grid item xs={12} className={classes.sectionStyle}>
                            {printFeatures.showMapTitle && (
                                <MapTitle mapTitle={printFeatures.mapTitle} modal={modalOpen} />
                            )}
                        </Grid>
                    </Grid>
                    {printFeatures.showMapScalebar && <div id="map-scalebar"></div>}
                    {printFeatures.showMapCopyright && (
                        <MapCopyright
                            mapCopyright={printFeatures.mapCopyright}
                            modal={modalOpen}
                            showMapLegend={printFeatures.showMapLegend}
                        />
                    )}
                    {printFeatures.showMapDate && (
                        <MapDate
                            mapDateFormat={printFeatures.mapDateFormat}
                            showMapLegend={printFeatures.showMapLegend}
                            modal={modalOpen}
                        />
                    )}
                    <div className={classes.notesSectionStyle}>
                        {printFeatures.showMapNotes && <MapNotes mapNotes={printFeatures.mapNotes} modal={modalOpen} />}
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export default MapLegendPortrait;

import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Divider, Input, Typography, IconButton, InputAdornment } from "@material-ui/core";
import * as mapActions from "../../../actions/map";
import * as toolsActions from "../../../actions/tools";
import CloseIcon from "@material-ui/icons/Close";
import { Close } from "@material-ui/icons";
import { searchJss } from "./searchJss";

class Search extends Component {
    state = {
        search: "",
        error: null
    };

    onSearch = (e) => {
        this.setState({
            search: e.target.value
        });
    };

    clearSearch = () => {
        this.setState({
            search: ""
        });
    };

    setSearch = (x, y) => {
        this.setState({
            search: x.toFixed(6) + "," + y.toFixed(6)
        });
    };

    _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            let re = /-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?/;
            if (this.state.search.match(re) && this.state.search.match(re)[0] === this.state.search.match(re).input) {
                //lat and long coords are good
                let x = this.state.search.split(",")[0];
                let y = this.state.search.split(",")[1];
                if (x > 180 || x < -180) {
                    this.setState({
                        error: "X must be between -180 and 180 degrees"
                    });
                } else if (y > 90 || y < -90) {
                    this.setState({
                        error: "Y must be between -90 and 90 degrees"
                    });
                } else {
                    this.setState({ error: null });
                    this.props.flyTo({ x: x, y: y });
                }
            } else {
                this.setState({
                    error: 'Please enter X and Y in the format "DD.DDDDD"'
                });
            }
        }
    };

    handleClose = () => {
        let widget = {
            name: "search",
            namePretty: "Search",
            enabled: true,
            toggled: false
        };
        this.props.toggleWidget(widget);
    };

    componentDidUpdate(prevProps) {
        if (this.state.mapLoaded) {
            return;
        }
        if (
            prevProps.mapState.clickPos.lng !== this.props.mapState.clickPos.lng ||
            prevProps.mapState.clickPos.lat !== this.props.mapState.clickPos.lat
        ) {
            this.setSearch(this.props.mapState.clickPos.lng, this.props.mapState.clickPos.lat);
            return;
        }
    }

    render() {
        let { classes } = this.props;

        return (
            <div id="search-widget" className={classes.root}>
                <Divider />
                <div className={classes.header}>
                    <Typography className={classes.headerText} variant="h6">
                        Search Location
                    </Typography>
                    <IconButton onClick={() => this.handleClose()}>
                        <Close />
                    </IconButton>
                </div>
                <Divider />
                <div className={classes.searchContainer}>
                    <Input
                        onKeyDown={this._handleKeyDown}
                        id="full-width"
                        // inputProps={{ autoComplete: "off" }}
                        placeholder="X, Y (ex: 12.34,56.78)"
                        value={this.state.search}
                        onChange={this.onSearch}
                        fullWidth
                        style={{ fontSize: 14 }}
                        endAdornment={
                            <InputAdornment position="end">
                                {this.state.search !== "" && (
                                    <IconButton
                                        className={
                                            classes.noPadButton + " " + classes.squareBtn + " " + classes.closeBtn
                                        }
                                        onClick={this.clearSearch}
                                        size="small"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        }
                    />
                    {this.state.error && (
                        <Typography variant="body2" className={classes.searchError}>
                            {this.state.error}
                        </Typography>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mapState: state.map
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        flyTo: (location) => dispatch(mapActions.flyTo(location)),
        toggleWidget: (widget) => dispatch(toolsActions.toggleWidget(widget))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(searchJss)(Search));

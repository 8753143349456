import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import Download from "downloadjs";
import tokml from "tokml";
import { getDraw } from "../../map/map";
import { useSelector, useDispatch } from "react-redux";
import JSZip from "jszip";
import { getDigitizeFeatures, getDigitizeLayersForDownload } from "../../../selectors/digitizeSelectors";
import { convertAndDownload } from "../../../actions/download";
import fileFormats from "../../../constants/files/fileFormats";
import toastr from "../../../utils/customToastr";
import { fileTypeToDownloadEnum } from "../../../utils/files/fileTypeToDownloadEnum";
import { fileTypeToDownloadExtension } from "../../../utils/files/fileTypeToDownloadExtension";
import { fileTypes } from "../../../constants/files/fileTypes";
import sanitize from "sanitize-filename";

const fileTypeOptions = [fileTypes.GEO_JSON, fileTypes.GEO_PACKAGE, fileTypes.SHAPEFILE];

const useStyles = makeStyles((theme) => ({
    dialogLayerName: {
        display: "inline-flex",
        color: "#024F79",
        fontSize: "large"
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "center"
    },
    dialogContentContainer: {
        maxWidth: 400,
        display: "flex",
        justifyContent: "center",
        padding: 16
    },
    downloadDialogSelect: {
        width: 100
    },
    radioGroupContainer: {
        marginTop: theme.spacing(2)
    },
    radioGroup: {
        maxWidth: 500
    },
    disablePointerEvents: {
        pointerEvents: "none"
    }
}));

const DownloadDialog = ({ onClose, open, downloadAllLayers, layer = null }) => {
    const classes = useStyles();
    const [selectedDownloadDialogValue, setSelectedDownloadDialogValue] = useState(fileTypeOptions[0]);
    const layers = useSelector(getDigitizeLayersForDownload(downloadAllLayers));
    const digitizeFeatures = useSelector(getDigitizeFeatures);

    const dispatch = useDispatch();

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        setSelectedDownloadDialogValue(e.target.value);
    };

    const downloadWithBackend = (featureCollection, fileName) => {
        const fileBlob = new Blob([JSON.stringify(featureCollection)], {
            type: "application/json"
        });
        const fileExtension = fileTypeToDownloadExtension(selectedDownloadDialogValue);

        dispatch(
            convertAndDownload(
                fileBlob,
                fileFormats.GEO_JSON,
                fileTypeToDownloadEnum(selectedDownloadDialogValue),
                fileName
            )
        )
            .then((blob) => {
                Download(blob, `${fileName}.${fileExtension}`);
            })
            .catch((err) => {
                toastr.error(err.message);
            });
    };

    const downloadLayerFeatures = () => {
        let featuresToBeDownloadedObject;
        if (!!layer) {
            let drawnFeatures = getDraw().getAll();
            let customDownloadFeatures = drawnFeatures.features.filter((drawnFeature) =>
                layer.features.includes(drawnFeature.id)
            );
            featuresToBeDownloadedObject = { ...drawnFeatures };
            featuresToBeDownloadedObject.features = customDownloadFeatures;
        } else {
            featuresToBeDownloadedObject = getDraw().getAll();
        }

        featuresToBeDownloadedObject.features = featuresToBeDownloadedObject.features.map((feature) => ({
            ...feature,
            properties: { fid: feature.id }
        }));

        const featureNameAndIdMap = digitizeFeatures.reduce((acc, feature) => {
            acc[feature.id] = feature.properties.name;
            return acc;
        }, {});

        const featureIdMap = featuresToBeDownloadedObject.features.reduce((acc, feature) => {
            acc[feature.id] = {
                ...feature,
                properties: { ...feature.properties, name: featureNameAndIdMap[feature.id] }
            };
            return acc;
        }, {});

        const targetLayers = layer ? [layer] : layers;

        var zip = new JSZip();
        switch (selectedDownloadDialogValue) {
            case fileTypes.GEO_JSON:
            case fileTypes.GEO_PACKAGE:
                targetLayers.forEach((layer) => {
                    const sanitizedFileName = sanitize(layer.name);
                    const featureCollection = {
                        type: "FeatureCollection",
                        name: sanitizedFileName,
                        features: []
                    };
                    layer.features.forEach((featureId) => {
                        featureCollection.features.push({
                            ...featureIdMap[featureId],
                            name: featureNameAndIdMap[featureId]
                        });
                    });
                    zip.file(
                        generateFileNameAndExtension(sanitizedFileName, selectedDownloadDialogValue, zip),
                        generateFileContentFromFeatures(featureCollection, selectedDownloadDialogValue)
                    );
                });
                zip.generateAsync({ type: "blob" }).then((blob) => {
                    Download(blob, "DigitizeLayers.zip");
                });
                break;
            case fileTypes.CSV:
            case fileTypes.SHAPEFILE:
                targetLayers.forEach((layer) => {
                    const sanitizedFileName = sanitize(layer.name);
                    const featureCollection = {
                        type: "FeatureCollection",
                        name: sanitizedFileName,
                        features: []
                    };
                    layer.features.forEach((featureId) => {
                        featureCollection.features.push({
                            ...featureIdMap[featureId],
                            name: featureNameAndIdMap[featureId]
                        });
                    });

                    downloadWithBackend(featureCollection, sanitizedFileName);
                });
                break;
            default:
                break;
        }
    };

    const generateFileNameAndExtension = (layerName, fileType, zip) => {
        const fileExtension = fileTypeToDownloadExtension(fileType);
        let fileName = layerName;
        let fileNameRegex = new RegExp(fileName + "." + fileExtension);
        let fileIndex = 0;

        while (zip.file(fileNameRegex).length) {
            fileIndex += 1;
            fileNameRegex = new RegExp(fileName + `\\(${fileIndex}\\)` + "." + fileExtension);
        }

        fileName = fileIndex === 0 ? fileName : fileName + `(${fileIndex})`;
        return fileName + "." + fileExtension;
    };

    const isCsvEnabled = layer ? layer?.type === "point" : !layers.some((dLayer) => dLayer.type !== "point");

    //returns a string
    const generateFileContentFromFeatures = (featureCollection, fileType) => {
        switch (fileType) {
            case "GeoJSON":
                return JSON.stringify(featureCollection);
            case "KML":
                return tokml(featureCollection);
            default:
                return null;
        }
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="digitize-download-dialog" open={open}>
            {layer ? (
                <DialogTitle id="digitize-download-dialog-title" className={classes.dialogTitle}>
                    Download features for{" "}
                    <Typography className={classes.dialogLayerName}>{layer.namePretty}</Typography>
                </DialogTitle>
            ) : downloadAllLayers ? (
                <DialogTitle id="digitize-download-all-dialog-title" className={classes.dialogTitle}>
                    Download all layers.
                </DialogTitle>
            ) : (
                <DialogTitle id="digitize-download-all-dialog-title" className={classes.dialogTitle}>
                    Download selected layers.
                </DialogTitle>
            )}
            <DialogContent className={classes.dialogContentContainer}>
                <Grid container alignItems="center">
                    <Grid item xs={6}>
                        <Select
                            labelId="digitize-select-file-type-label"
                            id="digitize-select-file-type"
                            value={selectedDownloadDialogValue}
                            onChange={handleChange}
                            className={classes.downloadDialogSelect}
                        >
                            {fileTypeOptions.map((fileType) => (
                                <MenuItem key={fileType} value={fileType}>
                                    {fileType}
                                </MenuItem>
                            ))}
                            {isCsvEnabled && <MenuItem value={fileTypes.CSV}>{fileTypes.CSV}</MenuItem>}
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="flex-end">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<GetApp />}
                                onClick={downloadLayerFeatures}
                            >
                                Download
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DownloadDialog;

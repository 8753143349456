import Digitize from "../components/sidebar/digitize/digitize";
import LayerSelector from "../components/sidebar/layerSelector/layerSelector";
import LegendTool from "../components/sidebar/legend/legend";
import { DIGITIZE_PATH_SUFFIX, LAYER_SELECTOR_PATH_SUFFIX, LEGEND_PATH_SUFFIX } from "./routes";

export const digitizeTool = { routeSuffix: DIGITIZE_PATH_SUFFIX, component: Digitize };
export const legendTool = { routeSuffix: LEGEND_PATH_SUFFIX, component: LegendTool };
export const layerSelectorTool = {
    routeSuffix: LAYER_SELECTOR_PATH_SUFFIX,
    component: LayerSelector
};

const toolsDict = {
    digitize: digitizeTool,
    legend: legendTool,
    "layer-selector": layerSelectorTool
};
export default toolsDict;

import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        position: "absolute",
        bottom: 10,
        zIndex: 1,
        textAlign: "center",
        width: "100%"
    },
    notes: {
        padding: 6,
        backgroundColor: "rgba(255,255,255,0.90)",
        borderRadius: "5px 0 5px 0",
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        maxWidth: "13vw",
        wordBreak: "break-all",
        fontSize: "0.75rem"
    },
    rootLegend: {
        position: "relative"
    },
    notesLegend: {
        margin: "auto",
        padding: 4,
        textAlign: "center",
        maxWidth: "100%",
        wordBreak: "break-all",
        borderTop: ({ orientation }) => (orientation === "portrait" ? "0" : "1px solid grey"),
        fontSize: ({ orientation }) => (orientation === "portrait" ? 10 : 16)
    }
}));

const MapNotes = ({ mapNotes, modal = false, orientation = "portrait" }) => {
    const classes = useStyles({ orientation });
    return (
        <div id="map-notes" className={`${modal ? classes.rootLegend : classes.root}`}>
            <Typography className={`${modal ? classes.notesLegend : classes.notes}`}>{mapNotes}</Typography>
        </div>
    );
};

export default MapNotes;

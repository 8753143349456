import { Button, IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { getIsDrawerOpen } from "../../../../../selectors/sidebarAndDrawer";
import { useNavButtonStyles } from "./navButtonStyles";

const NavButton = ({ onClick, icon, name, active }) => {
    const classes = useNavButtonStyles();
    const isDrawerOpen = useSelector(getIsDrawerOpen);

    return isDrawerOpen ? (
        <Button
            onClick={onClick}
            className={`${classes.navOpen} 
                ${active ? classes.actionButtonActive : classes.actionButton}`}
        >
            <span className={classes.toolIcon}>{icon}</span>
            <span className="tool-label">{name}</span>
        </Button>
    ) : (
        <Tooltip title={name} placement="left">
            <IconButton onClick={onClick} className={active ? classes.actionButtonActive : classes.actionButton}>
                {icon}
            </IconButton>
        </Tooltip>
    );
};

export default NavButton;

import * as types from "./actionTypes";
import axiosClient from "./mapboxClient";
import Config from "../config";

export function getStyle(stylePath) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_MAPBOX_STYLE });
        return axiosClient
            .get("styles/v1/" + stylePath + "?access_token=" + Config.mapboxApiKey)
            .then(function (res) {
                return dispatch({
                    type: types.FETCH_MAPBOX_STYLE_COMPLETED,
                    result: res.data
                });
            })
            .catch(() => {
                dispatch({ type: types.FETCH_MAPBOX_STYLE_COMPLETED });
                return null;
            });
    };
}

import axiosClient, { generateCancelToken } from "./apiClient";
import Download from "downloadjs";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const downloadDataset = createAsyncThunk("downloadDataset", async ({ datasetId, filename, format, config }) => {
    return axiosClient
        .get(`dataset/${datasetId}/download/${format}`, {
            ...config,
            responseType: "blob"
        })
        .then((res) => {
            Download(res.data, filename);
            return res.data;
        });
});

export const getLayerData = createAsyncThunk("getLayerData", async ({ datasetId, page, cancelToken }) => {
    return axiosClient.get(`dataset/${datasetId}/geojson/${page}`, { cancelToken }).then((res) => {
        return res.data.features || [];
    });
});

export const getLayerMetadata = (datasetId) => {
    return () => {
        return axiosClient
            .get(`dataset/meta/${datasetId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw err;
            });
    };
};

export const getMetadataSchema = () => {
    return () => {
        return axiosClient
            .get("metadata")
            .then((res) => res.data)
            .catch((err) => {
                throw err;
            });
    };
};

import { makeStyles } from "@material-ui/core";

export const useInfoTablePortalStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        borderRadius: 0,
        width: "100%",
        height: "100%",
        outline: "none"
    },
    attributeTableContainer: {
        height: "100%"
    },
    tableContainer: {
        height: "calc(100% - 50px)"
    },
    modalHeader: {
        position: "relative",
        backgroundColor: "#024F79",
        padding: theme.spacing(1),
        color: "#fff",
        textAlign: "center"
    },
    headerText: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff"
    },
    layerName: {
        padding: 5,
        fontSize: "1.25rem",
        color: "#e0e0e0"
    },
    downloadDialogSelectContainer: {
        padding: 20
    },
    downloadDialogSelect: {
        width: "40%"
    },
    modalText: {
        textAlign: "center",
        padding: 20
    },
    layerNameDownloadModal: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        fontWeight: "bold"
    },
    errorModalText: {
        textAlign: "center"
    },
    loaderContainer: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    closeButton: {
        position: "absolute",
        top: 0,
        right: 0,
        color: "#fff"
    }
}));

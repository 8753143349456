import { Button, Chip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import toastr from "../../../../utils/customToastr";
import { TextField } from "@material-ui/core";
import { useStyles } from "./styles";
import { MAX_LIMITS } from "../../../../constants/zander/constants";

const LimitsCreator = ({ limits, onLimitsChanged, validationSchema, text, displayDecimals }) => {
    const [limitValue, setLimitValue] = useState("");
    const [error, setError] = useState(false);
    const classes = useStyles();

    const shouldRenderInputField = limits.length < MAX_LIMITS;

    const onLimitRemove = (limit) => onLimitsChanged(limits.filter((x) => x.value !== limit.value).map((x) => x.value));

    const onAddLimit = () => {
        let newLimits = [...limits.map((l) => l.value), limitValue];
        newLimits = newLimits.sort((a, b) => a - b);

        validationSchema
            .validate(newLimits)
            .then((newLimits) => {
                onLimitsChanged(newLimits);
            })
            .catch((error) => {
                setError(true);
                toastr.error(error.errors.join("\n"));
            });
    };

    const onLimitKeyUp = (e) => {
        if (e.key === "Enter") {
            onAddLimit();
        }
    };

    const onLimitValueChanged = (e) => {
        setLimitValue(parseFloat(e.target.value).toFixed(2));
        setError(false);
    };

    const limitToChip = (limit, index) => {
        let label = displayDecimals ? limit.value.toFixed(2) : limit.value;

        return (
            <Chip
                key={index}
                label={label}
                variant="outlined"
                className={`${classes.chip} ${limit.error ? classes.chipError : ""}`}
                size="small"
                onDelete={() => onLimitRemove(limit)}
                variant="contained"
                deleteIcon={<CloseIcon />}
            />
        );
    };

    const chipTextFieldInputProps = {
        classes: {
            input: classes.percentilesInput
        }
    };

    const onDragStart = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div className={classes.limitCreator}>
            {limits.map((limit, index) => limitToChip(limit, index))}
            <div className={classes.grow}></div>
            {shouldRenderInputField && (
                <>
                    <TextField
                        draggable
                        variant="outlined"
                        size="small"
                        className={classes.inputChipTextField}
                        InputProps={chipTextFieldInputProps}
                        error={error}
                        onDragStart={onDragStart}
                        onChange={onLimitValueChanged}
                        onKeyUp={onLimitKeyUp}
                    />
                    <Button variant="text" color="primary" disabled={limitValue === ""} onClick={onAddLimit}>
                        <AddIcon className={classes.icon} fontSize="small" />
                        {text}
                    </Button>
                </>
            )}
        </div>
    );
};

export default LimitsCreator;

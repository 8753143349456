import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import * as mapActions from "../../../actions/map";

import OpenIcon from "@material-ui/icons/Layers";
import CloseIcon from "@material-ui/icons/Clear";
import { Typography } from "@material-ui/core";

import { convertStylesToLegend, renderLabels } from "../../../utils/legendUtils";
import { history } from "../../../store";
import { getStylesForLegend } from "../../../selectors/layerSelector";
import { legendJss } from "./jss/legendJss";
import { getPrintToggleStatus } from "../../../selectors/toolsSelectors";
import { getAllLayersVisibilityMap } from "../../../selectors/generalSelectors";
import { LEGEND_PATH_SUFFIX } from "../../../constants/routes";

class Legend extends Component {
    state = {
        labels: [],
        open: false
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.stylesForLegend !== prevProps.stylesForLegend ||
            this.props.styleState.digitizeStyles !== prevProps.styleState.digitizeStyles
        ) {
            const digitizeStyles = this.props.styleState.digitizeStyles;
            const digitizedGroup = {
                name: "Digitized",
                layers: digitizeStyles.map((dStyle) => ({
                    sourceName: dStyle.name,
                    name: dStyle.name,
                    layerId: dStyle.layerId
                }))
            };
            const copyOfDigitizedStyles = digitizeStyles.map((style) => ({ ...style }));
            let labels = convertStylesToLegend(
                [...this.props.stylesForLegend, ...copyOfDigitizedStyles],
                [...this.props.layerGroups, digitizedGroup]
            );

            this.setState({ labels });
        }
        if (this.props.printFeatures.showMapLegend !== prevProps.printFeatures.showMapLegend) {
            this.setState({ open: this.props.printFeatures.showMapLegend });
        }
        history.listen((location) => {
            let pathLocation = location.pathname.split("/");
            pathLocation = pathLocation[pathLocation.length - 1];
            if (pathLocation === LEGEND_PATH_SUFFIX) {
                this.setState({ open: false });
            }
        });
    }

    componentDidMount() {
        let pathLocation = history.location.pathname.split("/");
        pathLocation = pathLocation[pathLocation.length - 1];
        if (pathLocation === LEGEND_PATH_SUFFIX) {
            this.setState({ open: false });
        }
    }

    onOpenToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    render() {
        let { classes, allLayersVisibilityMap } = this.props;
        let visibleLayers = [];
        Object.keys(allLayersVisibilityMap).forEach((layerId) => {
            if (allLayersVisibilityMap[layerId]) {
                let foundLayer = this.state.labels.find((layerLabel) => layerLabel.layerId === layerId);
                if (foundLayer) {
                    visibleLayers.push(foundLayer);
                }
            }
        });
        let labels = renderLabels(classes, visibleLayers);
        if (labels.length === 0) {
            return null;
        }
        return (
            <>
                {!this.props.modalOpen && (
                    <div className={this.state.open ? classes.root : classes.rootClosed}>
                        <div
                            size="small"
                            onClick={this.onOpenToggle}
                            className={this.state.open ? classes.closeButton : classes.openButton}
                        >
                            {this.state.open && <Typography className={classes.headerText}>Legend</Typography>}
                            {this.state.open ? <CloseIcon style={{ width: 20 }}></CloseIcon> : <OpenIcon></OpenIcon>}
                        </div>
                        <div className={classes.labels} style={{ display: this.state.open ? "block" : "none" }}>
                            {labels}
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        styleState: state.style,
        stylesForLegend: getStylesForLegend(state),
        layerGroups: state.layerSelector.layerGroups,
        printFeatures: state.print.printFeatures,
        modalOpen: getPrintToggleStatus(state),
        allLayersVisibilityMap: getAllLayersVisibilityMap(state)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateMapPaint: (paint) => dispatch(mapActions.updatePaint(paint))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(legendJss)(Legend));

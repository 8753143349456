import React, { Component } from "react";
import { connect } from "react-redux";
import windfarms from "../../../utils/windfarms.json";
import { withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { getMap } from "../../map/map";
import TextField from "@material-ui/core/TextField";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

import * as atlasActions from "../../../actions/atlasClient";

import toBbox from "@turf/bbox";

import Config from "../../../config";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

const styles = (theme) => ({
    root: {
        position: "absolute",
        top: 10,
        right: 49,
        minWidth: 300,
        zIndex: 2,
        borderRadius: 10,
        backgroundColor: "white",
        [theme.breakpoints.down("sm")]: {
            minWidth: 235
        }
    },
    content: {
        height: 44
    },
    headerText: {
        flexGrow: 1
    },
    squareBtn: {
        borderRadius: 0
    },
    noPadButton: {
        padding: 0
    },
    inputRoot: {
        paddingLeft: 6
    }
});

class SearchLayer extends Component {
    getBbox = (bounds, coord) => {
        if (bounds[0] < coord[0]) bounds[0] = coord[0];
        if (bounds[2] > coord[0]) bounds[2] = coord[0];
        if (bounds[1] < coord[1]) bounds[1] = coord[1];
        if (bounds[3] > coord[1]) bounds[3] = coord[1];
        return bounds;
    };

    zoomTo = (e, value) => {
        if (value) {
            let map = getMap();
            let bbox = toBbox(value.geometry);

            map.fitBounds(bbox, {
                padding: 200
            });
        }
    };

    render() {
        let { classes } = this.props;
        let defaultProps = {
            options: windfarms.features,
            getOptionLabel: (option) => option.properties.wf_name
        };
        const filterOptions = createFilterOptions({
            matchFrom: "any",
            stringify: (option) => option.properties.wf_name
        });

        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <Autocomplete
                        {...defaultProps}
                        onChange={this.zoomTo}
                        id="disable-open-on-focus"
                        classes={{ inputRoot: classes.inputRoot }}
                        disableOpenOnFocus
                        focusedTag={false}
                        filterOptions={filterOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true
                                }}
                                placeholder="Search windfarm"
                                margin="normal"
                            />
                        )}
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.properties.wf_name, inputValue);
                            const parts = parse(option.properties.wf_name, matches);

                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapState: state.map,
        auth: state.auth,
        widgets: state.tools.widgets
    };
};

export default connect(mapStateToProps)(withStyles(styles)(SearchLayer));

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import * as atlasActions from "../../../../actions/atlasClient";

import Modal from "@material-ui/core/Modal";
import { Tabs, Tab, Select, IconButton, Tooltip, Menu } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import PaletteOutlinedIcon from "@material-ui/icons/PaletteOutlined";

import * as ColorUtils from "../../../../utils/colorUtils";

const StyledTabs = withStyles({
    root: {
        height: 32,
        minHeight: 32
    },
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent"
    }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        height: 32,
        minHeight: 32,
        width: "50%",
        padding: 0,

        "&:focus": {
            opacity: 1
        },
        "&:hover": {
            filter: "brightness(75%)"
        }
    },
    selected: {
        backgroundColor: theme.palette.background,
        "&:hover": {
            backgroundColor: "#f1f0f0",
            filter: "none"
        }
    }
}))((props) => <Tab {...props} />);

let gradients = [
    {
        name: "Greens",
        start: "#FFFFFF",
        end: "#013220"
    },
    {
        name: "Blues",
        start: "#FFFFFF",
        end: "#00008b"
    },
    {
        name: "Reds",
        start: "#FFFFFF",
        end: "#8b0000"
    },
    {
        name: "Grays",
        start: "#FFFFFF",
        end: "#000000"
    },
    {
        name: "Blue Red",
        start: "#00008b",
        end: "#8b0000"
    }
];

class GraduateModal extends Component {
    state = {
        page: "column",
        selectedColumn: "",
        minValue: 0.0,
        maxValue: 0.0,
        steps: 5,
        stepValues: [],
        colorRamp: {
            start: "#FFFFFF",
            end: "#013220"
        }
    };

    changePage(page) {
        this.setState({
            page: page
        });
    }

    onFinished = (e) => {
        let interpolateArray = ["interpolate", ["linear"], ["get", this.state.selectedColumn]];

        for (let i = 0; i < this.state.stepValues.length; i++) {
            let step = this.state.stepValues[i];
            interpolateArray.push(parseFloat(step.value), step.color);
        }

        this.props.onClose();
        this.props.onFinish(interpolateArray);
    };

    onColumnChange = (e) => {
        this.setState({
            selectedColumn: e.target.value
        });
    };

    onColumnNextClick = () => {
        this.props.getMinMaxColumnValues(this.props.datasetId, this.state.selectedColumn).then((res) => {
            let values = this.calculateSteps(this.state.steps, res.result.minValue, res.result.maxValue);

            this.setState({
                minValue: res.result.minValue,
                maxValue: res.result.maxValue,
                page: "graduate",
                stepValues: values
            });
            // for (let i = 0; i < res.result.length; i++) {
            //     let uniqueColumnValue = res.result[i];
            //     matchArray.push(uniqueColumnValue, colors[i]);
            // }
            // matchArray.push("#FFFFFF");
            // this.props.onPropertyExpressionTypeChanged("match", matchArray);
            // this.setState({
            //     categoriseDialogOpen: false
            // });
        });
    };

    calculateSteps(steps, minValue, maxValue) {
        let singleStepValue = Math.abs(minValue - maxValue) / (steps - 1);

        let colors = ColorUtils.interpolateColors(this.state.colorRamp.start, this.state.colorRamp.end, steps - 2);

        let stepValues = [];

        //Add min value
        stepValues.push({
            index: 0,
            color: colors[0],
            value: minValue.toFixed(2)
        });

        for (let i = 1; i < steps - 1; i++) {
            stepValues.push({
                index: i,
                color: colors[i],
                value: (singleStepValue * i + minValue).toFixed(2)
            });
        }

        //Add max value
        stepValues.push({
            index: steps,
            color: colors[steps - 1],
            value: maxValue.toFixed(2)
        });

        return stepValues;
    }

    onStepsChange = (e) => {
        let newSteps = parseInt(e.target.value);

        if (newSteps < 2) {
            return;
        }

        let values = this.calculateSteps(newSteps, this.state.minValue, this.state.maxValue);

        this.setState({
            steps: newSteps,
            stepValues: values
        });

        return false;
    };

    validColumnType = (columnType) => {
        switch (columnType.toLowerCase()) {
            case "smallint":
            case "integer":
            case "bigint":
            case "decimal":
            case "numeric":
            case "real":
            case "double precision":
            case "serial":
            case "bigserial":
                return true;
            default:
                return false;
        }
    };

    onOpenColorRampMenu = (e) => {
        this.setState({
            colorRampMenuAnchor: e.target
        });
    };

    onCloseColorRampMenu = (e) => {
        this.setState({
            colorRampMenuAnchor: null
        });
    };

    onInvert = () => {
        this.setState(
            {
                colorRamp: {
                    start: this.state.colorRamp.end,
                    end: this.state.colorRamp.start
                }
            },
            () => {
                let values = this.calculateSteps(this.state.steps, this.state.minValue, this.state.maxValue);

                this.setState({
                    stepValues: values
                });
            }
        );
    };

    onColorRampSelected = (colorRamp) => {
        this.setState(
            {
                colorRamp: {
                    start: colorRamp.start,
                    end: colorRamp.end
                }
            },
            () => {
                let values = this.calculateSteps(this.state.steps, this.state.minValue, this.state.maxValue);

                this.setState({
                    stepValues: values
                });
            }
        );
        this.onCloseColorRampMenu();
    };

    render() {
        let { props } = this;

        let columns = props.columns
            .filter((x) => this.validColumnType(x.type))
            .map((item, index) => {
                return (
                    <MenuItem key={index} value={item.name} className="menu-item-flex">
                        <div className="name">{item.name}</div>
                        <span className="type">{item.type}</span>
                    </MenuItem>
                );
            });

        let values = this.state.stepValues.map((step) => {
            return (
                <div className="step-container">
                    {/* <div className="step-index">{step.index}</div> */}
                    <div className="step-value">{step.value}</div>
                    <div className="step-color" style={{ backgroundColor: step.color }}></div>
                </div>
            );
        });

        let colorRamps = gradients.map((gradient, index) => {
            return (
                <MenuItem key={index} onClick={() => this.onColorRampSelected(gradient)}>
                    <Typography variant="body2" className="color-ramp-name">
                        {gradient.name}
                    </Typography>
                    <div
                        className="color-ramp-gradient"
                        style={{ background: `linear-gradient(to right,${gradient.start},${gradient.end})` }}
                    ></div>
                </MenuItem>
            );
        });

        return (
            <Modal
                className="modal graduate-style-modal"
                open={props.open}
                onClose={props.onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={props.open}>
                    <div className="modal-container">
                        <Typography variant="h6" id="transition-modal-title" className="header">
                            Graduate
                        </Typography>
                        {/* {this.state.uploading && <LinearProgress className="no-margin-progress" />} */}
                        <StyledTabs value={this.state.page} TabIndicatorProps={<div />}>
                            <StyledTab label="column" onClick={() => this.changePage("column")} value="column" />
                            <StyledTab label="graduate" onClick={() => this.changePage("graduate")} value="graduate" />
                        </StyledTabs>
                        {this.state.page === "column" && (
                            <div className="page">
                                <Typography variant="body1">Column</Typography>

                                <Select value={this.state.selectedColumn} onChange={this.onColumnChange} fullWidth>
                                    {columns}
                                </Select>
                                <Button
                                    className="next-button"
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => this.onColumnNextClick()}
                                >
                                    Next
                                </Button>
                            </div>
                        )}

                        {this.state.page === "graduate" && (
                            <div className="page">
                                <div className="steps-container">
                                    <Typography variant="body1">Steps</Typography>
                                    <TextField
                                        id="full-width"
                                        inputProps={{ autoComplete: "no" }}
                                        type="number"
                                        placeholder="Number of steps in graduation"
                                        value={this.state.steps}
                                        onChange={this.onStepsChange}
                                        fullWidth
                                        margin="none"
                                    />
                                </div>
                                <div className="actions">
                                    <Tooltip title="invert">
                                        <IconButton onClick={this.onInvert}>
                                            <CachedOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Color Ramps">
                                        <IconButton onClick={this.onOpenColorRampMenu}>
                                            <PaletteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.colorRampMenuAnchor}
                                        keepMounted
                                        open={Boolean(this.state.colorRampMenuAnchor)}
                                        onClose={this.onCloseColorRampMenu}
                                    >
                                        {colorRamps}
                                    </Menu>
                                </div>
                                <div className="scroll-container">{values}</div>

                                <Button
                                    className="next-button"
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => this.onFinished()}
                                >
                                    Finish
                                </Button>
                            </div>
                        )}
                    </div>
                </Fade>
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapState: state.map
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMinMaxColumnValues: (datasetId, columnName) =>
            dispatch(atlasActions.getMinMaxColumnValues(datasetId, columnName))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GraduateModal);

import { createSelector } from "reselect";

const digitizeState = (state) => state.digitize;

const digitizeFeatures = (state) => state.digitize.features;

const digitizeLayers = (state) => state.digitize.layers;

export const getDigitizeStateEditingStatus = createSelector(digitizeState, (digitizeState) => digitizeState.editing);

export const getDigitizeStateInfoHelp = createSelector(digitizeState, (digitizeState) => digitizeState.infoHelp);

export const getDigitizeLayers = createSelector(digitizeState, (digitizeState) => digitizeState.layers);

export const getEditing = createSelector(digitizeState, (digitizeState) => digitizeState.editing);

export const getSelectedFeature = createSelector(digitizeState, (digitizeState) => digitizeState.getSelectedFeature);

export const getDigitizeFeatures = createSelector(digitizeFeatures, (features) => features);

export const getDigitizeLayersForDownload = (downloadAll) =>
    createSelector(digitizeState, (digitizeState) =>
        downloadAll ? digitizeState.layers : digitizeState.layers.filter((layer) => layer.selected)
    );

export const getNumberOfSelectedDigitizeLayers = createSelector(
    digitizeLayers,
    (layers) => layers.filter((layer) => layer.selected).length
);

export const getDigitizeFeaturesForLayer = (layerId) =>
    createSelector(digitizeFeatures, (digitizeFeatures) =>
        digitizeFeatures.filter((digitizeFeature) => digitizeFeature.properties.layerId === layerId)
    );

import * as types from "../actions/actionTypes";

const styleInit = {
    appId: null,
    digitizeStyles: []
};

export function styleReducer(state = styleInit, action) {
    switch (action.type) {
        case types.ADD_DIGITIZE_STYLE:
            return {
                ...state,
                digitizeStyles: [...state.digitizeStyles, action.result]
            };
        case types.UPDATE_DIGITIZE_STYLE:
            return {
                ...state,
                digitizeStyles: updateStyle(state.digitizeStyles, { ...action.result })
            };
        case types.REMOVE_DIGITIZE_STYLE:
            return {
                ...state,
                digitizeStyles: removeFromArray(state.digitizeStyles, action.result)
            };
        case types.REMOVE_ALL_DIGITIZE_STYLES_FOR_LAYER: //action.result is an id (datasetId)
            return {
                ...state,
                digitizeStyles: removeFromArrayAfterBaseLayerId(state.digitizeStyles, action.result)
            };
        case types.RENAME_DIGITIZE_STYLES_NAME_FOR_LAYER:
            return {
                ...state,
                digitizeStyles: changeLayerNameForStyles(state.digitizeStyles, action.result)
            };
        case types.REINITIALIZE_DIGITIZE_STYLES_LAYER_ID:
            return {
                ...state,
                digitizeStyles: reinitializeDigitizeStylesLayerId(state.digitizeStyles, action.result)
            };
        default:
            return state;
    }
}
const changeLayerNameForStyles = (stylesArray, layer) => {
    return stylesArray.map((style) =>
        style.datasetId === layer.datasetId ? { ...style, name: layer.name, sourceName: layer.name } : style
    );
};

function updateStyle(array, item) {
    return array.map((style) => {
        if (style.layerId === item.layerId) {
            return {
                ...style,
                properties: item.properties,
                paint: item.properties.filter((x) => x.type === "paint"),
                layout: item.properties.filter((x) => x.type === "layout"),
                //name and sourceName are updated for digitize layer
                name: item.name || style.name,
                sourceName: item.sourceName || style.sourceName
            };
        }
        return style;
    });
}

function removeFromArray(array, item) {
    return array.filter((x) => item.layerId !== x.layerId);
}

function removeFromArrayAfterBaseLayerId(array, datasetId) {
    return array.filter((x) => datasetId !== x.layerId.split(":")[0]);
}

function reinitializeDigitizeStylesLayerId(array, layer) {
    let count = -1;
    return array.map((style) => {
        count += 1;
        return style.datasetId === layer.datasetId ? { ...style, layerId: `${style.datasetId}:${count}` } : style;
    });
}

import { createSelector } from "reselect";

const sidebarAndDrawerState = (state) => state.sidebarAndDrawer;

export const getIsDrawerOpen = createSelector(
    sidebarAndDrawerState,
    (sidebarAndDrawerState) => sidebarAndDrawerState.isDrawerOpen
);

export const getIsSidebarOpen = createSelector(
    sidebarAndDrawerState,
    (sidebarAndDrawerState) => sidebarAndDrawerState.isSidebarOpen
);

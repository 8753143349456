import * as types from "../actions/actionTypes";

const entireWorldBounds = [
    [-158.26, -62.04],
    [196.7, 83.64]
];

const configInit = {
    config: {
        sidebarCollapsed: false,
        mapZoom: 0,
        mapBounds: entireWorldBounds,
        basemaps: [],
        widgets: [],
        tools: [],
        projections: []
    }
};

export function configReducer(state = configInit, action) {
    switch (action.type) {
        case types.SET_CONFIG:
            return {
                config: { ...state.config, ...action.result }
            };
        default:
            return state;
    }
}

import React, { useState, useEffect, Suspense } from "react";
import { CircularProgress } from "@material-ui/core";
import MapComponent from "../../map/map";
import { useSelector } from "react-redux";
import MapLegendPortrait from "./mapLegendPortrait";
import MapLegendLandscape from "./mapLegendLandscape";
import { convertStylesToLegend, renderLabels } from "../../../utils/legendUtils";
import { getPrintFeatures } from "../../../selectors/printSelectors";
import { getDigitizeStyles } from "../../../selectors/stylesSelectors";
import { getLayerGroups, getLayerVisibilityMap, getStylesForLegend } from "../../../selectors/layerSelector";
import { useMapPreviewStyles } from "./styles/mapPreviewStyles";
import { getAllLayersVisibilityMap } from "../../../selectors/generalSelectors";

const MapPreview = (props) => {
    const { orientation } = props;
    const classes = useMapPreviewStyles({ orientation });
    const printFeatures = useSelector(getPrintFeatures);
    const stylesForLegend = useSelector(getStylesForLegend);
    const layerGroups = useSelector(getLayerGroups);
    const layerVisibilityMap = useSelector(getLayerVisibilityMap);
    const digitizeStyles = useSelector(getDigitizeStyles);

    const [labels, setLabels] = useState([]);

    let visibleLayers = [];

    const allLayersVisibilityMap = useSelector(getAllLayersVisibilityMap);
    Object.keys(allLayersVisibilityMap).forEach((layerId) => {
        if (allLayersVisibilityMap[layerId]) {
            let foundLayer = labels.find((layerLabel) => layerLabel.layerId === layerId);
            if (foundLayer) {
                visibleLayers.push(foundLayer);
            }
        }
    });

    let renderedLabels = renderLabels(classes, visibleLayers);

    useEffect(() => {
        const digitizedGroup = {
            name: "Digitized",
            layers: digitizeStyles.map((dStyle) => ({
                sourceName: dStyle.name,
                name: dStyle.name,
                layerId: dStyle.layerId
            }))
        };
        const copyOfDigitizedStyles = digitizeStyles.map((style) => ({ ...style }));
        setLabels(
            convertStylesToLegend([...stylesForLegend, ...copyOfDigitizedStyles], [...layerGroups, digitizedGroup])
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layerVisibilityMap, stylesForLegend, digitizeStyles, layerGroups]);

    return (
        <Suspense
            fallback={
                <div className={classes.loaderContainer}>
                    <CircularProgress />
                </div>
            }
        >
            <div className={classes.root}>
                <div className={classes.modalMapContainer} id="modal-map">
                    <MapComponent modal={true} />
                </div>
                {printFeatures.showMapLegend && orientation === "landscape" && (
                    <div className={classes.modalLegendLandscape}>
                        <MapLegendLandscape renderedLabels={renderedLabels} orientation={orientation} />
                    </div>
                )}
                {printFeatures.showMapLegend && orientation === "portrait" && (
                    <div className={classes.modalLegendPortrait}>
                        <MapLegendPortrait renderedLabels={renderedLabels} />
                    </div>
                )}
            </div>
        </Suspense>
    );
};

export default MapPreview;

import appStyles from "../../../../utils/styles";

export const toolsMenuJss = (theme) => ({
    closeButton: {
        color: "white",
        height: 48,
        padding: 0,
        width: "100%",
        minWidth: "auto",
        backgroundColor: appStyles.color.CLOSE_BACKGROUND,
        borderRadius: 0,
        "&:hover": {
            backgroundColor: appStyles.color.TOOL_HOVER,
            borderRadius: 0
        }
    },
    accountButton: {
        color: "white",
        height: 64,
        padding: 0,
        width: "100%",
        backgroundColor: "#024F79",
        borderRadius: 0,
        "&:hover": {
            backgroundColor: appStyles.color.TOOL_HOVER,
            borderRadius: 0
        }
    },
    headerLabel: {
        fontSize: "0.875rem",
        textTransform: "none",
        opacity: 0.7
    },
    accountInactive: {
        opacity: 0.7
    },
    accountActive: {
        opacity: 1
    },
    closedMenu: {
        fontSize: "1.5em"
    },
    headerItem: {
        display: "flex"
    },
    toolbarBtn: {
        width: 25,
        borderRadius: 0,
        height: 25
    },
    navOpen: {
        justifyContent: "start"
    },
    toolIcon: {
        paddingLeft: 20,
        paddingRight: 20,
        display: "flex"
    },
    root: {
        flex: "1 1 100%",
        backgroundColor: "#024F79"
    },
    content: {
        backgroundColor: appStyles.color.PRIMARY_BG,
        height: "100%"
    },
    padding12: {
        backgroundColor: appStyles.color.PRIMARY_BG
    },
    profileInfo: {
        display: "flex",
        textAlign: "center",
        flexDirection: "column"
    },
    signOut: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-evenly"
    },
    appName: {
        color: "#fff",
        textAlign: "center",
        fontSize: "0.65rem",
        width: "100%",
        backgroundColor: appStyles.color.CLOSE_BACKGROUND
    }
});

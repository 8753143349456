const highlightTypes = {
    fill: {
        id: "highlight",
        type: "fill",
        source: "highlight-features",
        layout: {},
        paint: {
            "fill-color": "gray"
        }
    },
    "fill-extrusion": {
        id: "highlight",
        type: "fill",
        source: "highlight-features",
        layout: {},
        paint: {
            "fill-color": "gray"
        }
    },
    line: {
        id: "highlight",
        type: "line",
        source: "highlight-features",
        layout: {},
        paint: {
            "line-color": "gray",
            "line-width": 4
        }
    },
    circle: {
        id: "highlight",
        type: "circle",
        source: "highlight-features",
        layout: {},
        paint: {
            "circle-color": "gray",
            "circle-radius": 6
        }
    },
    symbol: {
        id: "highlight",
        type: "symbol"
    }
};
export default highlightTypes;

import React from "react";
import { getDraw } from "../../map/map";
import * as digitizeActions from "../../../actions/digitize";
import * as toolsActions from "../../../actions/tools";
import { Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DigitizeLayer from "./digitizeLayer";
import LayerFeatures from "./digitizeLayerFeatures";
import { getDigitizeLayers, getEditing } from "../../../selectors/digitizeSelectors";
import { getWidgets } from "../../../selectors/toolsSelectors";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        backgroundColor: "#fff"
    }
}));

const DigitizeLayers = ({
    selectedLayer,
    setSelectedLayer,
    renderDistanceMarkers,
    resetDrawingState,
    drawingDistanceMarkersArray,
    setIsStylerOpen,
    deselectAllFeaturesAndLabels
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const editing = useSelector(getEditing);
    const digitizeLayers = useSelector(getDigitizeLayers);
    const widgets = useSelector(getWidgets);

    const closeWidgets = () => {
        widgets.forEach((widget) => {
            if (widget.toggled) {
                let updatedWidget = { ...widget };
                updatedWidget.toggled = false;
                dispatch(toolsActions.toggleWidget(updatedWidget));
            }
        });
    };

    const onDraw = (layer) => {
        setSelectedLayer(layer);

        dispatch(digitizeActions.editingFeatures(true));
        closeWidgets();
        if (editing) {
            getDraw().trash();
        }
        switch (layer.type) {
            case "point":
                getDraw().changeMode("draw_point");
                break;
            case "line":
                getDraw().changeMode("draw_line_string");
                break;
            case "polygon":
                getDraw().changeMode("draw_polygon");
                break;
            case "circle":
                getDraw().changeMode("drag_circle");
                break;
            default:
                return null;
        }
    };

    return (
        <Grid container className={classes.root}>
            {!!Object.keys(selectedLayer).length ? (
                <LayerFeatures
                    selectedLayer={selectedLayer}
                    setSelectedLayer={setSelectedLayer}
                    onDraw={onDraw}
                    getDraw={getDraw}
                    renderDistanceMarkers={renderDistanceMarkers}
                    drawingDistanceMarkersArray={drawingDistanceMarkersArray}
                    setIsStylerOpen={setIsStylerOpen}
                    deselectAllFeaturesAndLabels={deselectAllFeaturesAndLabels}
                />
            ) : (
                digitizeLayers.map((layer) => (
                    <DigitizeLayer
                        key={layer.id}
                        resetDrawingState={resetDrawingState}
                        setIsStylerOpen={setIsStylerOpen}
                        layer={layer}
                        selectedLayer={selectedLayer}
                        setSelectedLayer={setSelectedLayer}
                        onDraw={onDraw}
                        getDraw={getDraw}
                        deselectAllFeaturesAndLabels={deselectAllFeaturesAndLabels}
                    />
                ))
            )}
        </Grid>
    );
};

export default DigitizeLayers;

import React, { useState } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
        borderRadius: "5px 5px 0 0",
        backgroundColor: "#fff",
        maxWidth: 200
    },
    modalHeader: {
        borderRadius: "5px 5px 0 0",
        position: "relative",
        backgroundColor: "#024F79",
        padding: theme.spacing(1),
        color: "#fff",
        textAlign: "center"
    },
    headerText: {
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff"
    },
    closeButton: {
        position: "absolute",
        top: 5,
        right: 5,
        color: "#fff"
    },
    content: {
        padding: theme.spacing(1)
    },
    text: {
        fontSize: "0.85rem"
    }
}));

const MapHelper = ({ modal = false }) => {
    const classes = useStyles();
    const [close, setClose] = useState(false);

    const handleClose = () => {
        setClose(true);
    };
    return (
        !close && (
            <Grid container id="map-helper" className={classes.root}>
                <Grid item xs={12} className={classes.modalHeader}>
                    <Typography className={classes.headerText}>Info</Typography>
                    <IconButton className={classes.closeButton} size="small" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Grid>
                <Grid item xs={12} className={classes.content}>
                    <Typography className={classes.text}>Zoom in/out and pan around to adjust the map view.</Typography>
                </Grid>
            </Grid>
        )
    );
};

export default MapHelper;

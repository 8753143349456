// eslint-disable-next-line import/no-anonymous-default-export
export default {
    fill: [
        {
            name: "fill-color",
            title: "fill",
            type: "paint",
            propertyType: "color",
            expressionType: "none",
            index: 0,
            value: "darkcyan"
        },
        {
            name: "fill-outline-color",
            title: "outline",
            type: "paint",
            propertyType: "color",
            expressionType: "none",
            index: 1,
            value: "darkcyan"
        },
        {
            name: "fill-opacity",
            title: "opacity",
            type: "paint",
            propertyType: "float",
            expressionType: "none",
            index: 2,
            value: 0.1
        },
        {
            name: "fill-antialias",
            title: "antialias",
            type: "paint",
            propertyType: "boolean",
            expressionType: "none",
            index: 4,
            value: true
        }
    ],
    "fill-extrusion": [
        {
            name: "fill-extrusion-color",
            title: "color",
            type: "paint",
            propertyType: "color",
            expressionType: "none",
            index: 1,
            value: "darkcyan"
        },
        {
            name: "fill-extrusion-height",
            title: "height",
            type: "paint",
            propertyType: "number",
            expressionType: "none",
            index: 2,
            value: 5
        },
        {
            name: "fill-extrusion-base",
            title: "base",
            type: "paint",
            propertyType: "number",
            expressionType: "none",
            index: 3,
            value: 5
        },
        {
            name: "fill-extrusion-opacity",
            title: "opacity",
            type: "paint",
            propertyType: "float",
            expressionType: "none",
            index: 4,
            value: 1
        }
    ],
    line: [
        {
            name: "line-color",
            title: "color",
            type: "paint",
            propertyType: "color",
            expressionType: "none",
            index: 0,
            value: "darkcyan"
        },
        {
            name: "line-width",
            title: "width",
            type: "paint",
            propertyType: "number",
            expressionType: "none",
            index: 1,
            value: 1
        },
        {
            name: "line-dasharray",
            title: "line dashes",
            type: "paint",
            propertyType: "numberArray",
            expressionType: "none",
            index: 2,
            value: [1, 0]
        },
        {
            name: "line-gap-width",
            title: "gap width",
            type: "paint",
            propertyType: "number",
            expressionType: "none",
            index: 3,
            value: 0
        },
        {
            name: "line-opacity",
            title: "opacity",
            type: "paint",
            propertyType: "float",
            expressionType: "none",
            index: 4,
            value: 1
        },
        {
            name: "line-offset",
            title: "offset",
            type: "paint",
            propertyType: "float",
            expressionType: "none",
            index: 5,
            value: 0
        },
        {
            name: "line-cap",
            title: "line cap",
            type: "layout",
            propertyType: "select",
            expressionType: "none",
            options: ["butt", "round", "square"],
            index: 5,
            value: "butt"
        },
        {
            name: "line-join",
            title: "line join",
            type: "layout",
            propertyType: "select",
            expressionType: "none",
            options: ["bevel", "round", "miter"],
            index: 6,
            value: "miter"
        }
    ],
    circle: [
        {
            name: "circle-color",
            title: "color",
            type: "paint",
            propertyType: "color",
            expressionType: "none",
            index: 0,
            value: "darkcyan"
        },
        {
            name: "circle-radius",
            title: "radius",
            type: "paint",
            propertyType: "number",
            expressionType: "none",
            index: 1,
            value: 5
        },
        {
            name: "circle-stroke-color",
            title: "stroke-color",
            type: "paint",
            propertyType: "color",
            expressionType: "none",
            index: 2,
            value: "darkcyan"
        },
        {
            name: "circle-stroke-width",
            title: "stroke-width",
            type: "paint",
            propertyType: "number",
            expressionType: "none",
            index: 3,
            value: 0
        },
        {
            name: "circle-opacity",
            title: "opacity",
            type: "paint",
            propertyType: "float",
            expressionType: "none",
            index: 4,
            value: 1
        }
    ],
    symbol: [
        {
            name: "text-field",
            title: "field",
            type: "layout",
            propertyType: "text",
            expressionType: "none",
            index: 0,
            value: ""
        },
        {
            name: "text-size",
            title: "font size",
            type: "layout",
            propertyType: "number",
            expressionType: "none",
            index: 0,
            value: 16
        },
        {
            name: "symbol-placement",
            title: "placement",
            type: "layout",
            propertyType: "select",
            expressionType: "none",
            options: ["point", "line", "line-center"],
            index: 0,
            value: "point"
        },

        {
            name: "text-color",
            title: "color",
            type: "paint",
            propertyType: "color",
            expressionType: "none",
            index: 1,
            value: "darkcyan"
        },
        {
            name: "text-halo-color",
            title: "halo color",
            type: "paint",
            propertyType: "color",
            expressionType: "none",
            index: 1,
            value: "white"
        },
        {
            name: "text-halo-blur",
            title: "halo blur",
            type: "paint",
            propertyType: "number",
            expressionType: "none",
            index: 1,
            value: 0
        },
        {
            name: "text-halo-width",
            title: "halo width",
            type: "paint",
            propertyType: "number",
            expressionType: "none",
            index: 1,
            value: 0
        },
        {
            name: "text-opacity",
            title: "opacity",
            type: "paint",
            propertyType: "number",
            expressionType: "none",
            index: 1,
            value: 1
        },
        {
            name: "text-offset",
            title: "offset",
            type: "layout",
            propertyType: "numberArray",
            expressionType: "none",
            index: 0,
            value: [0, 0]
        },
        // {
        //     name: 'text-translate',
        //     title: 'translate',
        //     type: 'layout',
        //     propertyType: 'numberArray',
        //     expressionType:'none',
        //     index: 0,
        //     value: [0,0]
        // },
        {
            name: "text-rotation-alignment",
            title: "rotation alignment",
            type: "layout",
            propertyType: "select",
            expressionType: "none",
            options: ["map", "viewport", "auto"],
            index: 0,
            value: "auto"
        },
        {
            name: "text-letter-spacing",
            title: "letter-spaceing",
            type: "layout",
            propertyType: "float",
            expressionType: "none",
            index: 1,
            value: 0
        },
        {
            name: "text-anchor",
            title: "anchor",
            type: "layout",
            propertyType: "select",
            expressionType: "none",
            options: [
                "center",
                "left",
                "right",
                "top",
                "bottom",
                "top-left",
                "top-right",
                "bottom-left",
                "bottom-right"
            ],
            index: 0,
            value: "center"
        },

        {
            name: "text-justify",
            title: "justify",
            type: "layout",
            propertyType: "select",
            expressionType: "none",
            options: ["center", "left", "right", "auto"],
            index: 0,
            value: "center"
        }
    ],
    raster: [{ name: "raster-opacity", type: "paint", value: 1 }]
};

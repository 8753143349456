import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";

import * as Utils from "../../../utils/infoBoxUtils";
import { mobileInfoBoxJss } from "./jss/mobileInfoBoxJss";

class MobileInfoBox extends Component {
    state = {
        labels: [],
        open: false
    };

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.infoBoxState.features) !== JSON.stringify(this.props.infoBoxState.features)) {
            this.setState({ open: true });
        }
    }

    onOpenToggle = () => {
        this.setState({ open: false });
    };

    render() {
        let { classes } = this.props;

        let features = this.props.infoBoxState.features.map((feature, featureIndex) => {
            let propertiesEls = Utils.BuildProperties(feature, classes);

            return (
                <div className={classes.feature} key={featureIndex}>
                    <Typography variant="body1" className={classes.featureHeader}>
                        {feature.sourceLayer}
                    </Typography>
                    <table className={classes.table}>
                        <tbody>{propertiesEls}</tbody>
                    </table>
                </div>
            );
        });

        return (
            <div className={classes.root} style={{ display: this.state.open ? "block" : "none" }}>
                <div className={classes.features}>
                    <div size="small" onClick={this.onOpenToggle} className={classes.closeButton}>
                        {<CloseIcon></CloseIcon>}
                    </div>
                    {features}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        infoBoxState: state.infoBox,
        mapState: state.map
    };
};
export default connect(mapStateToProps)(withStyles(mobileInfoBoxJss)(MobileInfoBox));

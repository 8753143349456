import { toastr } from "react-redux-toastr";
import { bindActionCreators } from "redux";
import { actions as toastrActions } from "react-redux-toastr";
const options = { position: "bottom-right" };

const customToastr = {
    info: (text, opt = options) => toastr.info("", text, opt),
    success: (text, opt = options) => toastr.success("", text, opt),
    warning: (text, opt = options) => toastr.warning("", text, opt),
    error: (text, opt = options) => toastr.error("", text, opt),
    confirm: (text, opt) => toastr.confirm(text, opt)
};

export class ManagedToastr {
    constructor(dispatch) {
        this.toastr = bindActionCreators(toastrActions, dispatch);
    }

    info = (id, text, opt = options) => {
        this.toastr.add({
            id,
            type: "info",
            title: "",
            position: opt.position,
            message: text,
            options: { timeOut: 0 }
        });
    };

    remove = (id) => this.toastr.remove(id);
}

export default customToastr;

import axios from "axios";
import Config from "../config";

export default axios.create({
    baseURL: Config.apiUrl,
    timeout: 120000,
    crossDomain: true,
    "Content-Type": "application/json",
    withCredentials: true
});

export const generateCancelToken = () => axios.CancelToken.source();

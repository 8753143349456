import { Grid } from "@material-ui/core";
import React from "react";
import CoordinatesWidget from "./coordinatesWidget";
import Disclaimer from "./disclaimer";
import { useMapToolsStyles } from "./styles/mapToolsStyles";
import ZoomLevelWidget from "./ZoomLevelWidget/ZoomLevelWidget";

const MapTools = () => {
    const classes = useMapToolsStyles();
    return (
        <Grid container alignItems="center" justifyContent="flex-end" spacing={1} className={classes.root}>
            <Grid item>
                <CoordinatesWidget />
            </Grid>
            <Grid item>
                <ZoomLevelWidget />
            </Grid>
        </Grid>
    );
};

export default MapTools;

import React, { useEffect, useState } from "react";
import {
    Modal,
    Fade,
    Typography,
    Backdrop,
    LinearProgress,
    Button,
    Select,
    MenuItem,
    Grid,
    Paper
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import Download from "downloadjs";
import tokml from "tokml";
import { useSelector, useDispatch } from "react-redux";
import { getLayerData } from "../../../selectors/layerSelector";
import { useInfoTableDownloadStyles } from "./styles/infoTableDownloadStyles";
import { convertAndDownload } from "../../../actions/download";
import { fileTypeToDownloadExtension } from "../../../utils/files/fileTypeToDownloadExtension";
import { fileTypeToDownloadEnum } from "../../../utils/files/fileTypeToDownloadEnum";
import fileFormats from "../../../constants/files/fileFormats";
import toastr from "../../../utils/customToastr";
import { fileTypes } from "../../../constants/files/fileTypes";
import sanitize from "sanitize-filename";
const fileTypeOptions = [fileTypes.GEO_JSON, fileTypes.GEO_PACKAGE, fileTypes.SHAPEFILE];

const DownloadLayerAttributes = ({ open, handleClose, selectedData, selectedLayer }) => {
    const classes = useInfoTableDownloadStyles();
    const [downloading, setDownloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectedDownloadDialogValue, setSelectedDownloadDialogValue] = useState(fileTypeOptions[0]);

    const dispatch = useDispatch();

    const layerAttributes = useSelector(getLayerData);
    const handleChange = (e) => {
        setSelectedDownloadDialogValue(e.target.value);
    };

    useEffect(() => {
        setSelectedDownloadDialogValue(fileTypes.GEO_JSON);
    }, [selectedLayer]);

    useEffect(() => {
        return () => {
            setErrorMessage(null);
        };
    }, []);

    const downloadGeoPackage = (featureCollection, fileName) => {
        Download(tokml(featureCollection), `${fileName}.kml`);
    };

    const downloadGeoJson = (featureCollection, fileName) => {
        Download(JSON.stringify(featureCollection), `${fileName}.geojson`);
    };

    const downloadWithBackend = (featureCollection, fileName) => {
        const fileBlob = new Blob([JSON.stringify(featureCollection)], {
            type: "application/json"
        });
        const fileExtension = fileTypeToDownloadExtension(selectedDownloadDialogValue);

        dispatch(
            convertAndDownload(
                fileBlob,
                fileFormats.GEO_JSON,
                fileTypeToDownloadEnum(selectedDownloadDialogValue),
                fileName
            )
        )
            .then((blob) => {
                Download(blob, `${fileName}.${fileExtension}`);
            })
            .catch((err) => {
                toastr.error(err.message);
            });
    };

    const onDownload = () => {
        setDownloading(true);
        let featuresToBeDownloadedObject = [];

        for (let i = 0; i <= layerAttributes.length - 1; i++) {
            for (let j = 0; j <= selectedData.length - 1; j++) {
                if (layerAttributes[i].properties === selectedData[j]) {
                    featuresToBeDownloadedObject.push(layerAttributes[i]);
                }
            }
        }
        if (featuresToBeDownloadedObject.length) {
            const sanitizedFileName = sanitize(`${selectedLayer.name}-subset`);
            const featureCollection = {
                type: "FeatureCollection",
                name: sanitizedFileName,
                features: featuresToBeDownloadedObject
            };
            switch (selectedDownloadDialogValue) {
                case fileTypes.GEO_PACKAGE:
                    downloadGeoPackage(featureCollection, sanitizedFileName);
                    break;
                case fileTypes.GEO_JSON:
                    downloadGeoJson(featureCollection, sanitizedFileName);
                    break;
                case fileTypes.CSV:
                case fileTypes.SHAPEFILE:
                    downloadWithBackend(featureCollection, sanitizedFileName);
                    break;
                default:
                    break;
            }
        }
        setDownloading(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open}>
                <Paper className={classes.root}>
                    <Grid container>
                        <Grid item xs={12} className={classes.modalHeader}>
                            <Typography className={classes.headerText} variant="h6">
                                Download layer
                            </Typography>
                        </Grid>
                        {downloading && (
                            <Grid item xs={12}>
                                <LinearProgress variant="indeterminate" />
                            </Grid>
                        )}
                        {!downloading && (
                            <Grid item xs={12} className={classes.downloadDialogSelectContainer}>
                                <Grid container justify="center">
                                    <Typography className={classes.layerNameDownloadModal} gutterBottom component="div">
                                        Download selected attributes
                                    </Typography>
                                    <Select
                                        labelId="digitize-select-file-type-label"
                                        id="digitize-select-file-type"
                                        value={selectedDownloadDialogValue}
                                        onChange={handleChange}
                                        className={classes.downloadDialogSelect}
                                    >
                                        {fileTypeOptions.map((fileType) => (
                                            <MenuItem key={fileType} value={fileType}>
                                                {fileType}
                                            </MenuItem>
                                        ))}
                                        {selectedLayer.geometryType.toLowerCase() === "point" && (
                                            <MenuItem value={fileTypes.CSV}>CSV</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                            </Grid>
                        )}
                        {!downloading && (
                            <Grid item xs={12}>
                                <Grid container justify="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<GetApp />}
                                        onClick={onDownload}
                                    >
                                        Download
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        {errorMessage && (
                            <Grid item xs={12}>
                                <Typography className={classes.errorModalText} color="error">
                                    {errorMessage}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Fade>
        </Modal>
    );
};

export default DownloadLayerAttributes;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLayerGroups, getFetchingLayers, getStylerLayerId } from "../../../selectors/layerSelector";
import { getLayers, getMapLoaded, getOnClick } from "../../../selectors/mapSelectors";

import * as mapActions from "../../../actions/map";
import * as layerSelectorActions from "../../../reducers/layerSelector/layerSelector";
import Group from "./group";
import Layer from "./layer";
import LayerSelectorStyler from "../styler/layerSelectorStyler";

import { getMap } from "../../map/map";

import { IconButton, LinearProgress, InputAdornment, Input, Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import FiltersRegion from "./filtersRegion";

import VirtualizedTree from "../../../utils/virtualized-tree";

import DownloadLayer from "./downloadLayer";
import { PortalComponent } from "../../../utils/portal";
import InfoTablePortal from "../attributes/infoTablePortal";
import LayerMetadata from "./layerMetadata";
import { isGroup } from "@emblautec/rescursive-array-extensions";
import usePrevious from "../../../utils/customHooks/usePrevious";

import { useStyles } from "./layerSelectorStyles";
import { getSelectedApp } from "../../../selectors/appsSelectors";

const LayerSelector = () => {
    const [search, setSearch] = useState("");
    const prevSearchValue = usePrevious(search);
    //an id
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [openLayerMetadata, setOpenLayerMetadata] = useState(false);
    const [openAttributeTable, setOpenAttributeTable] = useState(false);

    //possible duplicate with an id
    const [layer, setLayer] = useState({});

    const mapRef = useRef();
    const mapLoaded = useSelector(getMapLoaded);
    const layerGroups = useSelector(getLayerGroups);
    const onMapClick = useSelector(getOnClick);
    const mapLayers = useSelector(getLayers);
    const fetching = useSelector(getFetchingLayers);
    const currentApp = useSelector(getSelectedApp);
    const stylerLayerId = useSelector(getStylerLayerId);

    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect(() => {
        dispatch(layerSelectorActions.setStylerLayerId(null));
        setOpenAttributeTable(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentApp]);

    useEffect(() => {
        if (mapLoaded) init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapLoaded]);

    const init = () => {
        mapRef.current = getMap();
        dispatch(mapActions.mapChangeClick(onMapClick));
    };

    const handleOpenLayerMetadata = (layer) => {
        setOpenLayerMetadata(true);
        setOpenAttributeTable(false);
        setLayer(layer);
    };

    const handleCloseLayerMetadata = () => {
        setOpenLayerMetadata(false);
        setLayer({});
    };

    const handleOpenDownloadModal = (layer) => {
        setOpenDownloadModal(true);
        setLayer(layer);
    };

    const handleCloseDownloadModal = () => {
        setOpenDownloadModal(false);
    };

    const handleOpenAttributeTable = (layer) => {
        setOpenAttributeTable(true);
        setOpenLayerMetadata(false);
        setLayer(layer);
    };

    const handleCloseAttributeTable = () => {
        setOpenAttributeTable(false);
        setLayer({});
    };

    const handleHideAll = () => {
        let displayLayers = "none";

        mapLayers.forEach((layer) => {
            dispatch(
                mapActions.updateLayout({
                    layerId: layer.layerId,
                    datasetId: layer.datasetId,
                    properties: [
                        {
                            name: "visibility",
                            value: displayLayers
                        }
                    ]
                })
            );
        });

        dispatch(layerSelectorActions.hideAllLayers());
    };

    const onSearch = (e) => {
        setSearch(e.target.value);
    };

    const clearSearch = () => {
        setSearch("");
    };

    const onStyleLayer = (layer) => {
        dispatch(layerSelectorActions.setStylerLayerId(layer.resourceId));
        setLayer(layer);
    };

    const layerHandlers = {
        handleOpenDownloadModal,
        handleOpenLayerMetadata,
        handleOpenAttributeTable,
        onStyleLayer
    };

    const getSearchedLayers = (layerGroups) => {
        return layerGroups
            .map((layerGroup, index) => {
                let alteredGroup;

                if (isGroup(layerGroup)) {
                    //Making a shallow copy of the objects we modify.
                    alteredGroup = { ...layerGroup, options: { ...layerGroup.options } };
                    alteredGroup.layers = layerGroup.layers.filterLayersRecursive((layer) =>
                        layer.name.toLowerCase().includes(search.toLowerCase())
                    );
                    alteredGroup = cleanEmptySubGroups(alteredGroup);

                    if (prevSearchValue !== search) setCollapsedToFalse(alteredGroup);

                    return alteredGroup;
                }
                return layerGroup;
            })
            .filter((layerGroup) =>
                isGroup(layerGroup)
                    ? layerGroup.layers.length
                    : layerGroup.name.toLowerCase().includes(search.toLowerCase())
            );
    };

    const cleanEmptySubGroups = (group) => {
        //This function shouldn't modify the original object
        let mappedData = [];
        group.layers.forEach((layer) => {
            if (isGroup(layer)) {
                const cleanedGroup = cleanEmptySubGroups(layer);
                if (cleanedGroup.layers.length > 0) {
                    mappedData.push(cleanedGroup);
                }
            } else mappedData.push(layer);
        });
        return { ...group, layers: mappedData };
    };

    const setCollapsedToFalse = (group) => {
        //Still need to make a shallow copy for the nested groups
        group.layers.forGroupsRecursive((group) => {
            group.options = { ...group.options, collapsed: false };
        });
        group.options.collapsed = false;
    };

    const groups = search !== "" ? getSearchedLayers(layerGroups) : layerGroups;

    return (
        <div className={classes.root}>
            <div className={classes.flexContainer}>
                <Divider />

                <FiltersRegion className={classes.container} handleHideAll={handleHideAll} />
                <Divider />

                <div className={classes.searchContainer}>
                    <Input
                        id="full-width"
                        inputProps={{ autoComplete: "off" }}
                        placeholder="Search Layers..."
                        value={search}
                        onChange={onSearch}
                        fullWidth
                        className={classes.layerInput}
                        endAdornment={
                            <InputAdornment position="end">
                                {search !== "" && (
                                    <IconButton
                                        className={classes.noPadButton + " " + classes.squareBtn}
                                        aria-label="toggle password visibility"
                                        onClick={clearSearch}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        }
                    />
                </div>
                <div className={classes.layers} id="layer-menu">
                    {fetching && <LinearProgress variant="indeterminate" color="primary" />}
                    <VirtualizedTree data={groups} layerHandlers={layerHandlers} />
                </div>
            </div>
            {stylerLayerId !== null && <LayerSelectorStyler isDigitizeLayer={false} />}
            <PortalComponent>
                {openAttributeTable ? (
                    <InfoTablePortal
                        open={openAttributeTable}
                        handleClose={handleCloseAttributeTable}
                        selectedLayer={layer}
                        handleOpenDownloadModal={handleOpenDownloadModal}
                    />
                ) : null}
            </PortalComponent>
            <DownloadLayer open={openDownloadModal} layer={layer} handleClose={handleCloseDownloadModal} />
            <LayerMetadata open={openLayerMetadata} layer={layer} handleClose={handleCloseLayerMetadata} />
        </div>
    );
};

export default LayerSelector;

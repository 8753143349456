export function geometryTypeToMapboxType(geometryType) {
    switch (geometryType) {
        case "POLYGON":
        case "MULTIPOLYGON":
            return "fill";
        case "LINESTRING":
        case "MULTILINESTRING":
            return "line";
        case "POINT":
        case "MULTIPOINT":
            return "circle";
        default:
            console.error("Invalid geometry type");
    }
}

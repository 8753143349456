import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { generateDocument } from "../actions/atlasClient";

const initialState = {
    isConnectionStarted: false,
    connectionId: "",
    loading: false,
    excelLink: "",
    error: false,
    errorMessage: ""
};

//this action is used in the zanderMiddleware and that is its sole purpose
export const requestZanderConnectionStart = createAction("requestZanderConnectionStart");

export const generateExcel = createAsyncThunk("generateExcel", async (data, { getState }) => {
    return generateDocument({ ...data, connectionId: getState().zander.connectionId });
});

export const zanderSlice = createSlice({
    name: "zander",
    initialState,
    reducers: {
        startConnection: (state, { payload: connectionId }) => {
            state.isConnectionStarted = true;
            state.connectionId = connectionId;
        },
        setExcelLink: (state, { payload: link }) => {
            state.loading = false;
            state.excelLink = link;
        },
        setError: (state, { payload: errorMessage }) => {
            state.loading = false;
            state.error = true;
            state.errorMessage = errorMessage;
        },
        reset: (state) => {
            state.loading = false;
            state.error = false;
            state.errorMessage = "";
            state.excelLink = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(generateExcel.pending, (state) => {
                state.loading = true;
            })
            .addCase(generateExcel.rejected, (state) => {
                state.error = true;
                state.errorMessage = "Error while generating excel file";
            });
    }
});

export const { requestConnectionStart, startConnection, setExcelLink, setError, reset } = zanderSlice.actions;

export default zanderSlice.reducer;

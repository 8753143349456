export default {
    apiUrl: "https://api.esox2.windgis.io/api/",
    baseLayers: {
        aerial: "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibGF1dGVjIiwiYSI6ImNrNzRpNjFxaTBpc2IzZnBkNHU3MGJoMmoifQ.yNFawhQiPQGVujuEwb-TIg",
        street: "mapbox://styles/lautec/ck949aof41kc41itbk257vk78"
    },
    mapboxApiKey: "pk.eyJ1IjoibGF1dGVjIiwiYSI6ImNrNzRpNjFxaTBpc2IzZnBkNHU3MGJoMmoifQ.yNFawhQiPQGVujuEwb-TIg",
    recaptchaKey: "6LebhkofAAAAANZTaiiDqYUdjX1u3e-2oBP6Fb90",
    auth0Domain: "lautec-gis.au.auth0.com",
    auth0Audience: "test",
    auth0ClientId: "lL4IMa04CwVHJSpuH4ZKaEjdO3rYN6z7",
    eraDatasetResource:
        "https://lau-sda.azurewebsites.net/api/download?code=5KThUDySpkOguD6BIKpP820FjObFwBHZ0QuEMHFjELXSWtPdrQBC4A==",
    esoxAppId: "b8acba2b-1532-4381-bbfb-adf9a0e5d43d"
};

import { createTheme } from "@material-ui/core";
import { lighten, darken } from "@material-ui/core/styles/colorManipulator";

import styles from "./scss/variables.module.scss";

const PRIMARY_BG = styles.primaryBackgroundColor;
const SECONDARY_BG = styles.secondaryBackgroundColor;
const PRIMARY_COLOR = styles.primaryColor;
const SECONDARY_COLOR = styles.secondaryColor;
const SUCCESS_COLOR = styles.successColor;
const WARNING_COLOR = styles.primaryWarningColor;
const ERROR_COLOR = styles.primaryErrorColor;
const TEXT_COLOR = styles.primaryTextColor;

const theme = {
    typography: {
        useNextVariants: true,
        fontFamily: [
            '"Nunito Sans"',
            "-apple-system",
            "BlinkMacSystemFont",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif"
        ].join(","),
        h1: {
            fontSize: 24,
            lineHeight: "36px"
        },
        h2: {
            fontSize: 20,
            lineHeight: "30px"
        },
        h3: {
            fontSize: 18,
            lineHeight: "26px"
        },
        subtitle1: {
            fontSize: 16,
            lineHeight: "24px"
        },
        body1: {
            fontSize: 16,
            lineHeight: "22px"
        },
        body2: {
            fontSize: 14,
            lineHeight: "20px"
        },
        caption: {
            fontSize: 12,
            lineHeight: "18px"
        }
    },
    palette: {
        type: "light",
        background: { main: PRIMARY_BG },
        secondaryBackground: {
            main: SECONDARY_BG
        },
        primary: {
            main: PRIMARY_COLOR,
            light: lighten(PRIMARY_COLOR, 0.2),
            dark: darken(PRIMARY_COLOR, 0.2)
        },
        secondary: {
            main: PRIMARY_COLOR,
            light: lighten(PRIMARY_COLOR, 0.2),
            dark: darken(PRIMARY_COLOR, 0.2)
        },
        success: {
            main: SUCCESS_COLOR,
            light: lighten(SUCCESS_COLOR, 0.2),
            dark: darken(SUCCESS_COLOR, 0.2)
        },
        text: {
            main: TEXT_COLOR
        },
        warning: {
            main: WARNING_COLOR
        },
        error: {
            main: ERROR_COLOR
        }
    },
    overrides: {
        //Try not to override the elevation because most components accept the prop: elevation={x}
        MuiTypography: {
            root: {
                color: TEXT_COLOR
            }
        },
        MuiCardContent: {
            root: {
                "&:last-child": {
                    paddingBottom: 0
                }
            }
        },
        MuiSelect: {
            root: {
                width: "100%"
            },
            select: {
                color: TEXT_COLOR,
                width: "100%"
            },
            icon: {
                color: TEXT_COLOR
            }
        },
        MuiTableCell: {
            body: {
                color: TEXT_COLOR
            }
        },
        MuiTableSortLabel: {
            root: {
                color: TEXT_COLOR
            }
        },
        MuiTablePagination: {
            root: {
                color: TEXT_COLOR
            },
            actions: {
                color: TEXT_COLOR
            }
        },
        MuiFormControlLabel: {
            label: {
                color: TEXT_COLOR
            }
        },
        MuiInput: {
            root: {
                color: TEXT_COLOR
            },
            underline: {
                "&:before": {
                    "border-bottom": "gray 1px solid"
                },
                "&:hover:before": {
                    "border-bottom": "lightgray 1px solid !important"
                },
                "&:after": {
                    "border-bottom": PRIMARY_COLOR + " 2px solid"
                }
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: "#F5F7F8"
            }
        },
        MuiFormLabel: {
            root: {
                color: TEXT_COLOR,
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: PRIMARY_COLOR
                }
            }
        },
        MuiFormControl: {
            root: {
                color: "gray"
            }
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: PRIMARY_BG
            }
        },
        MuiPickersModal: {
            withAdditionalAction: {
                backgroundColor: PRIMARY_BG
            }
        },
        MuiMenuItem: {
            root: {
                color: TEXT_COLOR
            }
        },
        MuiButton: {
            root: {
                padding: "8px 10px",
                minHeight: 36,
                lineHeight: 0,
                fontSize: 16
            },
            label: {
                textTransform: "capitalize"
            },
            text: {
                padding: "8px 10px",
                fontWeight: 700
            },
            containedPrimary: {
                fontWeight: 600,
                "&:hover": {
                    backgroundColor: lighten(PRIMARY_COLOR, 0.2)
                }
            },
            containedSecondary: {
                color: PRIMARY_COLOR,
                backgroundColor: SECONDARY_COLOR,
                fontWeight: 700,
                "&:hover": {
                    backgroundColor: lighten(SECONDARY_COLOR, 0.4)
                }
            }
        },
        MuiIconButton: {
            colorSecondary: {
                color: PRIMARY_COLOR,
                backgroundColor: SECONDARY_COLOR,
                "&:hover": {
                    backgroundColor: lighten(SECONDARY_COLOR, 0.4)
                }
            }
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: "transparent"
            }
        },
        MuiAppBar: {
            root: {
                boxShadow: "none",
                height: 48,
                minHeight: 48
            }
        },
        MuiToolbar: {
            root: {
                paddingLeft: "16px !important",
                height: 48,
                minHeight: "48px !important"
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 12,
                maxWidth: 700
            }
        },
        MuiTab: {
            wrapper: {
                color: TEXT_COLOR
            },
            root: {
                backgroundColor: SECONDARY_BG,
                textTransform: "capitalize",
                fontSize: "14px",
                lineHeight: "20px"
            }
        },
        MuiAutocomplete: {
            root: {
                paddingRight: 6,
                paddingLeft: 6,
                "& .MuiFormControl-root": {
                    marginTop: 6,
                    marginBottom: 6,
                    borderRadius: 5
                }
            }
        },
        MuiDialog: {
            paper: {
                minWidth: 400
            }
        },
        MuiSlider: {
            root: {
                height: 3
            },
            thumb: {
                backgroundColor: SECONDARY_BG,
                borderColor: SECONDARY_BG,
                boxShadow: "0 0 5px " + PRIMARY_COLOR,
                "&:hover": {
                    borderColor: PRIMARY_COLOR
                }
            },
            track: {
                height: 3
            },
            mark: {
                width: 0
            }
        }
    },
    props: {
        MuiButton: {
            disableElevation: true
        }
    }
};

export default createTheme(theme);

import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { getPrintFeatures } from "../../../selectors/printSelectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
    root: {
        position: "absolute",
        right: 10,
        zIndex: 1,
        padding: "2px 0 6px 0",
        backgroundColor: "rgba(0,0,0,0)"
    },
    bottom50: {
        bottom: 50
    },
    bottom20: {
        bottom: 20
    },
    rootLegend: {
        position: "relative",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2
    },
    text: {
        padding: 6,
        backgroundColor: "rgba(255,255,255,0.90)",
        // borderRadius: '0 0 0 5px',
        borderWidth: "1px 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "grey",
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        maxWidth: "13vw",
        wordBreak: "break-all",
        fontSize: "0.5rem"
    },
    textLegend: {
        fontSize: "0.5rem"
    }
}));

const MapDate = ({ mapDateFormat, modal = false, showMapLegend }) => {
    const printFeatures = useSelector(getPrintFeatures);
    const classes = useStyles();
    let dateObj = new Date();
    return (
        <div
            id="map-date"
            className={`${
                modal
                    ? classes.rootLegend
                    : `${classes.root} ${printFeatures.showMapScalebar ? classes.bottom50 : classes.bottom20}`
            } `}
        >
            <Typography className={showMapLegend ? classes.textLegend : classes.text} variant="caption">
                {mapDateFormat === "local"
                    ? dayjs(dateObj).format("YYYY-MM-DD HH:mm")
                    : `${dayjs.utc(dateObj).format("YYYY-MM-DD HH:mm")} UTC`}
            </Typography>
        </div>
    );
};

export default MapDate;

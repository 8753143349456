import { makeStyles } from "@material-ui/core";

export const useAppViewStyles = makeStyles((theme) => ({
    root: {
        background: "#f5f7f8",
        height: "100%",
        overflow: "hidden"
    },
    topbar: {
        paddingTop: 16
    },
    logo: {
        height: 30
    },
    infoContainer: {
        height: "calc(100% - 58px)",
        overflow: "auto",
        marginTop: "1%"
    },
    app: {
        display: "flex",
        width: "22vw",
        minWidth: 570,
        [theme.breakpoints.down("xs")]: {
            minWidth: 330
        },
        color: "black",
        background: "#fff",
        cursor: "pointer",
        margin: 12,
        marginBottom: 24,
        boxShadow: "0 2px 12px 1px rgba(60,62,66,0.1)",
        border: "1px solid rgba(0,0,0,0)",
        transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
        "&:hover": {
            boxShadow: "0 2px 12px 1px rgba(60,62,66,0.31)",
            border: "1px solid #024F79"
        }
    },
    searchField: {
        background: "#EDEEEE",
        borderRadius: "4px 4px 0 0",
        width: "22vw",
        minWidth: 570,
        [theme.breakpoints.down("xs")]: {
            minWidth: 330
        },
        margin: 12,

        "& input::placeholder": {
            color: "#616C6F",
            fontWeight: "bold",
            fontSize: 14
        }
    },
    selectAppTitle: {
        margin: 12,
        fontSize: 24,
        fontWeight: "bold"
    },
    searchIcon: {
        color: "#616C6F",
        width: 20
    },
    marginTop: { marginTop: "25vh" },
    content: {
        flex: "1 0 auto",
        padding: 16,
        paddingLeft: 0
    },
    appInfo: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        maxWidth: "60%"
    },
    appName: {
        color: "black",
        fontWeight: "bold",
        fontSize: 20,
        letterSpacing: 0.19,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    modifiedUtcText: {
        color: "black",
        fontSize: 16
    },
    mapIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 60,
        color: "#616C6F"
    },
    mapPreview: {
        minWidth: 150
    },
    loader: {
        color: "#616C6F"
    },
    noAppsTitle: {
        padding: `${theme.spacing(2)}px 0 0 0`
    },
    logoutButton: {
        position: "fixed",
        bottom: 5,
        left: 5
    },
    logoutIcon: {
        fontSize: 30
    },
    chip: {
        display: "flex",
        alignItems: "center",
        padding: 20,
        flexGrow: 1,
        justifyContent: "flex-end"
    },
    publishedChip: {
        color: "#149B62",
        fill: "#149B62",
        fontWeight: "bold",
        backgroundColor: "#E5F2E4",
        padding: "0px 3px",
        [theme.breakpoints.down("xs")]: {
            width: 40,
            padding: 0
        }
    },
    unpublishedChip: {
        color: "#616C6F",
        fill: "#616C6F",
        fontWeight: "bold",
        backgroundColor: "#E3E4E5",
        padding: "0px 3px",
        [theme.breakpoints.down("xs")]: {
            width: 40,
            padding: 0
        }
    },
    chipIcon: {
        width: 18,
        fill: "inherit"
    }
}));

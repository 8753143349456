import { createSlice } from "@reduxjs/toolkit";
import { getApp } from "../../actions/atlasClient";
import { mapZoomEnd, toggleGroupLayers } from "../../actions/globalActions";
import { downloadDataset, getLayerData } from "../../actions/layerSelector";

const initialState = {
    maps: [],
    layerGroups: [],
    loading: false,
    fetchingAttributes: false,
    layerData: [],
    error: "",
    mapZoom: 0,
    stylerLayerId: null,
    layerVisibilityMap: {}, //{layerId: false/true}
    layerStylesMap: {} //{layerId: [styleOne, ...]}
};

export const layerSelectorSlice = createSlice({
    name: "layerSelector",
    initialState,
    reducers: {
        setLayerGroups: (state, { payload: newLayerGroups }) => {
            state.layerGroups = newLayerGroups;
            state.loading = false;
        },
        setLayerMaps: (state, { payload: { layerStylesMap, layerVisibilityMap } }) => {
            state.layerStylesMap = layerStylesMap;
            state.layerVisibilityMap = layerVisibilityMap;
        },
        setMapZoom: (state, { payload: newMapZoom }) => {
            state.mapZoom = newMapZoom;
        },
        setLoading: (state, { payload: loading }) => {
            state.loading = loading;
        },
        setStylerLayerId: (state, { payload: newStylerLayerId }) => {
            state.stylerLayerId = newStylerLayerId;
        },
        setLayerVisibility: ({ layerVisibilityMap }, { payload: { layerId, newVisibility } }) => {
            layerVisibilityMap[layerId] = newVisibility;
        },
        hideAllLayers: ({ layerVisibilityMap }) => {
            Object.keys(layerVisibilityMap).forEach((layerId) => {
                layerVisibilityMap[layerId] = false;
            });
        },
        addStyleToLayer: ({ layerGroups, layerStylesMap, mapZoom }, { payload: { layerId, style } }) => {
            layerStylesMap[layerId].push(style);
            const layer = layerGroups.getRecursive(layerId);
            const newMinZoom = Math.min(layer.minZoom, style.minZoom);
            const newMaxZoom = Math.max(layer.maxZoom, style.maxZoom);
            layer.minZoom = newMinZoom;
            layer.maxZoom = newMaxZoom;
            layer.isShown =
                Math.floor(newMinZoom) <= Math.floor(mapZoom) && Math.floor(mapZoom) <= Math.floor(newMaxZoom);
        },
        removeStyleFromLayer: ({ layerStylesMap, layerGroups, mapZoom }, { payload: { layerId, styleId } }) => {
            layerStylesMap[layerId] = layerStylesMap[layerId].filter((s) => s.styleId !== styleId);

            let minZoom = 24;
            let maxZoom = 0;
            layerStylesMap[layerId].forEach((style) => {
                if (style.minZoom < minZoom) minZoom = style.minZoom;
                if (style.maxZoom > maxZoom) maxZoom = style.maxZoom;
            });

            const layer = layerGroups.getRecursive(layerId);
            layer.minZoom = minZoom;
            layer.maxZoom = maxZoom;
            layer.isShown = Math.floor(minZoom) <= Math.floor(mapZoom) && Math.floor(mapZoom) <= Math.floor(maxZoom);
        },
        changePropertiesOfLayerStyle: ({ layerStylesMap }, { payload: { layerId, styleId, properties } }) => {
            layerStylesMap[layerId].forEach((style) => {
                if (style.styleId === styleId) {
                    style.properties = properties;
                }
            });
        },
        changeTypeOfLayerStyle: ({ layerStylesMap }, { payload: { layerId, styleId, properties, type } }) => {
            layerStylesMap[layerId].forEach((style) => {
                if (style.styleId === styleId) {
                    style.properties = properties;
                    style.type = type;
                }
            });
        },
        changeStyleOrder: ({ layerStylesMap }, { payload: { layerId, styleIndexOne, styleIndexTwo } }) => {
            const styles = layerStylesMap[layerId];
            [styles[styleIndexOne], styles[styleIndexTwo]] = [styles[styleIndexTwo], styles[styleIndexOne]];
        },
        toggleGroupCollapse: ({ layerGroups }, { payload: { groupId } }) => {
            const group = layerGroups.getRecursive(groupId);
            group.options.collapsed = !group.options.collapsed;
        },
        resetLayerData: (state) => {
            state.layerData = [];
        },
        updateStyleZoomRange: (
            { layerStylesMap, mapZoom, layerGroups },
            { payload: { layerId, styleId, minZoom, maxZoom } }
        ) => {
            layerStylesMap[layerId].forEach((style) => {
                if (style.styleId == styleId) {
                    style.minZoom = minZoom;
                    style.maxZoom = maxZoom;
                }
            });

            let newMinZoom = 24;
            let newMaxZoom = 0;
            layerStylesMap[layerId].forEach((style) => {
                if (style.minZoom < newMinZoom) newMinZoom = style.minZoom;
                if (style.maxZoom > newMaxZoom) newMaxZoom = style.maxZoom;
            });

            const layer = layerGroups.getRecursive(layerId);
            layer.minZoom = newMinZoom;
            layer.maxZoom = newMaxZoom;
            layer.isShown =
                Math.floor(newMinZoom) <= Math.floor(mapZoom) && Math.floor(mapZoom) <= Math.floor(newMaxZoom);
        }
    },
    extraReducers: (builder) =>
        builder
            .addCase(
                toggleGroupLayers,
                ({ layerGroups, layerVisibilityMap }, { payload: { groupId, newVisibility } }) => {
                    //Left it like this for when we want to implement the
                    //system with totalLayerCounts and visibleLayerCounts on groups
                    const group = layerGroups.getRecursive(groupId);
                    group.layers.forLayersRecursive((layer) => {
                        layerVisibilityMap[layer.resourceId] = newVisibility;
                    });
                }
            )
            .addCase(mapZoomEnd, (state, { payload: newZoom }) => {
                state.mapZoom = newZoom;
                state.layerGroups.forLayersRecursive((layer) => {
                    layer.isShown =
                        Math.floor(layer.minZoom) <= Math.floor(newZoom) &&
                        Math.floor(newZoom) <= Math.floor(layer.maxZoom);
                });
            })
            .addCase(downloadDataset.pending, (state) => {
                state.loading = true;
            })
            .addCase(downloadDataset.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(downloadDataset.rejected, (state, { payload: error }) => {
                state.loading = false;
                state.error = error;
            })
            .addCase(getLayerData.pending, (state) => {
                state.error = "";
                state.fetchingAttributes = true;
            })
            .addCase(getLayerData.fulfilled, (state, { payload: newFeatures }) => {
                state.fetchingAttributes = false;
                state.layerData.push(...newFeatures);
            })
            .addCase(getLayerData.rejected, (state) => {
                state.fetchingAttributes = false;
            })
            .addCase(getApp.pending, (state) => {
                state.loading = true;
            })

            .addCase(getApp.rejected, (state) => {
                state.loading = false;
            })
});

export const {
    setLayerGroups,
    setLayerMaps,
    setMapZoom,
    setLoading,
    setStylerLayerId,
    setLayerVisibility,
    hideAllLayers,
    addStyleToLayer,
    removeStyleFromLayer,
    changePropertiesOfLayerStyle,
    changeTypeOfLayerStyle,
    changeStyleOrder,
    toggleGroupCollapse,
    resetLayerData,
    updateStyleZoomRange
} = layerSelectorSlice.actions;

export default layerSelectorSlice.reducer;

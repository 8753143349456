import { useState, useRef } from "react";
import useDragAndDropStyles from "../../../utils/styles/dragAndDrop";

const withDragAndDropReorder =
    (WrappedComponent, type, index, onOrderChanged) =>
    ({ ...props }) => {
        const [isBeingDragged, setIsBeingDragged] = useState(false);
        const [dragOver, setDragOver] = useState(false);
        const [dragAboveMiddle, setDragAboveMiddle] = useState(null);
        const [eventTarget, setEventTarget] = useState(null);
        const dragContainer = useRef(null);
        const classes = useDragAndDropStyles();

        const onDragStart = (e) => {
            e.stopPropagation();

            const dropData = {
                index,
                type
            };

            e.dataTransfer.setData("text/plain", JSON.stringify(dropData));
            setIsBeingDragged(true);

            return false;
        };

        const onDragEnd = (e) => {
            e.stopPropagation();
            setIsBeingDragged(false);
        };

        const onDragOver = (event) => {
          
            event.stopPropagation();

            if (isBeingDragged) return false;

            event.preventDefault();

            const rect = dragContainer.current.getBoundingClientRect();
            setDragAboveMiddle(event.clientY < rect.y + rect.height / 2);
            setDragOver(true);
            setEventTarget(event.target);

            return true;
        };

        const onDragLeave = (event) => {
            event.preventDefault();

            if (eventTarget === event.target) {       
                setDragOver(false);
            }
        };

        const onDrop = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setDragOver(false);
            let dropData;

            try {
                dropData = JSON.parse(event.dataTransfer.getData("text") || "");
            } catch {
                return;
            }

            if (dropData.type !== type) return;
            if (dropData.index === index) return;

            onOrderChanged(dropData.index, index, !dragAboveMiddle);
        };

        const dragIndicatorClass = dragAboveMiddle ? classes.dragIndicatorAbove : classes.dragIndicatorBelow;

        return (
            <div
                className={`${classes.dragCursor} ${dragOver ? dragIndicatorClass : ""}`}
                draggable={true}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragEnter={onDragOver}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                style={{ opacity: isBeingDragged ? 0.4 : 1 }}
                ref={dragContainer}
            >
                <WrappedComponent {...props} />
            </div>
        );
    };

export default withDragAndDropReorder;

import React from "react";
import {
    TextField,
    Typography,
    Checkbox,
    makeStyles,
    Grid,
    IconButton,
    Tooltip,
    FormControlLabel,
    Radio,
    FormLabel,
    RadioGroup,
    FormControl,
    InputAdornment
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import * as printActions from "../../../actions/print";
import { FilterNone, LibraryAddCheck } from "@material-ui/icons";
import { getPrintFeatures } from "../../../selectors/printSelectors";
import { TITLE_CHAR_LIMIT, NOTES_CHAR_LIMIT } from "../../../constants/print/characterLimits";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff"
    },
    header: {
        padding: 10,
        borderBottom: "2px solid #f5f7f8"
    },
    section: {
        padding: 16,
        borderBottom: "2px solid #ececec"
    },
    formatSelector: {
        width: "80%",
        padding: 8
    },
    radioGroup: {
        paddingLeft: 42,
        justifyContent: "space-between"
    }
}));

const PrintFeatures = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const printFeatures = useSelector(getPrintFeatures);

    const selectedAll =
        printFeatures.showMapCopyright &&
        printFeatures.showMapDate &&
        printFeatures.showMapLogo &&
        printFeatures.showMapScalebar &&
        printFeatures.showMapTitle &&
        printFeatures.showMapNorthArrow &&
        printFeatures.showMapLegend;

    const onSelectAll = () => {
        if (selectedAll) {
            dispatch(printActions.toggleMapLegend());
            dispatch(printActions.toggleMapScalebar());
            dispatch(printActions.toggleMapCopyright());
            dispatch(printActions.toggleMapDate());
            dispatch(printActions.toggleMapNorthArrow());
            dispatch(printActions.toggleMapLogo());
            dispatch(printActions.toggleMapTitle());
            dispatch(printActions.toggleMapNotes());
        } else {
            !printFeatures.showMapLegend && dispatch(printActions.toggleMapLegend());
            !printFeatures.showMapScalebar && dispatch(printActions.toggleMapScalebar());
            !printFeatures.showMapCopyright && dispatch(printActions.toggleMapCopyright());
            !printFeatures.showMapDate && dispatch(printActions.toggleMapDate());
            !printFeatures.showMapNorthArrow && dispatch(printActions.toggleMapNorthArrow());
            !printFeatures.showMapLogo && dispatch(printActions.toggleMapLogo());
            !printFeatures.showMapTitle && dispatch(printActions.toggleMapTitle());
            !printFeatures.showMapTitle && dispatch(printActions.toggleMapNotes());
        }
    };

    const onShowLegendToggle = () => {
        dispatch(printActions.toggleMapLegend());
    };

    const onShowScalebarToggle = () => {
        dispatch(printActions.toggleMapScalebar());
    };

    const onShowCopyrightToggle = () => {
        dispatch(printActions.toggleMapCopyright());
    };

    const onShowDateToggle = () => {
        dispatch(printActions.toggleMapDate());
    };

    const onDateFormatToggle = (e) => {
        dispatch(printActions.toggleMapDateFormat(e.target.value));
    };

    const onShowNorthArrowToggle = () => {
        dispatch(printActions.toggleMapNorthArrow());
    };

    const onShowLogoToggle = () => {
        dispatch(printActions.toggleMapLogo());
    };

    const onShowTitleToggle = () => {
        dispatch(printActions.toggleMapTitle());
    };

    const onShowNotesToggle = () => {
        dispatch(printActions.toggleMapNotes());
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container alignItems="center" className={classes.header}>
                    <Grid item xs={10}>
                        <Grid container justify="flex-start">
                            <Typography variant="subtitle1">Additional Info</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Select All" placement="bottom">
                            <IconButton aria-label="select-all" onClick={() => onSelectAll()}>
                                {selectedAll ? <LibraryAddCheck /> : <FilterNone />}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.section}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapLegend} onChange={onShowLegendToggle} />
                            <Typography variant="body1">Legend</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapCopyright} onChange={onShowCopyrightToggle} />
                            <Typography variant="body1">Copyright</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapScalebar} onChange={onShowScalebarToggle} />
                            <Typography variant="body1">Scalebar</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapDate} onChange={onShowDateToggle} />
                            <Typography variant="body1">Date</Typography>
                        </Grid>
                    </Grid>

                    {printFeatures.showMapDate && (
                        <Grid item xs={12}>
                            <Grid container alignItems="center" className={classes.radioGroup}>
                                <FormControl component="fieldset">
                                    <FormLabel component="label">Date format</FormLabel>
                                    <RadioGroup
                                        aria-label="map-date-type"
                                        name="map-date"
                                        value={printFeatures.mapDateFormat}
                                        onChange={onDateFormatToggle}
                                    >
                                        <Grid container>
                                            <FormControlLabel value="local" control={<Radio />} label="Local time" />
                                            <FormControlLabel value="utc" control={<Radio />} label="UTC time" />
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapLogo} onChange={onShowLogoToggle} />
                            <Typography variant="body1">Logo</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapNorthArrow} onChange={onShowNorthArrowToggle} />
                            <Typography variant="body1">North Arrow</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapTitle} onChange={onShowTitleToggle} />
                            <Typography variant="body1">Map Title</Typography>
                        </Grid>
                    </Grid>
                    {printFeatures.showMapTitle && (
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                placeholder="Map title"
                                value={printFeatures?.mapTitle}
                                onChange={(e) => dispatch(printActions.onMapTitleChange(e.target.value))}
                                inputProps={{ maxLength: TITLE_CHAR_LIMIT }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {printFeatures.mapTitle.length} / {TITLE_CHAR_LIMIT}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapNotes} onChange={onShowNotesToggle} />
                            <Typography variant="body1">Notes</Typography>
                        </Grid>
                    </Grid>
                    {printFeatures.showMapNotes && (
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                placeholder="Map notes"
                                value={printFeatures?.mapNotes}
                                onChange={(e) => dispatch(printActions.onMapNotesChange(e.target.value))}
                                inputProps={{ maxLength: NOTES_CHAR_LIMIT }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {printFeatures.mapNotes.length} / {NOTES_CHAR_LIMIT}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PrintFeatures;

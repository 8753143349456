export const mobileInfoBoxJss = (theme) => ({
    root: {
        position: "absolute",
        backgroundColor: "white",
        zIndex: 4,
        borderRadius: 5,
        bottom: 16,
        left: 16,
        [theme.breakpoints.down("sm")]: {
            left: 8,
            bottom: 8,
            right: 8
        },
        maxHeight: "45vh"
    },
    features: {
        maxHeight: "45vh",
        overflow: "auto"
    },
    featureHeader: {
        padding: 8,
        backgroundColor: "#024F79",
        color: "white",
        paddingLeft: 20
    },
    property: {},
    propertyKey: {
        padding: 4,
        textTransform: "capitalize",
        fontWeight: "bold",
        textAlign: "left",
        fontSize: 14,
        borderBottom: "whitesmoke 1px solid"
    },
    propertyValue: {
        padding: 4,
        fontSize: 14,
        fontWeight: "normal",
        textAlign: "right",
        borderBottom: "whitesmoke 1px solid"
    },
    table: {
        tableLayout: "fixed",
        width: "100%",
        borderCollapse: "collapse"
    },
    closeButton: {
        position: "absolute",
        right: 12,
        top: "0.5rem",
        color: "white",
        backgroundColor: "#024F79",
        height: 24
    }
});

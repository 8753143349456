import { makeStyles } from "@material-ui/core";

export const useInfoTableStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%"
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750,
        position: "relative",
        overflow: "hidden"
    },
    tableContainer: {
        height: "calc(100% - 50px)",
        overflowY: "auto"
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    tableCell: {
        borderRight: "1px solid lightgrey",
        cursor: "pointer",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 200
    },
    downloadContainer: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        position: "absolute",
        top: 14,
        right: 50
    },
    downloadSelectedValue: {
        color: "#fff",
        marginRight: 5,
        marginTop: 2
    },
    downloadButton: {
        color: "#fff"
    }
}));

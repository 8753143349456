import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import copyButton from "../../../utils/icons/copy-icon.svg";

import * as Utils from "../../../utils/infoBoxUtils";
import { infoBoxJss } from "./jss/infoBoxJss";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import * as layerSelectorActions from "../../../reducers/layerSelector/layerSelector";

class InfoBox extends Component {
    state = {
        labels: [],
        open: false
    };

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.infoBoxState.features) !== JSON.stringify(this.props.infoBoxState.features)) {
            this.setState({ open: true });
        }
    }

    onOpenToggle = () => {
        this.setState({ open: false });
    };

    onCopyClick = (index) => {
        let properties = Object.keys(this.props.features[index].properties).reduce((a, b) => {
            a.push({
                key: b,
                value: this.props.features[index].properties[b]
            });
            return a;
        }, []);
        let theClipboard = navigator.clipboard;
        if (theClipboard) {
            let copyText = "";
            properties.map((propKeyValue, propIndex) => {
                let value;
                if (
                    ["ogc_fid", "gid", "geom_type", "?column?"].indexOf(propKeyValue.key) >= 0 ||
                    propKeyValue.key.includes("link") ||
                    propKeyValue.key.includes("foto")
                ) {
                    return false;
                }

                // Creating links
                if (propKeyValue.key.includes("dato")) {
                    let date = new Date(propKeyValue.value);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1; // january is 0
                    let yyyy = date.getFullYear();
                    value = `${dd}/${mm}-${yyyy}`;
                } else {
                    value = propKeyValue.value;
                }

                if (propIndex >= 1) copyText += "\n";
                copyText += propKeyValue.key.charAt(0).toUpperCase() + propKeyValue.key.slice(1) + ": " + value;
                return false;
            });
            navigator.clipboard.writeText(copyText);
        }
    };

    render() {
        let { classes, sourceToNameMapping } = this.props;
        let features = this.props.features.map((feature, featureIndex) => {
            let { featurePropertiesElements, hasPropertiesToDisplay } = Utils.BuildProperties(feature, classes);
            return (
                <div className={classes.feature} key={featureIndex}>
                    <div className={classes.featureHeaderContainer}>
                        <Typography variant="body1" className={classes.featureHeader}>
                            {sourceToNameMapping[feature.sourceLayer]}
                        </Typography>
                        {hasPropertiesToDisplay && (
                            <Tooltip key="copyTT" title="Copy" placement="left">
                                <IconButton
                                    onClick={() => this.onCopyClick(featureIndex)}
                                    className={classes.squareBtn}
                                >
                                    <img src={copyButton} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {/* <Tooltip title="Edit layer style" placement="left">
                            <IconButton
                                onClick={() => this.props.setStylerLayerId(feature.layer.metadata.resourceId)}
                                className={classes.squareBtn}
                            >
                                <ColorLensIcon color="primary" />
                            </IconButton>
                        </Tooltip> */}
                    </div>

                    <table className={classes.table}>
                        <tbody>{featurePropertiesElements}</tbody>
                    </table>
                </div>
            );
        });

        return (
            <div className={classes.root}>
                <div className={classes.header}></div>
                <div className={classes.features}>{features}</div>
            </div>
        );
    }
}

export default withStyles(infoBoxJss)(InfoBox);

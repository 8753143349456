import appStyles from "../../../../utils/styles";

export const infoBoxJss = (theme) => ({
    root: {
        backgroundColor: "white",
        zindex: 4,
        borderRadius: 5,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            left: 8,
            bottom: 8,
            right: 8
        }
    },
    header: {
        height: 30,
        minHeight: 30,
        zIndex: 8,
        backgroundColor: appStyles.color.CLOSE_BACKGROUND,
        color: "white",
        top: 0
    },
    features: {
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        marginBottom: 8,
        padding: "0px 8px 8px 8px",
        backgroundColor: "white"
    },
    featureHeaderContainer: {
        display: "flex",
        position: "sticky",
        top: 0,
        borderTop: "8px white solid",
        borderBottom: "2px solid " + appStyles.color.HEADER_BORDER,
        marginTop: "-8",
        backgroundColor: "white"
    },
    featureHeader: {
        fontSize: 16,
        color: "#024F79",
        fontWeight: "bold",
        padding: 8,
        paddingLeft: 16,
        flexGrow: 1
    },
    propertyKey: {
        paddingLeft: 16,
        padding: 8,
        textTransform: "capitalize",
        fontWeight: "bold",
        fontFamily: ["Roboto", "Helvetica Neue", "sans-serif"],
        textAlign: "left",
        fontSize: 14,
        borderBottom: "whitesmoke 1px solid"
    },
    propertyValue: {
        overflow: "hidden",
        padding: 8,
        fontSize: 14,
        fontWeight: "normal",
        fontFamily: ["Roboto", "Helvetica Neue", "sans-serif"],
        borderBottom: "whitesmoke 1px solid"
    },
    table: {
        tableLayout: "fixed",
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: 4,
        backgroundColor: "white"
    },
    squareBtn: {
        borderRadius: 0,
        paddingTop: 0,
        paddingBottom: 0,
        color: "black",
        backgroundColor: "white"
    },
    noData: {
        paddingLeft: 16,
        padding: 8,
        textTransform: "capitalize",
        fontWeight: "bold",
        fontFamily: ["Roboto", "Helvetica Neue", "sans-serif"],
        textAlign: "left",
        fontSize: 14,

        "&:not(:first-of-type)": {
            display: "none"
        }
    }
});

import { useEffect, useRef } from "react";

const useIfMounted = () => {
    const isMountedRef = useRef(true);
    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const execIfMounted = (funtion) => {
        if (isMountedRef.current === true) {
            funtion();
        }
    };
    return execIfMounted;
};
export default useIfMounted;

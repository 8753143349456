import React, { Component, createRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import * as mapActions from "../../../actions/map";
import * as toolsActions from "../../../actions/tools";
import { connect } from "react-redux";
import { getMap, getDraw } from "../../map/map";
import turfLength from "@turf/length";
import turfArea from "@turf/area";
import turfMidpoint from "@turf/midpoint";
import FilterNone from "@material-ui/icons/FilterNone";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import * as FeatureFactory from "../../../utils/geojsonFeatureFactory";
import { Typography, Tooltip, Button, Menu, Divider, IconButton, Popper, Fade, Paper } from "@material-ui/core";
import { Cancel, Close } from "@material-ui/icons";
import { lineString } from "@turf/helpers";
import { measureJss } from "./measureJss";

// GeoJSON object to hold the distance labels
let distanceLabels = {
    type: "FeatureCollection",
    features: []
};

// GeoJSON object to hold our measurement features
const pointLayer = {
    type: "FeatureCollection",
    features: []
};

// Used to draw a line between points
let lineStringfeature = {
    type: "Feature",
    geometry: {
        type: "LineString",
        coordinates: []
    }
};

let helpLineFeature = {
    type: "Feature",
    geometry: {
        type: "LineString",
        coordinates: []
    }
};

let lineLayer = {
    type: "FeatureCollection",
    features: [lineStringfeature]
};

let helpLineLayer = {
    type: "FeatureCollection",
    features: [helpLineFeature]
};

let units = [
    { multiply: 1, name: "km", namePretty: "Kilometers", unitName: "kilometers" },
    { multiply: 1000, name: "m", namePretty: "Meters", unitName: "meters" },
    { multiply: 0.621371, name: "mi", namePretty: "Miles", unitName: "miles" },
    { multiply: 0.539957, name: "nmi", namePretty: "Nautical Miles", unitName: "nauticalmiles" }
];

let areaUnits = [
    { multiply: 1, name: "m²", namePretty: "Square Meters" },
    { multiply: 0.000001, name: "km²", namePretty: "Square Kilometers" },
    { multiply: 3.86102e-7, name: "mi²", namePretty: "Square Miles" },
    { multiply: 2.91545189e-7, name: "nmi²", namePretty: "Square Nautical Miles" }
];

class Measure extends Component {
    constructor(props) {
        super(props);
        this.containerRef = createRef();

        this.state = {
            distance: 0,
            perimeter: 0,
            area: 0,
            drawing: true,
            selected: null,
            showTooltip: false,
            unitMenuAnchorEl: null,
            areaUnitMenuAnchorEl: null,
            units: units,
            areaUnits: areaUnits,
            selectedUnit: { multiply: 1, name: "km", namePretty: "Kilometers" },
            selectedAreaUnit: { multiply: 0.000001, name: "km²", namePretty: "Square Kilometers" }
        };
    }

    componentDidMount() {
        if (this.props.mapState.loaded) {
            this.init();
        }
    }

    componentDidUpdate(prevProps) {
        getMap().doubleClickZoom.disable();
        if (prevProps.mapState.loaded !== this.props.mapState.loaded) {
            this.init();
        }

        if (prevProps.reinit !== this.props.reinit) {
            this.removeLayersAndSources();
            this.addLayersAndSources();
        }
    }

    componentWillUnmount() {
        this.unmount();
    }

    init() {
        this.map = getMap();
        this.draw = getDraw();
        this.map.doubleClickZoom.disable();
        document.getElementById("map").addEventListener("keydown", this.onPressingEnter);
        document.getElementById("map").addEventListener("dblclick", this.onDoubleClick);

        this.removeLayersAndSources();

        this.draw.changeMode("static");
        this.lastOnClickHandler = this.props.mapState.onClick;
        this.props.mapChangeClick(this.onDrawClick);

        this.map.on("mousedown", "measure-points", this.onMapMouseDown);
        this.map.on("touchstart", "measure-points", this.onMapMouseDown);

        this.map.on("mousemove", this.onMapMouseMove);
        this.map.on("touchmove", this.onMapMouseMove);

        this.map.on("mousemove", "measure-points", this.onMapPointsMouseMove);
        this.map.on("touchmove", "measure-points", this.onMapMouseMove);

        this.map.on("mouseleave", "measure-points", this.onMapPointsMouseLeave);
        this.map.on("touchend", "measure-points", this.onMapMouseUp);
        this.map.on("touchcancel", "measure-points", this.onMapMouseUp);
        this.map.on("mouseup", "measure-points", this.onMapMouseUp);

        this.index = 0;
        this.map.getCanvas().style.cursor = "crosshair";

        this.addLayersAndSources();
    }

    removeLayersAndSources() {
        if (this.map.getLayer("measure-points")) this.map.removeLayer("measure-points");
        if (this.map.getLayer("measure-lines")) this.map.removeLayer("measure-lines");
        if (this.map.getLayer("measure-drawing-help-line")) this.map.removeLayer("measure-drawing-help-line");
        if (this.map.getLayer("measure-drawn-help-line-measurement"))
            this.map.removeLayer("measure-drawn-help-line-measurement");
        if (this.map.getSource("measure-points")) this.map.removeSource("measure-points");
        if (this.map.getSource("measure-lines")) this.map.removeSource("measure-lines");
        if (this.map.getSource("measure-drawing-help-line")) this.map.removeSource("measure-drawing-help-line");
        if (this.map.getSource("measure-drawn-help-line-measurement"))
            this.map.removeSource("measure-drawn-help-line-measurement");
    }

    addLayersAndSources() {
        this.map.addSource("measure-points", {
            type: "geojson",
            data: pointLayer
        });

        this.map.addSource("measure-lines", {
            type: "geojson",
            data: lineLayer
        });

        this.map.addSource("measure-drawing-help-line", {
            type: "geojson",
            data: helpLineLayer
        });

        this.map.addSource("measure-drawn-help-line-measurement", {
            type: "geojson",
            data: distanceLabels
        });

        this.map.addLayer({
            id: "measure-drawing-help-line",
            type: "line",
            source: "measure-drawing-help-line",
            layout: {
                "line-cap": "round",
                "line-join": "round"
            },
            paint: {
                "line-color": "#024F79",
                "line-width": 2.5,
                "line-dasharray": [0.2, 2]
            }
        });

        this.map.addLayer({
            id: "measure-lines",
            type: "line",
            source: "measure-lines",
            layout: {
                "line-cap": "round",
                "line-join": "round"
            },
            paint: {
                "line-color": "#024F79",
                "line-width": 2.5
            }
        });
        this.map.addLayer({
            id: "measure-points",
            type: "circle",
            source: "measure-points",
            paint: {
                "circle-radius": 6,
                "circle-stroke-width": 2,
                "circle-color": ["case", ["boolean", ["feature-state", "hover"], false], "white", "#024F79"],
                "circle-stroke-color": ["case", ["boolean", ["feature-state", "hover"], false], "#024F79", "white"]
            }
        });
        this.map.addLayer({
            id: "measure-drawn-help-line-measurement",
            type: "symbol",
            source: "measure-drawn-help-line-measurement",
            paint: {
                "text-color": "#000000",
                "text-halo-color": "#ffffff",
                "text-halo-width": 2
            },
            layout: {
                "symbol-placement": "point",
                "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                "text-field": "{distance}\n{unit}",
                "text-anchor": "center",
                "text-justify": "center",
                "text-size": 15,
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                "icon-ignore-placement": true
            }
        });
    }

    unmount() {
        this.map.removeLayer("measure-points");
        this.map.removeLayer("measure-lines");
        this.map.removeSource("measure-points");
        this.map.removeSource("measure-lines");
        this.map.removeLayer("measure-drawing-help-line");
        this.map.removeSource("measure-drawing-help-line");
        this.map.removeLayer("measure-drawn-help-line-measurement");
        this.map.removeSource("measure-drawn-help-line-measurement");

        lineStringfeature = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: []
            }
        };
        lineLayer.features = [lineStringfeature];
        helpLineFeature = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: []
            }
        };
        helpLineLayer.features = [helpLineFeature];
        pointLayer.features = [];
        distanceLabels.features = [];

        this.map.off("mousedown", "measure-points", this.onMapMouseDown);
        this.map.off("mousemove", this.onMapMouseMove);
        this.map.off("mousemove", "measure-points", this.onMapPointsMouseMove);
        this.map.off("mouseleave", "measure-points", this.onMapPointsMouseLeave);
        this.map.off("mouseup", "measure-points", this.onMapMouseUp);

        this.map.getCanvas().style.cursor = "";
        this.props.mapChangeClick(this.lastOnClickHandler);
        document.getElementById("map").removeEventListener("keydown", this.onPressingEnter);
        document.getElementById("map").removeEventListener("dblclick", this.onDoubleClick);
        this.map.doubleClickZoom.enable();
    }

    onPressingEnter = (e) => {
        if (e.key === "Enter" && this.state.drawing) {
            this.onFinishMeasureButtonClicked();
        }
    };

    onDoubleClick = (e) => {
        if (lineLayer.features[0].geometry.coordinates.length && this.state.drawing) {
            this.onFinishMeasureButtonClicked();
        }
    };

    renderDistanceMarkers(lines, helpLine = null, unitNew = null) {
        let drawnLinesFromParameter = [...lines.features[0].geometry.coordinates];

        if (helpLine) {
            drawnLinesFromParameter[drawnLinesFromParameter.length] = helpLine.geometry.coordinates[1];
        }
        // check if the last two elements are the same, pop the last one out
        if (
            drawnLinesFromParameter[drawnLinesFromParameter.length - 2].toString() ===
            drawnLinesFromParameter[drawnLinesFromParameter.length - 1].toString()
        ) {
            drawnLinesFromParameter.pop();
        }
        let polygonLines = [];

        polygonLines = drawnLinesFromParameter;
        let unit = this.state.selectedUnit.unitName;
        let unitShort = this.state.selectedUnit.name;
        if (unitNew) {
            unit = unitNew.unitName;
            unitShort = unitNew.name;
        }

        let points = [];
        if (polygonLines.length > 0) {
            for (let i = 0; i <= polygonLines.length - 2; i++) {
                let polygonLine = lineString([polygonLines[i], polygonLines[i + 1]], { name: `segment-${i + 1}` });
                if (polygonLine) {
                    let midPoint = turfMidpoint(polygonLines[i], polygonLines[i + 1]);
                    let routeDistance = turfLength(polygonLine, { units: unit });
                    routeDistance = Number.parseFloat(routeDistance).toFixed(2);
                    points.push({
                        type: "Feature",
                        properties: { unit: unitShort, distance: routeDistance },
                        geometry: {
                            type: "Point",
                            coordinates: midPoint.geometry.coordinates
                        }
                    });
                    if (this.state.drawing && i === polygonLines.length - 2) {
                        let entireLine = lineString(polygonLines, { name: `entire-line-segment` });
                        let routeDistance = turfLength(entireLine, { units: unit });
                        routeDistance = Number.parseFloat(routeDistance).toFixed(2);
                        points.push({
                            type: "Feature",
                            properties: { unit: unitShort, distance: routeDistance },
                            geometry: {
                                type: "Point",
                                coordinates: entireLine.geometry.coordinates[entireLine.geometry.coordinates.length - 1]
                            }
                        });
                    }
                }
            }
            distanceLabels.features = points;
            //render labels
            this.map.getSource("measure-drawn-help-line-measurement").setData(distanceLabels);
        }
    }

    calculatePerimeter(polygon) {
        let distance = 0;
        for (let i = 0; i < polygon.geometry.coordinates[0].length - 1; i++) {
            let point = polygon.geometry.coordinates[0][i];
            let point2 = polygon.geometry.coordinates[0][i + 1];

            let line = FeatureFactory.toLine([point, point2], {});

            distance += turfLength(line);
        }
        return distance;
    }

    onMapMouseDown = (e) => {
        var msFeatures = this.map.queryRenderedFeatures(e.point, {
            layers: ["measure-points"]
        });

        //If no features where hit
        if (msFeatures.length === 0) {
            return;
        }

        //Prevent default map mousedown event
        e.preventDefault();

        this.map.setFeatureState({ source: "measure-points", id: msFeatures[0].id }, { hover: true });

        //Get a referece to the selected feature in pointlayer
        let feature = pointLayer.features.find((x) => x.properties.id === msFeatures[0].properties.id);

        this.setState({
            selected: feature
        });
    };

    onMapMouseUp = (e) => {
        //Dont do anything if nothing is selected
        if (!this.state.selected) {
            return;
        }

        this.map.setFeatureState({ source: "measure-points", id: this.state.selected.id }, { hover: false });

        if (this.state.isPolygon) {
            this.setState({
                selected: null,
                perimeter: this.calculatePerimeter(lineStringfeature),
                area: turfArea(lineStringfeature)
            });
        } else {
            this.setState({
                selected: null,
                distance: turfLength(lineStringfeature)
            });
        }
    };

    onMapMouseMove = (e) => {
        if (helpLineFeature.geometry.coordinates.length > 0) {
            helpLineFeature.geometry.coordinates[1] = [e.lngLat.lng, e.lngLat.lat];
            if (helpLineFeature.geometry.coordinates.length === 2) {
                this.renderDistanceMarkers(lineLayer, helpLineFeature);
                this.map.getSource("measure-drawing-help-line").setData(helpLineLayer);
                this.map.setFeatureState({ source: "measure-drawing-help-line", id: 2 }, { hover: false });
            }
        }

        if (!this.state.selected) {
            return;
        }

        //Move point
        let selectedNew = this.state.selected;
        selectedNew.geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];
        this.setState({ selected: selectedNew });

        //Handle moving linestring
        if (lineStringfeature.geometry.type === "LineString") {
            lineStringfeature.geometry.coordinates[this.state.selected.properties.index] = [e.lngLat.lng, e.lngLat.lat];
        } else {
            //If the first point in the polygon is selected, move both the first and last point
            if (this.state.selected.properties.index === 0) {
                lineStringfeature.geometry.coordinates[0][0] = [e.lngLat.lng, e.lngLat.lat];
                lineStringfeature.geometry.coordinates[0][lineStringfeature.geometry.coordinates[0].length - 1] = [
                    e.lngLat.lng,
                    e.lngLat.lat
                ];
            } else {
                lineStringfeature.geometry.coordinates[0][this.state.selected.properties.index] = [
                    e.lngLat.lng,
                    e.lngLat.lat
                ];
            }
        }
        this.map.getSource("measure-points").setData(pointLayer);
        this.map.getSource("measure-lines").setData(lineLayer);
        if (this.state.selected) {
            if (lineStringfeature.geometry.type === "Polygon") {
                let polygonLayer = {
                    type: "FeatureCollection",
                    features: [{ geometry: { coordinates: lineStringfeature.geometry.coordinates[0] } }]
                };
                this.renderDistanceMarkers(polygonLayer);
            } else {
                this.renderDistanceMarkers(lineLayer);
            }
        }
    };

    onMapPointsMouseMove = (e) => {
        this.map.getCanvas().style.cursor = this.state.drawing ? "crosshair" : "pointer";
    };

    onMapPointsMouseLeave = (e) => {
        this.map.getCanvas().style.cursor = this.state.drawing ? "crosshair" : "";
    };

    drawingFinished(endPoint) {
        this.handleCloseTooltip();
        helpLineFeature = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: []
            }
        };
        helpLineLayer.features = [helpLineFeature];
        this.map.getSource("measure-drawing-help-line").setData(helpLineLayer);

        //If the first point is clicked, convert to a polygon. Else continue as line
        if (endPoint.properties.index === 0) {
            let polygon = FeatureFactory.lineToPolygon(lineStringfeature.geometry.coordinates, {});
            lineStringfeature = polygon;
            lineLayer = {
                type: "FeatureCollection",
                features: [lineStringfeature]
            };
            this.map.getSource("measure-lines").setData(lineLayer);

            this.setState({
                perimeter: this.calculatePerimeter(lineStringfeature),
                area: turfArea(lineStringfeature),
                isPolygon: true,
                drawing: false
            });
            let polygonLayer = {
                type: "FeatureCollection",
                features: [{ geometry: { coordinates: lineStringfeature.geometry.coordinates[0] } }]
            };
            this.renderDistanceMarkers(polygonLayer);
        } else {
            this.setState({
                distance: turfLength(lineStringfeature),
                drawing: false
            });
            this.renderDistanceMarkers(lineLayer);
        }

        this.map.getCanvas().style.cursor = "";
        this.props.mapChangeClick(() => {});
    }

    onDrawClick = (e) => {
        helpLineFeature.geometry.coordinates = [];
        var msFeatures = this.map.queryRenderedFeatures(e.point, {
            layers: ["measure-points"]
        });
        e.preventDefault();

        //If a previous point is clicked, and more than one point has been drawn
        if (msFeatures.length > 0 && pointLayer.features.length > 2) {
            helpLineFeature = {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: []
                }
            };
            helpLineLayer.features = [helpLineFeature];
            this.map.getSource("measure-drawing-help-line").setData(helpLineLayer);
            this.renderDistanceMarkers(lineLayer);
            this.drawingFinished(msFeatures[0]);
            return;
        }

        let newId = this.index++;

        let newPoint = FeatureFactory.toPoint(e.lngLat, { id: newId, index: newId }, newId);

        pointLayer.features.push(newPoint);
        //add the new point as the new end for the line
        lineStringfeature.geometry.coordinates.push(newPoint.geometry.coordinates);
        helpLineFeature.geometry.coordinates.push(newPoint.geometry.coordinates);
        this.map.getSource("measure-points").setData(pointLayer);
        this.map.getSource("measure-lines").setData(lineLayer);
        this.map.getSource("measure-drawing-help-line").setData(helpLineLayer);

        this.map.setFeatureState({ source: "measure-points", id: newId }, { hover: false });
        this.map.setFeatureState({ source: "measure-drawing-help-line", id: newId }, { hover: false });

        this.setState({
            distance: turfLength(lineStringfeature)
        });
        if (this.map.getSource("measure-points")._data.features.length === 3) {
            this.setState({ showTooltip: true });
        } else if (this.map.getSource("measure-points")._data.features.length === 4) {
            this.handleCloseTooltip();
            this.setState({ showTooltip: false });
        }
    };

    onCopyValue = (text) => {
        navigator.clipboard.writeText(text);
    };

    onOpenUnitMenu = (e) => {
        this.setState({
            unitMenuAnchorEl: e.currentTarget
        });
    };

    onCloseUnitMenu = (e) => {
        this.setState({
            unitMenuAnchorEl: null
        });
    };

    onOpenAreaUnitMenu = (e) => {
        this.setState({
            areaUnitMenuAnchorEl: e.currentTarget
        });
    };

    onCloseAreaUnitMenu = (e) => {
        this.setState({
            areaUnitMenuAnchorEl: null
        });
    };

    getLengthUnitFormatted(distance) {
        let roundedDistance = (distance * this.state.selectedUnit.multiply).toFixed(2);
        return { value: roundedDistance, unit: this.state.selectedUnit.name };
    }

    getAreaUnitFormatted(area) {
        let roundedArea = (area * this.state.selectedAreaUnit.multiply).toFixed(2);
        return { value: roundedArea, unit: this.state.selectedAreaUnit.name };
    }

    onUnitItemClick = (unit) => {
        this.setState({
            selectedUnit: unit,
            unitMenuAnchorEl: null
        });

        if (lineLayer.features[0].geometry.coordinates.length === 0) return; // nothing measured on the map

        if (lineLayer.features[0].geometry.type === "LineString") {
            this.renderDistanceMarkers(lineLayer, null, unit);
        } else {
            let polygonLayer = {
                type: "FeatureCollection",
                features: [{ geometry: { coordinates: lineStringfeature.geometry.coordinates[0] } }]
            };
            this.renderDistanceMarkers(polygonLayer, null, unit);
        }
    };

    onAreaUnitItemClick = (unit) => {
        this.setState({
            selectedAreaUnit: unit,
            areaUnitMenuAnchorEl: null
        });
    };

    onStartNewMeasure = () => {
        this.props.mapChangeClick(this.onDrawClick);

        lineStringfeature = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: []
            }
        };
        helpLineFeature = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: []
            }
        };
        lineLayer.features = [lineStringfeature];
        helpLineLayer.features = [helpLineFeature];
        pointLayer.features = [];
        distanceLabels.features = [];

        this.setState({
            distance: 0,
            perimeter: 0,
            area: 0,
            drawing: true,
            selected: null,
            isPolygon: false
        });

        this.index = 0;
        this.map.getCanvas().style.cursor = "crosshair";

        this.map.getSource("measure-points").setData(pointLayer);
        this.map.getSource("measure-lines").setData(lineLayer);
        this.map.getSource("measure-lines").setData(helpLineLayer);
        this.map.getSource("measure-drawn-help-line-measurement").setData(distanceLabels);
    };

    onFinishMeasureButtonClicked = () => {
        //Finish drawing, and select the last drawn point as the final
        this.drawingFinished(pointLayer.features[pointLayer.features.length - 1]);
    };

    handleClose = () => {
        let widget = {
            name: "measure",
            namePretty: "Measure",
            enabled: true,
            toggled: false
        };
        this.props.toggleWidget(widget);
    };

    handleCloseTooltip = () => {
        this.setState({ showTooltip: false });
    };

    render() {
        let { classes } = this.props;

        let menuItems = this.state.units.map((unit, index) => {
            return (
                <MenuItem
                    key={index}
                    className={classes.squareBtn}
                    onClick={() => this.onUnitItemClick(unit)}
                    value={unit.name}
                >
                    {unit.namePretty}
                </MenuItem>
            );
        });

        let areaMenuItems = this.state.areaUnits.map((unit, index) => {
            return (
                <MenuItem
                    key={index}
                    className={classes.squareBtn}
                    onClick={() => this.onAreaUnitItemClick(unit)}
                    value={unit.name}
                >
                    {unit.namePretty}
                </MenuItem>
            );
        });

        let distanceText = this.getLengthUnitFormatted(this.state.distance);
        let perimeterText = this.getLengthUnitFormatted(this.state.perimeter);
        let areaText = this.getAreaUnitFormatted(this.state.area);

        return (
            <div id="measure-widget" className={classes.root} ref={this.containerRef}>
                <div className={classes.header}>
                    <Typography variant="h6" className={classes.headerText}>
                        Measure
                    </Typography>
                    {this.state.drawing ? (
                        <Button
                            variant="outlined"
                            size="small"
                            disabled={pointLayer.features.length < 2}
                            onClick={this.onFinishMeasureButtonClicked}
                        >
                            Finish
                        </Button>
                    ) : (
                        <Button variant="outlined" size="small" onClick={this.onStartNewMeasure}>
                            Start new
                        </Button>
                    )}
                    <IconButton onClick={() => this.handleClose()}>
                        <Close />
                    </IconButton>
                </div>
                <Divider />
                <div className={classes.content}>
                    <Typography variant="body1" gutterBottom className={classes.headerText}>
                        {this.state.isPolygon ? "Perimeter" : "Total distance"}
                    </Typography>
                    <div>
                        {this.state.isPolygon ? (
                            <div className={classes.infoRow}>
                                <span className={classes.measurementValue}>{perimeterText.value}</span>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={classes.measurementUnit}
                                    onClick={this.onOpenUnitMenu}
                                >
                                    <Typography className={classes.measurementUnitText}>
                                        {perimeterText.unit}
                                    </Typography>
                                    <ArrowDownIcon className={classes.arrowIcon} />
                                </Button>
                                <span className={classes.infoRowSpan}></span>
                                <Tooltip key="copyTT" title="Copy" placement="left">
                                    <IconButton
                                        color="primary"
                                        className={classes.copyBtn}
                                        onClick={() => this.onCopyValue(`${perimeterText.value} ${perimeterText.unit}`)}
                                    >
                                        <FilterNone fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ) : (
                            <div className={classes.infoRow}>
                                <span className={classes.measurementValue}>{distanceText.value}</span>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={classes.measurementUnit}
                                    onClick={this.onOpenUnitMenu}
                                >
                                    <Typography className={classes.measurementUnitText}>{distanceText.unit}</Typography>
                                    <ArrowDownIcon className={classes.arrowIcon} />
                                </Button>
                                <span className={classes.infoRowSpan}></span>
                                <Tooltip key="copyTT" title="Copy" placement="left">
                                    <IconButton
                                        color="primary"
                                        className={classes.copyBtn}
                                        onClick={() => this.onCopyValue(`${distanceText.value} ${distanceText.unit}`)}
                                    >
                                        <FilterNone fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}

                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.unitMenuAnchorEl}
                            keepMounted
                            open={Boolean(this.state.unitMenuAnchorEl)}
                            onClose={this.onCloseUnitMenu}
                        >
                            {menuItems}
                        </Menu>
                    </div>
                    {this.state.isPolygon && (
                        <div>
                            <Typography variant="body1" gutterBottom className={classes.headerText}>
                                Area
                            </Typography>
                            <div className={classes.infoRow}>
                                <span className={classes.measurementValue}>{areaText.value}</span>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={classes.measurementUnit}
                                    onClick={this.onOpenAreaUnitMenu}
                                >
                                    <Typography className={classes.measurementUnitText}>{areaText.unit}</Typography>

                                    <ArrowDownIcon className={classes.arrowIcon} />
                                </Button>
                                <span className={classes.infoRowSpan}></span>
                                <Tooltip key="copyTT" title="Copy" placement="left">
                                    <Button
                                        color="primary"
                                        className={classes.copyBtn}
                                        onClick={() => this.onCopyValue(`${areaText.value} ${areaText.unit}`)}
                                    >
                                        <FilterNone fontSize="small" />
                                    </Button>
                                </Tooltip>
                            </div>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.areaUnitMenuAnchorEl}
                                keepMounted
                                open={Boolean(this.state.areaUnitMenuAnchorEl)}
                                onClose={this.onCloseAreaUnitMenu}
                            >
                                {areaMenuItems}
                            </Menu>
                        </div>
                    )}
                </div>
                {this.state.showTooltip && (
                    <Popper
                        className={classes.infoPanelContainer}
                        open={this.state.showTooltip}
                        anchorEl={this.containerRef.current}
                        placement="bottom-start"
                        transition
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper className={classes.infoPanel}>
                                    <Typography>Click on the first point to measure area.</Typography>
                                    <IconButton size="small" onClick={this.handleCloseTooltip}>
                                        <Cancel />
                                    </IconButton>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        measure: ownProps,
        menu: state.menu,
        mapState: state.map
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        toggleMeasuring: (measure) => dispatch(mapActions.toggleMeasuring(measure)),
        mapChangeClick: (func) => dispatch(mapActions.mapChangeClick(func)),
        setmeasureDist: (distance) => dispatch(mapActions.setmeasureDist(distance)),
        toggleWidget: (widget) => dispatch(toolsActions.toggleWidget(widget))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(measureJss)(Measure));

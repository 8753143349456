import { makeStyles } from "@material-ui/styles";
import styles from "./../../../../scss/variables.module.scss";

export const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: 8
    },
    chip: {
        marginRight: 8,
        height: 28,
        width: 58,
        fontSize: 12,
        fontWeight: "bold",
        background: "white",
        justifyContent: "space-between",
        borderColor: styles.primaryColor,
    },
    chipError: {
        color: styles.primaryErrorColor,
        borderColor: styles.primaryErrorColor
    },
    percentilesTextField: {
        width: 25,
        paddingTop: 6
    },
    percentilesInput: {
        fontSize: 12,
        fontWeight: "bold",
        borderColor: styles.primaryColor
    },
    rightAlign: {
        display: "flex"
    },
    limitCreator: {
        minHeight: 35,
        display: "flex",
        alignItems: "center",
        width: "100%"
    },
    inputChipTextField: {
        width: 58,
        height: 28,
        marginRight: 8,
        marginBottom:0 + "!Important",
        "& .MuiOutlinedInput-root": {
            position: "relative",
            borderRadius: 16,
            height: 28,
            color: styles.primaryColor
        }
    },
    grow: {
        flexGrow: 1
    }
}));

import * as THREE from "three/build/three.module.js";

const _v1$4 = /*@__PURE__*/ new THREE.Vector3();
// eslint-disable-next-line no-unused-vars
const _v2$2 = /*@__PURE__*/ new THREE.Vector3();

export default class MapboxLOD extends THREE.Object3D {
    constructor() {
        super();

        this._currentLevel = 0;

        this.type = "LOD";

        Object.defineProperties(this, {
            levels: {
                enumerable: true,
                value: []
            },
            isLOD: {
                value: true
            }
        });

        this.autoUpdate = false;
    }

    copy(source) {
        super.copy(source, false);

        const levels = source.levels;

        for (let i = 0, l = levels.length; i < l; i++) {
            const level = levels[i];

            this.addLevel(level.object.clone(), level.animations, level.distance);
        }

        this.autoUpdate = source.autoUpdate;

        return this;
    }

    addLevel(object, animations, distance = 0) {
        distance = Math.abs(distance);

        const levels = this.levels;

        let l;

        for (l = 0; l < levels.length; l++) {
            if (distance < levels[l].distance) {
                break;
            }
        }

        levels.splice(l, 0, {
            distance: distance,
            object: object,
            animations: animations
        });

        this.add(object);

        return this;
    }

    getCurrentLevel() {
        return this._currentLevel;
    }

    getObjectForDistance(distance) {
        const levels = this.levels;

        if (levels.length > 0) {
            let i, l;

            for (i = 1, l = levels.length; i < l; i++) {
                if (distance < levels[i].distance) {
                    break;
                }
            }

            return levels[i - 1].object;
        }

        return null;
    }

    raycast(raycaster, intersects) {
        const levels = this.levels;

        if (levels.length > 0) {
            _v1$4.setFromMatrixPosition(this.matrixWorld);

            const distance = raycaster.ray.origin.distanceTo(_v1$4);

            this.getObjectForDistance(distance).raycast(raycaster, intersects);
        }
    }

    update(map) {
        const levels = this.levels;

        if (levels.length > 1) {
            // _v1$4.setFromMatrixPosition( camera.matrixWorld );
            // _v2$2.setFromMatrixPosition( this.matrixWorld );

            const distance = map.getZoom();

            levels[0].object.visible = true;

            let i, l;

            for (i = 1, l = levels.length; i < l; i++) {
                if (distance >= levels[i].distance) {
                    levels[i - 1].object.visible = false;
                    levels[i].object.visible = true;
                } else {
                    break;
                }
            }

            this._currentLevel = i - 1;

            for (; i < l; i++) {
                levels[i].object.visible = false;
            }
        }
    }

    toJSON(meta) {
        const data = super.toJSON(meta);

        if (this.autoUpdate === false) data.object.autoUpdate = false;

        data.object.levels = [];

        const levels = this.levels;

        for (let i = 0, l = levels.length; i < l; i++) {
            const level = levels[i];

            data.object.levels.push({
                object: level.object.uuid,
                distance: level.distance
            });
        }

        return data;
    }
}

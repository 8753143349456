import thunk from "redux-thunk";
import reducers from "./reducers/index";
import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";
import zanderMiddleware from "./middleware/zanderMiddleware";

let history = createBrowserHistory({
    /* pass a configuration object here if needed */
});

const store = configureStore({
    reducer: { ...reducers },
    middleware: [thunk, zanderMiddleware],
    devTools: process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION__
});

export { store, history };

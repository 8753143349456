import { createSelector } from "reselect";

const stylesState = (state) => state.style;

const digitizeStylesSelector = (state) => state.style.digitizeStyles;

export const getStyles = createSelector(stylesState, (stylesState) => {
    return stylesState.styles;
});

export const getAppId = createSelector(stylesState, (stylesState) => {
    return stylesState.appId;
});

export const getDigitizeStyles = createSelector(stylesState, (stylesState) => {
    return stylesState.digitizeStyles;
});

export const getStylesForDigitizeLayerId = (digitizeLayerId) =>
    createSelector(digitizeStylesSelector, (digitizeStyles) => {
        if (digitizeLayerId === undefined) return undefined;

        const digitizeLayerDatasetId = digitizeLayerId.split(":")[0];
        return digitizeStyles.filter((style) => style.layerId.split(":")[0] === digitizeLayerDatasetId);
    });

import React from "react";
import { Dialog, Modal, Backdrop } from "@material-ui/core";
import PrintPreview from "./printPreview";
import { useDispatch, useSelector } from "react-redux";
import * as toolsActions from "../../../actions/tools";
import { getPrintToggleStatus, getWidgets } from "../../../selectors/toolsSelectors";
import widgetTypes from "../../../utils/tools/widgets";

const PrintModal = () => {
    // getModalStyle is not a pure function, we roll the style only on the first render
    const dispatch = useDispatch();
    const widgets = useSelector(getWidgets);
    const modalOpen = useSelector(getPrintToggleStatus);
    const printWidget = widgets.find((widget) => widget.name === widgetTypes.PRINT);

    const handleClose = () => {
        printWidget.toggled = !printWidget.toggled;
        dispatch(toolsActions.toggleWidget(printWidget));
    };

    return (
        <Dialog BackdropComponent={Backdrop} open={modalOpen} onClose={handleClose} maxWidth={false}>
            <PrintPreview handleClose={handleClose} />
        </Dialog>
    );
};

export default PrintModal;

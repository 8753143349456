import { Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
    root: {
        position: "absolute",
        height: 25,
        fontWeight: "bold",
        color: "#f5f5f5",
        backgroundColor: "#024F79",
        zIndex: 5,
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
    },
    whiteText: {
        color: "#fff"
    }
}));

const MapEditing = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Grid container justify="center" alignItems="center">
                    <Typography className={classes.whiteText} variant="subtitle2">
                        Editing map features
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MapEditing;

import { makeStyles } from "@material-ui/core";

export const useCoordinatesWidgetStyles = makeStyles((theme) => ({
    root: {
        fontWeight: "bold",
        color: "#333",
        backgroundColor: "hsla(0,0%,100%,.75)",
        borderWidth: "1px 1px 1px 1px",
        borderColor: "#333",
        borderStyle: "solid",
        display: "flex",
        alignItems: "center"
    },
    coordinateContainer: {
        padding: "1px 6px 1px 6px"
    },
    iconStyle: {
        height: 15,
        color: "#333"
    },
    iconStyleDisabled: {
        height: 15,
        color: "grey"
    },
    coordinatesText: {
        fontSize: "0.685rem",
        padding: "0px 8px",
        minWidth: 90
    },
    crsBox: {
        padding: 8,
        backgroundColor: theme.palette.primary.main,
        color: "white",
        marginRight: 8
    },
    crsName: {
        textTransform: "uppercase"
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark
        }
    }
}));

import React from "react";

const MapLegend = ({ labels, desiredNumberOfEntries }) => {
    const structureLabels = (labels, numberOfDesiredLabels) => {
        let sizeOfCustomSizeRenderedLabels = 0;
        let sizeOfActualRenderedLabels = 0;

        for (let i = 0; i < labels.length; i++) {
            if (sizeOfActualRenderedLabels <= numberOfDesiredLabels) {
                if (Array.isArray(labels[i].props.children[1])) {
                    sizeOfActualRenderedLabels = sizeOfActualRenderedLabels + labels[i].props.children[1].length + 1;
                } else {
                    sizeOfActualRenderedLabels++;
                }
            } else {
                break;
            }
            if (sizeOfActualRenderedLabels <= numberOfDesiredLabels) {
                sizeOfCustomSizeRenderedLabels++;
            }
        }
        return labels.slice(0, sizeOfCustomSizeRenderedLabels);
    };

    return <>{structureLabels(labels, desiredNumberOfEntries)}</>;
};

export default MapLegend;

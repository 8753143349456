import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import CustomTypography from "../../common/CustomTypography/CustomTypography";
import BuildIcon from "@material-ui/icons/Build";
import CopyIcon from "@material-ui/icons/FilterNone";
import FilterNone from "@material-ui/icons/GetApp";

import { useStyles } from "./jss/eraInfoBoxJss";
import config from "../../../config";
import toastr from "../../../utils/customToastr";

const EraInfoBox = ({ features, onZanderToolOpen }) => {
    const classes = useStyles();

    const onDownload = (lat, lon) => {
        const location = parseLocation(lat, lon);

        const link = new URL(config.eraDatasetResource);
        link.searchParams.append("location", `n${location.lat}_e${location.lon}`);
        link.searchParams.append("container", "esoxera5");
        window.open(decodeURIComponent(link.toString()), "_blank");
    };

    const onDownloadMetaOnly = (lat, lon) => {
        const location = parseLocation(lat, lon);

        const link = new URL(config.eraDatasetResource);
        link.searchParams.append("container", "esoxera5");
        link.searchParams.append("location", `n${location.lat}_e${location.lon}`);
        link.searchParams.append("onlyData", true);
        window.open(decodeURIComponent(link.toString()), "_blank");
    };

    const onCopyLink = (e, lat, lon) => {
        const location = parseLocation(lat, lon);

        let link = `https://esox.lautec.com/map/?location=${location.lat}/${location.lon}`;

        window.parent.postMessage({ type: "copy-to-clipboard", toCopy: link }, "*");

        navigator.clipboard.writeText(link);
        toastr.success("The text has been copied");
    };

    const parseLocation = (lat, lon) => {
        return {
            lat: parseFloat(lat).toFixed(2),
            lon: parseFloat(lon).toFixed(2)
        };
    };

    const feature = features[0];

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <CustomTypography variant="h2" fontWeight="bold">
                    Download
                </CustomTypography>
            </div>
            <div className={classes.content}>
                <div className={classes.item}>
                    <CustomTypography variant="subtitle1">Latitude:</CustomTypography>
                    <CustomTypography variant="subtitle1"> {parseFloat(feature.properties.latitude)}</CustomTypography>
                </div>
                <div className={classes.item}>
                    <CustomTypography variant="subtitle1">Longitude:</CustomTypography>
                    <CustomTypography variant="subtitle1"> {parseFloat(feature.properties.longitude)}</CustomTypography>
                </div>
                <div className={classes.item}>
                    <CustomTypography variant="subtitle1">Land-sea mask:</CustomTypography>
                    <CustomTypography variant="subtitle1"> {feature.properties["land-sea mask"]}</CustomTypography>
                </div>

                <div className={classes.item}>
                    <CustomTypography variant="subtitle1" style={{ flexGrow: 1 }}>
                        Copy direct link to data point:
                    </CustomTypography>
                    <Tooltip key="copyTT" title="Copy link" placement="left">
                        <IconButton
                            onClick={(e) => onCopyLink(e, feature.properties.latitude, feature.properties.longitude)}
                            className={classes.squareBtn}
                            color="secondary"
                            variant="contained"
                        >
                            <CopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.item}>
                    <CustomTypography variant="subtitle1" style={{ flexGrow: 1 }}>
                        Download metocean data + ESOX tool:
                    </CustomTypography>
                    <Tooltip key="copyTT" title="Download" placement="left">
                        <IconButton
                            onClick={() => onDownload(feature.properties.latitude, feature.properties.longitude)}
                            color="secondary"
                            variant="contained"
                            className={classes.squareBtn}
                        >
                            <FilterNone fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.item}>
                    <CustomTypography variant="subtitle1" style={{ flexGrow: 1 }}>
                        Download metocean data:
                    </CustomTypography>
                    <Tooltip key="copyTT" title="Download" placement="left">
                        <IconButton
                            onClick={() =>
                                onDownloadMetaOnly(feature.properties.latitude, feature.properties.longitude)
                            }
                            color="secondary"
                            variant="contained"
                            className={classes.squareBtn}
                        >
                            <FilterNone fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.item}>
                    <CustomTypography variant="subtitle1" style={{ flexGrow: 1 }}>
                        ESOX Zander Weather window statistics:
                    </CustomTypography>
                    <Tooltip key="copyTT" title="Zander Tool" placement="left">
                        <IconButton
                            onClick={() => onZanderToolOpen(feature.properties.latitude, feature.properties.longitude)}
                            color="secondary"
                            variant="contained"
                            className={classes.squareBtn}
                        >
                            <BuildIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default EraInfoBox;

import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography, Slider, Popover, Grid, TextField, Switch, Divider } from "@material-ui/core";
import { Terrain } from "@material-ui/icons";
import { getMap } from "../map";
import { useExaggerationSliderStyles } from "./styles/exaggerationSliderStyles";

const ExaggerationSlider = ({
    fullScreenEl,
    terrainEnabled,
    onTerrainToggled,
    exaggerationValue,
    onTerrainExaggerationChanged,
    onTerrainExaggerationCommitted
}) => {
    const classes = useExaggerationSliderStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const onOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onClosePopover = () => {
        setAnchorEl(null);
    };

    const onExaggerationChange = (e, newValue) => {
        onTerrainExaggerationChanged(newValue);
    };

    const onExaggerationCommited = () => {
        onTerrainExaggerationCommitted();
    };

    return (
        <div className={classes.iconRoot}>
            <Tooltip title="Terrain exaggeration">
                <div className={classes.iconContainer} onClick={onOpenPopover}>
                    <Terrain className={`${classes.icon} ${terrainEnabled ? classes.IconEnabled : ""}`}  />
                </div>
            </Tooltip>
            <Popover
                open={open}
                container={fullScreenEl}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                onClose={onClosePopover}
               
               
            >
                <div className={classes.popoverRoot}>
                    <div className={classes.headerContainer}>
                        <Typography className={classes.headerTypography} variant="h6">
                            <Terrain className={`${classes.headerIcon} ${terrainEnabled ? classes.IconEnabled : ""}`} />
                            3D Terrain
                        </Typography>
                        <Switch checked={terrainEnabled} onChange={onTerrainToggled} />
                    </div>
                    <Divider />
                    <div className={classes.exaggerationContainer}>
                        <Typography
                            className={classes.flexGrow}
                            style={{ color: terrainEnabled ? "rgba(0, 0, 0, 0.87)" : "lightgray" }}
                            variant="subtitle2"
                        >
                            Exaggeration
                        </Typography>
                        <div style={{ color: terrainEnabled ? "rgba(0, 0, 0, 0.87)" : "lightgray" }}>
                            {exaggerationValue}
                        </div>
                    </div>

                    <div className={classes.sliderContainer}>
                        <Tooltip title="Drag slider to adjust exaggeration">
                            <Slider
                                defaultValue={exaggerationValue}
                                value={exaggerationValue}
                                onChange={onExaggerationChange}
                                onChangeCommitted={onExaggerationCommited}
                                aria-labelledby="map-exaggeration-slider"
                                step={0.1}
                                min={1}
                                max={5}
                                valueLabelDisplay="auto"
                                disabled={!terrainEnabled}
                            />
                        </Tooltip>
                    </div>
                </div>
            </Popover>
        </div>
    );
};

export default ExaggerationSlider;

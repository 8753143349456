import { makeStyles } from "@material-ui/styles";

const useDragAndDropStyles = makeStyles((theme) => ({
    dragIndicatorAbove: {
        borderTop: "2px solid " + theme.palette.primary.main + "!important",
    },
    dragIndicatorBelow: {
        borderBottom: "2px solid " + theme.palette.primary.main + "!important"
    },
    dragCursor: {
        cursor: "move",
        borderTop: "2px solid transparent",
        borderBottom: "2px solid transparent"
    },

}));

export default useDragAndDropStyles;

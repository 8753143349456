export const digitizeTools = [
    {
        name: "point",
        namePretty: "Point",
        icon: "pointIcon",
        enabled: true
    },
    {
        name: "line",
        namePretty: "Line",
        icon: "lineIcon",
        enabled: true
    },
    {
        name: "polygon",
        namePretty: "Polygon",
        icon: "polygonIcon",
        enabled: true
    },
    {
        name: "circle",
        namePretty: "Circle",
        icon: "circleIcon",
        enabled: true
    }
];

import * as types from "../actions/actionTypes";
import apiClient from "../actions/apiClient";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";

const authInit = {
    authenticated: false,
    fetching: true,
    token: null,
    expiresOn: new Date(),
    authPromise: null,
    failReason: ""
};

export function authReducer(state = authInit, action) {
    switch (action.type) {
        case types.AUTHENTICATED:
            apiClient.defaults.headers.common["Authorization"] = "Bearer " + action.result;
            let jwtProperties = jwt_decode(action.result);
            let expiresOnDate = dayjs(jwtProperties.exp * 1000).toDate();
            return {
                ...state,
                authenticated: true,
                fetching: false,
                token: action.result,
                expiresOn: expiresOnDate,
                authPromise: null,
                failReason: ""
            };
        case types.AUTHENTICATING:
            return { ...state, fetching: true, authPromise: action.authPromise };
        case types.AUTHENTICATING_FAILED:
            return {
                ...state,
                authenticated: false,
                fetching: false,
                failReason: action.result,
                authPromise: null
            };
        default:
            return state;
    }
}

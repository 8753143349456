import { makeStyles } from "@material-ui/core";
import appStyles from "../../../utils/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        transition: "all 0.4s ",
        height: "calc(100% - 50px)",
        [theme.breakpoints.down("xs")]: {
            right: 4,
            left: 4,
            maxWidth: 450
        },
        position: "relative"
    },
    flexContainer: {
        height: "100%"
    },
    absoluteContainer: {
        position: "absolute",
        left: 411,
        backgroundColor: "#f5f7f8"
    },
    container: {
        padding: 9,
        backgroundColor: "#fafbff"
    },
    searchContainer: {
        padding: "10px 16px",
        backgroundColor: "white"
    },
    paper: {
        marginTop: 8,
        "&:not:first-child": {
            marginTop: 8
        }
    },
    map: {
        display: "flex",
        alignItems: "center",
        padding: 8,
        backgroundColor: appStyles.color.LAYER_BACKGROUND,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "whitesmoke"
        },
        "&:not(:last-child)": {
            borderBottom: " 1px whitesmoke solid"
        }
    },
    layerInput: { fontSize: 14 },
    layer: {
        display: "flex",
        alignItems: "center",
        padding: 8,

        cursor: "pointer",
        "&:hover": {
            backgroundColor: "whitesmoke"
        },
        "&:not(:last-child)": {
            borderBottom: " 1px whitesmoke solid"
        }
    },
    layerHeader: {
        padding: 10,
        borderBottom: " 1px whitesmoke solid",
        borderTop: " 1px whitesmoke solid"
    },
    layers: {
        height: "calc(100% - 50px)",
        [theme.breakpoints.down("sm")]: {
            maxHeight: "100%"
        }
    },
    squareBtn: {
        borderRadius: 0
    },
    noPadButton: {
        padding: 0,
        background: "#F3F3F3"
    },
    attributeTableContainer: {
        position: "absolute",
        bottom: 1,
        left: "calc (50% - ",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#fff"
    }
}));

import { MenuItem, withStyles } from "@material-ui/core";

const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:active": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white
            }
        },
        padding: "4px 6px"
    }
}))(MenuItem);

export default StyledMenuItem;

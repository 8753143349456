import React from "react";
import { Typography, TextField } from "@material-ui/core";
import ColorPopover from "../colorPopover";

function ColorProperty(props) {
    return (
        <div className="property" onClick={props.onClick}>
            <div className="property-content">
                {props.editable && (
                    <TextField className="textfield" onChange={props.onTitleChanged} value={props.title} />
                )}
                {!props.editable && (
                    <Typography variant="body1" className="name">
                        {props.title}
                    </Typography>
                )}
                <ColorPopover
                    color={props.value}
                    onColorChanged={(color) =>
                        props.onPropertyChanged(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
                    }
                />
            </div>
        </div>
    );
}

export default ColorProperty;

import { makeStyles } from "@material-ui/styles";
import styles from "../../../scss/variables.module.scss";

export const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: 8
    },
    zanderDialog: {
        "& .MuiDialog-paper": {
            width: 660,
            maxHeight: "90%"
        }
    },
    zanderDialogActions: {
        padding: 20,
        display: "flex",
        alignItems: "left"
    },
    recaptchaDisclaimer: {
        maxWidth: 300,
        marginLeft: "auto"
    },
    spacing: {
        marginRight: 8
    },
    tabLabel: {
        display: "flex",
        fontSize: 600
    },
    closeTabIcon: {
        display: "none",
        position: "absolute",
        right: 8,
        marginTop: -8,
        cursor: "pointer",
        color: styles.mutedColor,
        "&:hover": {
            color: styles.primaryTextColor
        }
    },
    closeIconVisible: {
        display: "initial"
    },
    activeTab: {
        cursor: "unset"
    },
    clearButton: {
        marginLeft: 8
    }
}));

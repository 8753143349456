import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
    zanderParameter: {
        display: "flex",      
        boxShadow: "0 2px 12px 1px rgba(60, 62, 66, 0.1)",      
        margin: "4px 0px",
        borderRadius: 8
    },
    dragIndicatorColumn: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        padding:"0px 16px",
        backgroundColor:theme.palette.primary.main,
        color:'white',
        borderRadius: "8px 0px 0px 8px"
    },
    typeColumn:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        padding:"0px 16px",
        color:theme.palette.primary.main
    },
    criteriaUnit:{
        color:theme.palette.primary.main,
        marginRight:8
    },
    contentColumn: {
        flexGrow:1,     
    },
    zanderParameterHeader: {
        display: "flex",
        alignItems: "center",
        padding: 16,
        paddingBottom:0,
        color: 'white'
    },
    chip: {
        marginRight: 8,
        marginBottom: -18,
        height: 20,
        fontSize: 11,
        fontWeight: "bold",
        background: "white"
    },
    dividerSpacing: {
        marginTop: 10
    },
    limitsContainer: {
        padding: 16,  
        display:'flex',
        alignItems:'center'  
    },

}));

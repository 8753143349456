import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { Provider } from "react-redux";
import { store } from "./store.js";
import theme from "./MuiConfig";
import { ThemeProvider } from "@material-ui/core";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime).extend(utc);

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <App />
        </Provider>
    </ThemeProvider>,
    document.getElementById("root")
);

serviceWorkerRegistration.registerTileCache();

import React, { Component } from "react";
import { connect } from "react-redux";
import MapComponent, { getMap } from "../components/map/map";
import ToolsMenu from "../components/sidebar/toolsMenu/toolsMenu";
import SidebarRoot from "../components/sidebar/sidebarRoot";

import * as mapActions from "../actions/map";
import * as serverActions from "../actions/atlasClient";
import * as layerSelectorActions from "../reducers/layerSelector/layerSelector";
import * as configActions from "../actions/config";
import * as sidebarActions from "../actions/sidebarAndDrawer";

import StyleConfig from "../components/sidebar/layerSelector/style/styleConfig";
import { PortalDiv } from "../utils/portal";

import proj4 from "proj4";
import { isGroup } from "@emblautec/rescursive-array-extensions";

class MapView extends Component {
    state = {
        appName: null
    };

    componentDidMount() {
        this.props.clear();
        this.loadApp();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.appId !== this.props.match.params.appId) {
            this.props.clear();
            this.loadApp();
        }

        if (!this.state.appName) {
            //In case of double layer/sources addition bugs, consider adding a this.props.clear() here
            this.loadApp();
        }
    }

    isUpdated(resourceId, modifiedDate) {
        const savedDate = new Date(localStorage.getItem(resourceId));
        return modifiedDate > savedDate;
    }

    checkCache(maps) {
        for (let i = 0; i < maps.length; i++) {
            let map = maps[i];

            const modifiedDate = new Date(map.modifiedUtc);

            if (this.isUpdated(map.id, modifiedDate)) {
                caches.delete(map.id);
                localStorage.setItem(map.id, map.modifiedUtc);
            }
        }
    }

    checkQueryParams = (mapBounds) => {
        let queryParams = new URLSearchParams(document.location.search);

        //To be backwards compatiable we check if the users is using a old map copy state link
        var coordinates = queryParams.get("coordinates");
        var bounds = queryParams.get("bounds");

        //Then we just return the default bounds. this should force users to create new links so that we can remove this
        if (coordinates) {
            return mapBounds;
        } else if (bounds) {
            return JSON.parse(bounds);
        }
        return mapBounds;
    };

    loadApp() {
        return this.props.getApp(this.props.match.params.appId).then(({ payload: appResponse }) => {
            if (!appResponse) {
                this.props.history.replace("/");
                return;
            }
            let app = appResponse;

            //We disable any use of localstorage because we are loading the app through an iframe
            //this.checkCache(app.maps);
            caches.delete("public");

            this.addProjections(app.configJson.projections);

            if (!this.props.match.params.lon) {
                this.props.setMapBounds(this.checkQueryParams(app.configJson.mapBounds), {
                    padding: { top: 45, bottom: 45, left: 45, right: 45 },
                    animate: false
                });
            }

            this.addSources(app.maps, app.rasters);

            this.setState({
                appName: app.name
            });

            this.loadStyles(app);

            this.props.setSidebarOpen(!app.configJson.sidebarCollapsed);

            this.props.setConfig({
                sidebarCollapsed: app.configJson.sidebarCollapsed,
                mapZoom: app.configJson.mapZoom,
                mapBounds: app.configJson.mapBounds,
                basemaps: app.configJson.basemaps || [],
                layerGroups: app.configJson.layerGroups,
                projections: app.configJson.projections,
                tools: app.configJson.tools,
                widgets: app.configJson.widgets,
                languages: app.configJson.languages
            });
        });
    }

    loadStyles(app) {
        let layers = [];
        let paintsMap = {};
        let layoutsMap = {};
        let visibleLayersIdsMap = {};

        const datasetsMap = app.maps.reduce((acc, map) => {
            map.datasets.forEach((dataset) => {
                acc[dataset.id] = dataset;
            });
            return acc;
        }, {});

        let queryParams = document.location.search;
        let layerGroups = app.configJson.layerGroups;
        let previousStyleId = null;

        const currentZoomLevel = getMap().getZoom();
        if (queryParams.length) {
            let mapQueryState = queryParams.split("&");
            mapQueryState.forEach((element) => {
                if (!!element.length) {
                    let mapStateType = element.split("=");
                    if (mapStateType[0] === "visibleLayers") {
                        let decodedVisibleLayers = decodeURIComponent(mapStateType[1]);
                        const visibleIds = decodedVisibleLayers.split(",");
                        visibleIds.forEach((id) => {
                            visibleLayersIdsMap[id] = id;
                        });
                    }
                }
            });

            layerGroups.forGroupsRecursive((group) => {
                const hasVisibleLayer = group.layers.some(
                    (layer) => visibleLayersIdsMap[layer.resourceId] !== undefined && !isGroup(layer)
                );

                group.options.collapsed = hasVisibleLayer ? false : true;
            });
        }

        const layerVisibilityMap = {};
        const layerStylesMap = {};

        layerGroups.forLayersRecursive((layer) => {
            layerVisibilityMap[layer.resourceId] = queryParams.length
                ? visibleLayersIdsMap[layer.resourceId] !== undefined
                : layer.options.enabled;

            layer.rowCount = datasetsMap[layer.resourceId]?.rowCount || 0;

            let styleMinZoom = 24,
                styleMaxZoom = 0;

            layer.styles.forEach((style) => {
                const layerId = style.styleId;
                if (style.minZoom < styleMinZoom) styleMinZoom = style.minZoom;
                if (style.maxZoom > styleMaxZoom) styleMaxZoom = style.maxZoom;
                layers.push({
                    sourceId: layer.sourceId,
                    layerId,
                    resourceId: layer.resourceId,
                    sourceName: layer.sourceName,
                    type: style.type,
                    minZoom: style.minZoom,
                    maxZoom: style.maxZoom,
                    drawBefore: previousStyleId
                });

                //Add any new paint/layout properties to style
                const properties = JSON.parse(JSON.stringify(StyleConfig[style.type]));
                properties.forEach((property) => {
                    let styleProperty = style.properties.find((x) => x.name === property.name);
                    if (styleProperty) {
                        property.value = styleProperty.value;
                        property.expressionType = styleProperty.expressionType || "none";
                    }
                });

                paintsMap[layerId] = {
                    layerId,
                    properties: properties.filter((x) => x.type === "paint")
                };

                layoutsMap[layerId] = {
                    layerId,
                    datasetId: layer.resourceId,
                    properties: [
                        ...properties.filter((x) => x.type === "layout"),
                        {
                            name: "visibility",
                            value: layerVisibilityMap[layer.resourceId] ? "visible" : "none"
                        }
                    ]
                };

                previousStyleId = style.styleId;
            });
            layerStylesMap[layer.resourceId] = layer.styles;
            delete layer.styles;
            layer.minZoom = styleMinZoom;
            layer.maxZoom = styleMaxZoom;
            layer.isShown =
                Math.floor(styleMinZoom) <= Math.floor(currentZoomLevel) &&
                Math.floor(currentZoomLevel) <= Math.floor(styleMaxZoom);
        });

        //This is for copy map state
        this.props.setLayerMaps({ layerVisibilityMap, layerStylesMap });
        this.props.setLayerGroups(layerGroups);
        this.props.initMapResources({ layers, paintsMap, layoutsMap });
    }

    addProjections(projections) {
        let defs = [];
        for (let i = 0; i < projections.length; i++) {
            let projection = projections[i];

            defs.push([projection.crs, projection.value]);
        }
        proj4.defs(defs);
    }

    addSources(maps, rasters) {
        const sources = [];
        maps.forEach((map) => {
            sources.push({
                id: map.id,
                minZoom: map.minZoom,
                maxZoom: map.maxZoom,
                type: "vector"
            });
        });

        rasters.forEach((raster) => {
            sources.push({
                id: raster.id,
                minZoom: raster.minZoom,
                maxZoom: raster.maxZoom,
                type: "raster"
            });
        });
        this.props.addMapSources(sources);
    }

    render() {
        return (
            <div id="grid" className="main-grid main-view">
                <div className="flex-container">
                    <MapComponent
                        location={this.props.location}
                        lon={this.props.match.params.lon}
                        lat={this.props.match.params.lat}
                    />
                    <PortalDiv />
                </div>

                <ToolsMenu history={this.props.history} />
                <SidebarRoot match={this.props.match} appName={this.state.appName} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return {
        getApp: (appid) => dispatch(serverActions.getApp(appid)),
        clear: () => dispatch(mapActions.clear()),
        initMapResources: (mapResources) => dispatch(mapActions.initMapResources(mapResources)),
        setConfig: (config) => dispatch(configActions.setConfig(config)),
        setLayerGroups: (groups) => dispatch(layerSelectorActions.setLayerGroups(groups)),
        setLayerMaps: (maps) => dispatch(layerSelectorActions.setLayerMaps(maps)),
        addMapSources: (source) => dispatch(mapActions.addMapSources(source)),
        setMapZoom: (mapZoom) => dispatch(layerSelectorActions.setMapZoom(mapZoom)),
        setMapBounds: (bbox, options) => dispatch(mapActions.fitBounds(bbox, options)),
        setSidebarOpen: (isSidebarOpen) => dispatch(sidebarActions.setSidebarOpen(isSidebarOpen))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MapView);

import * as types from "./actionTypes";

export const updateLayer = (layer) => ({ type: types.MAP_UPDATE_LAYER, result: layer });

export const addLayer = (layer) => ({ type: types.MAP_ADD_LAYER, result: layer });

export const initMapResources = (mapResources) => ({ type: types.INIT_MAP_RESOURCES, payload: mapResources });

export const removeLayer = (layerId) => ({ type: types.MAP_REMOVE_LAYER, result: layerId });

export const moveLayer = (layerId, beforeLayerId) => ({
    type: types.MAP_MOVE_LAYER,
    result: { layerId, beforeLayerId }
});

export const updateZoomRange = (zoomRange) => ({ type: types.MAP_UPDATE_ZOOMRANGE, result: zoomRange });

export const toggleMeasuring = (measuring) => ({ type: types.MAP_TOGGLE_MEASURE, result: measuring });

export const setmeasureDist = (distance) => ({ type: types.MAP_SET_MEASUREDIST, result: distance });

export const addPaint = (paint) => ({ type: types.MAP_ADD_PAINT, result: paint });

export const updatePaint = (paint) => ({ type: types.MAP_UPDATE_PAINT, result: paint });

export const addLayout = (layout) => ({ type: types.MAP_ADD_LAYOUT, result: layout });

export const updateLayout = (layout) => ({ type: types.MAP_UPDATE_LAYOUT, result: layout });

export const flyTo = (location) => ({ type: types.MAP_FLY_TO, result: location });

export const fitBounds = (bbox, options) => ({ type: types.MAP_FIT_BOUNDS, result: { bbox, options } });

export const clear = () => ({ type: types.MAP_CLEAR });

export const selectFeatures = (features) => ({ type: types.SELECT_FEATURES, result: features });

export const mapLoaded = (status) => ({ type: types.MAP_LOADED, result: status });

export const mapChangeClick = (func) => ({ type: types.MAP_CHANGE_CLICK, result: func });

export const mapClick = (position) => ({ type: types.MAP_CLICK, result: position });

export const setBasemap = (basemap) => ({ type: types.MAP_SET_BASEMAP, result: basemap });

export const setLanguage = (language) => ({ type: types.MAP_SET_LANGUAGE, result: language });

export const addMapSources = (sources) => ({ type: types.MAP_ADD_SOURCES, result: sources });

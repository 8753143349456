import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        position: "absolute",
        top: 10,
        left: 10,
        zIndex: 1,
        backgroundColor: "rgba(0,0,0,0)"
    },
    image: {
        maxHeight: 50,
        height: "5vh"
    },
    rootLegend: {
        position: "relative",
        display: "flex",
        justifyContent: "center"
    }
}));

const MapLogo = ({ modal = false }) => {
    const classes = useStyles();
    return (
        <div id="map-logo" className={`${modal ? classes.rootLegend : classes.root}`}>
            <img src={"/media/customer-logo.png"} alt="map-logo" className={classes.image} />
        </div>
    );
};

export default MapLogo;

import * as Yup from "yup";
import { zanderParameterType } from "../../../constants/zander/constants";
import {
    MAX_HOUR,
    MAX_HS_M,
    MAX_PERCENTILE,
    MAX_TP_S,
    MAX_WEATHER_WINDOW_VALUE,
    MAX_WINDSPEED10,
    MAX_WINDSPEED100,
    MIN_HOUR,
    MIN_HS_M,
    MIN_PERCENTILE,
    MIN_PERCENTILE_ELEMENTS,
    MIN_TP_S,
    MIN_WEATHER_WINDOWS_ELEMENTS,
    MIN_WEATHER_WINDOW_VALUE,
    MIN_WINDSPEED10,
    MIN_WINDSPEED100
} from "./constants";

const minErrorMessage = (min) => `Number must be greater than ${min}`;
const maxErrorMessage = (max) => `Number must be less than ${max}`;
const minArraySizeErrorMessage = (min) => `Must provide at least ${min} values`;
const integerTypeErrorMessage = "Number must be an integer";
const numberTypeErrorMessage = "The input must be a number";
const duplicateLimitErrorMessage = "This limit is already added";
const duplictePercentileErrorMessage = "This percentile is already added";

const hasAtLeastOneLimit = (parameters) => parameters.some((p) => p.maxLimits.length > 0);

export const WeatherWindowsSchema = Yup.array()
    .of(
        Yup.number()
            .min(MIN_WEATHER_WINDOW_VALUE, minErrorMessage(MIN_WEATHER_WINDOW_VALUE))
            .max(MAX_WEATHER_WINDOW_VALUE, maxErrorMessage(MAX_WEATHER_WINDOW_VALUE))
            .typeError(numberTypeErrorMessage)
    )
    .unique(duplicateLimitErrorMessage);

const limitsCommon = Yup.number().typeError(numberTypeErrorMessage);

const parametersTypeValidators = {
    [zanderParameterType.WindSpeed10.value]: limitsCommon
        .min(MIN_WINDSPEED10, minErrorMessage(MIN_WINDSPEED10))
        .max(MAX_WINDSPEED10, maxErrorMessage(MAX_WINDSPEED10))
        .integer(integerTypeErrorMessage),
    [zanderParameterType.WindSpeed100.value]: limitsCommon
        .min(MIN_WINDSPEED100, minErrorMessage(MIN_WINDSPEED100))
        .max(MAX_WINDSPEED100, maxErrorMessage(MAX_WINDSPEED100))
        .integer(integerTypeErrorMessage),
    [zanderParameterType.WaveHeight.value]: limitsCommon
        .min(MIN_HS_M, minErrorMessage(MIN_HS_M))
        .max(MAX_HS_M, maxErrorMessage(MAX_HS_M)),
    [zanderParameterType.WavePeriod.value]: limitsCommon
        .min(MIN_TP_S, minErrorMessage(MIN_TP_S))
        .max(MAX_TP_S, maxErrorMessage(MAX_TP_S))
        .integer(integerTypeErrorMessage)
};

export const LimitsSchema = (parameterType) =>
    Yup.array().of(parametersTypeValidators[parameterType]).unique(duplicateLimitErrorMessage);

export const PercentilesSchema = Yup.array()
    .of(
        Yup.number()
            .typeError(numberTypeErrorMessage)
            .min(MIN_PERCENTILE, minErrorMessage(MIN_PERCENTILE))
            .max(MAX_PERCENTILE, maxErrorMessage(MAX_PERCENTILE))
            .integer(integerTypeErrorMessage)
    )
    .unique(duplictePercentileErrorMessage);

export const SheetsSchema = Yup.array().of(
    Yup.object({
        endHour: Yup.number(),
        startHour: Yup.number(),
        weatherWindowDurations: Yup.array()
            .when(["startHour", "endHour"], (startHour, endHour, schema) => {
                return startHour > MIN_HOUR || endHour < MAX_HOUR
                    ? Yup.array(Yup.number().max(endHour - startHour))
                    : schema;
            })
            .min(MIN_WEATHER_WINDOWS_ELEMENTS, minArraySizeErrorMessage(MIN_WEATHER_WINDOWS_ELEMENTS)),
        percentiles: Yup.array().min(MIN_PERCENTILE_ELEMENTS, minArraySizeErrorMessage(MIN_PERCENTILE_ELEMENTS)),
        parameters: Yup.array().test(
            "at least one criteria test",
            "Must provide at least one limit",
            hasAtLeastOneLimit
        )
    })
);

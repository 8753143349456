import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
    imageSizeContainer: {
        height: "100%",
        width: "100%",
        zIndex: 2,
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        bottom: 4,
        left: 0,
        right: 0,
        pointerEvents: "none"
    },
    imageSize: {
        border: "2px dashed gray",
        animation: "spin 2s linear infinite"
    }
});

function PrintSizeVisualizer(props) {
    let { classes } = props;

    if (!props.options) {
        return null;
    }

    return (
        <div className={classes.imageSizeContainer}>
            <div
                style={{ height: props.options.height, width: props.options.width }}
                className={classes.imageSize}
            ></div>
        </div>
    );
}

export default withStyles(styles)(PrintSizeVisualizer);

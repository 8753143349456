import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getMap } from "../map";

const useStyles = makeStyles(() => ({
    root: {
        position: "absolute",
        top: 20,
        right: 20,
        zIndex: 1,
        backgroundColor: "rgba(0,0,0,0)"
    },
    image: {
        maxHeight: 40
    }
}));

const MapNorthArrow = ({ modal = false }) => {
    const classes = useStyles();
    const [north, setNorth] = useState(getMap().getBearing());

    useEffect(() => {
        let arrow = document.getElementById("north-arrow-icon");
        getMap().on("rotate", () => {
            setNorth(getMap().getBearing());
            arrow.style.transform = `rotate(${-north}deg)`;
        });
    });
    return (
        <div id="map-north-arrow" className={classes.root}>
            <img
                id="north-arrow-icon"
                src={process.env.PUBLIC_URL + "/media/arrow.png"}
                alt="map-north-arrow"
                className={classes.image}
            />
        </div>
    );
};

export default MapNorthArrow;

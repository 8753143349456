import { Grid } from "@material-ui/core";
import { marks, MAX_HOUR, MAX_INTERVAL_LENGTH, MIN_HOUR } from "../../../../constants/zander/constants";
import { default as toastr } from "../../../../utils/customToastr";
import CustomTypography from "../../../common/CustomTypography/CustomTypography";
import withDragAndDropReorder from "../../../common/HOCs/withDragAndDropReorder";
import LimitsCreator from "../limitsCreator/LimitsCreator";
import ZanderParameter from "../zanderParameter/ZanderParameter";
import { PercentilesSchema, WeatherWindowsSchema } from "./../../../../utils/validators/zander/zander";
import { PrettySlider, useStyles } from "./styles";

const ZanderSheet = ({ sheet, onSheetChanged }) => {
    const classes = useStyles();

    const onWeatherWindowsChanged = (newWeatherWindows) => {
        onSheetChanged({ ...sheet, weatherWindowDurations: newWeatherWindows });
    };

    const onParameterChanged = (newParameter, index) => {
        const newParameters = sheet.parameters.map((parameter, i) => {
            return index === i ? newParameter : parameter;
        });

        onSheetChanged({ ...sheet, parameters: newParameters });
    };

    const onPercentilesChanged = (newPercentiles) => onSheetChanged({ ...sheet, percentiles: newPercentiles });

    const generateWeatherWindowsLimits = () => {
        if (sheet.startHour === MIN_HOUR && sheet.endHour === MAX_HOUR) {
            return sheet.weatherWindowDurations.map((duration) => {
                return {
                    value: duration,
                    error: false
                };
            });
        }

        const intervalLength = sheet.endHour - sheet.startHour;

        return sheet.weatherWindowDurations.map((duration) => {
            const error = duration > intervalLength;

            return {
                value: duration,
                error
            };
        });
    };

    const onHourIntervalChanged = (_, newValue) => {
        const intervalLength = sheet.endHour - sheet.startHour;
        const newIntervalLength = newValue[1] - newValue[0];

        if (intervalLength === MAX_INTERVAL_LENGTH && newIntervalLength !== MAX_INTERVAL_LENGTH) {
            toastr.info("Setting a time of day interval will limit the possible weather windows duration values");
        }

        onSheetChanged({
            ...sheet,
            startHour: newValue[0],
            endHour: newValue[1]
        });
    };

    const onChangeParameterOrder = (index, newIndex, moveBelow) => {
        const parameter = sheet.parameters[index];
        const newParameters = sheet.parameters.filter((_, paramIndex) => index !== paramIndex);
        const insertPosition = newIndex - Number(index < newIndex) + Number(moveBelow);
        newParameters.splice(insertPosition, 0, parameter);
        onSheetChanged({ ...sheet, parameters: newParameters });
    };

    const parameterToComponent = (parameter, index) => {
        const ParameterWithReorder = withDragAndDropReorder(
            ZanderParameter,
            "zanderParameter",
            index,
            onChangeParameterOrder
        );

        return (
            <ParameterWithReorder
                key={index}
                parameter={parameter}
                onParameterChanged={(newParameter) => onParameterChanged(newParameter, index)}
            />
        );
    };

    return (
        <div className={classes.zanderSheet}>
            <Grid container direction="column">
                <CustomTypography variant="h6" fontWeight="bold" gutterBottom>
                    Settings
                </CustomTypography>
                <div className={classes.sectionCard}>
                    <Grid item className={classes.spacingBottom}>
                        <CustomTypography variant="body2" fontWeight="bold">
                            Minimum weather window duration [h]
                        </CustomTypography>
                    </Grid>
                    <Grid item className={classes.spacingX}>
                        <LimitsCreator
                            limits={generateWeatherWindowsLimits()}
                            onLimitsChanged={onWeatherWindowsChanged}
                            text="Save"
                            validationSchema={WeatherWindowsSchema}
                        />
                    </Grid>
                </div>
                <div className={classes.sectionCard}>
                    <Grid item className={classes.spacingBottom}>
                        <CustomTypography variant="body2" fontWeight="semibold">
                            Time of day limitation (UTC)
                        </CustomTypography>
                    </Grid>
                    <Grid item className={`${classes.spacingTop} ${classes.zanderSliderContainer}`}>
                        <div className={classes.zanderSlider}>
                            <PrettySlider
                                defaultValue={[sheet.startHour, sheet.endHour]}
                                onChangeCommitted={onHourIntervalChanged}
                                valueLabelDisplay="auto"
                                size="medium"
                                step={1}
                                marks={marks}
                                min={0}
                                max={24}
                            />
                        </div>
                    </Grid>
                </div>
                <div className={classes.sectionCard}>
                    <Grid item className={classes.spacingBottom}>
                        <CustomTypography variant="body2" fontWeight="semibold">
                            Persistence exceedance percentiles [%]
                        </CustomTypography>
                    </Grid>
                    <Grid item className={classes.percentiles}>
                        <LimitsCreator
                            limits={sheet.percentiles.map((percentile) => {
                                return {
                                    value: percentile,
                                    error: false
                                };
                            })}
                            onLimitsChanged={onPercentilesChanged}
                            text="Save"
                            validationSchema={PercentilesSchema}
                        />
                    </Grid>
                </div>
                <CustomTypography variant="h6" fontWeight="bold" >
                    Criteria
                </CustomTypography>
                <div>{sheet.parameters.map((parameter, index) => parameterToComponent(parameter, index))}</div>
            </Grid>
        </div>
    );
};

export default ZanderSheet;

import { makeStyles } from "@material-ui/core";

export const useLayerSelectorStylerStyle = makeStyles(() => ({
    stylerDivWrapper: { flexGrow: 1, overflow: "auto", display: "flex" },
    backIcon: {
        color: "black",
        transform: "rotate(180deg)"
    },
    headerText: {
        lineHeight: "30px",
        fontWeight: "400"
    }
}));

const PRIMARY_BG = "#024F79";
const SECONDARY_BG = "#0393e2";

const appStyles = {
    color: {
        CHECKED_SWITCH: SECONDARY_BG, 
        HEADER_BACKGROUND: PRIMARY_BG, 
        CLOSE_COLOR: "#2a385d",
        TOOL_HOVER: "#0C567E",
        LAYER_BACKGROUND: "#F1F3F3",
        CLOSE_BACKGROUND: "#013c5c",
        HEADER_BORDER: "#BDBDBD",
        ACTION_BACKGROUND: PRIMARY_BG,
    },
    fontSize: {
        LIST_TEXT: 12
    }
};
export default appStyles;

export const MIN_WEATHER_WINDOWS_ELEMENTS = 1;
export const MAX_WEATHER_WINDOWS_ELEMENTS = 5;

export const MIN_WEATHER_WINDOW_VALUE = 1;
export const MAX_WEATHER_WINDOW_VALUE = 240;

export const MIN_WINDSPEED10 = 1;
export const MAX_WINDSPEED10 = 30;

export const MIN_WINDSPEED100 = 1;
export const MAX_WINDSPEED100 = 30;

export const MIN_HS_M = 0.01;
export const MAX_HS_M = 10;

export const MIN_TP_S = 1;
export const MAX_TP_S = 25;

export const MIN_PERCENTILE = 4;
export const MAX_PERCENTILE = 96;

export const MIN_PERCENTILE_ELEMENTS = 1;
export const MAX_PERCENTILE_ELEMENTS = 5;

export const MIN_HOUR = 0;
export const MAX_HOUR = 24;

import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import InfoIcon from "@material-ui/icons/Info";
import { Tooltip } from "@material-ui/core";
import * as mapActions from "../../../actions/map";
import * as layerSelectorActions from "../../../reducers/layerSelector/layerSelector";

import Context from "./contextMenu";
import { Icon } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useLayerStyles from "./styles/layerStyles";
import { getLayerStyles, getLayerVisibility } from "../../../selectors/layerSelector";

const Layer = ({ layer, layerHandlers }) => {
    const classes = useLayerStyles();

    const [contextOpen, setContextOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const [opacity, setOpacity] = useState(100);

    const visible = useSelector(getLayerVisibility(layer.resourceId));
    const styles = useSelector(getLayerStyles(layer.resourceId));
    const dispatch = useDispatch();

    const toggleCMenu = (e) => {
        if (e) e.stopPropagation();
        setContextOpen((contextOpen) => !contextOpen);
        return null;
    };

    const closeCMenu = () => {
        setContextOpen(false);
    };

    const handleOpacity = (e, newValue) => {
        setOpacity(newValue);
    };

    const handleCommitOpacity = () => {
        styles.forEach((style) => {
            const newProperties = style.properties.map((paintProperty) =>
                paintProperty.name.includes("opacity") ? { ...paintProperty, value: opacity / 100 } : paintProperty
            );
            dispatch(
                mapActions.updatePaint({
                    layerId: style.styleId,
                    properties: newProperties
                })
            );
            dispatch(
                layerSelectorActions.changePropertiesOfLayerStyle({
                    layerId: layer.resourceId,
                    styleId: style.styleId,
                    properties: newProperties
                })
            );
        });
    };

    const onToggle = () => {
        let newDisplayValue = visible ? "none" : "visible";

        dispatch(layerSelectorActions.setLayerVisibility({ layerId: layer.resourceId, newVisibility: !visible }));

        styles.forEach((style) => {
            dispatch(
                mapActions.updateLayout({
                    layerId: style.styleId,
                    datasetId: layer.resourceId,
                    properties: [
                        {
                            name: "visibility",
                            value: newDisplayValue
                        }
                    ]
                })
            );
        });
    };

    return (
        <div className={"layer"}>
            <Switch
                className={classes.switch}
                edge="end"
                checked={visible}
                color={!layer.isShown ? "default" : "secondary"}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
                onClick={() => onToggle()}
            />
            <Tooltip title={layer.name} enterDelay={800} leaveDelay={100}>
                <Typography variant="body2" color="inherit" className={classes.layerText} onClick={() => onToggle()}>
                    {layer.name}
                </Typography>
            </Tooltip>
            {!layer.isShown && (
                <Tooltip title="Layer is not visible at current zoom level.">
                    <Icon className={classes.grayIcon}>
                        <InfoIcon />
                    </Icon>
                </Tooltip>
            )}

            <IconButton
                className={classes.contextMenuBtn}
                ref={anchorRef}
                aria-controls={contextOpen ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={toggleCMenu}
            >
                <MoreHoriz />
            </IconButton>
            <Context
                layer={layer}
                onToggle={onToggle}
                anchorEl={anchorRef.current}
                closeCMenu={closeCMenu}
                open={contextOpen}
                toggleCMenu={toggleCMenu}
                opacity={opacity}
                handleOpacity={handleOpacity}
                handleCommitOpacity={handleCommitOpacity}
                layerHandlers={layerHandlers}
            />
        </div>
    );
};

export default Layer;

import { makeStyles } from "@material-ui/core";
import appStyles from "../../../../../utils/styles";

export const useNavButtonStyles = makeStyles(() => ({
    actionButtonActive: {
        color: "white",
        backgroundColor: "hsla(0,0%,100%,.04)",
        width: "100%",
        padding: 0,
        height: 48,
        borderRadius: 0,
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        "&:hover": {
            borderRadius: 0,
            backgroundColor: appStyles.color.TOOL_HOVER
        },
        "&.navOpen": {
            justifyContent: "start"
        }
    },
    actionButton: {
        backgroundColor: appStyles.color.PRIMARY_BG,
        color: "white",
        width: "100%",
        opacity: 0.7,
        borderRadius: 0,
        padding: 0,
        height: 48,
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        "&:hover": {
            borderRadius: 0,
            backgroundColor: appStyles.color.TOOL_HOVER
        }
    },
    navOpen: {
        justifyContent: "start"
    },
    toolIcon: {
        paddingLeft: 20,
        paddingRight: 20,
        display: "flex"
    }
}));

import React, { useEffect, useState } from "react";
import {
    Modal,
    Fade,
    Typography,
    Backdrop,
    LinearProgress,
    Button,
    Select,
    MenuItem,
    makeStyles,
    Grid,
    Paper
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { downloadDataset } from "../../../actions/layerSelector";

const dataTypes = [
    { name: "GeoJSON", prettyName: "GeoJson" },
    { name: "ESRI Shapefile", prettyName: "Shapefile" },
    { name: "KML", prettyName: "Kml" }
];

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        height: 220,
        outline: "none"
    },
    modalHeader: {
        backgroundColor: "#024F79",
        padding: theme.spacing(2),
        color: "#fff",
        textAlign: "center"
    },
    headerText: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff"
    },
    layerName: {
        padding: 5,
        fontSize: "1.25rem",
        color: "#e0e0e0"
    },
    downloadDialogSelectContainer: {
        padding: 20
    },
    downloadDialogSelect: {
        width: "40%"
    },
    modalText: {
        textAlign: "center",
        padding: 20
    },
    layerNameDownloadModal: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        fontWeight: "bold"
    }
}));

const DownloadLayer = ({ open, handleClose, layer }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [downloading, setDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [dataType, setDataType] = useState(dataTypes[0].name);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        return () => {
            setErrorMessage(null);
        };
    }, [dataType]);

    const onDownload = () => {
        setDownloading(true);

        let config = {
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setDownloadProgress(percentCompleted);
            }
        };
        let fileName = makeFileName(layer.name, dataType);

        dispatch(
            downloadDataset({ datasetId: layer.resourceId, filename: fileName, format: dataType, config: config })
        ).then(
            () => {
                handleClose();
                setDownloading(false);
                setDownloadProgress(0);
            },
            (err) => {
                setErrorMessage(err.message);
                setDownloading(false);
                setDownloadProgress(0);
            }
        );
    };

    const makeFileName = (name, format) => {
        switch (format) {
            case "GeoJSON":
            case "KML":
            case "ESRI Shapefile":
                return `${name}.zip`;
            default:
                console.error("Invalid file type");
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open}>
                <Paper className={classes.root}>
                    <Grid container>
                        <Grid item xs={12} className={classes.modalHeader}>
                            <Typography className={classes.headerText} variant="h6">
                                Download layer
                            </Typography>
                        </Grid>
                        {downloading && (
                            <Grid item xs={12}>
                                {downloadProgress === 0 ? (
                                    <LinearProgress variant="indeterminate" />
                                ) : (
                                    <LinearProgress variant="determinate" value={downloadProgress} />
                                )}
                            </Grid>
                        )}
                        {!downloading && (
                            <Grid item xs={12} className={classes.downloadDialogSelectContainer}>
                                <Grid container justify="center">
                                    <Typography className={classes.layerNameDownloadModal} gutterBottom component="div">
                                        {layer.name}
                                    </Typography>
                                    <Select
                                        labelId="layer-selector-download-data-type-label"
                                        id="layer-selector-download-data-type"
                                        value={dataType}
                                        onChange={(e) => setDataType(e.target.value)}
                                        className={classes.downloadDialogSelect}
                                    >
                                        {dataTypes.map((dataType) => (
                                            <MenuItem key={dataType.name} value={dataType.name}>
                                                {dataType.prettyName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        )}
                        {!downloading && (
                            <Grid item xs={12}>
                                <Grid container justify="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<GetApp />}
                                        onClick={onDownload}
                                    >
                                        Download
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        {downloading && (
                            <Grid item xs={12}>
                                {downloadProgress === 0 ? (
                                    <Typography className={classes.modalText} variant="h6" color="primary">
                                        Generating File
                                    </Typography>
                                ) : (
                                    <Typography className={classes.modalText} variant="h6" color="primary">
                                        Downloading... {downloadProgress}%
                                    </Typography>
                                )}
                            </Grid>
                        )}
                        {errorMessage && (
                            <Grid item xs={12}>
                                <Typography className={classes.errorModalText} color="error">
                                    {errorMessage}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Fade>
        </Modal>
    );
};

export default DownloadLayer;

export const AUTHENTICATING = "AUTHENTICATING";
export const AUTHENTICATED = "AUTHENTICATED";
export const AUTHENTICATING_FAILED = "AUTHENTICATING_FAILED";
export const DRAW_FEATURES_CREATED = "DRAW_FEATURES_CREATED";
export const DRAW_FEATURE_DELETED = "DRAW_FEATURE_DELETED";
export const DRAW_FEATURE_EDITED = "DRAW_FEATURE_EDITED";
export const DRAW_FEATURES_RENAMED = "DRAW_FEATURES_RENAMED";
export const SELECT_DIGITIZE_FEATURE = "SELECT_DIGITIZE_FEATURE";
export const ADD_DIGITIZE_LAYER = "ADD_DIGITIZE_LAYER";
export const EDIT_DIGITIZE_LAYER = "EDIT_DIGITIZE_LAYER";
export const DELETE_DIGITIZE_LAYER = "DELETE_DIGITIZE_LAYER";
export const DIGITIZE_EDITING = "DIGITIZE_EDITING";
export const DIGITIZE_HELP = "DIGITIZE_HELP";
export const TOGGLE_DIGITIZE_LAYER = "TOGGLE_DIGITIZE_LAYER";
export const TOGGLE_ALL_DIGITIZE_LAYERS = "TOGGLE_ALL_DIGITIZE_LAYERS";
export const MAP_LOADED = "MAP_LOADED";
export const MAP_CLICK = "MAP_CLICK";
export const MAP_CHANGE_CLICK = "MAP_CHANGE_CLICK";
export const MAP_UPDATE_LAYER = "MAP_UPDATE_LAYER";
export const MAP_ADD_LAYER = "MAP_ADD_LAYER";
export const MAP_ADD_SOURCES = "MAP_ADD_SOURCES";
export const MAP_REMOVE_LAYER = "MAP_REMOVE_LAYER";
export const MAP_MOVE_LAYER = "MAP_MOVE_LAYER";
export const MAP_UPDATE_ZOOMRANGE = "MAP_UPDATE_ZOOMRANGE";
export const MAP_UPDATE_PAINT = "MAP_UPDATE_PAINT";
export const MAP_ADD_PAINT = "MAP_ADD_PAINT";
export const MAP_UPDATE_LAYOUT = "MAP_UPDATE_LAYOUT";
export const MAP_TOGGLE_MEASURE = "MAP_TOGGLE_MEASURE";
export const MAP_SET_MEASUREDIST = "MAP_SET_MEASUREDIST";
export const MAP_FIT_BOUNDS = "MAP_FIT_BOUNDS";
export const MAP_SET_BASEMAP = "MAP_SET_BASEMAP";
export const MAP_SET_LANGUAGE = "MAP_SET_LANGUAGE";
export const MAP_ADD_LAYOUT = "MAP_ADD_LAYOUT";
export const MAP_FLY_TO = "MAP_FLY_TO";
export const MAP_CLEAR = "MAP_CLEAR";
export const INIT_MAP_RESOURCES = "INIT_MAP_RESOURCES";
export const SELECT_FEATURES = "SELECT_FEATURES";

export const SET_SEARCH = "SET_SEARCH";
export const FETCH_APPS_START = "FETCH_APPS_START";
export const FETCH_APPS_COMPLETE = "FETCH_APPS_COMPLETE";
export const FETCH_APPS_FAILED = "FETCH_APPS_FAILED";
export const SET_CONFIG = "SET_CONFIG";
export const FETCH_STYLES = "FETCH_STYLES";
export const FETCH_MULTIPLE_STYLES = "FETCH_MULTIPLE_STYLES";
export const FETCH_MULTIPLE_STYLES_COMPLETE = "FETCH_MULTIPLE_STYLES_COMPLETE";
export const ADD_DIGITIZE_STYLE = "ADD_DIGITIZE_STYLE";
export const UPDATE_DIGITIZE_STYLE = "UPDATE_DIGITIZE_STYLE";
export const REMOVE_DIGITIZE_STYLE = "REMOVE_DIGITIZE_STYLE";
export const REMOVE_ALL_DIGITIZE_STYLES_FOR_LAYER = "REMOVE_ALL_DIGITIZE_STYLES_FOR_LAYER";
export const RENAME_DIGITIZE_STYLES_NAME_FOR_LAYER = "RENAME_DIGITIZE_STYLES_NAME_FOR_LAYER";
export const REINITIALIZE_DIGITIZE_STYLES_LAYER_ID = "REINITIALIZE_DIGITIZE_STYLES_LAYER_ID";
export const TOGGLE_SIDEBAR_OPEN = "TOGGLE_SIDEBAR_OPEN";
export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";
export const TOOLS_TOGGLE_WIDGET = "TOOLS_TOGGLE_WIDGET";
export const PRINT_MAP_LEGEND = "PRINT_MAP_LEGEND";
export const PRINT_MAP_SCALEBAR = "PRINT_MAP_SCALEBAR";
export const PRINT_TOGGLE_MAP_NOTES = "PRINT_TOGGLE_MAP_NOTES";
export const PRINT_MAP_NOTES = "PRINT_MAP_NOTES";
export const PRINT_TOGGLE_MAP_TITLE = "PRINT_TOGGLE_MAP_TITLE";
export const PRINT_MAP_TITLE = "PRINT_MAP_TITLE";
export const PRINT_TOGGLE_MAP_DATE = "PRINT_TOGGLE_MAP_DATE";
export const PRINT_TOGGLE_MAP_DATE_FORMAT = "PRINT_TOGGLE_MAP_DATE_FORMAT";
export const PRINT_TOGGLE_MAP_COPYRIGHT = "PRINT_TOGGLE_MAP_COPYRIGHT";
export const PRINT_TOGGLE_MAP_NORTH_ARROW = "PRINT_TOGGLE_MAP_NORTH_ARROW";
export const PRINT_TOGGLE_MAP_LOGO = "PRINT_TOGGLE_MAP_LOGO";
export const RESET_PRINT_FEATURES = "RESET_PRINT_FEATURES";

export const FETCH_MAPBOX_STYLE = "FETCH_MAPBOX_STYLE";
export const FETCH_MAPBOX_STYLE_COMPLETED = "FETCH_MAPBOX_STYLE_COMPLETED";

export const TOGGLE_NAVIGATION_OPEN = "TOGGLE_NAVIGATION_OPEN";
export const TOGGLE_DRAWER_OPEN = "TOGGLE_DRAWER_OPEN";

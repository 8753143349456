import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import * as sidebarAndDrawerActions from "../../../actions/sidebarAndDrawer";
import * as toolsActions from "../../../actions/tools";
import { measureIcon } from "../../../utils/mapIcons";
import { history } from "../../../store";

import SvgIcon from "@material-ui/core/SvgIcon";
import Button from "@material-ui/core/Button";
import OpenNav from "@material-ui/icons/Menu";
import CloseNav from "@material-ui/icons/DoubleArrow";
import LayerIcon from "@material-ui/icons/Layers";
import SearchIcon from "@material-ui/icons/Search";
import DigitizeIcon from "@material-ui/icons/Timeline";
import PrintIcon from "@material-ui/icons/Print";
import UserMenu from "./userMenu";
import { List } from "@material-ui/icons";
import { toolsMenuJss } from "./jss/toolsMenuJss";
import { LAYER_SELECTOR_PATH_SUFFIX } from "../../../constants/routes";
import acceptedTools from "../../../constants/acceptedTools";
import toolsDict from "../../../constants/tools";

import NavButton from "./components/NavButton/NavButton";

class ToolsMenu extends Component {
    state = {
        icons: {
            search: <SearchIcon fontSize="medium" />,
            print: <PrintIcon fontSize="medium" />,
            measure: <SvgIcon className={this.props.classes.toolbarBtn}>{measureIcon}</SvgIcon>,
            digitize: <DigitizeIcon fontSize="medium" />,
            legend: <List fontSize="medium" />
        }
    };

    navigate = (location) => {
        history.replace(location);
        if (!this.props.sidebarState) this.props.toggleSidebarOpen();
    };

    toggle = (widget) => {
        let openWidget = this.props.widgets.filter((x) => x.toggled)[0];
        if (!openWidget) {
            widget.toggled = !widget.toggled;
            this.props.toggleWidget(widget);
        } else {
            openWidget.toggled = !openWidget.toggled;
            this.props.toggleWidget(openWidget);

            widget.toggled = !widget.toggled;
            this.props.toggleWidget(widget);
        }
    };

    render() {
        const { classes, location, tools, widgets, isDrawerOpen } = this.props;
        const locationSplit = location.pathname.split("/");
        const currentLocaiton = locationSplit[locationSplit.length - 1];

        const toolsSidebar = tools
            .filter((x) => x.enabled)
            .map((tool) => {
                if (!acceptedTools[tool.name]) return null;

                const toolIcon = this.state.icons[tool.name];
                const toolRoute = toolsDict[tool.name].routeSuffix;

                return (
                    <NavButton
                        key={tool.namePretty}
                        name={tool.namePretty}
                        onClick={() => this.navigate(toolRoute)}
                        active={currentLocaiton === tool.name}
                        icon={toolIcon}
                    />
                );
            });

        //Should be rendered if we want to add these widgets
        const widgetsSidebar = widgets
            .filter((x) => x.enabled)
            .map((widget) => {
                const widgetIcon = this.state.icons[widget.name];
                return (
                    <NavButton
                        key={widget.namePretty}
                        name={widget.namePretty}
                        onClick={() => this.toggle(widget)}
                        icon={widgetIcon}
                        active={widget.toggled}
                    />
                );
            });

        return (
            <div className={`toolbar ${isDrawerOpen && `open`}`}>
                <div className={"endAlign " + classes.padding12}>
                    <Button
                        className={`${isDrawerOpen && classes.navOpen} ${classes.closeButton}  ${
                            this.props.sidebarState ? " " + classes.closedSidebar : ""
                        }`}
                        onClick={this.props.toggleDrawerOpen}
                        aria-label="Close"
                    >
                        {!isDrawerOpen ? (
                            <OpenNav />
                        ) : (
                            <div className={classes.headerItem}>
                                <CloseNav className={classes.toolIcon} style={{ transform: "rotate(180deg)" }} />
                                <span className={classes.headerLabel}>Navigation</span>
                            </div>
                        )}
                    </Button>
                </div>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <NavButton
                            name="Layer selector"
                            onClick={() => this.navigate(LAYER_SELECTOR_PATH_SUFFIX)}
                            icon={<LayerIcon fontSize="medium" />}
                            active={currentLocaiton === LAYER_SELECTOR_PATH_SUFFIX}
                        />
                        {toolsSidebar}
                        {widgetsSidebar}
                    </div>
                </div>
                <UserMenu menu={this.props.sidebarState} classes={classes} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sidebarState: state.sidebarAndDrawer.isSidebarOpen,
        isDrawerOpen: state.sidebarAndDrawer.isDrawerOpen,
        tools: state.tools.tools,
        widgets: state.tools.widgets
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSidebarOpen: () => dispatch(sidebarAndDrawerActions.toggleSidebarOpen()),
        toggleDrawerOpen: () => dispatch(sidebarAndDrawerActions.toggleDrawerOpen()),
        toggleWidget: (widget) => dispatch(toolsActions.toggleWidget(widget))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(toolsMenuJss)(withRouter(ToolsMenu)));

import React from "react";

export default function PropertyButton(props) {
    function renderExpression() {
        switch (props.expressionType) {
            case "none":
                return renderValue();
            case "match":
                return <div>Categorised</div>;
            case "interpolate":
                return <div>Graduated</div>;
            default:
                return renderValue();
        }
    }

    function renderValue() {
        switch (props.type) {
            case "color":
                return <div className="color-value" style={{ backgroundColor: props.value }}></div>;
            case "number":
                return <div>{props.value}</div>;
            case "numberArray":
                return <div>[{props.value}]</div>;
            case "float":
                return <div>{props.value}</div>;
            case "text":
                return <div>{props.value}</div>;
            case "select":
                return <div>{props.value}</div>;
            case "boolean":
                return <div>{props.value}</div>;
            default:
                return <p>No value</p>;
        }
    }

    return (
        <div className={props.selected ? "property-button selected" : "property-button"} onClick={props.onClick}>
            <div className="property-title">{props.title}</div>
            <div className="property-value">{renderExpression()}</div>
        </div>
    );
}

import { makeStyles } from "@material-ui/core";
import appStyles from "../../../../utils/styles";

const useLayerStyles = makeStyles((theme) => ({
    contextMenuBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        backgroundColor: "#e3e4e5",
        color: "black",
        marginLeft: 20
    },
    grow: {
        flexGrow: 1
    },
    grayIcon: {
        color: "rgba(0, 0, 0, 0.54)",
        paddingLeft: 5
    },
    switch: {
        marginLeft: -12,
        marginRight: 8
    },
    layerText: {
        fontSize: appStyles.fontSize.LIST_TEXT,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textTransform: "capitalize",
        flexGrow: 1,
        cursor: "pointer"
    }
}));

export default useLayerStyles;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as datasetActions from "../../../actions/atlasClient";
import styleConfig from "../layerSelector/style/styleConfig";
import * as layerSelectorActions from "../../../reducers/layerSelector/layerSelector";
import * as mapActions from "../../../actions/map";
import * as GeometryUtils from "../../../utils/GeometryUtils";
import { v4 as generateUuid } from "uuid";
import { LayerStyleEditor } from "@emblautec/layer-styler-component";
import { getSourceMinZoom } from "../../../selectors/mapSelectors";
import { getLayerForStyling, getLayerStyles, getLayerVisibility } from "../../../selectors/layerSelector";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { StyledTab, StyledTabs } from "../../customTabs/customTabs";
import { useLayerSelectorStylerStyle } from "./styles";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const CHARACTER_VARYING = "character varying";
const DOUBLE_PRECISION = "double precision";

const LayerSelectorStyler = ({ isDigitizeLayer }) => {
    const classes = useLayerSelectorStylerStyle();

    const [datasetColumns, setDatasetColumns] = useState([]);
    const [selectedDatasetColumn, setSelectedDatasetColumn] = useState(null);
    const [distinctColumnValues, setDistinctColumnValues] = useState([]);
    const [minMaxColumnValues, setMinMaxColumnValues] = useState([]); //[min,max]

    const layer = useSelector(getLayerForStyling);
    const styles = useSelector(getLayerStyles(layer.resourceId));
    const sourceMinZoomLimit = useSelector(getSourceMinZoom(layer.sourceId));
    const visible = useSelector(getLayerVisibility(layer.resourceId));

    const dispatch = useDispatch();

    useEffect(() => {
        //Based on the selected layer we bring the dataset columns and by default select the first column
        if (Object.keys(layer).length === 0) {
            setDatasetColumns([]);
            setSelectedDatasetColumn(null);
            setDistinctColumnValues([]);
            setMinMaxColumnValues([]);
        }

        if (layer && layer.resourceId && layer.type !== "raster") {
            dispatch(datasetActions.getDatasetColumns(layer.resourceId)).then((res) => {
                setDatasetColumns(res.result);
                setSelectedDatasetColumn(res.result[0]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layer.resourceId]);

    useEffect(() => {
        //Based on the selected column we bring the distinct values and min,max pair
        if (selectedDatasetColumn) {
            const columnName = selectedDatasetColumn.name;
            const selectedDatasetId = layer.resourceId;
            if (selectedDatasetColumn.type !== DOUBLE_PRECISION) {
                dispatch(datasetActions.getDistinctColumnValues(selectedDatasetId, columnName)).then((res) => {
                    setDistinctColumnValues(res.result);
                });
            }
            if (selectedDatasetColumn.type !== CHARACTER_VARYING) {
                dispatch(datasetActions.getMinMaxColumnValues(selectedDatasetId, columnName)).then((res) => {
                    setMinMaxColumnValues([res.result.minValue, res.result.maxValue]);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDatasetColumn]);

    const clearEditView = () => {
        dispatch(layerSelectorActions.setStylerLayerId(null));
    };

    const initializeNewStyle = (layerId, properties) => {
        let paintProperties = properties.filter((x) => x.type === "paint");

        let paint = {
            layerId,
            properties: paintProperties
        };

        let layoutProperties = properties.filter((x) => x.type === "layout");

        let layout = {
            layerId,
            properties: [
                ...layoutProperties,
                {
                    name: "visibility",
                    value: visible ? "visible" : "none"
                }
            ]
        };
        dispatch(mapActions.addPaint(paint));
        dispatch(mapActions.addLayout(layout));
    };

    const onStyleTypeChanged = (type, style) => {
        let properties = JSON.parse(JSON.stringify(styleConfig[type]));
        let styleIndex = styles.findIndex((x) => x.styleId === style.styleId);
        dispatch(
            mapActions.updateLayer({
                sourceId: layer.sourceId,
                layerId: style.styleId,
                resourceId: layer.resourceId,
                sourceName: layer.sourceName,
                type,
                minZoom: style.minZoom,
                maxZoom: style.maxZoom,
                drawBefore: styleIndex === 0 ? null : styles[styleIndex - 1].styleId
            })
        );
        initializeNewStyle(style.styleId, properties);

        dispatch(
            layerSelectorActions.changeTypeOfLayerStyle({
                layerId: layer.resourceId,
                styleId: style.styleId,
                type,
                properties
            })
        );
    };

    const onPropertiesChanged = (layer, style, newProperties) => {
        //A more efficient implementeation will take into account this layer prop when searching for the style in the reducer.
        let paint = {
            layerId: style.styleId,
            properties: newProperties.filter((x) => x.type === "paint")
        };

        let layout = {
            layerId: style.styleId,
            properties: newProperties.filter((x) => x.type === "layout")
        };

        dispatch(mapActions.updatePaint(paint));
        dispatch(mapActions.updateLayout(layout));

        dispatch(
            layerSelectorActions.changePropertiesOfLayerStyle({
                layerId: layer.resourceId,
                styleId: style.styleId,
                properties: newProperties
            })
        );
    };

    const onAddStyleToLayer = (layer) => {
        if (layer.type === "raster") addStyleToRasterLayer(layer);
        else addStyleToVectorLayer(layer);
    };

    const addStyleToVectorLayer = (layer) => {
        let layerType = GeometryUtils.geometryTypeToMapboxType(layer.geometryType);
        let properties = styleConfig[layerType];
        let layerId = generateUuid();

        dispatch(
            mapActions.addLayer({
                sourceId: layer.sourceId,
                layerId: layerId,
                resourceId: layer.resourceId,
                sourceName: layer.sourceName,
                type: layerType,
                minZoom: 0,
                maxZoom: 24
            })
        );

        initializeNewStyle(layerId, properties);
        const style = {
            styleId: layerId,
            properties,
            type: layerType,
            minZoom: 0,
            maxZoom: 24
        };
        dispatch(layerSelectorActions.addStyleToLayer({ layerId: layer.resourceId, style }));
    };
    const addStyleToRasterLayer = (layer) => {
        let layerId = generateUuid();

        let properties = [
            {
                name: "fill-color",
                title: "Legend",
                type: "paint",
                propertyType: "color",
                expressionType: "match",
                index: 0,
                value: ["match", ["get", null], "replace this", "darkcyan", "replace this also", "pink", "black"]
            }
        ];

        dispatch(
            mapActions.addLayer({
                sourceId: layer.sourceId,
                layerId: layerId,
                resourceId: layer.resourceId,
                sourceName: layer.sourceName,
                type: "raster",
                minZoom: 0,
                maxZoom: 24
            })
        );
        initializeNewStyle(layerId, properties);
        const style = {
            styleId: layerId,
            properties,
            type: "raster",
            minZoom: 0,
            maxZoom: 24
        };
        dispatch(layerSelectorActions.addStyleToLayer({ layerId: layer.resourceId, style }));
    };

    const onZoomSliderChange = (layerId, style, zoom) => {
        dispatch(mapActions.updateZoomRange({ layerId: style.styleId, minZoom: zoom[0], maxZoom: zoom[1] }));
        dispatch(
            layerSelectorActions.updateStyleZoomRange({
                layerId,
                styleId: style.styleId,
                minZoom: zoom[0],
                maxZoom: zoom[1]
            })
        );
    };

    const onRemoveStyleFromLayer = (layer, styleLayer) => {
        dispatch(mapActions.removeLayer(styleLayer.styleId));
        dispatch(layerSelectorActions.removeStyleFromLayer({ layerId: layer.resourceId, styleId: styleLayer.styleId }));
    };

    const onStyleDrop = (droppedLayerIndex, hitLayerIndex) => {
        let styleLayers = [...styles];
        let droppedLayer = null;
        let hitLayer = styleLayers[hitLayerIndex];

        droppedLayer = styleLayers.splice(droppedLayerIndex, 1)[0];
        styleLayers.splice(hitLayerIndex, 0, droppedLayer);

        if (droppedLayerIndex < hitLayerIndex) {
            dispatch(mapActions.moveLayer(hitLayer.styleId, droppedLayer.styleId));
            dispatch(
                layerSelectorActions.changeStyleOrder({
                    layerId: layer.resourceId,
                    styleIndexOne: hitLayerIndex,
                    styleIndexTwo: droppedLayerIndex
                })
            );
        } else {
            dispatch(mapActions.moveLayer(droppedLayer.styleId, hitLayer.styleId));
            dispatch(
                layerSelectorActions.changeStyleOrder({
                    layerId: layer.resourceId,
                    styleIndexOne: droppedLayerIndex,
                    styleIndexTwo: hitLayerIndex
                })
            );
        }
    };

    return (
        <div className="edit-view-container">
            <Grid container wrap="nowrap" alignItems="center">
                <IconButton onClick={clearEditView}>
                    <ArrowRightAltIcon className={classes.backIcon} />
                </IconButton>
                <Typography width="wrap" variant="h6" className={classes.headerText}>
                    {layer.name}
                </Typography>
            </Grid>

            <StyledTabs value={"style"} TabIndicatorProps={<div />}>
                <StyledTab label={<div>Style</div>} value="style" />
            </StyledTabs>
            <Box marginBottom={"7px"} />

            <div className={classes.stylerDivWrapper}>
                <LayerStyleEditor
                    layer={{ ...layer, styles: styles.map((style, index) => ({ ...style, index })) }}
                    isDigitizeLayer={isDigitizeLayer}
                    styleConfig={styleConfig}
                    //column stuff
                    minZoomLimit={sourceMinZoomLimit ?? 0}
                    columns={datasetColumns}
                    selectedDatasetColumn={selectedDatasetColumn}
                    setSelectedDatasetColumn={setSelectedDatasetColumn}
                    distinctColumnValues={distinctColumnValues}
                    minMaxColumnValues={minMaxColumnValues}
                    //onHandlers
                    onPropertiesChanged={onPropertiesChanged}
                    onPropertyExpressionTypeChanged={onStyleTypeChanged}
                    onTypeChanged={onStyleTypeChanged}
                    onZoomSliderChange={onZoomSliderChange}
                    onAddStyleToLayer={onAddStyleToLayer}
                    onRemoveStyleFromLayer={onRemoveStyleFromLayer}
                    onStyleDrop={onStyleDrop}
                />
            </div>
        </div>
    );
};

export default LayerSelectorStyler;

import * as THREE from "three/build/three.module.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { MercatorCoordinate } from "mapbox-gl";
import MapboxLOD from "./MapboxLOD";

function getSpriteMatrix(sprite, center) {
    const { model, position, altitude } = sprite;
    const { scale, rotate } = model;
    const rotationX = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1, 0, 0), rotate[0]);
    const rotationY = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), rotate[1]);
    const rotationZ = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), rotate[2]);

    const coord = MercatorCoordinate.fromLngLat(position, altitude);
    return new THREE.Matrix4()
        .makeTranslation(coord.x - center.x, coord.y - center.y, coord.z - center.z)
        .scale(new THREE.Vector3(scale, -scale, scale))
        .multiply(rotationX)
        .multiply(rotationY)
        .multiply(rotationZ);
}

/**
 * Load a 3D model and render it at specific Lat/Lngs.
 * This renders a THREE.js scene in the same WebGL canvas as Mapbox GL.
 */
export default class SpriteCustomLayer {
    constructor(id, coordinates, options) {
        this.id = id;
        this.options = options;
        this.id = "3d-model";
        this.type = "custom";
        this.renderingMode = "3d";
        this.coordinates = coordinates;
        this.models = [];
        this.mixers = [];
        this.modelConfig = {
            scale: options.scale || 1,
            rotate: [
                options.rotateDeg ? options.rotateDeg.x || 0 : 0,
                options.rotateDeg ? options.rotateDeg.y || 0 : 0,
                options.rotateDeg ? options.rotateDeg.z || 0 : 0
            ].map((deg) => (Math.PI / 180) * deg)
        };
        // this.loadLODModels([
        //     { url: "/media/model_0.gltf", zoom: 16 },
        //     { url: "/media/model_05.gltf", zoom: 10 }
        // ]).then((model) => {
        //     this.model = model
        // });
    }

    async onAdd(map, gl) {
        this.camera = new THREE.Camera();

        this.center = MercatorCoordinate.fromLngLat(map.getCenter(), 0);
        const { x, y, z } = this.center;
        this.cameraTransform = new THREE.Matrix4().makeTranslation(x, y, z);

        this.map = map;

        this.scene = new THREE.Scene();
        this.clock = new THREE.Clock();

        // use the Mapbox GL JS map canvas for three.js
        this.renderer = new THREE.WebGLRenderer({
            canvas: map.getCanvas(),
            context: gl,
            antialias: true
        });

        const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        new RGBELoader().setDataType(THREE.UnsignedByteType).load("/media/env2.hdr", (texture) => {
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;

            this.scene.background = envMap;

            this.scene.environment = envMap;

            texture.dispose();
            pmremGenerator.dispose();
        });

        // From https://threejs.org/docs/#examples/en/loaders/GLTFLoader

        //Env map config
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1;
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.autoClear = false;

        this.model = await this.loadLODModels([
            { url: "/media/model_0.gltf", zoom: 16 },
            { url: "/media/model_05.gltf", zoom: 10 }
        ]);

        pmremGenerator.compileEquirectangularShader();
        this.setData(this.coordinates);
    }

    makeScene() {
        const scene = new THREE.Scene();

        const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        new RGBELoader().setDataType(THREE.UnsignedByteType).load("/media/env2.hdr", (texture) => {
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;

            //this.scene.background = envMap;
            scene.environment = envMap;

            texture.dispose();
            pmremGenerator.dispose();
        });

        pmremGenerator.compileEquirectangularShader();

        return scene;
    }

    async loadLODModels(modelDefinitions) {
        // use the three.js GLTF loader to add the 3D model to the three.js scene
        var loader = new GLTFLoader();
        var dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("/draco/");
        loader.setDRACOLoader(dracoLoader);

        let promises = modelDefinitions.map((model) => {
            return new Promise((resolve) => {
                loader.load(model.url, resolve);
            });
        });

        const lod = new MapboxLOD();

        return Promise.all(promises).then((models) => {
            for (let i = 0; i < modelDefinitions.length; i++) {
                let modelDef = modelDefinitions[i];
                let model = models[i];
                // this.mixer = new THREE.AnimationMixer(model);
                // var action = this.mixer.clipAction(model.animations[0]);
                // action.play();
                lod.addLevel(model.scene, model.animations, modelDef.zoom);
            }

            //this.scene.add(lod);

            return lod;
        });
    }

    async setData(coordinates) {
        const model = await this.model;

        const models = coordinates.map((cord) => {
            const scene = model.clone();
            scene.applyMatrix(
                getSpriteMatrix(
                    {
                        model: this.modelConfig,
                        position: {
                            lng: cord[0],
                            lat: cord[1]
                        },
                        altitude: 0
                    },
                    this.center
                )
            );
            scene.rotateY(Math.PI / 2);
            return scene;
        });

        this.models = models;

        for (const lodModel of models) {
            this.scene.add(lodModel);
            let mixer = new THREE.AnimationMixer(lodModel.levels[1].object);
            this.mixers.push(mixer);
            var action = mixer.clipAction(lodModel.levels[1].animations[0], lodModel.levels[1].object);
            action.play();

            let mixer2 = new THREE.AnimationMixer(lodModel.levels[0].object);
            this.mixers.push(mixer2);
            var action2 = mixer2.clipAction(lodModel.levels[0].animations[0], lodModel.levels[0].object);
            action2.play();
        }
    }

    render(gl, matrix) {
        //requestAnimationFrame( render );
        var delta = this.clock.getDelta();

        if (this.mixers.length > 0) {
            for (let i = 0; i < this.mixers.length; i++) {
                this.mixers[i].update(delta);
            }
        }
        // eslint-disable-next-line no-unused-vars
        var modelAltitude = -6;
        var modelRotate = [Math.PI / 2, 90, 0];

        // transformation parameters to position, rotate and scale the 3D model onto the map
        var modelTransform = {
            rotateX: modelRotate[0],
            rotateY: modelRotate[1],
            rotateZ: modelRotate[2]
            /* Since our 3D model is in real world meters, a scale transform needs to be
             * applied since the CustomLayerInterface expects units in MercatorCoordinates.
             */
        };

        var rotationX = new THREE.Matrix4().makeRotationX(Math.PI / 2);
        // eslint-disable-next-line no-unused-vars
        var rotationY = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), modelTransform.rotateY);
        // eslint-disable-next-line no-unused-vars
        var rotationZ = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), modelTransform.rotateZ);
        // eslint-disable-next-line no-unused-vars
        var l = new THREE.Matrix4().multiply(rotationX);
        var m = new THREE.Matrix4().fromArray(matrix).multiply(this.cameraTransform);

        this.camera.projectionMatrix = m;

        //this.camera.projectionMatrix = new THREE.Matrix4().fromArray(matrix).multiply(this.cameraTransform);
        this.renderer.state.reset();
        this.renderer.render(this.scene, this.camera);

        if (this.models.length > 0) {
            for (let i = 0; i < this.models.length; i++) {
                this.models[i].update(this.map);
            }
        }

        this.map.triggerRepaint();
    }
}

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Typography from "@material-ui/core/Typography";
import Layer from "./layer";

import { Collapse, Divider, Tooltip } from "@material-ui/core";

import { isGroup } from "@emblautec/rescursive-array-extensions";
import { useDispatch, useSelector } from "react-redux";

import * as layerSelectorActions from "../../../reducers/layerSelector/layerSelector";
import useGroupStyles from "./styles/groupStyles";

import { toggleGroupLayers } from "../../../actions/globalActions";
import { getLayerVisibilityMap } from "../../../selectors/layerSelector";
const Group = ({ group, depth, layerHandlers, index, onCollapsedToggled }) => {
    const classes = useGroupStyles();

    const layerVisibilityMap = useSelector(getLayerVisibilityMap);

    const dispatch = useDispatch();
    let groupTotalLayers = 0;
    let groupVisibleLayers = 0;

    group.layers.forLayersRecursive((layer) => {
        groupTotalLayers++;
        if (layerVisibilityMap[layer.resourceId]) groupVisibleLayers++;
    });

    const toggleLayers = (e) => {
        if (e) e.stopPropagation();
        const nextDisplayValue = groupVisibleLayers !== groupTotalLayers ? "visible" : "none";

        const groupLayersMap = {};

        //process legend
        group.layers.forLayersRecursive((layer) => {
            groupLayersMap[layer.resourceId] = true;
        });

        dispatch(
            toggleGroupLayers({
                groupId: group.resourceId,
                newVisibility: nextDisplayValue === "visible",
                groupLayersMap
            })
        );
    };

    const handleCollapse = (e, group) => {
        e.stopPropagation();

        dispatch(layerSelectorActions.toggleGroupCollapse({ groupId: group.resourceId }));

        onCollapsedToggled?.(index);
    };

    function renderGroup(depth) {
        if (depth === 0) {
            return (
                <div className={"group"} onClick={(e) => handleCollapse(e, group)}>
                    <div className={classes.grow}>
                        <Typography variant="body1" color="inherit" className={classes.groupName}>
                            {group.name}
                        </Typography>
                    </div>
                    <IconButton onClick={toggleLayers} className={classes.squareBtn}>
                        <Typography className={classes.layerCount}>
                            {groupVisibleLayers}/{groupTotalLayers}
                        </Typography>
                    </IconButton>
                    <div className={classes.layerCountSeperator}></div>

                    <Tooltip title={group.options.collapsed ? "Expand" : "Collapse"}>
                        <IconButton onClick={(e) => handleCollapse(e, group)} className={classes.collapseBtn}>
                            <ExpandLess
                                className={group.options.collapsed ? classes.expandedRight : classes.collapsed}
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            );
        } else {
            return (
                <div className={"sub-group"} onClick={(e) => handleCollapse(e, group)}>
                    <Tooltip title={group.options.collapsed ? "Expand" : "Collapse"}>
                        <IconButton onClick={(e) => handleCollapse(e, group)} className={classes.innerCollapseBtn}>
                            <ExpandLess
                                className={group.options.collapsed ? classes.expandedLeft : classes.collapsed}
                            />
                        </IconButton>
                    </Tooltip>

                    <div className={classes.grow}>
                        <Typography
                            variant="body1"
                            color="inherit"
                            className={classes.subGoupName}
                            style={{ fontSize: 16 - depth }}
                        >
                            {group.name}
                        </Typography>
                    </div>
                    <IconButton onClick={toggleLayers} className={classes.squareBtn}>
                        <Typography className={classes.layerCount}>
                            {groupVisibleLayers}/{groupTotalLayers}
                        </Typography>
                    </IconButton>
                </div>
            );
        }
    }

    let groupElement = renderGroup(depth);

    return (
        <div className={depth === 0 ? "group-root" : "sub-group-root"}>
            {groupElement}

            <Collapse in={!group.options.collapsed && group.layers.length !== 0} timeout={200} unmountOnExit>               
                {depth === 0 && <Divider className={classes.groupSeperator} />}
                <div className={depth === 0 ? "" : classes.borderBox}>
                    <div className={depth === 0 ? classes.layerContainer : classes.innerLayerContainer}>
                        {group.layers.map((layer) => {
                            return isGroup(layer) ? (
                                <Group
                                    group={layer}
                                    layerHandlers={layerHandlers}
                                    onCollapsedToggled={onCollapsedToggled}
                                    depth={depth + 1}
                                    key={layer.resourceId}
                                />
                            ) : (
                                <Layer layer={layer} key={layer.resourceId} layerHandlers={layerHandlers} />
                            );
                        })}
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default Group;

export const zanderIsConnectionStarted = (state) => state.zander.isConnectionStarted;

export const getZanderConnectionId = (state) => state.zander.connectionId;

export const getZanderIsLoading = (state) => state.zander.loading;

export const getZanderExcelLink = (state) => state.zander.excelLink;

export const getZanderError = (state) => state.zander.error;

export const getZanderErrorMessage = (state) => state.zander.errorMessage;

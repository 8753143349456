import { zanderParameterTypes } from "../../../constants/zander/constants";

export const getParameterByValue = (value) => {
    return zanderParameterTypes.find((x) => x.value === value);
};

const normalizeSheetPercentiles = (sheets) =>
    sheets.map((sheet) => {
        return {
            ...sheet,
            percentiles: sheet.percentiles.map((percentile) => percentile / 100)
        };
    });

const sanitizeSheetParameters = (sheets) =>
    sheets.map((sheet) => {
        const { error, ...actualSheet } = sheet;

        return {
            ...actualSheet,
            parameters: actualSheet.parameters.filter((params) => params.maxLimits.length > 0)
        };
    });

export const sanitizeSheets = (sheets) => normalizeSheetPercentiles(sanitizeSheetParameters(sheets));

export const zanderParameterType = {
    WindSpeed10: {
        displayName: "Mean wind speed at 10m height [m/s]",
        value: "WindSpeed10"
    },
    WindSpeed100: {
        displayName: "Mean wind speed at 100m height [m/s]",
        value: "WindSpeed100"
    },
    WaveHeight: {
        displayName: "Significant wave height (Hs) [m]",
        value: "WaveHeight"
    },
    WavePeriod: { displayName: "Peak wave period (Tp) [s]", value: "WavePeriod" }
};

export const zanderParameterTypes = [
    zanderParameterType.WindSpeed10,
    zanderParameterType.WindSpeed100,
    zanderParameterType.WaveHeight,
    zanderParameterType.WavePeriod
];

const getInitialMaxLimits = (type) => {
    switch (type) {
        case zanderParameterType.WindSpeed10.value:
            return [];
        case zanderParameterType.WindSpeed100.value:
            return [14];
        case zanderParameterType.WaveHeight.value:
            return [1.75,2];
        case zanderParameterType.WavePeriod.value:
            return [];
        default:
            return [3, 5];
    }
};

const initialPercentiles = [50, 80];

const initialParameters = zanderParameterTypes.map((parameter) => ({
    type: parameter.value,
    maxLimits: getInitialMaxLimits(parameter.value)
}));

const emptyParameters = zanderParameterTypes.map((parameter) => ({ type: parameter.value, maxLimits: [] }));

export const initialSheet = {
    weatherWindowDurations: [3, 6, 12],
    parameters: initialParameters,
    startHour: 0,
    endHour: 24,
    percentiles: initialPercentiles,
    error: false
};

export const emptySheet = {
    weatherWindowDurations: [],
    parameters: emptyParameters,
    startHour: 0,
    endHour: 24,
    percentiles: [],
    error: false
};

export const marks = [...Array(25).keys()].map((i) => {
    return {
        value: i,
        label:
            i % 8 === 0 ? (
                <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ width: 0, height: 10, border: "1px solid" }} />
                    {i}
                </div>
            ) : (
                <div style={{ width: 0, height: 10, border: "1px solid", opacity: "0.3" }}></div>
            )
    };
});

export const MAX_SHEETS = 3;
export const MAX_SOCKET_RETRIES = 5;
export const MAX_LIMITS = 5;
export const MAX_INTERVAL_LENGTH = 24;
export const MIN_HOUR = 0;
export const MAX_HOUR = 24;

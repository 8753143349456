import MapboxLanguage from "@emblautec/mapbox-gl-language";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { validate } from "@mapbox/mapbox-gl-style-spec";
import { capitalize, Collapse, ThemeProvider } from "@material-ui/core";
import GlMap from "mapbox-gl";
import { CircleMode, DirectMode, DragCircleMode, SimpleSelectMode } from "mapbox-gl-draw-circle";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import ResizeObserver from "resize-observer-polyfill";
import { mapZoomEnd } from "../../actions/globalActions";
import * as mapActions from "../../actions/map";
import * as mapboxApiActions from "../../actions/mapbox";
import Config from "../../config";
import { mapboxAttribution } from "../../constants/map/attributions";
import blankBasemapStyle from "../../constants/map/blankBasemapStyle";
import highlightTypes from "../../constants/map/highlightTypes";
import { DIGITIZE_PATH_SUFFIX } from "../../constants/routes";
import WTG_POSITIONS from "../../constants/wtgPositions";
import theme from "../../MuiConfig";
import * as layerSelectorActions from "../../reducers/layerSelector/layerSelector";
import { requestZanderConnectionStart } from "../../reducers/zander";
import { getSelectedApp } from "../../selectors/appsSelectors";
import { getLayerStylesMap, getLayerVisibilityMap } from "../../selectors/layerSelector";
import toastr from "../../utils/customToastr";
import isMobile from "../../utils/isMobile";
import { mapboxDrawStyles } from "../../utils/mapboxDrawStyles";
import PolygonLabelGenerator from "../../utils/polygonLabelGenerator";
import Measure from "../sidebar/measure/measure";
import Print from "../sidebar/print/print";
import Search from "../sidebar/search/search";
import EraInfoBox from "./infoBoxes/eraInfoBox";
import InfoBox from "./infoBoxes/infoBox";
import MapEditing from "./infoBoxes/mapEditing";
import MobileInfoBox from "./infoBoxes/mobileInfoBox";
import BasemapSelector from "./mapTools/basemapSelector";
import Disclaimer from "./mapTools/disclaimer";
import ExaggerationSlider from "./mapTools/exaggerationSlider";
import LanguageSelector from "./mapTools/languageSelector";
import Legend from "./mapTools/legend";
import MapCopyState from "./mapTools/mapCopyState";
import MapTools from "./mapTools/MapTools";
import { getSearchWidgetToggledStatus } from "../../selectors/toolsSelectors";
import SearchLayer from "./mapTools/searchLayer";
import MapCopyright from "./printMapExtraInfo/mapCopyright";
import MapDate from "./printMapExtraInfo/mapDate";
import MapHelper from "./printMapExtraInfo/mapHelper";
import MapLogo from "./printMapExtraInfo/mapLogo";
import MapNorthArrow from "./printMapExtraInfo/mapNorthArrow";
import MapNotes from "./printMapExtraInfo/mapNotes";
import MapTitle from "./printMapExtraInfo/mapTitle";
import PrintSizeVisualizer from "./printMapExtraInfo/printSizeVisualizer";
import PitchToggle from "./utils/pitchToggle";
import ThreeCustomLayer from "./utils/ThreeCustomLayer";
import ZanderModal from "./zanderTool/ZanderModal";

// Quick fix for https://github.com/mapbox/mapbox-gl-js/issues/10173 (might be fixed in the future, so this can be removed)
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
GlMap.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

let marker, getMap, getDraw, popupEl, getTrueMap;

var modes = {
    ...MapboxDraw.modes,
    draw_circle: CircleMode,
    drag_circle: DragCircleMode,
    direct_select: DirectMode,
    simple_select: SimpleSelectMode
};
modes.static = StaticMode;

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFeatures: [],
            enabledWidgets: {},
            fullScreenEl: document.body,
            terrainEnabled: false,
            terrainExaggeration: 1.0,
            reinitMeasureWidget: false,
            stylesLoaded: false,
            lat: 0,
            long: 0,
            zanderToolOpen: false
        };
    }

    componentDidMount() {
        this.initMap();
        getMap = this.getMap;
        if (!this.props.modal) {
            getDraw = this.getDraw;
            getTrueMap = this.getMap;
        }

        document.onfullscreenchange = (event) => {
            if (document.fullscreenElement) {
                this.setState({ fullScreenEl: document.fullscreenElement });
            } else {
                this.setState({ fullScreenEl: document.body });
            }
        };
    }

    getMap = () => {
        return this.map;
    };

    getDraw = () => {
        return this.draw;
    };

    initMap() {
        GlMap.accessToken = Config.mapboxApiKey;

        let glMapOptions = {
            container: this.props.modal ? "modal-map" : "map",
            dragPan: true,
            style: blankBasemapStyle,
            preserveDrawingBuffer: true,
            attributionControl: false,
            hash: false,
            minZoom: 1,
            maxZoom: 22,
            antialias: true,
            transformRequest: (url, resourceType) => {
                if (resourceType === "Tile" && url.startsWith(Config.apiUrl)) {
                    return {
                        url: url + "?key=" + this.props.auth.token
                        // headers: { 'Authorization': 'Bearer ' + this.props.auth.token }
                    };
                }
            }
        };

        if (this.props.lat) {
            glMapOptions.center = [this.props.lon, this.props.lat];
            glMapOptions.zoom = 10;

           
        }

        this.map = new GlMap.Map(glMapOptions);
        this.mapboxLanguage = new MapboxLanguage({ defaultLanguage: this.props.mapState.language });
        this.map.addControl(this.mapboxLanguage);
        this.map.addControl(new GlMap.AttributionControl({
            customAttribution: '<a class="map-attribution" href="https://lautec.com/" target="_blank">© LAUTEC</a>'
        }));

        if (this.props.lat) {


            const marker1 = new GlMap.Marker().setLngLat([this.props.lon, this.props.lat]).addTo(this.map);
            marker1.getElement().addEventListener('click', () => {
                console.log("test")
                marker1.remove();
            })
        }

        this.map.getCanvas().style.outline = "none";

        this.draw = new MapboxDraw({
            userProperties: true,
            controls: {
                point: false,
                line_string: false,
                polygon: false,
                combine_features: false,
                uncombine_features: false,
                trash: false
            },
            styles: mapboxDrawStyles,
            modes: modes
        });
        this.map.addControl(this.draw, "top-left");

        if (!this.props.modal) {
            const nav = new GlMap.NavigationControl();
            this.map.addControl(nav, "top-right");

            this.map.addControl(new PitchToggle({ minpitchzoom: 11 }, this.setTerrainToggled), "top-right");
        } else {
            this.draw.changeMode("static");
        }

        if (!this.props.modal) {
            let scale = new GlMap.ScaleControl({});
            this.map.addControl(scale, "bottom-right");
        }

        this.polygonLabelGenerator = new PolygonLabelGenerator(this.map);

        this.map.on("load", () => this._mapLoad());

        let map = document.getElementById(this.props.modal ? "modal-map" : "map");
        const ro = new ResizeObserver((entries) => {
            setTimeout(() => {
                this._resize();
            }, 25);
        });
        ro.observe(map);

        this.map.on("mousedown", () => {
            this.map.getCanvas().style.cursor = "move";
        });

        this.map.on("mousemove", () => {
            this.map.getCanvas().style.cursor = "auto";
        });

        if (this.props.modal) {
            let mapBoxLogo = document.getElementsByClassName("mapboxgl-ctrl-logo")[1];
            mapBoxLogo.style.visibility = "hidden";
        }
        this.map.boxZoom.enable();
    }

    componentWillUnmount() {
        getMap = getTrueMap;
        this.polygonLabelGenerator.removeLayers();
        try {
            this.map.remove();
        } catch {
            //Sometimes this causes an internal library call of get() on something that is undefined.
        }
        if (!this.props.modal) {
            this.props.mapLoaded(false);
        }
    }

    _mapLoad = () => {
        let dragging = false;
        this.map.on("click", (e) => {
            !dragging && this.props.mapState.onClick ? this.props.mapState.onClick(e) : this._onMapClick(e);
        });
        this.map.on("touchmove", (e) => {
            dragging = true;
        });
        this.map.on("touchend", (e) => {
            if (!dragging) {
                this.props.mapState.onClick ? this.props.mapState.onClick(e) : this._onMapClick(e);
            } else {
                dragging = false;
            }
        });
        this.map.on("moveend", (e) => this._onMapMoveEnd(e));
        this.map.on("zoomend", () => this._mapZoom());

        // let layer3D = this.loadThreeModel();

        // this.map.addLayer(layer3D);
        let features = [];
        const digitizeState = this.props.digitizeState;
        digitizeState.layers.forEach((layer) => {
            digitizeState.features.forEach((feature) => {
                if (feature.properties.layerId === layer.id) {
                    const newFeature = { ...feature, properties: { ...feature.properties, hidden: !layer.selected } };

                    layer.properties.forEach((property) => {
                        newFeature.properties[property.name] = property.value;
                    });
                    newFeature.properties.customStyle = true;
                    features.push(newFeature);
                }
            });
        });

        this.props.modal && this.addFeatures(features);

        //Should be noted that this also adds sources and layers. The sources and the layers of the app are added before the basemap so we have 3 scenarios:
        //-app loads faster than the map, so this call to changeBaseLayers initializes the app properly with a basemap
        //-map loads faster in which case nothing will happen in this call, and the changes will be applied in the componentDidUpdate
        //-map is loaded faster by just a bit and it starts setting the map to none, but before it finishes the true basemap was already added into redux
        //We fix the last scenario by adding a styleLoaded flag, and allowing the changeBasemap to run twice. This way we overwrite the none basemap in this rare case
        this.changeBaseLayer(this.props.basemap);

        if (this.props.modal) {
            this.map.setZoom(getTrueMap().getZoom());
            this.map.setCenter(getTrueMap().getCenter());
        }
        this.props.mapLoaded(true);
        //We enable componentDidUpdate actions only after the map is ready for use, meaning after the style loaded
    };

    addRasterBasemapSources(basemaps) {
        let RasterBasemaps = basemaps.filter((x) => x.type === "raster");
        for (let i = 0; i < RasterBasemaps.length; i++) {
            let raster = RasterBasemaps[i];
            if (!this.map.getSource(raster.title)) {
                this.map.addSource(raster.title, {
                    type: "raster",
                    tiles: [raster.url],
                    tileSize: 256
                });
            }
        }
    }

    loadThreeModel() {
        var modelOrigin = [7.780347, 56.968961];
        var modelAltitude = -6;
        // eslint-disable-next-line no-unused-vars
        var modelRotate = [Math.PI / 2, 90, 0];

        var modelAsMercatorCoordinate = GlMap.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);

        return new ThreeCustomLayer("abc", WTG_POSITIONS, {
            scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
            rotateDeg: { x: 0, y: 90, z: 90 }
        });
    }

    _mapZoom = () => {
        let zoom = this.map.getZoom();
        this.props.zoomEnd(zoom);
    };

    _resize = () => {
        this.map.resize();
    };

    _onMapMoveEnd = () => {
        this.polygonLabelGenerator.generateLabels();
    };

    _onLayerClick = (e) => {
        // let feature = e.features[0];
        // let gids = e.features.map(x => x.properties.ogc_fid);
        // let features = this.map.querySourceFeatures(feature.source, {
        //     sourceLayer: feature.sourceLayer
        //     // filter: ['in', 'gid', ...gids]
        // });
        // if (this.map.getSource("highlight-features")) {
        //     this.map.removeLayer("highlight");
        //     this.map.removeSource("highlight-features");
        // }
        // this.map.addSource("highlight-features", {
        //     type: "geojson",
        //     data: {
        //         type: "FeatureCollection",
        //         features: features
        //     }
        // });
        // if (feature.layer.type === "symbol") {
        //     let layerId = feature.layer.id.split("_")[0];
        //     feature.layer.type = this.props.mapState.layers.find(x => x.layerId === layerId).type;
        // }
        // //don't highlight anything for symbol
        // if (feature.layer.type !== "symbol") {
        //     let highlightLayer = this._GetHighlightType(feature.layer.type);
        //     this.map.addLayer(highlightLayer, this.props.mapState.layers[0].layerId);
        // }
    };

    _onToolTipClose = () => {
        if (this.map.getSource("highlight-features")) {
            if (this.map.getLayer("highlight")) {
                this.map.removeLayer("highlight");
            }
            this.map.removeSource("highlight-features");
        }
    };

    _GetHighlightType(geomType) {
        return highlightTypes[geomType] || null;
    }

    _onMapClick = (e) => {
        let layers = [];
        const sourceToNameMapping = {};

        this.props.layerGroups.forLayersRecursive((layer) => {
            if (this.props.layersVisibilityMap[layer.resourceId] === true) {
                const firstStyleLayer = this.props.layersStylesMap[layer.resourceId].find((e) => e.type !== "symbol");

                if (firstStyleLayer && !this.invalidLayers.hasOwnProperty(layer.sourceName)) {
                    sourceToNameMapping[layer.sourceName] = capitalize(layer.name);
                    layers.push(firstStyleLayer.styleId);
                }
            }
        });

        var bbox = [
            [e.point.x - 3, e.point.y - 3],
            [e.point.x + 3, e.point.y + 3]
        ];
        this.props.mapClick(e.lngLat);

        let currentApp = window.location.pathname.split("/");
        currentApp = currentApp[currentApp.length - 1];
        let isDigitize = currentApp === DIGITIZE_PATH_SUFFIX;

        if (!this.props.toolsState.widgets.filter((widget) => widget.toggled).length && !isDigitize) {
            let features = this.map.queryRenderedFeatures(bbox, { layers });
            //no popups for labels
            features = features.filter((e) => {
                return e.layer.type !== "symbol";
            });
            if (features.length === 0) {
                if (this.map.getSource("highlight-features")) {
                    if (this.map.getLayer("highlight")) {
                        this.map.removeLayer("highlight");
                    }
                    this.map.removeSource("highlight-features");
                }
                return;
            }

            //Use mobile infobox instead
            if (isMobile.phone) {
                this.props.selectFeatures(features);
                return;
            }

            let eraData = features[0].properties.hasOwnProperty("land-sea mask");
            const placeholder = document.createElement("div");

            ReactDOM.render(
                <ThemeProvider theme={theme}>
                    {eraData ? (
                        <EraInfoBox onZanderToolOpen={this.onZanderToolOpen} features={features} />
                    ) : (
                        <InfoBox
                            features={features}
                            sourceToNameMapping={sourceToNameMapping}
                            setStylerLayerId={(resourceId) => this.props.setStylerLayerId(resourceId)}
                        />
                    )}
                </ThemeProvider>,
                placeholder
            );

            popupEl = new GlMap.Popup({ closeButton: true, maxWidth: "500px", padding: 0 })
                .setLngLat(e.lngLat)
                .setDOMContent(placeholder)
                .addTo(this.map);
            popupEl.on("close", this._onToolTipClose);
        }
    };

    removeLayers(previousLayers, currentLayers) {
        let LayersMap = currentLayers.reduce((a, b, index) => {
            a[b.layerId] = index;
            return a;
        }, {});
        previousLayers.forEach((layer) => {
            if (LayersMap[layer.layerId] === undefined) {
                this.map.removeLayer(layer.layerId);
            }
        });
    }

    addLayers(layers) {
        // Find the index of the first symbol layer in the map style
        let FirstSymbolId;
        let mapLayers = this.map.getStyle().layers;

        for (let i = 0; i < mapLayers.length; i++) {
            if (mapLayers[i].type === "symbol") {
                FirstSymbolId = mapLayers[i].id;
                break;
            }
        }
        const symbolLayers = layers.filter((x) => x.type === "symbol");
        const normalLayers = layers.filter((x) => x.type !== "symbol");
        const arrangedLayers = [...normalLayers, ...symbolLayers];
        //Draw layers in reverse order
        for (let i = 0; i < arrangedLayers.length; i++) {
            let layer = arrangedLayers[i];
            const vectorLayer = {
                id: layer.layerId,
                type: layer.type,
                source: layer.sourceId,
                "source-layer": layer.sourceName,
                minzoom: layer.minZoom,
                maxzoom: layer.maxZoom,
                metadata: { resourceId: layer.resourceId }
            };

            if (layer.type !== "symbol") {
                this.map.addLayer(vectorLayer, FirstSymbolId);
                FirstSymbolId = layer.layerId;
                if (!this.map.firstLayer) this.map.firstLayer = vectorLayer.id;
                this.map.on("click", layer.layerId, this._onLayerClick);
            } else {
                this.map.addLayer(vectorLayer);
            }
        }
    }

    addFeatures(features) {
        if (features.length > 0) {
            let drawnFeatures = {
                type: "FeatureCollection",
                features: features,
                paint: {
                    "line-color": "red"
                }
            };
            this.draw.add(drawnFeatures);
        }
    }

    removeAllFeatures() {
        this.draw.deleteAll();
    }

    changeLayers(previousLayers, currentLayers) {
        //Handle initial condition
        if (previousLayers.length === 0) {
            previousLayers = currentLayers;
        }

        for (let i = 0; i < currentLayers.length; i++) {
            let layer = currentLayers[i];
            let previousLayer = previousLayers[i];

            if (layer.zoomRangeChanged) {
                this.map.setLayerZoomRange(layer.layerId, layer.minZoom, layer.maxZoom);

                layer.zoomRangeChanged = false;
            }

            if (layer.changed) {
                this.map.removeLayer(layer.layerId);

                var vectorSource = {
                    id: layer.layerId,
                    type: layer.type,
                    source: layer.sourceId,
                    "source-layer": layer.sourceName,
                    minZoom: layer.minZoom,
                    maxZoom: layer.maxZoom,
                    metadata: { resourceId: layer.resourceId }
                };

                if (layer.drawBefore !== null) {
                    this.map.addLayer(vectorSource, layer.drawBefore);
                } else {
                    this.map.addLayer(vectorSource);
                }

                let paint = this.props.mapState.paints[layer.layerId] || { properties: [] };
                let layout = this.props.mapState.layouts[layer.layerId] || { properties: [] };
                this.changePaint([paint]);
                this.changeLayout([layout]);

                layer.changed = false;
            } else if (layer.layerId !== previousLayer.layerId) {
                this.map.moveLayer(layer.layerId, i === 0 ? null : currentLayers[i - 1].layerId);
            }
        }
    }

    changePaint(paintsDict) {
        const paints = Object.values(paintsDict);
        paints.forEach((paint) => {
            paint.properties.forEach((property) => {
                property.title !== "Legend" && this.map.setPaintProperty(paint.layerId, property.name, property.value);
            });
        });
    }

    changeLayout(layoutsDict) {
        const layouts = Object.values(layoutsDict);
        //remove highlight if a layer's layout is changed
        if (this.map.getSource("highlight-features")) {
            if (this.map.getLayer("highlight")) {
                this.map.removeLayer("highlight");
            }
            this.map.removeSource("highlight-features");
        }
        layouts.forEach((layout) => {
            layout.properties.forEach((property) => {
                this.map.setLayoutProperty(layout.layerId, property.name, property.value);
            });
        });
    }

    changeLanguage = (lang) => {
        let style = this.map.getStyle();
        let isV8Style = this.mapboxLanguage.isVersion8Style(style);
        if (!isV8Style) {
            toastr.warning("Changing language is not supported for current basemap");
            return;
        }

        style = this.mapboxLanguage.setLanguage(style, lang);
        this.map.setStyle(style);
        this.mapboxLanguage._defaultLanguage = lang; // this is a hack needed to make the language stay the same when changing the basemap
        this.props.setLanguage(lang);
    };

    changeBaseLayer = (basemap) => {
        switch (basemap.type) {
            case "vector":
                this.setVectorBaseMap(basemap);
                break;
            case "raster":
                this.setRasterBasemap(basemap);
                break;
            case "none":
                this.setEmptyBaseMap();
                break;
            default:
                throw new Error("Invalid basemap type");
        }
    };

    setRasterBasemap(basemap) {
        let newStyle = JSON.parse(JSON.stringify(blankBasemapStyle));

        newStyle.layers = [
            {
                id: "raster-basemap-layer",
                type: basemap.type,
                source: basemap.title,
                paint: {}
            }
        ];

        let basemapStyle = this.addCurrentLayersToStyle(newStyle);

        this.setMapBasemapStyle(basemapStyle);
    }

    setVectorBaseMap(basemap) {
        const basemapUrlSplit = basemap.url.split("://");
        const styleType = basemapUrlSplit[0];
        const path = basemapUrlSplit[1];
        this.getVectorStyleConfig(styleType, path).then((res) => {
            let basemapStyle = this.addCurrentLayersToStyle(res.result);
            this.setMapBasemapStyle(basemapStyle);
        });
    }

    setEmptyBaseMap() {
        const newStyle = JSON.parse(JSON.stringify(blankBasemapStyle));

        const basemapStyle = this.addCurrentLayersToStyle(newStyle);

        this.setMapBasemapStyle(basemapStyle);
    }

    setMapBasemapStyle(basemapStyle) {
        let errors = validate(basemapStyle);

        let indexMap = {};
        this.invalidLayers = {};

        for (let i = 0; i < errors.length; i++) {
            const error = errors[i];

            // Error message looks like "layers[128].paint.line-width: number expected, null found"
            // So we split on : to get the first part
            let layerText = error.message.split(":")[0];

            // This is done for the situation when we have layers[128].paint.line-width[5]
            let layerArray = layerText.split(".")[0];

            const numberBetweenSquareBracketsExpression = /\[(.*?)\]/;
            let layerIndex = layerArray.match(numberBetweenSquareBracketsExpression)[1];

            let layer = basemapStyle.layers[layerIndex];
            this.invalidLayers[layer["source-layer"]] = true;
            console.error(`layer:${layer.id} has invalid styling`);
            console.error(`specific error:${error.message}`);

            indexMap[layerIndex] = true;
        }

        basemapStyle.layers = basemapStyle.layers.filter((x, index) => !indexMap[index]);
        this.map.once("style.load", () => this.setState({ stylesLoaded: true }));
        this.setState({ stylesLoaded: false });
        this.map.setStyle(basemapStyle, { diff: false });
    }

    getVectorStyleConfig(styleType, path) {
        switch (styleType) {
            case "mapbox":
                let mapboxPath = path.split("styles/")[1];
                return this.props.getMapboxStyle(mapboxPath);
        }
    }

    layerToMapLayer(layer) {
        let paint = this.props.mapState.paints[layer.layerId].properties.reduce((a, b) => {
            a[b.name] = b.value;
            return a;
        }, {});

        let layout = this.props.mapState.layouts[layer.layerId].properties.reduce((a, b) => {
            a[b.name] = b.value;
            return a;
        }, {});
        return {
            id: layer.layerId,
            type: layer.type,
            source: layer.sourceId,
            "source-layer": layer.sourceName,
            minzoom: layer.minZoom,
            maxzoom: layer.maxZoom,
            paint: paint,
            layout: layout,
            metadata: {
                resourceId: layer.resourceId
            }
        };
    }

    addCurrentLayersToStyle(newStyle) {
        //we want to use our own settings, so we delete the default on the new style
        delete newStyle.zoom;
        delete newStyle.center;

        //add Map sources to new style
        this.props.mapState.sources.map((source) => {
            let endpointName = source.type === "raster" ? "raster" : "tile";
            newStyle.sources[source.id] = {
                type: source.type === "raster" ? "raster" : "vector",
                tiles: [Config.apiUrl + `${endpointName}/public/${source.id}/{z}/{x}/{y}`],
                minzoom: source.minZoom,
                maxzoom: source.maxZoom
            };
        });

        //Add Raster sources to new style
        this.props.config.basemaps
            .filter((x) => x.type === "raster")
            .map((source) => {
                newStyle.sources[source.title] = {
                    type: source.type === "raster" ? "raster" : "vector",
                    tiles: [source.url],
                    tileSize: 256,
                    attribution: mapboxAttribution
                };
            });

        newStyle.sources["mapbox-dem"] = {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
            tileSize: 512,
            maxzoom: 14
        };

        if (this.state.terrainEnabled) {
            newStyle.terrain = {
                source: "mapbox-dem",
                exaggeration: this.state.terrainExaggeration
            };
        }
        //Chose to do this instead of a sort because we need to keep the order of the layers
        const symbolMapLayers = newStyle.layers.filter((layer) => layer.type === "symbol");

        const nonSymbolMapLayers = newStyle.layers.filter((layer) => layer.type !== "symbol");

        //We want to keep the symbols on top, so we reverse the layers this way
        //This will keep the order in the layer selector, but also keep all the symbols on top
        const normalLayers = this.props.mapState.layers
            .filter((x) => x.type !== "symbol")
            .reverse()
            .map((layer) => this.layerToMapLayer(layer));

        const symbolLayers = this.props.mapState.layers
            .filter((x) => x.type === "symbol")
            .reverse()
            .map((layer) => this.layerToMapLayer(layer));

        //add Layers and assosiated styling to new style
        newStyle.layers = [...nonSymbolMapLayers, ...normalLayers, ...symbolMapLayers, ...symbolLayers];

        return newStyle;
    }

    onBasemapChanged = (basemap) => {
        this.props.setBasemap(basemap);
    };

    fitBounds(bbox, options) {
        this.map.fitBounds(bbox, options);
    }

    onTerrainToggled = () => {
        let isTerrainEnabled = !this.state.terrainEnabled;

        this.setTerrainToggled(isTerrainEnabled);
    };

    setTerrainToggled = (toggled) => {
        this.setState({ terrainEnabled: toggled });

        if (toggled) {
            this.map.setTerrain({ source: "mapbox-dem", exaggeration: this.state.terrainExaggeration });
        } else {
            this.map.setTerrain(null);
        }
    };

    onTerrainExaggerationChanged = (exaggeration) => {
        this.setState({
            terrainExaggeration: exaggeration
        });
    };

    onTerrainExaggerationCommitted = () => {
        this.map.setTerrain({ source: "mapbox-dem", exaggeration: this.state.terrainExaggeration });
    };

    flyTo(location) {
        this.map.flyTo({
            center: [location.y, location.x],
            zoom: 7,
            speed: 0.5,
            curve: 1
        });

        if (marker) marker.remove();

        marker = new GlMap.Marker().setLngLat({ lng: location.y, lat: location.x }).addTo(this.map);
    }

    moveLayer(layerId, beforeLayerId) {
        this.map.moveLayer(layerId, beforeLayerId);
    }

    addSources(sources) {
        sources.forEach((source) => {
            if (!this.map.getSource(source.id)) {
                let endpointName = source.type === "raster" ? "raster" : "tile";
                this.map.addSource(source.id, {
                    type: source.type === "raster" ? "raster" : "vector",
                    tiles: [Config.apiUrl + `${endpointName}/${source.id}/{z}/{x}/{y}`],
                    minzoom: source.minZoom,
                    maxzoom: source.maxZoom
                });
            }
        });
    }

    onZanderToolOpen = (lat, long) => {
        this.props.requestZanderConnectionStart();

        this.setState({
            lat,
            long,
            zanderToolOpen: true
        });
    };

    onZanderToolClose = () => this.setState({ zanderToolOpen: false });

    download = (documentLink) => {
        const link = document.createElement("a");
        link.download = "esoxDownload";
        link.href = documentLink;
        link.click();
    };

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.mapState.loaded) {
            return;
        }
        if (prevProps.mapState.loaded !== this.props.mapState.loaded) {
            const fitBounds = this.props.mapState.fitBounds;
            fitBounds.bbox.length !== 0 && this.fitBounds(fitBounds.bbox, fitBounds.options);
        }

        if (prevProps.basemap !== this.props.basemap) {
            this.changeBaseLayer(this.props.basemap);
        }

        if (prevProps.mapState.fitBounds !== this.props.mapState.fitBounds) {
            //Temporary fix for applications that have bounds set to [[0,0][0,0]]
            if (this.props.mapState.fitBounds.bbox[0][0] == 0) {
                this.fitBounds(
                    [
                        [-158.26, -62.04],
                        [196.7, 83.64]
                    ],
                    this.props.mapState.fitBounds.options
                );
            } else {
                this.fitBounds(this.props.mapState.fitBounds.bbox, this.props.mapState.fitBounds.options);
            }
        }

        if (!this.state.stylesLoaded) {
            return;
        }

        if (prevProps.mapState.sources !== this.props.mapState.sources) {
            this.addSources(this.props.mapState.sources);
        }
        if (prevProps.mapState.layers.length < this.props.mapState.layers.length) {
            this.addLayers(
                this.props.mapState.layers.slice(prevProps.mapState.layers.length, this.props.mapState.layers.length)
            );
        } else if (prevProps.mapState.layers.length > this.props.mapState.layers.length) {
            this.removeLayers(prevProps.mapState.layers, this.props.mapState.layers);
        } else if (prevProps.mapState.layers !== this.props.mapState.layers) {
            this.changeLayers(prevProps.mapState.layers, this.props.mapState.layers);
        }

        if (prevProps.mapState.paints !== this.props.mapState.paints) {
            this.changePaint(this.props.mapState.paints);
        }

        if (prevProps.mapState.layouts !== this.props.mapState.layouts) {
            this.changeLayout(this.props.mapState.layouts);
        }

        if (prevProps.mapState.flyTo !== this.props.mapState.flyTo) {
            this.flyTo(this.props.mapState.flyTo);
        }

        if (prevProps.widgets !== this.props.widgets) {
            this.setState({
                enabledWidgets: this.props.widgets
                    .filter((x) => x.toggled)
                    .reduce((a, b) => {
                        a[b.name] = true;
                        return a;
                    }, {})
            });
        }

        if (prevProps.mapState.moveLayer !== this.props.mapState.moveLayer) {
            this.moveLayer(this.props.mapState.moveLayer.layerId, this.props.mapState.moveLayer.beforeLayerId);
        }

        if (prevProps.selectedApp !== this.props.selectedApp) {
            this.removeAllFeatures();
        }

        if (prevProps.mapState.language !== this.props.mapState.language) {
            this.changeLanguage(this.props.mapState.language);
        }

        if (prevProps.searchToggledStatus !== this.props.searchToggledStatus) {
            if (!this.props.searchToggledStatus) {
                marker?.remove();
            }
        }
    }

    renderPrintMapExtraInfo() {
        return (
            <>
                {this.props.printFeatures.showMapTitle && !this.props.printFeatures.showMapLegend && (
                    <MapTitle mapTitle={this.props.printFeatures.mapTitle} />
                )}
                {this.props.printFeatures.showMapNotes && !this.props.printFeatures.showMapLegend && (
                    <MapNotes mapNotes={this.props.printFeatures.mapNotes} />
                )}
                {this.props.printFeatures.showMapCopyright && !this.props.printFeatures.showMapLegend && (
                    <MapCopyright
                        mapCopyright={this.props.printFeatures.mapCopyright}
                        showMapLegend={this.props.printFeatures.showMapLegend}
                    />
                )}
                {this.props.printFeatures.showMapDate && !this.props.printFeatures.showMapLegend && (
                    <MapDate
                        mapDateFormat={this.props.printFeatures.mapDateFormat}
                        showMapLegend={this.props.printFeatures.showMapLegend}
                    />
                )}
                {this.props.printFeatures.showMapLogo && <MapLogo />}
                {this.props.printFeatures.showMapNorthArrow && <MapNorthArrow />}
                <MapHelper />
            </>
        );
    }

    renderNormalMapTools() {
        return (
            <>
                <BasemapSelector
                    value={this.props.basemap}
                    basemaps={this.props.config?.basemaps}
                    onChange={this.onBasemapChanged}
                    fullScreenEl={this.state.fullScreenEl}
                />
                <ExaggerationSlider
                    fullScreenEl={this.state.fullScreenEl}
                    terrainEnabled={this.state.terrainEnabled}
                    onTerrainToggled={this.onTerrainToggled}
                    exaggerationValue={this.state.terrainExaggeration}
                    onTerrainExaggerationChanged={this.onTerrainExaggerationChanged}
                    onTerrainExaggerationCommitted={this.onTerrainExaggerationCommitted}
                />
                {/* <MapCopyState /> */}
                {this.props.config.languages?.length > 1 && (
                    <LanguageSelector
                        value={this.props.mapState.language}
                        languages={this.props.config.languages}
                        onChange={this.changeLanguage}
                        fullScreenEl={this.state.fullScreenEl}
                    />
                )}
                <MapTools />
                <Disclaimer />
            </>
        );
    }

    render() {
        const isPrintMap = this.props.modal;
        return (
            <div id={isPrintMap ? "modal-map" : "map"}>
                <Collapse in={this.props.digitizeState.editing} timeout={500}>
                    <MapEditing />
                </Collapse>
                <Legend loaded={this.props.mapState.loaded} />

                {isPrintMap ? this.renderPrintMapExtraInfo() : this.renderNormalMapTools()}
                <SearchLayer />
                {this.state.enabledWidgets["measure"] && <Measure reinit={this.state.reinitMeasureWidget} />}
                {this.state.enabledWidgets["search"] && <Search />}
                {this.state.enabledWidgets["print"] && <Print />}
                <MobileInfoBox />
                <ZanderModal
                    open={this.state.zanderToolOpen}
                    onClose={this.onZanderToolClose}
                    lat={this.state.lat}
                    long={this.state.long}
                    download={this.download}
                />
                <PrintSizeVisualizer options={this.props.mapState.printOutline} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapState: state.map,
        auth: state.auth,
        widgets: state.tools.widgets,
        config: state.config.config,
        printFeatures: state.print.printFeatures,
        digitizeState: state.digitize,
        toolsState: state.tools,
        layerGroups: state.layerSelector.layerGroups,
        basemap: state.map.basemap,
        selectedApp: getSelectedApp(state),
        layersVisibilityMap: getLayerVisibilityMap(state),
        layersStylesMap: getLayerStylesMap(state),
        searchToggledStatus: getSearchWidgetToggledStatus(state)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        selectFeatures: (features) => dispatch(mapActions.selectFeatures(features)),
        clearMap: () => dispatch(mapActions.clear()),
        setBasemap: (basemap) => dispatch(mapActions.setBasemap(basemap)),
        mapLoaded: (status) => dispatch(mapActions.mapLoaded(status)),
        zoomEnd: (zoom) => dispatch(mapZoomEnd(zoom)),
        mapClick: (lnglat) => dispatch(mapActions.mapClick(lnglat)),
        getMapboxStyle: (stylePath) => dispatch(mapboxApiActions.getStyle(stylePath)),
        setLanguage: (language) => dispatch(mapActions.setLanguage(language)),
        setStylerLayerId: (layerId) => dispatch(layerSelectorActions.setStylerLayerId(layerId)),
        requestZanderConnectionStart: () => dispatch(requestZanderConnectionStart())
    };
};

let MapComponent = connect(mapStateToProps, mapDispatchToProps)(Map);

export { getMap, getDraw };

export default MapComponent;

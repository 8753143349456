import * as types from "../actions/actionTypes";
import { getApp } from "../actions/atlasClient";
import isMobile from "../utils/isMobile";

const sidebarInit = {
    isSidebarOpen: isMobile.phone ? false : true,
    isDrawerOpen: false
};

export function sidebarAndDrawerReducer(state = sidebarInit, action) {
    switch (action.type) {
        case types.TOGGLE_SIDEBAR_OPEN:
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen
            };
        case types.SET_SIDEBAR_OPEN:
            return {
                ...state,
                open: action.payload
            };
        case types.TOGGLE_DRAWER_OPEN:
            return {
                ...state,
                isDrawerOpen: !state.isDrawerOpen
            };
        case getApp.fulfilled.type:
            return {
                ...state,
                isSidebarOpen: !action.payload.configJson.sidebarCollapsed
            };
        default:
            return state;
    }
}

export const digitizeLayerToStyle = (layer, layerNumber) => ({
    name: layer.name,
    sourceName: layer.name,
    paint: layer.paint,
    layout: layer.layout,
    properties: layer.properties,
    type: layer.styleType,
    layerId: layer.datasetId + ":" + layerNumber,
    datasetId: layer.datasetId,
    sourceId: layer.datasetId
});

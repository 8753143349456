import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AtlasActions from "../actions/atlasClient";
import {
    Grid,
    Card,
    CardMedia,
    Typography,
    IconButton,
    Tooltip,
    Button,
    TextField,
    InputAdornment,
    Chip
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Map } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { ReactComponent as PublishedWithChangesIcon } from "../utils/icons/published_with_changes.svg";
import { ReactComponent as UnpublishedIcon } from "../utils/icons/unpublished.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import { getAppsSelector, getAppsError, getLoadingApps } from "../selectors/appsSelectors";
import { useAppViewStyles } from "./styles/appsViewStyles";
import RefreshIcon from "@material-ui/icons/Refresh";
import dayjs from "dayjs";
import toastr from "../utils/customToastr";

const ChipIcon = (props) =>
    props.isPublished ? (
        <PublishedWithChangesIcon className={props.className} />
    ) : (
        <UnpublishedIcon className={props.className} />
    );

const AppsView = () => {
    const classes = useAppViewStyles();

    const [selectedApp, setSelectedApp] = useState(null);
    const [appLoading, setAppLoading] = useState(false);
    const [searchString, setSearchString] = useState("");

    const apps = useSelector(getAppsSelector);
    const appsLoading = useSelector(getLoadingApps);
    const hasErrorOccured = useSelector(getAppsError);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        if (apps.length === 1) onAppClick(apps[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apps]);

    const handleLoading = (loading) => {
        setAppLoading(loading);
    };

    const signOut = (e) => {};

    const onAppClick = (app) => {
        setSelectedApp(app);
        handleLoading(true);
        dispatch(AtlasActions.getApp(app.id)).then((appResponse) => {
            if (appResponse.payload) {
                handleLoading(false);
                history.push(`/${app.id}/map/layer-selector`);
            } else {
                handleLoading(false);
                toastr.error("This app has not been published yet!");
            }
        });
    };

    const sortByModified = (app1, app2) => {
        return new Date(app2.modifiedUtc) - new Date(app1.modifiedUtc);
    };

    const onSearchChange = (e) => {
        setSearchString(e.target.value);
    };

    const filteredAndSortedApps = useMemo(
        () =>
            apps
                .filter((app) => app.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()))
                .sort(sortByModified),
        [apps, searchString]
    );

    const renderedApps =
        apps !== null &&
        filteredAndSortedApps.map((app, index) => {
            return (
                <Card key={index} className={classes.app} onClick={() => onAppClick(app)}>
                    {app.previewMap ? (
                        <CardMedia
                            className={classes.mapPreview}
                            image={app.previewMap}
                            title="Map preview of the application"
                        />
                    ) : (
                        <div className={classes.mapIcon}>
                            {selectedApp?.name === app.name && appLoading ? (
                                <CircularProgress className={classes.loader} />
                            ) : (
                                <Map />
                            )}
                        </div>
                    )}
                    <div className={classes.appInfo}>
                        <div className={classes.content}>
                            <Typography className={classes.appName} component="h6" variant="h6">
                                {app.name}
                            </Typography>
                            <Typography className={classes.modifiedUtcText} variant="body2">
                                Updated: {dayjs(app.modifiedUtc).fromNow()}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.chip}>
                        <Chip
                            label={app.isPublished ? "Published" : "Unpublished"}
                            className={app.isPublished ? classes.publishedChip : classes.unpublishedChip}
                            icon={<ChipIcon className={classes.chipIcon} isPublished={app.isPublished} />}
                        />
                    </div>
                </Card>
            );
        });

    return (
        <Grid className={classes.root}>
            <Grid item xs={12} className={classes.topbar}>
                <Grid container justify="center" alignItems="center">
                    <img src="/media/Lautec_WindGIS-ProductLogo_Black.svg" className={classes.logo} alt="Logo" />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                direction="column"
                spacing={3}
                wrap="nowrap"
                className={classes.infoContainer}
            >
                {appsLoading ? (
                    <>
                        <Grid item className={classes.marginTop}>
                            <Typography variant="h5">Getting your apps</Typography>
                        </Grid>
                        <Grid item>
                            <CircularProgress size={65} />
                        </Grid>
                    </>
                ) : hasErrorOccured ? (
                    <>
                        <Grid item className={classes.marginTop}>
                            <Typography variant="h5">Encountered an error while getting your apps.</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                startIcon={<RefreshIcon />}
                                color="primary"
                                variant="contained"
                                onClick={() => dispatch(AtlasActions.getApps())}
                                size="large"
                            >
                                Try Again
                            </Button>
                        </Grid>
                    </>
                ) : apps.length > 1 ? (
                    <Grid>
                        <Typography className={classes.selectAppTitle} variant="h6">
                            Select an Application
                        </Typography>
                        <TextField
                            onChange={onSearchChange}
                            hiddenLabel
                            className={classes.searchField}
                            placeholder="Search for an Application"
                            variant="filled"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                        />
                        {renderedApps}
                    </Grid>
                ) : (
                    <>
                        <Typography className={classes.noAppsTitle} variant="h6">
                            You have no available apps at the moment!
                        </Typography>
                        <Typography variant="body2">Contact an admin for further support.</Typography>
                    </>
                )}
            </Grid>
            <Tooltip title="Sign out" placement="top" className={classes.logoutButton}>
                <IconButton onClick={signOut}>
                    <ExitToAppIcon className={classes.logoutIcon} />
                </IconButton>
            </Tooltip>
        </Grid>
    );
};

export default AppsView;

import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        position: "absolute",
        top: 10,
        zIndex: 1,
        textAlign: "center",
        width: "100%"
    },
    title: {
        padding: 10,
        backgroundColor: "rgba(255,255,255,0.75)",
        borderRadius: 5,
        left: 0,
        right: 0,
        margin: "auto",
        maxWidth: "15%",
        wordBreak: "break-all"
    },
    rootLegend: {
        position: "relative"
    },
    titleLegend: {
        margin: "auto",
        color: "#fff",
        padding: 4,
        textAlign: "center",
        maxWidth: "100%",
        fontWeight: "bold",
        wordBreak: "break-all",
        fontSize: ({ orientation }) => (orientation === "portrait" ? 11 : 18)
    }
}));

const MapTitle = ({ mapTitle, modal = false, orientation = "portrait" }) => {
    const classes = useStyles({ orientation });
    return (
        <div id="map-title" className={`${modal ? classes.rootLegend : classes.root}`}>
            <Typography className={`${modal ? classes.titleLegend : classes.title}`}>{mapTitle}</Typography>
        </div>
    );
};

export default MapTitle;

import { makeStyles } from "@material-ui/core";

export const useExaggerationSliderStyles = makeStyles((theme) => ({
    iconRoot: {
        position: "absolute",
        top: 184,
        right: 10,
        zIndex: 2,
        borderRadius: 4,
        backgroundColor: "white",
        boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)"
    },
    iconContainer: {
        width: 29,
        height: 29,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "whitesmoke",
            borderRadius: 4
        }
    },
    icon: {
        color: "#484848",
        transition: "0.2s all"
    },
    iconEnabled: {
        color: theme.palette.primary.main
    },
    sliderContainer: {
        display: "flex",
        padding: "0px 16px"
    },
    slider: {
        padding: 20
    },
    headerTypography: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center"
    },
    headerIcon: {
        marginRight: 8,
        color: "lightgray",
        transition: "0.2s all"
    },
    IconEnabled: {
        color: theme.palette.primary.main
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        padding: "8px 8px",
        paddingTop: 4
    },
    exaggerationContainer: {
        display: "flex",
        alignItems: "center",
        padding: "0px 16px",
        marginTop: 16
    },
    popoverRoot: {
        padding: 8,
        width: 250
    },
    flexGrow: {
        flexGrow: 1
    }
}));

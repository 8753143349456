import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { CloudDownload } from "@material-ui/icons/";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import * as layerSelectorActions from "../../../actions/layerSelector";
import { getMap } from "../../map/map";
import { getLayerData } from "../../../selectors/layerSelector";
import useIfMounted from "../../../utils/customHooks/useIfMounted";
import { useInfoTableStyles } from "./styles/infoTableStyles";
import { generateCancelToken } from "../../../actions/apiClient";
import toastr from "../../../utils/customToastr";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

const getComparator = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const useTableHeadStyles = makeStyles((theme) => ({
    tableCell: {
        borderRight: "1px solid lightgrey"
    },
    tableCellLabel: {
        fontWeight: "bold"
    }
}));

const EnhancedTableHead = (props) => {
    const classes = useTableHeadStyles();
    const { onSelectAllClick, order, orderBy, selectedRows, rowsOnPage, onRequestSort, headCells, downloadable } =
        props;

    const [allRowsAreSelected, setAllRowsAreSelected] = useState(false);
    const [someRowsAreSelected, setSomeRowsAreSelected] = useState(false);

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    useEffect(() => {
        let rowIsSelected = (row) => selectedRows.some((selectedRow) => selectedRow.ogc_fid === row.ogc_fid);
        let selectedRowsOnPage = rowsOnPage.filter(rowIsSelected);
        setAllRowsAreSelected(selectedRowsOnPage.length === rowsOnPage.length);
        setSomeRowsAreSelected(selectedRowsOnPage.length < rowsOnPage.length && selectedRowsOnPage.length !== 0);
    }, [rowsOnPage, selectedRows]);

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled={!downloadable}
                        indeterminate={someRowsAreSelected && !allRowsAreSelected}
                        checked={allRowsAreSelected}
                        onClick={onSelectAllClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                    />
                </TableCell>
                {headCells?.map((headCell) => (
                    <TableCell
                        className={classes.tableCell}
                        key={headCell.id}
                        align={"center"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className={classes.tableCellLabel}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const InfoTable = ({
    selectedLayer,
    activeRequestsCancelTokensRef,
    handleOpenDownloadModal,
    handleOpenDownloadAttributesModal
}) => {
    const classes = useInfoTableStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const [bePage, setBePage] = useState(0);
    const execIfMounted = useIfMounted();
    const data = useSelector(getLayerData);

    const tableRef = useRef();
    const dispatch = useDispatch();

    const downloadable = selectedLayer.options.downloadable;

    useEffect(() => {
        setPage(0);
        // eslint-disable-next-line
    }, [selectedLayer]);

    useEffect(() => {
        const layerAttributes = data.map((feature) => feature.properties);
        const newData = [...layerAttributes];
        setSelected([]);
        setTableData(newData);
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (tableData.length / rowsPerPage - 1 === page && page) {
            getDataAndRegisterToken();
        }
        // eslint-disable-next-line
    }, [page]);

    const getDataAndRegisterToken = () => {
        const tokenKey = selectedLayer.resourceId + (bePage + 1);
        const cancelToken = generateCancelToken();
        const req = dispatch(
            layerSelectorActions.getLayerData({
                datasetId: selectedLayer.resourceId,
                page: bePage + 1,
                cancelToken: cancelToken.token
            })
        );
        activeRequestsCancelTokensRef.current = [...activeRequestsCancelTokensRef.current, [tokenKey, cancelToken]];
        setBePage(bePage + 1);
        req.finally(() => {
            //No matter the result we remove the cancel token
            execIfMounted(() => {
                activeRequestsCancelTokensRef.current = activeRequestsCancelTokensRef.current.filter(
                    (tokenPair) => tokenPair[0] !== tokenKey
                );
            });
        });
    };

    const headCells =
        tableData[0] &&
        Object.getOwnPropertyNames(tableData[0]).map((headCell, index) => {
            return {
                id: headCell.toString(),
                numeric: typeof tableData[0][headCell] === "number",
                disablePadding: false,
                label: headCell.toString()
            };
        });
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortOrder = isAsc ? "desc" : "asc";
        setOrder(sortOrder);
        setOrderBy(property);

        setTableData(stableSort(tableData, getComparator(sortOrder, property)));
    };

    const handleSelectAllClick = (event) => {
        let startIndex = page * rowsPerPage;
        let endIndex = (page + 1) * rowsPerPage;
        let targetedRows = tableData.slice(startIndex, endIndex);

        let newSelected;
        if (event.target.checked) {
            newSelected = [...selected];
            for (let row of targetedRows) {
                let rowIsSelected = newSelected.some((selectedRow) => selectedRow.ogc_fid === row.ogc_fid);
                if (!rowIsSelected) {
                    newSelected.push(row);
                }
            }
        } else {
            newSelected = selected.filter(
                (selectedRow) => !targetedRows.some((row) => row.ogc_fid === selectedRow.ogc_fid)
            );
        }

        setSelected(newSelected);
    };

    const handleClick = (event, row) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        let newSelected = selected.filter((selectedRow) => selectedRow.ogc_fid !== row.ogc_fid);
        let rowIsSelected = newSelected.length !== selected.length;
        if (!rowIsSelected) {
            newSelected.push(row);
        }

        setSelected(newSelected);
    };

    const onHandleDownload = () => {
        if (selected.length > 0) {
            handleOpenDownloadAttributesModal(selected);
        } else {
            handleOpenDownloadModal(selectedLayer);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleUrlClick = (e, url) => {
        if (typeof url === "string" && url.includes("http")) {
            e.stopPropagation();
            toastr.info("URL copied to clipboard.");
            let tempInput = document.createElement("input");
            tempInput.value = url;
            document.body.appendChild(tempInput);
            tempInput.select();
            tempInput.setSelectionRange(0, 99999);
            document.execCommand("copy");
            document.body.removeChild(tempInput);
        }
    };

    const handleFlyToFeature = (e, row) => {
        e.stopPropagation();
        let feature = data.find((feature) => feature.properties.ogc_fid === row.ogc_fid);
        let featureCoordinates;
        if (feature.geometry.type === "Point") featureCoordinates = feature.geometry.coordinates;
        else featureCoordinates = feature.geometry.coordinates[0];

        if (Array.isArray(featureCoordinates[0])) featureCoordinates = featureCoordinates[0];

        if (Array.isArray(featureCoordinates[0])) featureCoordinates = featureCoordinates[0];

        getMap().flyTo({
            center: [featureCoordinates[0], featureCoordinates[1]],
            zoom: 11,
            speed: 1.25,
            curve: 1.2
        });
    };

    const isSelected = (row) => selected.indexOf(row) !== -1;
    return (
        <div className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="attributes table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        downloadable={downloadable}
                        onRequestSort={handleRequestSort}
                        rowsOnPage={tableData.slice(page * rowsPerPage, (page + 1) * rowsPerPage)}
                        selectedRows={selected}
                        headCells={headCells}
                    />
                    <TableBody>
                        {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `attributes-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    onClick={(e) => handleFlyToFeature(e, row)}
                                    hover
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={`row-${index}`}
                                    selected={isItemSelected}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            onClick={(e) => downloadable && handleClick(e, row)}
                                            disabled={!downloadable}
                                            checked={isItemSelected}
                                            inputProps={{ "aria-labelledby": labelId }}
                                        />
                                    </TableCell>
                                    {headCells.map((headCell, index) => (
                                        <TableCell
                                            align={typeof row[headCell.id] === "number" ? "right" : "left"}
                                            className={classes.tableCell}
                                            onClick={(e) => handleUrlClick(e, row[headCell.id])}
                                            ref={tableRef}
                                            key={headCell.id}
                                        >
                                            <Tooltip
                                                title={row[headCell.id] || ""}
                                                arrow
                                                PopperProps={{ container: tableRef.current }}
                                            >
                                                <Typography>{row[headCell.id]}</Typography>
                                            </Tooltip>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={selectedLayer.rowCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div className={classes.downloadContainer}>
                {selected.length > 0 && (
                    <Typography className={classes.downloadSelectedValue} variant="subtitle2">
                        {selected.length}
                    </Typography>
                )}
                <Tooltip
                    title={
                        downloadable
                            ? selected.length > 0
                                ? "Download selected features"
                                : "Download all features"
                            : "Layer is not downloadable"
                    }
                >
                    <div>
                        <IconButton
                            disabled={!downloadable}
                            size="small"
                            aria-label="Download"
                            onClick={onHandleDownload}
                            className={classes.downloadButton}
                        >
                            <CloudDownload />
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export default InfoTable;

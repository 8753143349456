import { makeStyles, withStyles } from "@material-ui/styles";
import { Slider } from "@material-ui/core";

export const PrettySlider = withStyles((theme) => ({
    valueLabel: {
        letterSpacing: -1,
        fontSize: 10,
        fontWeight: 500
    }
}))(Slider);

export const useStyles = makeStyles((theme) => ({
    spacingBottom: {
        paddingBottom: 16
    },
    spacingTop: {
        paddingTop: 16
    },
    percentiles: {
        justifyContent: "center"
    },
    zanderSliderContainer: {
        display: "flex",
        justifyContent: "center"
    },
    zanderSlider: {
        width: "95%",
        paddingBottom: 20
    },
    zanderSheet: {
        padding: 20
    },
    sectionCard: {
        boxShadow: "0 2px 12px 1px rgba(60, 62, 66, 0.1)",
        padding: 20,
        marginBottom: 16,
        borderRadius: 8,
    }
}));

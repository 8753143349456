import React from "react";
import { Grid, makeStyles, Typography, FormControl, Select, MenuItem } from "@material-ui/core";
import PrintFeatures from "./printFeatures";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff"
    },
    header: {
        padding: 16,
        borderBottom: "2px solid #f5f7f8"
    },
    section: {
        padding: 13,
        borderBottom: "2px solid #ececec"
    },
    formatSelector: {
        padding: 8
    },
    advancedButton: {
        padding: 8,
        marginBottom: 8,
        marginTop: 8,
        textAlign: "center",
        backgroundColor: "#0C567E",
        color: "#fff",
        cursor: "pointer"
    }
}));

const PrintOptions = ({
    dpiOptions,
    dpi,
    setDpi,
    outputSize,
    setOutputSize,
    orientation,
    setOrientation,
    width,
    height,
    unitType,
    setWidth,
    setHeight,
    setUnitType
}) => {
    const classes = useStyles();
    // const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Grid container className={classes.section}>
                    <Grid item>
                        <FormControl variant="outlined" size="small" className={classes.formatSelector}>
                            <Typography variant="body1">DPI</Typography>
                            <Select
                                value={dpi}
                                onChange={(e) => setDpi(e.target.value)}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "center"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {dpiOptions.map((element) => (
                                    <MenuItem key={element} value={element}>
                                        {element}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined" size="small" className={classes.formatSelector}>
                            <Typography variant="body1">Size</Typography>
                            <Select
                                value={outputSize}
                                onChange={(e) => setOutputSize(e.target.value)}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "center"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                <MenuItem value="A3">A3</MenuItem>
                                <MenuItem value="A4">A4</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined" size="small" className={classes.formatSelector}>
                            <Typography variant="body1">Orientation</Typography>
                            <Select
                                value={orientation}
                                onChange={(e) => setOrientation(e.target.value)}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "center"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                <MenuItem value="portrait">Portrait</MenuItem>
                                <MenuItem value="landscape">Landscape</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            {/* TEMPORARILY REMOVED ADVANCED OPTIONS */}
            {/* <Grid item xs={12} onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
                <Grid container justify='center' className={classes.advancedButton}>
                    <Typography variant="caption">Advanced Options</Typography>
                </Grid>
            </Grid> */}
            {/* {showAdvancedOptions && <Grid item xs={12}>
                <Grid container className={classes.section}>
                    <PrintAdvancedOptions
                        dpi={dpi}
                        setDpi={setDpi}
                        width={width}
                        setWidth={setWidth}
                        height={height}
                        setHeight={setHeight}
                        unitType={unitType}
                        setUnitType={setUnitType}
                    />
                </Grid>
            </Grid>} */}
            <Grid item xs={12}>
                <PrintFeatures />
            </Grid>
        </Grid>
    );
};

export default PrintOptions;

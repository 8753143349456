import { makeStyles } from "@material-ui/core";
import React from "react";

export const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        bottom: 0,
        right: 0,
        width: "auto",
        height: 20,
        zIndex: 5,
        fontWeight: "bold",
        color: "#333",
        backgroundColor: "hsla(0,0%,100%,.5)"
    },
    disclaimerContainer: {
        padding: "1px 6px 1px 6px",
        fontSize: "10px",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.main,
            textDecoration: "underline"
        }
    }
}));
const Disclaimer = () => {
    const classes = useStyles();

    const onClick = () => {
        window.open("https://esox.lautec.com/disclaimer/");
    };

    return (
        <div className={classes.root}>
            <div className={classes.disclaimerContainer} onClick={onClick}>
                Disclaimer
            </div>
        </div>
    );
};

export default Disclaimer;

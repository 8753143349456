import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import LanguageIcon from "@material-ui/icons/Translate";

import { Radio, FormControlLabel, Typography, Popover, Paper, List, RadioGroup } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    iconRoot: {
        position: "absolute",
        top: 258,
        right: 10,
        zIndex: 2,
        borderRadius: 4,
        backgroundColor: "white",
        boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)"
    },
    iconContainer: {
        width: 29,
        height: 29,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        cursor: "pointer",
        "&:hover": {
            backgroundColor: "whitesmoke"
        }
    },
    icon: {
        color: "#484848"
    },
    listTitle: {
        padding: 8,
        textAlign: "center"
    },
    list: {
        maxHeight: 280,
        overflowX: "overlay",
        position: "relative",
        overflow: "auto"
    },
    radioText: {
        textTransform: "capitalize",
        paddingLeft: 8
    },
    sectionContainer: {
        backgroundColor: "#fff",
        padding: 0
    },
    languagesContainer: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        padding: 0
    }
}));

const LanguageSelector = ({ languages, value, onChange, fullScreenEl }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "language-selector-menu" : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onLanguageChanged = (e) => {
        let newLanguage = e.target.value;
        onChange(newLanguage);
    };

    return (
        <div className={classes.iconRoot}>
            <Tooltip title="Map Language">
                <div className={classes.iconContainer} onClick={handleClick}>
                    <LanguageIcon className={classes.icon} />
                </div>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                container={fullScreenEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <Paper>
                    <div className={classes.listTitle}>
                        <Typography variant="h6">Map Language</Typography>
                    </div>
                    <List className={classes.list} subheader={<li />}>
                        <li className={classes.sectionContainer}>
                            <ul className={classes.languagesContainer}>
                                <RadioGroup
                                    aria-label="basement selector"
                                    name="basement selector"
                                    value={value}
                                    onChange={onLanguageChanged}
                                >
                                    {languages
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((language) => (
                                            <FormControlLabel
                                                key={language.name}
                                                className={classes.radioText}
                                                value={language.id}
                                                control={<Radio color="primary" />}
                                                label={language.name}
                                            />
                                        ))}
                                </RadioGroup>
                            </ul>
                        </li>
                    </List>
                </Paper>
            </Popover>
        </div>
    );
};

export default LanguageSelector;

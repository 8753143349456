import React, { useEffect, useState } from "react";
import { Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { Cancel, Delete, Edit, Save } from "@material-ui/icons";

const EditFeature = ({
    classes,
    feature,
    deleteLayerFeature,
    editLayerFeature,
    setSelectedFeatures,
    onFeatureClick,
    deselectAllFeaturesAndLabels
}) => {
    const [editMode, setEditMode] = useState(false);
    const [editedText, setEditedText] = useState("");

    useEffect(() => {
        return () => {
            handleCancelEditMode();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEdit = (e) => {
        e.stopPropagation();
        setEditMode(true);
        setEditedText(feature.properties.name);

        deselectAllFeaturesAndLabels();
    };

    const handleSave = (newName) => {
        editLayerFeature(feature, newName);
        setEditMode(false);
        setEditedText("");

        deselectAllFeaturesAndLabels();
    };

    const handleEditMode = (e) => {
        if (e.keyCode === 13) {
            handleSave(editedText);
        } else if (e.keyCode === 27) {
            setEditMode(false);
            setEditedText("");
        }
    };

    const handleCancelEditMode = () => {
        setEditMode(false);
        setEditedText("");
        setSelectedFeatures([]);

        deselectAllFeaturesAndLabels();
    };

    return (
        <Grid container alignItems="center" className={classes.featureContainer}>
            <Grid
                item
                xs={9}
                className={classes.editFieldContainer}
                onClick={(e) => !editMode && onFeatureClick(e, feature)}
            >
                {!editMode ? (
                    <Typography className={classes.featureName} variant="body2">
                        {feature.properties.name ? feature.properties.name : feature.geometry.type}
                    </Typography>
                ) : (
                    <TextField
                        id="digitize-edit-feature-name"
                        className={classes.textField}
                        placeholder="Feature name"
                        size="small"
                        value={editedText}
                        onKeyDown={(e) => handleEditMode(e, feature)}
                        onChange={(e) => setEditedText(e.target.value)}
                    />
                )}
            </Grid>
            <Grid item xs={3}>
                {editMode ? (
                    <IconButton className={classes.iconButton} size="small" onClick={() => handleSave(editedText)}>
                        <Save />
                    </IconButton>
                ) : (
                    <IconButton className={classes.iconButton} size="small" onClick={(e) => handleEdit(e)}>
                        <Edit />
                    </IconButton>
                )}
                {editMode ? (
                    <IconButton className={classes.iconButton} size="small" onClick={handleCancelEditMode}>
                        <Cancel />
                    </IconButton>
                ) : (
                    <IconButton
                        className={classes.iconButton}
                        size="small"
                        onClick={(e) => deleteLayerFeature(e, feature)}
                    >
                        <Delete />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
};

export default EditFeature;

import * as types from "../actions/actionTypes";

const initialState = {
    printFeatures: {
        showMapTitle: false,
        mapTitle: "",
        showMapNotes: false,
        mapNotes: "",
        showMapLegend: false,
        showMapScalebar: false,
        showMapCopyright: false,
        mapCopyright: "© Mapbox © Lautec",
        showMapDate: false,
        mapDateFormat: "local",
        showMapNorthArrow: false,
        showMapLogo: false
    }
};

export const printReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PRINT_TOGGLE_MAP_TITLE:
            return {
                ...state,
                printFeatures: {
                    ...state.printFeatures,
                    showMapTitle: !state.printFeatures.showMapTitle
                }
            };
        case types.PRINT_MAP_TITLE:
            return {
                ...state,
                printFeatures: {
                    ...state.printFeatures,
                    mapTitle: action.payload
                }
            };
        case types.PRINT_TOGGLE_MAP_NOTES:
            return {
                ...state,
                printFeatures: {
                    ...state.printFeatures,
                    showMapNotes: !state.printFeatures.showMapNotes
                }
            };
        case types.PRINT_MAP_NOTES:
            return {
                ...state,
                printFeatures: {
                    ...state.printFeatures,
                    mapNotes: action.payload
                }
            };
        case types.PRINT_MAP_LEGEND:
            return {
                ...state,
                printFeatures: {
                    ...state.printFeatures,
                    showMapLegend: !state.printFeatures.showMapLegend
                }
            };
        case types.PRINT_MAP_SCALEBAR:
            return {
                ...state,
                printFeatures: {
                    ...state.printFeatures,
                    showMapScalebar: !state.printFeatures.showMapScalebar
                }
            };
        case types.PRINT_TOGGLE_MAP_DATE:
            return {
                ...state,
                printFeatures: {
                    ...state.printFeatures,
                    showMapDate: !state.printFeatures.showMapDate
                }
            };
        case types.PRINT_TOGGLE_MAP_DATE_FORMAT:
            return {
                ...state,
                printFeatures: {
                    ...state.printFeatures,
                    mapDateFormat: action.payload
                }
            };
        case types.PRINT_TOGGLE_MAP_COPYRIGHT:
            return {
                ...state,
                printFeatures: {
                    ...state.printFeatures,
                    showMapCopyright: !state.printFeatures.showMapCopyright
                }
            };
        case types.PRINT_TOGGLE_MAP_NORTH_ARROW:
            return {
                ...state,
                printFeatures: {
                    ...state.printFeatures,
                    showMapNorthArrow: !state.printFeatures.showMapNorthArrow
                }
            };
        case types.PRINT_TOGGLE_MAP_LOGO:
            return {
                ...state,
                printFeatures: {
                    ...state.printFeatures,
                    showMapLogo: !state.printFeatures.showMapLogo
                }
            };
        case types.RESET_PRINT_FEATURES:
            return initialState;
        default:
            return state;
    }
};

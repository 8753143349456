import React from "react";
import { Typography, makeStyles, IconButton, Grid, Tooltip } from "@material-ui/core";
import { VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    title: {
        padding: "8px 16px"
    },
    visibilityOff: {
        color: "#000",
        marginRight: 20
    }
}));

const FiltersRegion = ({ handleHideAll }) => {
    const classes = useStyles();
    const onHideAllToggle = () => {
        handleHideAll();
    };

    return (
        <Grid container className={classes.root} justify="flex-end" alignItems="center">
            <Grid item xs={10}>
                <Grid container justify="flex-start" className={classes.title}>
                    <Typography variant="h6">Layers</Typography>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <Grid container justify="flex-end">
                    <Tooltip title="Turn off all layers">
                        <IconButton className={classes.visibilityOff} onClick={onHideAllToggle}>
                            <VisibilityOff />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FiltersRegion;

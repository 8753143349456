import { isGroup } from "@emblautec/rescursive-array-extensions";
import React, { useState, useEffect, useRef } from "react";
import { List, AutoSizer } from "react-virtualized";

import Group from "../components/sidebar/layerSelector/group";
import Layer from "../components/sidebar/layerSelector/layer";

const GROUP_CARD_HEIGHT = 56;
const GROUP_HEIGHT = 24;
const GROUP_MARGIN = 16;
const LAYER_HEIGHT = 24;
const LAYER_MARGIN = 16;

const VirtualizedTree = ({ data, layerHandlers }) => {
    const list = useRef(null);

    useEffect(() => {
        list.current.recomputeRowHeights(0);
      },[data]);

    const cellRenderer = ({ index, key, parent, style }) => {
        const renderedCell = renderItem(data[index], index);

        return (
            <div key={key} style={style} className="virtual-item">
                {renderedCell}
            </div>
        );
    };

    const renderItem = (item, index) => {
        if (isGroup(item)) {
            return (
                <Group
                    group={item}
                    depth={0}
                    layerHandlers={layerHandlers}
                    key={index}
                    index={index}
                    onCollapsedToggled={onGroupCollapsedToggled}
                />
            );
        } else {
            return <Layer key={index} layer={item} depth={0} layerHandlers={layerHandlers} />;
        }
    };

    const onGroupCollapsedToggled = (index) => {
        list.current.recomputeRowHeights(index);
    };

    const rowHeight = (params) => {
        return getItemHeight(data[params.index]);
    };

    const getItemHeight = (item) => {
        if (isGroup(item)) {
            return getItemHeightRecursive(item);
        }
        return calculateItemHeight(LAYER_HEIGHT, LAYER_MARGIN, false);
    };

    const getItemHeightRecursive = (item) => {
        //Initial height for a group
        let heightObj = { height: GROUP_CARD_HEIGHT };

        let isEmpty = item.layers.length == 0;

        //if the group is open then we show an additional divider that takes up 1 px
        if (!item.options.collapsed && !isEmpty) {
            heightObj.height++;
        }

        recurseItemHeight(item, heightObj);
        return heightObj.height;
    };

    function recurseItemHeight(layerGroup, heightObj, depth = 0) {
        //stop counting if group is collapsed
        if (layerGroup.options.collapsed) {
            return;
        }

        let isNested = depth > 0;

        for (let i = 0; i < layerGroup.layers.length; i++) {
            let layer = layerGroup.layers[i];

            let isLastChild = i == layerGroup.layers.length - 1;

            if (isGroup(layer)) {
                heightObj.height += calculateItemHeight(GROUP_HEIGHT, GROUP_MARGIN, isLastChild);
                depth++;
                recurseItemHeight(layer, heightObj, depth);
            } else {
                heightObj.height += calculateItemHeight(LAYER_HEIGHT, LAYER_MARGIN, isLastChild);
            }
        }

        let isEmpty = layerGroup.layers.length == 0;

        if (!isEmpty) {
            //Nested groups have less margin
            if (isNested) {
                heightObj.height += GROUP_MARGIN;
            } else {
                heightObj.height += GROUP_HEIGHT;
            }
        }
    }

    function calculateItemHeight(height, margin, isLastChild) {
        if (isLastChild) {
            return height;
        } else {
            return height + margin;
        }
    }

    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    height={height}
                    ref={list}
                    overscanRowCount={0}
                    rowHeight={rowHeight}
                    rowRenderer={cellRenderer}
                    rowCount={data.length}
                    width={width}
                ></List>
            )}
        </AutoSizer>
    );
};

export default VirtualizedTree;

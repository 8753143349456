import React from "react";

import { connect } from "react-redux";

const UserMenu = (props) => {
    let { classes } = props;
    return <></>;
};

const mapStateToProps = (state) => {
    return {
        navigationState: state.sidebarAndDrawer.isDrawerOpen
    };
};

export default connect(mapStateToProps)(UserMenu);

import { createAsyncThunk } from "@reduxjs/toolkit";
import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export const getApp = createAsyncThunk("getApp", async (appId) => {
    return axiosClient.get("application/public/" + appId).then((res) => res.data);
});

export function getApps() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_APPS_START });
        return axiosClient
            .get("application")
            .then(function (res) {
                return dispatch({
                    type: types.FETCH_APPS_COMPLETE,
                    result: res.data.map((app) => ({
                        id: app.id,
                        name: app.name,
                        modifiedUtc: app.modifiedUtc,
                        isPublished: app.isPublished
                    }))
                });
            })
            .catch((err) => {
                dispatch({ type: types.FETCH_APPS_FAILED, result: err.message });
                return null;
            });
    };
}

export function getStyle(mapId) {
    return () => {
        return axiosClient.get(`map/${mapId}/style`).then((res) => ({
            result: res.data,
            mapId: mapId
        }));
    };
}

export function getDistinctColumnValues(datasetId, column) {
    return () => {
        return axiosClient.get(`dataset/${datasetId}/distinct?column=` + column).then(function (res) {
            return { result: res.data };
        });
    };
}

export function getDatasetColumns(datasetId) {
    return () => {
        return axiosClient.get(`dataset/${datasetId}/columns`).then(function (res) {
            return { result: res.data, id: datasetId };
        });
    };
}

export function getMinMaxColumnValues(datasetId, column) {
    return () => {
        return axiosClient.get(`dataset/${datasetId}/minmax?column=` + column).then(function (res) {
            return { result: res.data };
        });
    };
}

export const generateDocument = (data) => {
    return axiosClient.post("zander", data);
};

const blankBasemapStyle = {
    version: 8,
    sprite: "mapbox://sprites/lautec/ckk3texd9324b17qjeyovy1h1/812abu9kwt5kxuokhr7dc9ajq",
    glyphs: "mapbox://fonts/lautec/{fontstack}/{range}.pbf",
    sources: {
        composite: {
            url: "mapbox://mapbox.mapbox-streets-v8,mapbox.92olaqdt,mapbox.mapbox-terrain-v2,mapbox.9tm8dx88,mapbox.4suez6c9,mapbox.akwnx50w,mapbox.8x70v9py,mapbox.b1l3wqbs,mapbox.cc9j0p61,mapbox.d4advw8k",
            type: "vector"
        }
    },  
    layers: [
        { id: "background", type: "background", layout: {}, paint: { "background-color": "hsl(33, 28%, 93%)" } },
    ]
};

export default blankBasemapStyle;

import { createSelector } from "reselect";

const mapState = (state) => state.map;

export const getMapZoomLevel = (state) => state.map.zoom;

const sources = (state) => state.map.sources;

export const getOnClick = createSelector(mapState, (mapState) => mapState.onClick);

export const getMapLoaded = createSelector(mapState, (mapState) => mapState.loaded);

export const getLayouts = createSelector(mapState, (mapState) => mapState.layouts);

export const getLayers = createSelector(mapState, (mapState) => mapState.layers);

export const getSourceMinZoom = (sourceId) =>
    createSelector(sources, (sources) => {
        return sources.find((source) => source.id === sourceId)?.minZoom;
    });

export const getBasemapTitle = createSelector(
    (state) => state.map.basemap,
    (basemap) => basemap.title
);

export const getMapLanguage = createSelector(mapState, (mapState) => mapState.language);

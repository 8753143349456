import { createSelector } from "reselect";

const digitizeLayers = (state) => state.digitize.layers;
const layerVisibilityMap = (state) => state.layerSelector.layerVisibilityMap;

export const getAllLayersVisibilityMap = createSelector(
    layerVisibilityMap,
    digitizeLayers,
    (layerVisibilityMap, digitizeLayers) => {
        const digitizeVisibilityMap = digitizeLayers.reduce((acc, layer) => {
            acc[layer.id] = layer.selected;
            return acc;
        }, {});
        return { ...layerVisibilityMap, ...digitizeVisibilityMap };
    }
);

import { Divider, SvgIcon, Chip, Tooltip } from "@material-ui/core";
import DragIndicator from "@material-ui/icons/DragIndicator";
import { LimitsSchema } from "../../../../utils/validators/zander/zander";
import CustomTypography from "../../../common/CustomTypography/CustomTypography";
import { zanderParameterType } from "../../../../constants/zander/constants";
import LimitsCreator from "../limitsCreator/LimitsCreator";

import { useStyles } from "./styles";


const ZanderParameter = ({ parameter, onParameterChanged }) => {
    const classes = useStyles();

    const onMaxLimitsChanged = (newLimits) => onParameterChanged({ ...parameter, maxLimits: newLimits });

    const parameterSettings = zanderParameterType[parameter.type];

    return (
        <>
            <div className={classes.zanderParameter}>
                <div className={classes.dragIndicatorColumn}>
                    <DragIndicator fontSize="medium" />
                </div>
                <div className={classes.contentColumn}>
                    <div className={classes.zanderParameterHeader}>
                        <CustomTypography variant="body2" fontWeight="semibold">
                            {parameterSettings.displayName}
                        </CustomTypography>
                    </div>
                    <div className={classes.limitsContainer}>
                        
                        <LimitsCreator
                            limits={parameter.maxLimits.map((limit) => {
                                return {
                                    value: limit,
                                    error: false
                                };
                            })}
                            onLimitsChanged={onMaxLimitsChanged}
                            displayDecimals={parameter.type === zanderParameterType.WaveHeight.value}
                            text="Save"
                            validationSchema={LimitsSchema(parameter.type)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ZanderParameter;
